export class ImagenFichaje {

    //public id: number;
    public id: number;
    public es: string;

    constructor( ) {}

    getId() {
        return this.id;
    }

    setId(id: number) {
        this.id = id;
    }

    getEs() {
        return this.es;
    }

    setEs(es: string) {
        this.es = es;
    }

}
