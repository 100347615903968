import { Component, OnInit } from '@angular/core';
import { AusenciasService } from '../../services/ausencias.service';
import { EmpleadoService } from 'src/app/services/empleado.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { HorarioService } from 'src/app/services/horario.service';
import { DepartamentosService } from 'src/app/services/departamentos.service';
import { VacacionesService } from 'src/app/services/vacaciones.service';
import { Departamentos } from '../../models/departamentos';
import { Usuario } from '../../models/usuario';
import { Ausencias} from '../../models/ausencias';
import { DatePipe } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { GLOBAL } from '../../services/global.service';
import { AgmMap } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { EmpleadosAusencias } from '../../models/empleados_ausencias';


@Component({
  selector: 'app-ausencias',
  templateUrl: './ausencias.component.html',
  styleUrls: ['./ausencias.component.css' , '../../../style.scss'],
  providers : [EmpleadoService, UsuarioService, HorarioService, DepartamentosService, VacacionesService,DatePipe],
  animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          ]),
          transition(':leave', [
            style({transform: 'translateX(0)', opacity: 1}),
            animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
          ])
        ]
      )
  ]
})
export class AusenciasComponent implements OnInit {

  public mostrar_filtro: boolean;
  public filtro: any;
  public codigo_empresa: string;
  public globaladmin;
  public mostrar_modificar: boolean;

  public lista_departamentos: Array<Departamentos>;
  public lista_usuarios: Array<Usuario>;
  public fecha_actual;
  public filtroHoras: string;
  public vec_Ausencias: Array<any>;
  public ausencia : Ausencias;

  public modificarInicio_ausencias: any;
  public modificarFin_ausencias: any;
  public nombreModificar: string;
  public apellidosModificar: string;
  public borrar: number;

  public modificarObservaciones: string;
  public observaciones: string;

  public modificar: boolean;
  public modificarInicio_ausencias_ant;
  public modificarFin_ausencias_ant;

  //Variables de busqueda y parametro para la llamada desde el menu admin->avisos
  public filterCode = '';
  public filterUsuario = '';
  public filterNombre = '';
  public filterDepartamento = '';
  public filterFecha: any;
  public filterFechaHasta: any;
  public filterHoras_contrato;
  public codeModificar;
  public item;

  constructor(private _ausensciasService: AusenciasService, protected toastr: ToastrService, private _empleadoService: EmpleadoService,
    private _usuarioService: UsuarioService,
    private _departamentosService: DepartamentosService) {
      
      this.filterFecha = '';
      this.filterFechaHasta = '';
      this.filtroHoras = '';
      this.globaladmin = GLOBAL.esAdmin;
      // this.filterHoras_contrato = 0;
      this.filterHoras_contrato = '';
      this.vec_Ausencias = [];
      this.codeModificar = '';
      this.ausencia = new Ausencias(0,null,null,null,null, null);
      this.item = JSON.parse(localStorage.getItem('identity'));
  }

  ngOnInit() {
    this.getAusencias();
    this.list_usuarios();
    this.list_departamentos();
  }

  delete(id: number, code: string, inicio_ausencias: string, fin_ausencias: string){
    this._ausensciasService.deleteAusencias(id,code,inicio_ausencias,fin_ausencias).subscribe(
      response => {
        console.log(response);
        if (response['code'] == 200){
            console.log("Eliminado con exito");
            this.toastr.success('Ausencia eliminada correctamente.', 'Eliminar ausencia');
            this.controlAusencias(); // actualizo el estado de las vacaciones de los empleados
        } else {
          if (response["messageModificacion"]) {
            this.toastr.warning( response["messageModificacion"])
            return
          }
            this.toastr.error('Se ha producido un error.', 'Eliminar ausencia');
            console.log("No se ha podido eliminar ausencias");
        }
        this.vec_Ausencias = [];
        this.getAusencias();
      }, error => {
          this.toastr.error('Se ha producido un error.', 'Eliminar ausencia');
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
    );
  }

  getAusencias(){
    this._empleadoService.getEmpleadosAusencias(this.codigo_empresa).subscribe(
      response => {
          if (response['code'] == 200){
              this.vec_Ausencias = response['data'];
              console.log("Ausencias de empleados encontradas: ");
              console.log(this.vec_Ausencias);
          } else {
              console.log("Ausencias de empleados no encontradas");
          }
      }, error => {
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
  );
  }

  controlAusencias(){
    this._ausensciasService.automatic_update_ausencias(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
          console.log(response);
          if (response['code'] === 200) {
                // console.log('Se ha realizado la actualizacion automática de vacaciones');
                console.log(response['message']);
          } else {
                console.log('Error realizando la actualizacion automática de vacaciones');
          }
      }, error => {
            console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
            console.log(error as any);
      }
    );
  }

  activeFiltrar(index: number) {
    if ( index === 1){ // Muestra filtros
      this.filtro = !this.filtro;
      // this.mostrar_modificar = false;
    } else if ( index === 2) { // Muestra crear/modificar ausencia
      this.mostrar_modificar = !this.mostrar_modificar;
      // this.filtro = false;
    }
  }

  modificarAusencias(code, inicio_ausencias , fin_ausencias , nombre , apellidos, observaciones: string) {
    window.scrollTo(0, 0);
    console.log('observaciones: ' + observaciones);
    if (code != null) {
      this.mostrar_modificar = true;
      this.codeModificar = code;
      this.modificarInicio_ausencias_ant = inicio_ausencias;
      this.modificarFin_ausencias_ant = fin_ausencias;
      this.modificarInicio_ausencias = inicio_ausencias;
      this.modificarFin_ausencias = fin_ausencias;
      this.nombreModificar = nombre;
      this.apellidosModificar = apellidos;
      this.modificarObservaciones = observaciones;
      console.log(this.modificarObservaciones);
      this.modificar = true;
      if(this.modificarFin_ausencias == '9999-12-31'){
        this.modificarFin_ausencias = '';
      }
    } else {
      this.cleanForm();
      this.codeModificar = '';
      this.modificar = false;
    }
  }

  aplicarAusencias(){
    if(this.codeModificar == null || this.codeModificar == ''){
      this.toastr.error("Falta usuario", "Aplicar Ausencia");
      return;
    }else if(this.modificarInicio_ausencias == '' || this.modificarInicio_ausencias == null){
      this.toastr.error("Falta fecha de inicio", "Aplicar Ausencia");
      return;
    }else if(this.modificarFin_ausencias < this.modificarInicio_ausencias && (this.modificarFin_ausencias != null && this.modificarFin_ausencias != '')){
      this.toastr.error("Fechas incorrectas", "Aplicar Ausencia");
      return;
    }else{
      if(this.modificar){
        this.ausencia.code = this.codeModificar;
        this.ausencia.fin_ausencias = this.modificarFin_ausencias;
        this.ausencia.inicio_ausencias = this.modificarInicio_ausencias;
        this.ausencia.observaciones = this.modificarObservaciones;
        let codeupd = this.item['code'];
        this._ausensciasService.updateAusencias(this.ausencia, this.modificarInicio_ausencias_ant, this.modificarFin_ausencias_ant, codeupd).subscribe(
          response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Eliminado con exito");
                this.toastr.success('Ausencia modificada correctamente.', 'Modificar Ausencia');
                this.controlAusencias(); // actualizo el estado de las vacaciones de los empleados
            } else {
              if (response["messageModificacion"]) {
                this.toastr.warning( response["messageModificacion"])
                return
              }
                this.toastr.error('Se ha producido un error.', 'Modificar Ausencia');
                console.log("No se ha podido modificar ausencia");
            }
            this.getAusencias();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Modificar Ausencia');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
        );
      }else{
        let persona = JSON.parse(localStorage.getItem('identity'))['code'];
        this.ausencia.code = this.codeModificar;
        this.ausencia.fin_ausencias = this.modificarFin_ausencias;
        this.ausencia.inicio_ausencias = this.modificarInicio_ausencias;
        this.ausencia.observaciones = this.modificarObservaciones;
        this._ausensciasService.addAusencias(this.ausencia, persona).subscribe(
          response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Eliminado con exito");
                this.toastr.success('Ausencia añadida correctamente.', 'Añadir Ausencia');
                this.controlAusencias(); // actualizo el estado de las vacaciones de los empleados
            } else {
              if (response["messageModificacion"]) {
                this.toastr.warning( response["messageModificacion"])
                return
              }
                this.toastr.error('Se ha producido un error.', 'Añadir Ausencia');
                console.log("No se ha podido añadir ausencia");
            }
            this.getAusencias();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Añadir Ausencia');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
        );
      }
      this.cleanForm();
      this.activeFiltrar(2);
    }
  }

  cleanForm(){
    this.codeModificar = null;
    this.modificarInicio_ausencias = '';
    this.modificarFin_ausencias = '';
    this.nombreModificar = '';
    this.apellidosModificar = '';
    this.modificarObservaciones = '';
  }

  nullerValues(index: number){

    console.log("Llamando metodo nullerValues");

    if (index === 1) {
      this.filterCode = '';
    }
    else if (index === 2) {
      this.filterFecha = '';
    }
    else if (index === 3) {
      this.filterFechaHasta = '';
    }
    else if (index === 4) {
      this.filterNombre = '';
    }
    else if (index === 5) {
      this.filterDepartamento = '';
    }
    else if (index === 6) {
      // this.filterHoras_contrato = 0;
      this.filterHoras_contrato = '';
    }

  }

  changeHoras_contrato(horas){
    if(horas == null){
      this.filtroHoras = '';
    } else{
      this.filtroHoras = horas;
    }
    console.log(this.filtroHoras);
  }

  //Metodo que se trae todos los departamentos de la base de datos
  list_departamentos(){
    console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
    this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log('Departamentos conseguidos con exito')
                this.lista_departamentos = response['data'];
                console.log(this.lista_departamentos);

            } else {
                console.log("Los departamentos no se han podido conseguir");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios(){

    // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
    if ( GLOBAL.rol !== "admin" ) {

        let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
        this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
        this.lista_usuarios.push(user);

    } else {

        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];
                    console.log(this.lista_usuarios);

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

  }
}
