import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { GLOBAL } from './global.service';
import { UsuarioLogin } from 'src/app/models/usuarioLogin';
import { Empresa } from 'src/app/models/empresa';
import { Observable } from 'rxjs';
// import { userInfo } from 'os';

@Injectable()
export class EmpresaService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    getEmpresas(){
        return this._http.get(this.url+'/empresas');
    }

    getEmpresa(codigo_empresa){
        return this._http.get(this.url+'/empresa-seleccionada/'+codigo_empresa);
    }

    addEmpresa(empresa: Empresa){
        let json = JSON.stringify(empresa);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this._http.post(this.url + '/empresa-add',params, {headers});
    }

    updateEmpresa(empresa: Empresa){
        let json = JSON.stringify(empresa);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this._http.post(this.url + '/empresa-update',params, {headers});
    }

    updateOpcionSolicitudes(codigo_empresa, opcion_hacer_solicitudes){
        let json = JSON.stringify({"codigo_empresa":codigo_empresa,"opcion_hacer_solicitudes":opcion_hacer_solicitudes});
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this._http.post(this.url + '/updateOpcionSolicitudes',params, {headers});
    }

	// YA NO SE USA
    loginEmpresa(userLogin: UsuarioLogin, getToken = null): Observable<any> {
        // devuelvo datos como objeto. accedo luego como: response.status, response.token.data, ....

        if (getToken != null) {
            userLogin.getToken = 'true';
        }

        // paso parametros por post
        let json = JSON.stringify(userLogin);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/empresa-login', params, {headers: headers});

    }

	// YA NO SE USA
    loginEmpresa_OLD(correo, password) {
        // devuelvo datos como array. accedo luego como: response['status'], response['data']['codigo_empresa'];, ....

        // paso por parametros en url
        return this._http.get(this.url + '/empresa-login/' + password + '/' + correo);
    }

}