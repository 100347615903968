import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { TiposContrato } from '../models/tipos_contrato';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class TiposContratoService {
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    getTiposContrato(codigo_empresa: string, comunes: string){
        return this._http.get(this.url + '/tipos-contrato/' + comunes);
    }

}