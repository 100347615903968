import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Horario } from '../models/horario';
import { FichajeDia } from 'src/app/models/fichaje-dia';
import { GLOBAL } from './global.service';
import { Foto } from 'src/app/models/foto';
import { ImagenFichaje } from 'src/app/models/img_fichaje';

@Injectable()
export class HorarioService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addHorario(horario: Horario){
        let json = JSON.stringify(horario);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/horario', params, {headers: headers});
    }

    getHorario(code, fecha){
        return this._http.get(this.url+'/horario/'+code+'/'+fecha);
    }

    getHorariosFecha(fecha){
        return this._http.get(this.url+'/horarios-empresa/'+fecha);
    }

    getHorarios(codigo_empresa){
        return this._http.get(this.url+'/horarios');
    }

    // upDateHorarioSalida(code, hora_entrada, horario:Horario){
    insertHorarioSalida_OK(horario: Horario) {
        let json = JSON.stringify(horario);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        // return this._http.post(this.url+'/horario-update-salida/'+code+'/'+hora_entrada, params, {headers: headers});
        return this._http.post(this.url + '/horario-insert-salida-ok', params, {headers: headers});
    }

    insertHorarioSalida_INCIDENCIA(horario: Horario) {
        let json = JSON.stringify(horario);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/horario', params, {headers: headers});
    }

    getHoraActualGuion(){
        return this._http.get(this.url + '/hora-actual-guion');
    }

    getHoraActual(){
        return this._http.get(this.url + '/hora-actual');
    }

    getDiaActual(){
        return this._http.get(this.url+'/fecha-actual');
    }

    getFechayHoraActual() {
        return this._http.get(this.url+'/fecha-y-hora-actual');
    }

    getFechasInicioFinSemana(fecha) {
        return this._http.get(this.url + '/fechas-inicio-fin-semana/' + fecha);
    }   
    
    //uploadFotoFichaje(code, foto: Foto, file: string){
    uploadFotoFichaje(codigo_empresa: string, folder:string, file_name: string, foto: Foto){

        let json = JSON.stringify(foto);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        console.log('Enviando foto de fichaje...');
        console.log('empresa: ' + codigo_empresa);
        console.log('folder: ' + folder);
        console.log('file_name: ' + file_name);

        return this._http.post(this.url + '/upload-file-fichaje/' + folder + '/' + file_name, params, {headers: headers});

    }

    getIncidencias_visto(codigo_empresa){
        return this._http.get(this.url + '/horario-incidencia-visto');
    }

    updateIncidencias_visto(id, estado: boolean){
        return this._http.get(this.url + '/horario-update-incidencias_visto/' + id + '/' + estado);
    }

    updateHorasHorario(id, horario: FichajeDia){

        console.log('update horario: ' + id);
        console.log(horario);

        let json = JSON.stringify(horario);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/horario-update-horas/' + id, params, {headers: headers});
    }

    getHorariosEmpleado(code, codigo_empresa) {
        return this._http.get(this.url + '/horarios-empleado/' + code);
    }

    deleteHorario_id(id) {
        return this._http.get(this.url + '/horario-delete-id/' + id);
    }

    deleteFoto(idHorario, es, codigo_empresa, foto) {
        return this._http.get(this.url + '/horario-delete-foto/' + idHorario + '/' + es + '/' + foto);
    }

    deleteLocalizacion(idHorario, es) {
        return this._http.get(this.url + '/horario-delete-localizacion/' + idHorario + '/' + es);
    }

    getFotoFichaje(id, es) {
        // console.log('Cargar foto horario: ' + id + '. ' + es);
        // return this._http.get(this.url + '/get-foto-fichaje/' + id + '/' + es);

        let fotoFichaje = new ImagenFichaje();
        fotoFichaje.id = id;
        fotoFichaje.es = es;

        let json = JSON.stringify(fotoFichaje);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/foto-fichaje' , params, {headers: headers});

    }

}
