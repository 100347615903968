import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global.service';
import { Observable } from 'rxjs';
import { Routes, RouterModule } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public url: string;
  private _token: string = null;
  private _identity: string = null;

  constructor( public _http: HttpClient, protected _router: Router, protected _route: ActivatedRoute ) {
    this.url = GLOBAL.url;
  }

  getToken(): string {
    this._token = this.getStorageToken();
    return this._token;
  }

  setToken(value: string) {
    this._token = value;
    localStorage.setItem('token', this._token);
  }

  getIdentity(): string {
    this._identity = this.getStorageIdentity();
    return this._identity;
  }

  setIdentity(value: string) {
    this._identity = value;
    localStorage.setItem('identity', this._identity);
  }

  getStorageToken() {
    let token = localStorage.getItem('token');
    if ( token && token !== 'undefined') {
        token = token;
    } else {
        token = null;
    }
    return token;
  }

  getStorageIdentity() {
    let identity = JSON.parse(localStorage.getItem('identity'));
    if ( identity && identity !== 'undefined') {
        identity = identity;
    } else {
        identity = null;
    }
    return identity;
  }

  checkToken(): Observable<any> {

    // paso parametros por post
    // let json = JSON.stringify(userLogin);
    let json = {};
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this._http.post(this.url + '/check-token', params, {headers: headers});
  }

  logout() {
    console.log('Cerrando sesion desde AuthService....');
    this._router.navigate(['/logout/1']);
  }

}
