import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { CalendariosTrabajo } from '../models/calendarios_trabajo';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class CalendariosTrabajoService {
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    getCalendariosTrabajo(codigo_empresa: string){
        return this._http.get(this.url + '/calendarios-trabajo');
    }

    addCalendariosTrabajo(calendario_trabajo: CalendariosTrabajo) {
        // return this._http.get(this.url + '/calendarios-trabajo-add/' + codigo_empresa );

        // paso parametros por post
        let json = JSON.stringify(calendario_trabajo);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/calendarios-trabajo-add', params, {headers: headers});

    }

    updateCalendariosTrabajo(calendario_trabajo: CalendariosTrabajo) {

        // paso parametros por post
        let json = JSON.stringify(calendario_trabajo);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/calendarios-trabajo-update', params, {headers: headers});

    }

    deleteCalendariosTrabajo(calendario_trabajo_id) {

        return this._http.get(this.url + '/calendarios-trabajo-delete/' + calendario_trabajo_id );

    }

}
