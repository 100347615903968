import { Component } from '@angular/core';
import { Departamentos } from 'src/app/models/departamentos';
import { DepartamentosService } from '../../services/departamentos.service';
import { GLOBAL } from '../../services/global.service';
import { EmpleadoService } from '../../services/empleado.service';
import { Empleado } from 'src/app/models/empleado';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector : 'departamento',
    templateUrl : './departamento.component.html',
    styleUrls : ['./departamento.component.css', '../../../style.scss'],
    providers: [ DepartamentosService , EmpleadoService ]
})

export class DepartamentoComponent {
    public titulo: string;
    public del: number;
    public codigo_empresa: string;
    public departamentos: Array<Departamentos>;
    public departamento: Departamentos;
    public empleados: Array<Empleado>;
    public tablaEmpleados: boolean;
    public crearDepartamento: boolean;
    public cambiarDepartamento: boolean;
    public departamento_seleccionado: string;
    public id_departamento_seleccionado: number;
    public nombreActualizado: string;
    public placeholder: string;
    public nombre:string;

    constructor(
        private _route: ActivatedRoute,
        private _departamentosService: DepartamentosService,
        private _empleadoService: EmpleadoService,
        protected toastr: ToastrService
    ) {
        this.titulo = "Página departamento";
        this.del = 0;
        this.departamentos = [];
        this.departamento = new Departamentos(0, null, null);
        this.tablaEmpleados = false;
        this.crearDepartamento = false;
        this.cambiarDepartamento = false;
        this.departamento_seleccionado = '';
        this.nombreActualizado = '';
        this.id_departamento_seleccionado = 0;
        this.placeholder = 'Nombre del departamento';
    }

    ngOnInit(){
        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });
        this.getDepartamentos();
    }

    borrar(id) {
        this.del = id;
    }

    editar(id, departamento) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }
        // TODO: editar departamento
        this.crearDepartamento = true;
        this.id_departamento_seleccionado = id;
        if(id != 0){
            this.departamento.nombre_departamento = departamento;
        }else{
            this.placeholder = "Nombre del departamento";
        }
    }

    eliminar(id) {
        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }
        // TODO: eliminar departamento
        this.id_departamento_seleccionado = id;
        this.del = 0;
        this._departamentosService.deleteDepartamento(this.id_departamento_seleccionado).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Departamento "+this.departamento_seleccionado+" borrado");
                    // this.departamentos = response['data'];
                    this.toastr.success("Departamento eliminado","Eliminar departamento");
                    this.getDepartamentos();
                } else {
                    if(response['code'] == 1001){
                        this.toastr.error("No se ha podido eliminar: posiblemente esté asignado","Eliminar departamento");
                    }else{
                        console.log("Departamento no encontrado");
                    }
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    getDepartamentos(){

        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Departamentos encontrados");
                    this.departamentos = response['data'];
                    console.log(this.departamentos);
                } else {
                    console.log("Departamentos no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );        
    }

    getEmpleados(departamento){
        this.departamento_seleccionado = departamento;
        this._empleadoService.getEmpleadosDepartamento( departamento , this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Empleados encontrados: ");
                    this.empleados = response['data'];
                    console.log(this.empleados);
                } else {
                    console.log("Empleados no encontrados");
                    this.empleados = [];
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
        
        
    }

    cambiarTabla(estado: boolean){
        this.tablaEmpleados = estado;
    }
    cambiarCreaDepartamento(estado: boolean){
        this.crearDepartamento = estado;
        this.departamento_seleccionado = '';
        this.departamento.nombre_departamento = '';
        this.id_departamento_seleccionado = 0;
    }

    addDepartamento() {
        if(this.id_departamento_seleccionado != 0 ){
            let i = 0;
            for(i = 0; i < this.departamentos.length; i++){
                if(this.departamentos[i]['nombre_departamento'] == this.departamento.nombre_departamento){
                    this.toastr.error("Departamento ya existente", "Actualizar departamento");
                    return;
                }
            }
            if(this.departamento.nombre_departamento.replace(/\s+/g, '') == ''){
                this.toastr.error("Nombre incompleto", "Actualizar departamento");
                return;
            }
            this.departamento.id = this.id_departamento_seleccionado;
            this.departamento.nombre_departamento = this.departamento.nombre_departamento;
            this._departamentosService.updateDepartamento(this.departamento).subscribe(
                response => {
                    if (response['code'] == 200){
                        console.log("Departamento modificado");
                        this.toastr.success("Departamento actualizado","Actualizar Departamento");
                        this.cambiarCreaDepartamento(false);
                        this.getDepartamentos();
                    } else {
                        console.log("Departamento no modificado");
                        this.cambiarCreaDepartamento(false);
                    }
                },
                error => {
                    console.log('Ha sucedido un error al modificar el departamento');
                    this.toastr.error("Error al actualizar","Actualizar Departamento");
                    console.log(<any>error);
                }
            );
        } else {
            let x = 0;
            for(x = 0; x < this.departamentos.length; x++){
                if(this.departamentos[x]['nombre_departamento'] == this.departamento.nombre_departamento){
                    this.toastr.error("Departamento ya existente", "Crear departamento");
                    return;
                }
            }
            if(this.departamento.nombre_departamento.replace(/\s+/g, '') == ''){
                this.toastr.error("Nombre incompleto", "Crear departamento");
                return;
            }
            this.placeholder = "Nombre del departamento";
            if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }
            this.departamento.codigo_empresa = this.codigo_empresa;
            this._departamentosService.addDepartamento(this.departamento).subscribe(
                response => {
                    if (response['code'] == 200){
                        console.log("Departamento creado","Crear departamento");
                        console.log(response['message']);
                        this.toastr.success("Departamento creado","Crear Departamento");
                        this.cambiarCreaDepartamento(false);
                        this.getDepartamentos();
                    } else {
                        console.log("Departamento no creado");
                        this.toastr.error("Departamento no creado","Crear Departamento");
                        this.cambiarCreaDepartamento(false);
                    }
                },
                error => {
                    console.log('Ha sucedido un error al crear el departamento');
                    console.log(<any>error);
                }
            );
        }
        this.placeholder = 'Nombre del departamento';
        this.id_departamento_seleccionado = 0;
        this.departamento.nombre_departamento = ' ';
        this.getDepartamentos();
    }
}
