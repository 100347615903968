import { Component } from '@angular/core';
import { Empleado } from 'src/app/models/empleado';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { HorarioService } from '../../services/horario.service';
import { ConfigService } from '../../services/config.service';
import { CalculoIncidenciasService } from '../../services/calculo_incidencias.service';
import { CalculoIncidencias } from '../../models/calculo_incidencias';
import { Horario } from 'src/app/models/horario';
import { Foto } from 'src/app/models/foto';
import { Observable, Subject } from 'rxjs';
import { GLOBAL } from '../../services/global.service';
import { Incidencia } from '../../models/incidencias';
import { IncidenciasService } from '../../services/incidencias.service';
import { Location } from '@angular/common';

// Base de datos interna
import { NgxIndexedDB } from 'ngx-indexed-db';

// Cámara
// https://www.npmjs.com/package/ngx-webcam
// Chrome >53, Safari >11, Firefox >38, Edge
import { WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { map } from 'rxjs/operators';

@Component({
    selector : 'entrada',
    templateUrl : './entrada.component.html',
    styleUrls : ['./entrada.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, HorarioService ]
})

export class EntradaComponent {
    public codigo_empresa: string;
    public titulo: string;
    public texto: string;
    public empleado: Empleado;
    public horarios: Array<Horario>;
    public horario: Horario;
    public horarios_empleado: Array<Horario>;
    public ultima_hora_entrada: any;
    public ultima_hora_salida: any;
    public codigo_empleado: string;
    public passwd_empleado: string;
    public fecha: Date;
    public hora_actual;
    public fecha_actual;
    public encontrado: boolean;
    public entrada: boolean = false; // Variable que indica al sistema que la entrada esta activada
    public incidencia: boolean;
    public incidencia_olvido: boolean;
    public tipo_incidencia: string;
    public foto: Foto;
    public consultaHorario: boolean;
    public calculo_incidencia: CalculoIncidencias;
    public suma_total_horas: number;
    public suma_total_horas_prevista: number;
    public lista_incidencias: Array<Incidencia>;

    //config_empresa_enable_camera: boolean = false;
    //config_empresa_enable_localizacion: boolean = false;

    // public horaentrada: any;
    // public dia: Date;
    // public diaHora: any;

    // Variable Base de datos interna
    public bd: NgxIndexedDB;

    // Variables eolocalizacion
    public latitud: number;
    public longitud: number;
    public geoLocationSupported: boolean;
    public empresa_enable_localizacion: boolean = false;
    public usuario_enable_localizacion: boolean = false;
    public enable_localizacion: boolean = false;

    // Cámara
    public seconds: number ;
    private trigger: Subject<void> = new Subject<void>();
    public webcamImage: WebcamImage = null;
    public camVisible: boolean;
    public empresa_enable_camara: boolean = false;
    public usuario_enable_camara: boolean = false;
    public enable_camara: boolean = false;

    public deBaja: boolean = false;
    public deVacaciones: boolean = false;
    public deAusencias: boolean = false;

    constructor(
        private _horarioService: HorarioService,
        private _configService: ConfigService,
        private _incidenciaService: IncidenciasService,
        private _calculoIncidenciasService: CalculoIncidenciasService,
        private _empleadoService: EmpleadoService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _location: Location
        ) {
        this.titulo = 'Entrada';
        this.texto = 'Entrada';
        this.horarios = [];
        this.horario = new Horario(0, '', '', null, null, null, null, 0, null, true, null, null, null, false, 0, 0, 0, 0, null, null);
        this.calculo_incidencia = new CalculoIncidencias(0, null, null, null, false, null);
        this.fecha = new Date(); // esta fecha solo la usamos para mostrar en pantalla
        this.entrada = true;
        //this.tipo_incidencia = 'Sin incidencia';
        this.tipo_incidencia = null;
        this.incidencia = false;
        this.incidencia_olvido = false;
        this.encontrado = true;
        this.foto = new Foto(null);
        this.latitud = 0;
        this.longitud = 0;
        this.consultaHorario = false;
        this.horarios_empleado = [];
        this.bd = new NgxIndexedDB('BDInternaEntrada' , 1);
        // this.dia = new Date() ;
        this.camVisible = false;
        this.geoLocationSupported = true;
        this.lista_incidencias = null;
        this.suma_total_horas_prevista = 0;
    }

    ngOnInit() {

        this.codigo_empresa = GLOBAL.codigo_empresa;

        this.abrirBD();

        this.list_incidencias();

        // ahora ya no pedimos login:
        this.getEmpleado();
    }

    backClicked() {
        this._location.back();
    }

    // Metodos para la cámara
    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public handleInitErrorCamera(error: WebcamInitError): void {
        // if the user denies permission -> NotAllowedError / PermissionDeniedError
        // matching media is not available ->  NotFoundError
        this.camVisible = false;
        console.log('Error de cámara: ');
        console.log(error);
        if (error.mediaStreamError && (error.mediaStreamError.name === "NotAllowedError" || error.mediaStreamError.name === "PermissionDeniedError")) {
            console.warn("Camera access was not allowed by user!");
            this.camVisible = false;
        }
        if (error.mediaStreamError && error.mediaStreamError.name === "NotFoundError") {
            console.warn("Camera access not possible !");
            this.camVisible = false;
        }
    }

    delBD() {
        this.bd.deleteDatabase().then(() => {
            console.log('Database deleted successfully');
          },
          error => {
            console.log(error);
          }
        );
    }

    //Metodo que se trae todos los tipos de incidencias de la base de datos
    list_incidencias(){
        console.log('Buscar tipos de incidencias de la empresa: ' + this.codigo_empresa);
        this._incidenciaService.getIncidencias(this.codigo_empresa, '1').subscribe( // 1: incluye las generales, no solo las de la empresa
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Tipos de incidencias conseguidos con exito')
                    this.lista_incidencias = response['data'];
                    console.log(this.lista_incidencias);

                } else {
                    console.log("Los tipos de incidencias no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // antes, para login antes de fichar
    getEmpleadoFichar() {

        let code = this.codigo_empleado;

        this._empleadoService.getEmpleadoFichar(code, this.passwd_empleado).subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Empleado encontrado');
                    this.empleado = response['data'];
                    this.encontrado = true;
                    this.getLocation();
                    this.fechaActual(); // recoje la fecha y hora -> this.hora_actual , this.fecha_actual
                    this.getFechayHoraActual(); // -> this.fecha
                } else {
                    console.log('No se ha encontrado el empleado');
                    this.mostarMsgErrAccesoRegistro();
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    // ahora, entramos directamente a fichar sin login
    getEmpleado() {
        this.codigo_empleado = GLOBAL.code;
        let codigo_empresa = GLOBAL.codigo_empresa;

        this._empleadoService.getEmpleado(this.codigo_empleado, codigo_empresa).subscribe(
            response => {
                console.log(response);
                if (response['code'] === 200) {
                    this.empleado = response['data'];
                    this.encontrado = true;
                    console.log('Empleado encontrado: ');
                    console.log(this.empleado);
                    this.fechaActual(); // recoje la fecha y hora -> this.hora_actual , this.fecha_actual
                    this.getFechayHoraActual(); // -> this.fecha
                    this.checkActivacionCamaraLocalizacion(); // aqui porque ya tenemos el empleado cargado, que nos hace falta

                    // GLOBAL.deBaja =  this.empleado.baja ? true : false; // FALLA cuando es 0, tambien lo pasa a true ¿¿¿???
                    GLOBAL.deBaja =  this.getBoolean(this.empleado.baja);
                    GLOBAL.deVacaciones =  this.getBoolean(this.empleado.vacaciones);
                    GLOBAL.deAusencias =  this.getBoolean(this.empleado.ausencia);
                    this.deBaja = GLOBAL.deBaja;
                    this.deVacaciones = GLOBAL.deVacaciones;
                    this.deAusencias = GLOBAL.deAusencias;

                    // console.log('De baja:' + this.empleado.baja + ' -> ' + GLOBAL.deBaja );
                    // console.log('De vacaciones:' + this.empleado.vacaciones + ' -> ' + GLOBAL.deVacaciones );
                    // console.log('De ausencia:' + this.empleado.ausencia + ' -> ' + GLOBAL.deAusencias );

                } else {
                    console.log('No se ha encontrado el empleado');
                    // this.mostarMsgErrAccesoRegistro();
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    getBoolean(value) {
        switch (value) {
             case true:
             case "true":
             case 1:
             case "1":
             case "on":
             case "yes":
                 return true;
             default:
                 return false;
        }
    }

    checkActivacionCamaraLocalizacion() {
        this.empresa_enable_camara = GLOBAL.config_empresa_enable_camera[0] === '1' ? true : false;
        this.empresa_enable_localizacion = GLOBAL.config_empresa_enable_localizacion[0] === '1' ? true : false;
        console.log('empresa_enable_camara: ' + this.empresa_enable_camara);
        console.log('empresa_enable_localizacion: ' + this.empresa_enable_localizacion);
        this.usuario_enable_camara = this.empleado.camara;
        this.usuario_enable_localizacion = this.empleado.localizacion;
        console.log('usuario_enable_camara: ' + this.usuario_enable_camara);
        console.log('usuario_enable_localizacion: ' + this.usuario_enable_localizacion);
        this.enable_camara = this.empresa_enable_camara && this.usuario_enable_camara;
        this.enable_localizacion = this.empresa_enable_localizacion && this.usuario_enable_localizacion;
        console.log('activar camara: ' + this.enable_camara);
        console.log('activar localizacion: ' + this.enable_localizacion);

        if (+this.enable_camara == 1) { // es raro, pero asi es como funciona con las 4 combinaciones posibles
            this.camVisible = true;
        } else {
            this.camVisible = false;
        }

        this.getLocation();

    }

    mostarMsgErrAccesoRegistro() {
        this.encontrado = false; // mostramos mensaje de error en rojo
        setTimeout(() => {
          // 2 segundos después...
          this.encontrado = true; // ocultamos mensaje de error en rojo
        }, 2000);
    }

    // Metodo que guarda el horario de entrada
    saveHorario() {
        // Se trae el horario
        this.getHorario();
    }

    getHorario() {
        console.log('Consultando registros del empleado:');

        // obtenemos los fichajes del dia actual
        this._horarioService.getHorario(this.codigo_empleado, this.fecha_actual).subscribe(
            response1 => {
                console.log('Registros encontrados:');
                console.log(response1);
                if (response1['code'] === 200) {
                    this.horarios = response1['data'];

                    if ( this.horarios.length === 0 ) {
                        // es el primer fichaje del dia

                        // ¿ entró a trabajar el día anterior ?
                        // TODO: comprobar (ver como hacerlo) si entró el dia anterior o check campo en empleado 'horario_noche' (crear este campo)                        
                        // contemplamos también los fichajes del dia anterior y decidimos segun los registros q obtengamos
                        // this._horarioService.getHorariosEmpleado(this.codigo_empleado, this.empleado.codigo_empresa,).subscribe(

                        // IF SI entró el día anterior

                        // IF NO entró el dia anterior
                        // recupera la hora actual del sistema y guarda el registro en la tabla horario
                        this.incidencia_olvido = false;
                        this.getHoraActualandSave();

                    } else {
                        // existen fichajes

                        console.log('Se han encontrado registros hoy para este empleado');
                        this.ultima_hora_entrada = this.horarios[this.horarios.length - 1].hora_entrada;
                        this.ultima_hora_salida = this.horarios[this.horarios.length - 1].hora_salida;
                        // this.idHorario = this.horario[this.horarios.length - 1].id;
                        console.log('Ultimo registro encontrado:');
                        // console.log('Id: ');
                        // console.log(this.idHorario);
                        console.log('Hora entrada: ');
                        console.log(this.ultima_hora_entrada);
                        console.log('Hora salida: ');
                        console.log(this.ultima_hora_salida);

                        if (this.ultima_hora_salida != null) {
                            // correcto, el ultimo fichaje es de salida
                            // recupera la hora actual del sistema y guarda el registro en la tabla horario
                            this.incidencia_olvido = false;
                            this.getHoraActualandSave();

                        } else if (this.ultima_hora_entrada != null && this.ultima_hora_salida == null) {
                            // fichajes de entrada seguidos
                            this.incidencia_olvido = true;
                            this.registrar_olvido_registro_salida();
                        }

                    }

                } else {

                }
            }, error => {
                console.log('Error recuperando los registros del empleado:');
                console.log(error as any);
            }
        );

    }


    getHorario_OLD() {

        this.componerHorario();

        console.log('Añadir Horario: ');
        console.log(this.horario);
        // Inserta el horario del empleado en la base de datos
        this._horarioService.addHorario(this.horario).subscribe(
            response => {
                console.log(response);
                if (response['code'] === 200) {
                    console.log('Horario añadido a la BD');
                    this._router.navigate(['/correct']);
                    // Guarda la foto
                    this.saveFoto();
                } else {
                    console.log('error al añadir Horario a la BD');
                    // Llamar al metodo para guardar en la base de datos interna
                    this.add_a_BD();
                    this.leerBD();
                    this._router.navigate(['/correct']);
                }
            },
            error => {
                // Llamar al metodo para guardar en la base de datos interna
                this.add_a_BD();
                this.leerBD();
                this._router.navigate(['/correct']);
            }
        );

        this.updateEstadoWorking();

    }

    insertarEntrada() {

        console.log('Añadir Horario: ');
        console.log(this.horario);
        
        // Inserta el horario del empleado en la base de datos
        this._horarioService.addHorario(this.horario).subscribe(
            response => {
                console.log(response);
                if (response['code'] === 200) {
                    console.log('Horario añadido a la BD');
                    if (this.incidencia_olvido) {
                        this._router.navigate(['/correct/Entrada registrada/OLVIDÓ REGISTRAR LA SALIDA ANTERIOR']);
                    } else {
                        this._router.navigate(['/correct/Entrada registrada/null']);
                    }
                } else {
                    console.log('Error: Horario NO añadido a la BD');
                    this.add_a_BD();
                    this.leerBD();
                    this._router.navigate(['/correct/null/ERROR: Entrada NO registrada']);
                }
            },
            error => {
                console.log('Error: Horario NO añadido a la BD');
                this.add_a_BD();
                this.leerBD();
                this._router.navigate(['/correct/null/ERROR: Entrada NO registrada']);
            }
        );

    }

    registrar_olvido_registro_salida() {

        console.log('Registrando fichaje de ENTRADA SIN SALIDA');

        // guarda  aviso en tabla calculo_incidencias:
        this.olvido_fichar_en_calculo_incidencias();

        // recupera la hora actual del sistema y guarda el registro en la tabla horario
        this.getHoraActualandSave();

    }

    olvido_fichar_en_calculo_incidencias() {

        // Componer incidencia
        this.calculo_incidencia.codigo_empresa = this.empleado.codigo_empresa;
        this.calculo_incidencia.fecha = this.fecha_actual;
        this.calculo_incidencia.tipo_incidencias = 'olvido_fichar';
        this.calculo_incidencia.visto = true;
        this.calculo_incidencia.code = this.empleado.code;


        // Inserta la incidencia de tipo olvido_fichar
        this._calculoIncidenciasService.addCalculo_incidencias(this.calculo_incidencia).subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Calculo incidencia insertada con exito');
                } else {
                    console.log('Calculo incidencia no se ha podido insertar con exito');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );

    }

    getHoraActualandSave() {
        this._horarioService.getHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Hora actual recuperada: ' + response['data']);
                    this.hora_actual = response['data'];

                    // Estado work trabajador a true
                    this.updateEstadoWorking();

                    // componemos el calendario
                    this.componerHorario();

                    // Insertando el horario en la base de datos
                    this.insertarEntrada();

                    // Guarda la foto en el directorio
                    this.saveFoto();

                } else {
                    console.log('No se ha podido conseguir la hora actual');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    add_a_BD() {
        /* TOD: check si lo hace bien y no da error */
        /*
        this.addElemetosBD(
            this.horario.code,
            this.horario.codigo_empresa,
            this.horario.foto,
            this.hora_actual,
            this.horario.fecha,
            this.horario.estado,
            this.horario.incidencias,
            this.horario.incidencias_visto,
            this.horario.latitud_entrada,
            this.horario.longitud_entrada
        );
        */
    }

    // Metodo que rellena los datos del objeto horario que se va a insertar
    componerHorario() {
        this.horario.hora_entrada = this.hora_actual;
        this.horario.fecha = this.fecha_actual;
        this.horario.id = null;
        this.horario.code = this.empleado.code;
        this.horario.codigo_empresa = this.empleado.codigo_empresa;
        if (this.webcamImage != null) {
            this.horario.foto = this.fecha_actual.replace(new RegExp('-', 'g'), '') + '-' + this.hora_actual.replace(new RegExp(':', 'g'), '')  + '-ENT-' + this.codigo_empleado + '.jpg'; // quitar ':' de la hora, los ficheros no lo admiten en su nombre            
        }
        this.horario.estado = true;
        this.horario.incidencias = this.tipo_incidencia;
        this.horario.latitud_entrada = this.latitud;
        this.horario.longitud_entrada = this.longitud;
    }

    getFechayHoraActual() {

        this._horarioService.getFechayHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('fecha y hora actual recuperada: ' + response['data']);
                    this.fecha = response['data'];

                    /* para compatibilidad con safari */
                    let fFecha: Date;
                    // asi suma una hora ¿¿¿????
                    // fFecha = new Date(String(this.fecha).replace(/\s/, 'T')); // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
                    let aFecha = String(this.fecha).split(/[^0-9]/);
                    fFecha = new Date (+aFecha[0], +aFecha[1] - 1, +aFecha[2], +aFecha[3], +aFecha[4], +aFecha[5] );

                    this.fecha = fFecha;



                } else {
                    console.log('No se ha podido conseguir la fecha y hora actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    ponerIncidencia() {
        this.incidencia = true;
        this.horario.incidencias_visto = true;
        console.log('Activada incidencia de empleado');
    }

    quitarIncidencia() {
        this.incidencia = false;
        this.horario.incidencias_visto = false;
        // this.tipo_incidencia = 'Sin incidencia';
        this.tipo_incidencia = null;
        console.log('Desactivada incidencia de empleado');
    }

    tipoIncidencia(tipo: string) {
        this.tipo_incidencia = tipo;
        this.incidencia = false;
    }

    // Metodo que actualiza en la base de datos el campo work_now
    // Este campo indica si el trabajador esta trabajando en ese momento
    updateEstadoWorking() {

        this._empleadoService.updateEstadoWorking(this.empleado.code, true).subscribe(
            response => {

                if (response['code'] === 200) {
                    console.log('Campo work_now actualizado con exito');
                } else {
                    console.log('Campo work_now no se ha podido actualizar');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    fechaActual(): boolean {

        this._horarioService.getHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                   this.hora_actual = response['data'];
                   console.log('Hora actual recuperada: ' + response['data']);
                } else {
                   console.log('No se ha podido conseguir la hora actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    this.fecha_actual = response['data'];
                    console.log('Fecha actual recuperada: ' + response['data']);
                } else {
                    console.log('No se ha podido conseguir la fecha actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );
        return true;
    }

    saveFoto() {
        if (this.webcamImage == null) {
            console.log('No hay foto que subir');
            return;
        }
        let folder = GLOBAL.url_fotos_fichajes;
        this.foto.bytes = this.webcamImage.imageAsBase64;
        this._horarioService.uploadFotoFichaje(GLOBAL.codigo_empresa, folder, this.horario.foto, this.foto).subscribe( 
            response => {
                if (response['code'] === 200) {
                    console.log('Foto insertada');
                } else {
                    console.log('La foto no se ha podido alamcenar');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    getHorariosEmpleado() {
        // cargamos los registros del empleado del dia actual
        this.suma_total_horas = 0;

        this._horarioService.getHorariosEmpleado(this.empleado.code, this.empleado.codigo_empresa).subscribe(
            response => {

                if (response['code'] === 200) {
                    this.horarios_empleado = response['data'];
                    // calculamos el total de horas del dia
                    for(let j=0;j<this.horarios_empleado.length;j++) {
                        this.suma_total_horas += +this.horarios_empleado[j].total_horas;
                    }
                    this.suma_total_horas = this.Round(this.suma_total_horas, 2);
                    this.suma_total_horas_prevista = this.suma_total_horas;

                    console.log('Horarios del empleado encontrados');
                } else {
                    console.log('Los horarios del empleado no se han podido conseguir');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    Round (numero, decimales = 2, usarComa = false): number {
        var opciones = {
            maximumFractionDigits: decimales,
            useGrouping: false
        };
        return +new Intl.NumberFormat((usarComa ? "es" : "en"), opciones).format(numero);
    }

    // Metodo para geolocalización
    getLocation() {

        if (+this.enable_localizacion == 1) {
        } else {
            console.log('Posicionamiento no buscado');
            this.geoLocationSupported = false; // para ocultar el icono que nos dice si se va a guardar o no
            return;
        }

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position: Position) => {
            if (position) {

                this.geoLocationSupported = true;
                console.log('Posicionamiento encontrado');

                if (+this.enable_localizacion == 1) { // es raro, pero asi es como funciona con las 4 combinaciones posibles
                    this.latitud = position.coords.latitude;
                    this.longitud = position.coords.longitude;
                    console.log('Posicionamiento asignado');
                } else {
                    console.log('Posicionamiento no asignado');
                    this.geoLocationSupported = false; // para ocultar el icono que nos dice si se va a guardar o no
                }

            }
          },
            (error: PositionError) => {
                this.geoLocationSupported = false;
                console.log('Posicionamiento no encontrado');
                console.log(error);
                /*
                switch(error.code) {
                    case error.PERMISSION_DENIED:
                      // "User denied the request for Geolocation."
                      break;
                    case error.POSITION_UNAVAILABLE:
                      // "Location information is unavailable."
                      break;
                    case error.TIMEOUT:
                      // "The request to get user location timed out."
                      break;
                    default:
                      // "An unknown error occurred."
                      break;
                }
                */
            });
        } else {
            this.geoLocationSupported = false;
            console.log('Geolocation is not supported by this browser');
            // alert('Geolocation is not supported by this browser.');
        }
    }

    // La variable consultaHorario es la que hace que se vea o no el Bloque div
    muestraHorario(empleado, consultaHorario) {
        this.consultaHorario = consultaHorario;
    }

    // Metodos para la base de datos interna

    // Crear la base de datos
    abrirBD() {
        console.log('Abriendo BD INTERNA Entrada');
        this.bd.openDatabase(1 , evt => {

            let objectStore = evt.currentTarget.result.createObjectStore('Entrada', { keyPath: 'id', autoIncrement: true });

            objectStore.createIndex('code', 'code', { unique: false });
            objectStore.createIndex('codigo_empresa', 'ecodigo_empresamail', { unique: false });
            objectStore.createIndex('foto', 'foto', { unique: false });
            objectStore.createIndex('hora_entrada', 'fohora_entradato', { unique: false });
            objectStore.createIndex('fecha', 'fecha', { unique: false });
            objectStore.createIndex('estado', 'estado', { unique: false });
            objectStore.createIndex('incidencias', 'incidencias', { unique: false });
            objectStore.createIndex('incidencias_visto', 'incidencias_visto', { unique: false });
            objectStore.createIndex('latitud_entrada', 'latitud_entrada', { unique: false });
            objectStore.createIndex('longitud_entrada', 'longitud_entrada', { unique: false });
            console.log('Abierta BD INTERNA Entrada');
        });
    }

    // Añadir elementos a la base de datos
    addElemetosBD(
        code,
        codigo_empresa,
        foto,
        hora_entrada,
        fecha,
        estado,
        incidencias,
        incidencias_visto,
        latitud_entrada,
        longitud_entrada
    ) {
        this.abrirBD();
        console.log('Añadiendo a la BD interna de Entrada:');
        this.bd.add('Entrada', {
            code : code,
            codigo_empresa : codigo_empresa,
            foto : foto,
            hora_entrada : hora_entrada,
            fecha : fecha,
            estado : estado,
            incidencias : incidencias,
            incidencias_visto : incidencias_visto,
            latitud_entrada : latitud_entrada,
            longitud_entrada : longitud_entrada
        }).then( () => {
            console.log('Añadido con exito a la BD interna ENtrada');
        },
        error => {
            console.log('Error addElemetosBD: ', error);
        });
    }

    // Leer base de datos
    leerBD() {
        this.abrirBD();
        this.bd.getAll('Entrada').then(
          response => {
            console.log('Base de datos interna Entrada leida');
          },
          error => {
            console.log('Error al leer la BD Entrada: ' , error);
          }
        );
    }

}
