import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Incidencia } from '../models/incidencias';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService {
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    getIncidencias(codigo_empresa: string, comunes: string){
        return this._http.get(this.url + '/incidencias/' + comunes);
    }

}
