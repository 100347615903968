import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global.service';
import { CalculoIncidencias } from '../models/calculo_incidencias';

@Injectable()
export class CalculoIncidenciasService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addCalculo_incidencias(incidencia: CalculoIncidencias){
        let json = JSON.stringify(incidencia);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/calculo_incidencias', params, {headers: headers});
    }

    getFecha_Calculo_incidencias(codigo_empresa, tipo){
        return this._http.get(this.url+'/calculo_incidencias-fecha/'+tipo);
    }

    updateFecha_Calculo_incidencias(codigo_empresa, fecha){
        return this._http.get(this.url+'/calculo_incidencias-fecha-update/'+fecha);
    }

    aumentarFecha(fecha){
        return this._http.get(this.url+'/sumar-fecha/'+fecha);
    }

    updateVisto_Calculo_incidencias(codigo_empresa, estado, tipo){
        return this._http.get(this.url+'/calculo_incidencias-visto-update/'+estado+'/'+tipo);
    }

    updateCode_Calculo_incidencias(codigo_empresa, code){
        return this._http.get(this.url+'/calculo_incidencias-code-update/'+code);
    }

    getVisto_Calculo_incidencias(codigo_empresa, tipo){
        return this._http.get(this.url+'/calculo_incidencias-visto/'+tipo);
    }

    getVisto_Calculo_incidencias_olvido(codigo_empresa){ // OLD
        return this._http.get(this.url+'/calculo_incidencias-visto-olvido');
    }

    getHorario_Olvido_Fichar(codigo_empresa){ // NUevo metodo para incidencias olvido fichar (no usa tabla calculo_incidencias)
        return this._http.get(this.url + '/horario-olvido-fichar');
    }

    getCalculo_incidencia(code, codigo_empresa){
        return this._http.get(this.url+'/calculo_incidencia/'+code);
    }

}