export class Ausencias{

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public inicio_ausencias: Date;
    public fin_ausencias: Date;
    public observaciones: string;

    constructor(id: number, code: string, codigo_empresa: string, inicio_ausencias: Date, fin_ausencias: Date, observaciones){
        this.id = id;
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.inicio_ausencias = inicio_ausencias
        this.fin_ausencias = fin_ausencias;
        this.observaciones = observaciones;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getInicio_ausencias(){
        return this.inicio_ausencias;
    }

    setInicio_ausencias(inicio_ausencias: Date){
        this.inicio_ausencias = inicio_ausencias;
    }

    getFin_ausencias(){
        return this.fin_ausencias;
    }

    setFin_ausencias(fin_ausencias: Date){
        this.fin_ausencias = fin_ausencias;
    }

}