import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { BajaService } from '../../services/baja.service';
import { VacacionesService } from '../../services/vacaciones.service';
import { AusenciasService } from '../../services/ausencias.service';
import { Empleado } from 'src/app/models/empleado';
import { Bajas } from 'src/app/models/bajas';
import { Vacaciones } from 'src/app/models/vacaciones';
import { Ausencias } from 'src/app/models/ausencias';
import { GLOBAL } from '../../services/global.service';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from 'src/app/models/usuario';
import { DatePipe } from '@angular/common';
import { HorarioService } from '../../services/horario.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser'; // para mostrar la foto en src cargada por api
import { LogService } from 'src/app/services/log.service';

@Component({
    selector : 'empleado-detail',
    templateUrl : '../empleado-detail/empleado-detail.component.html',
    styleUrls : ['../empleado-detail/empleado-detail.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, BajaService, VacacionesService, AusenciasService, HorarioService ]
})

export class EmpleadoDetailComponent {
    public fecha_actual;
    public titulo: string;
    public empleado: Empleado;
    public photoLoaded;
    public baja: Bajas;
    public vacaciones: Vacaciones;
    public ausencia: Ausencias;
    public parametro;
    public codigo_empresa: string;
    fecha = new Date();
    // public global_fichero: string;
    public global_fotos_empleados: string;
    public usuario: Usuario;
    public msgInfo;
    public msgError;
    public codeGenerated = ''; // Guardamos la contraseña de 15 dígitos
    public inputcodeGenerated = ''; // for any digit code
    public superadmin;
    public empSP = 0;
    randomString() {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
        const stringLength = 15;
        let randomstring = '';
        for (let i = 0; i < stringLength; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
        }
        this.codeGenerated = randomstring;
        // console.log('codeGenerated: ');
        // console.log(this.codeGenerated);
        // return 0;
        return this.codeGenerated;
    }

    constructor(
        private _horarioService: HorarioService,
        private datePipe: DatePipe,
        private _route: ActivatedRoute,
        private _router: Router,
        private _empleadoService: EmpleadoService,
        private _usuarioService: UsuarioService,
        private _bajaService: BajaService,
        private _vacacionesService: VacacionesService,
        private _ausenciasService: AusenciasService,
        protected toastr: ToastrService,
        private domSanitizer: DomSanitizer,
        private _logsService: LogService
        ){
            this.titulo = "Empleado detail";
            this.empleado = new Empleado(0, null, null, null,  null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, false, null, null, null, null, false, false, false, false, false, false, false, null, true, false, false, false, null, false, true, true, null, null, null);
            this.baja = new Bajas(0, null, null, null, null, null);
            this.vacaciones = new Vacaciones(0, null, null, null, null, null);
            this.ausencia = new Ausencias(0, null, null, null, null, null);
            this.usuario = new Usuario(0, null, null, null);
            this.global_fotos_empleados = null;
            this.msgInfo = '';
            this.msgError = '';
            this.photoLoaded = null;
            this.superadmin = GLOBAL.superadmin;
        }

    ngOnInit(){
        this._route.params.forEach((params:Params) =>{
            this.parametro = params['code'];
            this.codigo_empresa = params['codigo_empresa'];
        });
        this.global_fotos_empleados = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_fotos_empleados;
        this.fechaActual();
        this.getEmpleado();
        this.getUsuario();
    }

    closeAviso() {
        this.msgInfo = '';
    }
    closeAvisoError() {
        this.msgError = '';
    }

    updategeneratedPassword() {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        console.log('Email: ' + this.empleado.email);
        if (this.empleado.email == null) {
            this.msgError = 'El empleado no tiene email !!!';
            return;
        }

        this.empleado.password = this.codeGenerated;
        // let pwd2 = this.randomString();
        // console.log('codeGenerated2: ');
        // console.log(pwd2);

        // this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
        // console.log(this.empleado);
        this._empleadoService.updatePassword(this.empleado).subscribe(
          response => {
                // console.log(response);
                if (response['code'] == 200){
                    console.log('Contraseña del usuario actualizada');
                    // console.log(response);
                    // console.log(this.empleado);
                    this.msgInfo = 'Contraseña del usuario actualizada';
                    this.sendEmailPassword();
              } else {
                    console.log('No ha sido posible actualizar la password del usuario');
                    this.msgError = 'No ha sido posible actualizar la contraseña del usuario';
              }
          }, error => {
                console.log('Ha sucedido un error');
                console.log(this.empleado);
                this.msgError = 'No ha sido posible actualizar la contraseña del usuario';
          }
        );
    }

    sendEmailPassword() {
        this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
        this._empleadoService.sendEmailPassword(this.empleado).subscribe(
        result => {
            console.log(result);
            if(result['code'] == 200) {
                console.log('Contraseña del usuario enviada por email');
                //console.log(result);
                this.msgInfo = 'Contraseña del usuario actualizada y enviada por email';
            } else {
                this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
                console.log('Error: ' + result['message']);
            }
        },
            error => {
                this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
                console.log(error as any);
            }
        );
    }

    fechaActual() {
        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    this.fecha_actual = response['data'];
                    console.log('Fecha actual recuperada: ' + response['data']);
                } else {
                    console.log('No se ha podido conseguir la fecha actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );
    }

    getEmpleado(){
      this._empleadoService.getEmpleado(this.parametro, this.codigo_empresa).subscribe(
        response => {
            if (response['code'] == 200){
                console.log("Empleado encontrado");
                this.empleado = response['data'];
                this.empSP = response['data']['superadmin'];
                if (this.empleado != null && (this.empleado.foto == null || this.empleado.foto === '')){
                    this.empleado.foto = "assets/imagenes/silueta-empleado.png"; 
                    console.log(this.empleado.foto);
                } else {
                    // version cargando la foto por url, ahora lo hago por la api
                    // this.empleado.foto = this.global_fotos_empleados + '/' + this.empleado.foto + '?' + new Date().getTime();
                    // cargamos la foto por api:
                    this.getFotoEmpleado(this.empleado.id);
                }
            } else {
                console.log("Empleado no encontrado");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
      );
    }

    getFotoEmpleado(id) {
        this.photoLoaded = null;
        this._empleadoService.getFotoEmpleado(id).subscribe(
            response => {
                // console.log(response);
                if (response['code'] == 200) {
                    let img = 'data:image/jpeg;base64,' + response['data'];
                    this.photoLoaded = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
                    // console.log('this.photoLoaded: ' + this.photoLoaded);
                    console.log('La foto se ha cargado correctamente');
                } else {
                    console.log('La foto no se ha podido cargar');
                    console.log('id_empleado: ' + response['id_empleado']);
                    console.log('imagen: ' + response['imagen']);
                    // muestro imagen alternativa en lugar de toast
                    // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
                }

            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
                // muestro imagen alternativa en lugar de toast
                // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
            }
        );
    }

    editUsuario() {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }
        let rolanterior = this.usuario.rol;
        if(this.usuario.rol == 'admin') {
            this.usuario.rol = 'empleado';
        } else if (this.usuario.rol == 'empleado') {
            this.usuario.rol = 'admin';
        }
        let rolactual = this.usuario.rol;

        this.usuario = new Usuario(this.usuario.id, this.usuario.code, this.usuario.codigo_empresa, this.usuario.rol);
        console.log(this.usuario);
        this._usuarioService.editUsuario(this.usuario.id, this.usuario).subscribe(
        response => {
            if (response['code'] == 200){
                console.log("Rol actualizado con éxito");
                console.log(response);
                this.getUsuario();
                this.getEmpleado();
                this._logsService.addLogs('Roles', 'Se ha modificado el rol del usuario: '+this.usuario.code,'Rol anterior: '+rolanterior,'Rol actual: '+rolactual).subscribe( result => {
                    if(result['code'] == 200){
                        console.log("Log registrado");
                    }
                });
            } else {
                console.log("No ha sido posible actualizar el rol de usuario");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
      );
    }

    getUsuario() {
      this._usuarioService.getUsuario(this.parametro, this.codigo_empresa).subscribe(
        response => {
            if (response['code'] == 200){
                console.log("Usuario encontrado");
                this.usuario = response['data'];
            } else {
                console.log("usuario no encontrado");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
      );
    }

    inicializa_bajas() {
        this.baja.inicio_baja = this.fecha_actual;
        this.baja.fin_baja = this.fecha_actual;
    }

    darBaja(){

        let fdesde = new Date(this.baja.inicio_baja);
        let fhasta = new Date(this.baja.fin_baja);
        console.log('Fecha desde: ' + fdesde);
        console.log('Fecha hasta: ' + fhasta);
        if (fdesde > fhasta) {
            this.toastr.error('Fechas no válidas.', 'Aplicar baja');
            return;
        }

        //Componer baja
        this.baja.code = this.empleado.code;
        this.baja.codigo_empresa = this.empleado.codigo_empresa;

        console.log('Añadir baja:');
        console.log(this.baja);
        //Insertar baja en la base de datos
        this._bajaService.addBaja(this.baja).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Baja insertada con exito");
                    this.toastr.success('La baja se ha aplicado correctamente.', 'Aplicar baja');
                    this.controlBajas(); // actualizo el estado de las bajas de los empleados
                } else {
                    this.toastr.error('Se ha producido un error.', 'Aplicar baja');
                    console.log("La baja no se ha podido insertar");
                }
            }, error => {
                this.toastr.error('Se ha producido un error.', 'Aplicar baja');
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    // Metodo que comprueba quien está hoy de baja y actualiza los estados
    controlBajas() {
        console.log('controlBajas ...');
        this._bajaService.automatic_update_bajas(this.codigo_empresa, String(this.fecha_actual)).subscribe(
        response => {
            console.log(response);
            if (response['code'] === 200) {
                // console.log('Se ha realizado la actualizacion automática de bajas');
                console.log(response['message']);
            } else {
                console.log('Error realizando la actualizacion automática de bajas');
            }
            }, error => {
                console.log('Ha sucedido un error realizando la actualizacion automática de bajas');
                console.log(error as any);
            }
        );
    }

    inicializa_vacaciones() {
        this.vacaciones.inicio_vacaciones = this.fecha_actual;
        this.vacaciones.fin_vacaciones = this.fecha_actual;
    }

    darVacaciones() {

        let fdesde = new Date(this.vacaciones.inicio_vacaciones);
        let fhasta = new Date(this.vacaciones.fin_vacaciones);
        console.log('Fecha desde: ' + fdesde);
        console.log('Fecha hasta: ' + fhasta);
        if (fdesde > fhasta) {
            this.toastr.error('Fechas no válidas.', 'Aplicar vacaciones');
            return;
        }

        //Componer Vacaciones
        this.vacaciones.code = this.empleado.code;
        this.vacaciones.codigo_empresa = this.empleado.codigo_empresa;

        console.log('Añadir vacaciones:');
        console.log(this.vacaciones);
        //Insertar vacaciones en la base de datos
        this._vacacionesService.addVacaciones(this.vacaciones).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log("Insertado con exito");
                    this.toastr.success('Vacaciones aplicadas correctamente.', 'Aplicar vacaciones');
                    this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
                } else {
                    this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
                    console.log("No se ha podido insertar vacaciones");
                }
            }, error => {
                this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    controlVacaciones() {
        console.log('controlVacaciones ...');
        this._vacacionesService.automatic_update_vacaciones(this.codigo_empresa, String(this.fecha_actual)).subscribe(
          response => {
              console.log(response);
              if (response['code'] === 200) {
                    // console.log('Se ha realizado la actualizacion automática de vacaciones');
                    console.log(response['message']);
              } else {
                    console.log('Error realizando la actualizacion automática de vacaciones');
              }
          }, error => {
                console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
                console.log(error as any);
          }
        );
      }

    inicializa_ausencias() {
        this.ausencia.inicio_ausencias = this.fecha_actual;
        this.ausencia.fin_ausencias = this.fecha_actual;
    }

    darAusencia() {

        let fdesde = new Date(this.ausencia.inicio_ausencias);
        let fhasta = new Date(this.ausencia.fin_ausencias);
        console.log('Fecha desde: ' + fdesde);
        console.log('Fecha hasta: ' + fhasta);
        if (fdesde > fhasta) {
            this.toastr.error('Fechas no válidas.', 'Aplicar ausencia');
            return;
        }

        //Componer ausencia
        this.ausencia.code = this.empleado.code;
        this.ausencia.codigo_empresa = this.empleado.codigo_empresa;

        console.log('Añadir ausencia:');
        console.log(this.ausencia);
        //Insertar ausencia en la base de datos
        this._ausenciasService.addAusencias(this.ausencia).subscribe(
            response => {
                if (response['code'] == 200){
                    this.toastr.success('La ausencia se ha aplicado correctamente.', 'Aplicar ausencia');
                    console.log("Ausencia insertada con exito");
                    this.controlAusencias(); // actualizo el estado de las ausencias de los empleados
                } else {
                    this.toastr.error('Se ha producido un error.', 'Aplicar ausencia');
                    console.log("La ausencia no se ha podido insertar");
                }
            }, error => {
                this.toastr.error('Se ha producido un error.', 'Aplicar ausencia');
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

      controlAusencias() {
        console.log('controlAusencias ...');
        this._ausenciasService.automatic_update_ausencias(this.codigo_empresa, String(this.fecha_actual)).subscribe(
          response => {
              console.log(response);
              if (response['code'] === 200) {
                    // console.log('Se ha realizado la actualizacion automática de ausencias');
                    console.log(response['message']);
              } else {
                    console.log('Error realizando la actualizacion automática de ausencias');
              }
          }, error => {
                console.log('Ha sucedido un error realizando la actualizacion automática de ausencias');
                console.log(error as any);
          }
        );

      }

}
