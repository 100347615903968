import { Component, OnInit } from '@angular/core';
import { GLOBAL } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { EmpresaService } from '../../services/empresa.service';
import * as moment from 'moment';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'config-empresa',
  templateUrl: './config-empresa.component.html',
  styleUrls: ['./config-empresa.component.css']
})
export class ConfigEmpresaComponent implements OnInit {

  public codigo_empresa: string;
  public fecha_renovacion;

  public empresa: any;
  public tipo_cuenta: string;
  public packs: number;
  public tipo_pago: number;
  public pack_usuarios:number;
  public total:number;

  public diffInDays: number;
  public fecha_actual_renovacion: any;
  public today = new Date();

  // public empresa_enable_camara: boolean = false;
  // public empresa_enable_localizacion: boolean = false;
  // public empresa_correccion_por_empleado: boolean = false;

  // declaro como array de boolean en lugar de boolean, pero solo uso [0]. explicacion en la funcion en app.component
  public empresa_enable_camara: Array<boolean>;
  public empresa_enable_localizacion: Array<boolean>;
  public empresa_correccion_por_empleado: Array<boolean>;
  public opcion_hacer_solicitudes: boolean = false;


  constructor(
    protected toastr: ToastrService,
    protected _configService: ConfigService,
    protected _empresaService: EmpresaService,
    private mensajesService: MensajesService,
  ) {
    this.empresa_enable_camara = [];
    this.empresa_enable_camara[0] = false;
    this.empresa_enable_localizacion = [];
    this.empresa_enable_localizacion[0] = false;
    this.empresa_correccion_por_empleado = [];
    this.empresa_correccion_por_empleado[0] = false;
    this.fecha_renovacion = null;
    this.empresa = '';
  }

  ngOnInit() {
    this.opcion_hacer_solicitudes = GLOBAL.opcion_hacer_solicitudes;
    this.codigo_empresa = GLOBAL.codigo_empresa;
    this.fecha_renovacion = GLOBAL.fecha_renovacion;

    console.log('GLOBAL.config_empresa_enable_camera: ' + GLOBAL.config_empresa_enable_camera); // guarda 0/1
    console.log('GLOBAL.config_empresa_enable_localizacion: ' + GLOBAL.config_empresa_enable_localizacion); // guarda 0/1
    console.log('GLOBAL.config_correccion_por_empleado: ' + GLOBAL.config_correccion_por_empleado); // guarda true / false

    // this.empresa_enable_camara = GLOBAL.config_empresa_enable_camera[0] === '1' ? true : false;
    // this.empresa_enable_localizacion = GLOBAL.config_empresa_enable_localizacion[0] === '1' ? true : false;
    this.empresa_enable_camara[0] = this.getBoolean( GLOBAL.config_empresa_enable_camera[0] );
    this.empresa_enable_localizacion[0] = this.getBoolean( GLOBAL.config_empresa_enable_localizacion[0] );
    this.empresa_correccion_por_empleado[0] = this.getBoolean( GLOBAL.config_correccion_por_empleado[0] );

    console.log('empresa_enable_camara: ' + this.empresa_enable_camara[0]);
    console.log('empresa_enable_localizacion: ' + this.empresa_enable_localizacion[0]);
    console.log('empresa_correccion_por_empleado: ' + this.empresa_correccion_por_empleado[0]);

    this.CompareDates();
    this.getEmpresa();

  }

  // Controlamos los días que quedan hasta el final de la subscripción, le sumamos 2 días por que empieza en 0 y queremos contar el día actual
  CompareDates() {
      this.fecha_actual_renovacion = this.today;
      this.fecha_renovacion = moment(this.fecha_renovacion);
      this.fecha_actual_renovacion = moment(this.fecha_actual_renovacion);
      this.diffInDays = Math.abs(this.fecha_renovacion.diff(this.fecha_actual_renovacion, 'days') + 2);
  }

  getBoolean(value) {
    switch (value) {
         case true:
         case "true":
         case 1:
         case "1":
         case "on":
         case "yes":
             return true;
         default:
             return false;
    }
  }

  toggle(opcion) {

    if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

    let valor: string;

    if (opcion === 1) { // CAMARA

        if (this.empresa_enable_camara[0]) { // está activada la opción
          console.log('Desactivar la opción');
          // this.empresa_enable_camara = false;
          valor = '0';
        } else {
          console.log('Activar la opción');
          // this.empresa_enable_camara = true;
          valor = '1';
        }

        this._configService.setConfigParam(this.codigo_empresa, 'GENERAL', 'CAMARA', valor).subscribe(
          response => {
              console.log(response);
              if (response['code'] === 200) {
                console.log('El parámetro se ha actualizado correctamente');
                this.toastr.success('Se ha actualizado el parámetro', 'ACTUALIZAR PARÁMETRO');
                this.cargar_parametros();
              } else {
                console.log('Error actualizando el parámetro');
                this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
              }
          },
          error => {
              console.log(error as any);
              this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
          }
        );

    } else if (opcion === 2) { // LOCALIZACION

      if (this.empresa_enable_localizacion[0]) { // está activada la opción
        console.log('Desactivar la opción');
        // this.empresa_enable_localizacion = false;
        valor = '0';
      } else {
        console.log('Activar la opción');
        // this.empresa_enable_localizacion = true;
        valor = '1';
      }

      this._configService.setConfigParam(this.codigo_empresa, 'GENERAL', 'LOCALIZACION', valor).subscribe(
        response => {
            console.log(response);
            if (response['code'] === 200) {
              console.log('El parámetro se ha actualizado correctamente');
              this.toastr.success('Se ha actualizado el parámetro', 'ACTUALIZAR PARÁMETRO');
              this.cargar_parametros();
            } else {
              console.log('Error actualizando el parámetro');
              this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
            }
        },
        error => {
            console.log(error as any);
            this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
        }
      );

    } else if (opcion === 3) { // CORRECCION POR EMPLEADO

      if (this.empresa_correccion_por_empleado[0]) { // está activada la opción
        console.log('Desactivar la opción');
        // this.empresa_correccion_por_empleado = false;
        valor = '0';
      } else {
        console.log('Activar la opción');
        // this.empresa_correccion_por_empleado = true;
        valor = '1';
      }

      this._configService.setConfigParam(this.codigo_empresa, 'GENERAL', 'CORRECCION_POR_EMPLEADO', valor).subscribe(
        response => {
            console.log(response);
            if (response['code'] === 200) {
              console.log('El parámetro se ha actualizado correctamente');
              this.toastr.success('Se ha actualizado el parámetro', 'ACTUALIZAR PARÁMETRO');
              this.cargar_parametros();
            } else {
              console.log('Error actualizando el parámetro');
              this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
            }
        },
        error => {
            console.log(error as any);
            this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
        }
      );

    }
    else if (opcion === 4) { // opcion activar solicitudes de vacaciones desde empleado
      if (this.opcion_hacer_solicitudes) {
        this.opcion_hacer_solicitudes = false;
      }
      else{
        this.opcion_hacer_solicitudes = true;
      }
      this._empresaService.updateOpcionSolicitudes(this.codigo_empresa,this.opcion_hacer_solicitudes).subscribe(
        response => {
            console.log(response);
            if (response['code'] === 200) {
              console.log('El parámetro se ha actualizado correctamente');
              this.toastr.success('Se ha actualizado la opcion de Solicitudes');
              GLOBAL.opcion_hacer_solicitudes = response["data"];
            } else {
              this.opcion_hacer_solicitudes = GLOBAL.opcion_hacer_solicitudes
              console.log('Error actualizando el parámetro');
              this.toastr.error('No se ha podido actualizar la opcion de Solicitudes');
            }
            this.mensajesService.emite({
              categoria: 'opcionSolicitudes',
              contenido: this.opcion_hacer_solicitudes.toString()
            });
        },
        error => {
            console.log(error as any);
            this.toastr.error('No se ha podido actualizar', 'ACTUALIZAR PARÁMETRO');
        }
      );

    }

  }
  cargar_parametros() {
    // recupero parámetros de configuracion de la BD y actualizo GLOBAL
    this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'CORRECCION_POR_EMPLEADO', true, GLOBAL.config_correccion_por_empleado);
    this.getConfigParam(this.codigo_empresa, 'GENERAL', 'CAMARA', '0', GLOBAL.config_empresa_enable_camera);
    this.getConfigParam(this.codigo_empresa, 'GENERAL', 'LOCALIZACION', '0', GLOBAL.config_empresa_enable_localizacion);
    // recupero parámetros de configuracion de la BD y actualizo para actualizar el estado de los botones
    this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'CORRECCION_POR_EMPLEADO', true, this.empresa_correccion_por_empleado);
    this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'CAMARA', true, this.empresa_enable_camara);
    this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'LOCALIZACION', true, this.empresa_enable_localizacion);
  }

  getConfigParam(codigo_empresa, seccion, clave, porDefecto, aValor) {
    // aValor: el hecho de usar un array en lugar de una variable directamente es porque los arrays se pasan por referencia y 
    // así directamente modificamos su valor aquí dentro y nos ahorramos problemas.
    // es raro, pero es lo que se me ha ocurrido !!!!

      this._configService.getConfigParam(codigo_empresa, seccion, clave).subscribe(
          response => {
              // console.log(response);
              if (response['code'] === 200) {
                  if (response['result'] === true) {
                      aValor[0] = response['valor'];
                      // console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha encontrado: ');
                  } else {
                      // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
                      aValor[0] = porDefecto;
                  }
              } else {
                  // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
                  aValor[0] = porDefecto;
              }
              console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ' + aValor[0]);
          },
          error => {
              console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
              console.log(error as any);
              aValor[0] = porDefecto;
              console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ');
              console.log(aValor[0]);
          }
      );

  }

  getConfigParamBoolean(codigo_empresa, seccion, clave, porDefecto, aValor) {
    // aValor: el hecho de usar un array en lugar de una variable directamente es porque los arrays se pasan por referencia y 
    // así directamente modificamos su valor aquí dentro y nos ahorramos problemas.
    // es raro, pero es lo que se me ha ocurrido !!!!

    this._configService.getConfigParam(codigo_empresa, seccion, clave).subscribe(
        response => {
            // console.log(response);
            if (response['code'] === 200) {
                if (response['result'] === true) {
                    aValor[0] = this.getBoolean(response['valor']);
                    // console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha encontrado: ');
                } else {
                    // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
                    aValor[0] = this.getBoolean(porDefecto);
                }
            } else {
                // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
                aValor[0] = this.getBoolean(porDefecto);
            }
            console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ' + aValor[0]);
        },
        error => {
            console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
            console.log(error as any);
            aValor[0] = this.getBoolean(porDefecto);
            console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ');
            console.log(aValor[0]);
        }
    );

  }

  getEmpresa() {
    this._empresaService.getEmpresa(GLOBAL.codigo_empresa).subscribe(
      response => {
          console.log(response);
          if (response['code'] == 200){
              this.empresa = response['data'];
              console.log('Empresa cargada:');
              console.log(this.empresa);
              if (this.empresa.num_licencias >= 25) {
                this.tipo_cuenta = 'Professional';
              } else if (this.empresa.num_licencias < 25) {
                this.tipo_cuenta = 'Basic';
              }
              if (this.tipo_cuenta == 'Professional') {
                this.tipo_pago = 1500;
              } else if (this.tipo_cuenta == 'Basic') {
                this.tipo_pago = 275;
              }
              // Total de usuarios extra sin contar con los usuarios gratis
              this.packs = this.empresa.num_licencias - 25;
              // Obtengo el número de paquetes contratados
              this.packs = this.packs / 10;
              this.pack_usuarios = this.packs * 25;
              this.total = this.tipo_pago + this.pack_usuarios;
          } else {
              console.log('Empresa no encontrada');
          }
      }, error => {
          console.log(this.empresa);
          console.log('Ha sucedido un error');
          console.log(error as any);
      }
    );
  }

}
