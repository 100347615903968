import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Vacaciones } from '../models/vacaciones';
import { GLOBAL } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class VacacionesService {
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addVacaciones(vacaciones: Vacaciones, persona?: string ){
        let json = JSON.stringify(vacaciones);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        if(persona){
            return this._http.post(this.url + '/vacaciones-add/' + persona, params, {headers: headers});
        }else{
            return this._http.post(this.url + '/vacaciones-add', params, {headers: headers});
        }
        
    }

    automatic_update_vacaciones(codigo_empresa: string, fecha: string) {
        return this._http.get(this.url + '/vacaciones-actualizar-fecha/' + fecha);
    }

    deleteVacaciones(id: number, code: string, fecha_ini: string, fecha_fin:string){
        return this._http.get(this.url + '/vacaciones-delete/'+id+'/'+code+'/'+fecha_ini+'/'+fecha_fin);
    }

    updateVacaciones(vacaciones: Vacaciones,fecha_ini_ant:string, fecha_fin_ant:string, code_upd) {
        let json = JSON.stringify(vacaciones);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this._http.post(this.url + '/vacaciones-update/'+fecha_ini_ant+'/'+fecha_fin_ant+'/'+code_upd,params, {headers:headers});
    }
}
