import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { empty} from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MensajesService } from './mensajes.service';  // Nuestro proveedor de mensajes
import { ToastrService } from 'ngx-toastr';

/* para registrar este interceptor: */
// IMPORTANT !!!: Making sure that no other module imports HttpClientModule in your application, ONLY AT app.module !!!
// en nuestro módulo raíz, por lo general AppModule:
// Importamos HTTP_INTERCEPTORS y HttpClientModule: import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Importamos el interceptor: import { AuthInterceptorService } from './services/auth-interceptor.service';
// proveer en el array de providers: {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}
// tslint:disable-next-line: max-line-length
// multi: true esto permitirá agregar más interceptors si lo requerímos y no sobre escribir nuestro interceptor. En otras palabras, crean un array con el mismo provider token (HTTP_INTERCEPTORS)

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private mensajesService: MensajesService, protected toastr: ToastrService,) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // agregamos la cabecera "Content-Type" si no existe ya
      if (!req.headers.has('Content-Type')) {
        req = req.clone({headers: req.headers.set('Content-Type', 'application/x-www-form-urlencoded')});
        // console.log('añadida cabecera: Content-Type: application/x-www-form-urlencoded')
      }

    // agregamos la cabecera "authorization" si no esxiste ya el token existe
    // si no hacemos esto aquí, tendria que agregar la cabecera siempre antes hacer todas las peticiones así:
    // let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':localStorage.getItem('token')});
    // return this._http.get(this.url + '/usuario/' + code + '/' + codigo_empresa, {headers: headers} );

      const token: string = localStorage.getItem('token');

      if (token && !req.headers.has('Authorization')) {
        req = req.clone({headers: req.headers.set('Authorization', token)});
    }

      // console.log('Interceptor req: ');
      // console.log(req);

    // si cualquier respuesta en la cabecera devuelve el status=401, redirigimos a la pagina de entrada ( en este caso, hacemos logout )
    // ojo, no es lo mismo la cabecera con code=401 que en el cuerpo llegue un dato con code=401
    // en nuestra api, solo el token invalido inserta la cabecera con code=401
      return next.handle(req)
        .pipe(
          tap(
          event => {
            //console.log('Interceptor return event:');
            //console.log(event);
            if (event instanceof HttpResponse) {
              // event = event.clone({ headers: event.headers.set('endTime', endTime.toString()) });
              if (event.body && event.body.code && event.body.code === 401) {
                console.log('capturado error 401 en body. no se hace nada ...');
              }
            }
            return;
          },
          error => {
            // console.log('Interceptor return ERROR');
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                // this.router.navigate(['/login/1']);  // seria lo ideal, pero no hace nada ¿¿¿???

                // intento de solución: emito un mensaje que capturaría app.component para redireccionar a: [/]
                // no va, no se reciben porque tienen q estar los 2 componentes en memoria y app.component.destroy se ejecutó
                this.mensajesService.emite({
                  categoria: 'Authorization',
                  contenido: 'Unauthorized'
                });

                window.location.href = '/';        // funciona pero no es lo suyo
                return empty();
                // return throwError(error);
              }

              if (error.status === 440) {
                this.toastr.error(error["error"]["message"])
                window.location.href = '/';
              }
            }
            return empty();
            // return throwError(error);
          })
        );

  }

}


