import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { Usuario } from '../../models/usuario';
import { EmpleadoService } from '../../services/empleado.service';
import { UsuarioService } from '../../services/usuario.service';
import { HorarioService } from '../../services/horario.service';
import { Horario } from 'src/app/models/horario';
import { FichajeDia } from 'src/app/models/fichaje-dia';
import { Empleado } from 'src/app/models/empleado';
import { Vacaciones } from 'src/app/models/vacaciones';
import { EmpleadosVacaciones } from '../../models/empleados_vacaciones';
import { VacacionesService } from "../../services/vacaciones.service";
import { GLOBAL } from '../../services/global.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vacaciones',
  templateUrl: './vacaciones.component.html',
  styleUrls: ['./vacaciones.component.css', '../../../style.scss'],
  providers : [EmpleadoService, UsuarioService, HorarioService, DepartamentosService, VacacionesService,DatePipe],
  animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          ]),
          transition(':leave', [
            style({transform: 'translateX(0)', opacity: 1}),
            animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
          ])
        ]
      )
  ],
})

export class VacacionesComponent implements OnInit{

  public titulo: string;
    public mostrar_filtro: boolean;
    public filtro: number;
    public fichajeUpdate: FichajeDia;
    public fichajeInsert: Horario;
    // public fecha: Date;
    public codigo_empresa: string;
    public globaladmin;
    public mostrar_modificar: boolean;

    public lista_departamentos: Array<Departamentos>;
    public lista_usuarios: Array<Usuario>;
    public fecha_actual;

    public identidad: string;

    public borrar: number;


    //Variables de busqueda y parametro para la llamada desde el menu admin->avisos
    public filterCode = '';
    public filterUsuario = '';
    public filterNombre = '';
    public filterDepartamento = '';
    public filterFecha: any;
    public filterFechaHasta: any;
    public filterHoras_contrato;

    //Variables de modificacion
    public idModificar: number;
    public modificarInicio_vacaciones_antes: any;
    public modificarFin_vacaciones_antes: any;
    public modificarInicio_vacaciones: any;
    public modificarFin_vacaciones: any;
    public modificarObservaciones: any;
    public observaciones: string;
    public modificarFecha: Date;
    public codeModificar: string;
    public nombreModificar: string;
    public apellidosModificar: string;
    public msgErrorModificar: string;
    public msgModificar: string;
    public saveORcancel: boolean = false;
    public confirmado;
    public disabledCode: boolean = false;
    public fentrada:Date;
    public fsalida:Date;
    public empleado:Empleado;
    public vacaciones: Vacaciones;
    public vec_Vacaciones: Array<EmpleadosVacaciones>;
    public inicio_vac_sel: string;
    public fin_vac_sel: string;
    public code_seleccionado:string;
    public modificar: boolean = false;
    public item;
    

    public loading: boolean = false; // cargando lista..
    public cargandoPDF: boolean;
    public msgLista: string = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        protected toastr: ToastrService,
        private _empleadoService: EmpleadoService,
        private _usuarioService: UsuarioService,
        private _departamentosService: DepartamentosService,
        private _vacacionesService: VacacionesService, private _date: DatePipe){
        this.titulo = "Página de Vacaciones"
        this.vec_Vacaciones = [];
        this.fichajeUpdate = new FichajeDia(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
        this.mostrar_modificar = false;
        this.mostrar_filtro = true;
        this.filtro = 0;
        this.modificar = false;
        this.lista_departamentos = null;
        this.lista_usuarios = null;
        this.vacaciones = new Vacaciones(0, null, null, null, null, null);
        this.confirmado = null;
        this.disabledCode = false;
        this.filterFecha = '';
        this.filterFechaHasta = '';
        this.globaladmin = GLOBAL.esAdmin;
        this.code_seleccionado = '';
        // this.filterHoras_contrato = 0;
        this.filterHoras_contrato = '';
        this.inicio_vac_sel = '';
        this.item = JSON.parse(localStorage.getItem('identity'));
        this.codeModificar = '';
    }

    ngOnInit(){
      this.list_usuarios();
      this.list_departamentos();
      this.getVacaciones();
      this.globaladmin;
  }

  
  getVacaciones() {

    this._empleadoService.getEmpleadosVacaciones(this.codigo_empresa).subscribe(
        response => {
            if (response['code'] == 200){                
                this.vec_Vacaciones = response['data'];
                console.log("Vacaciones de empleados encontradas: ");
                console.log(this.vec_Vacaciones);

            } else {
                console.log("Vacaciones de empleados no encontradas");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

}

  active_modificar(){
    this.modificar = false;
    this.filtro = 0;
    if (this.mostrar_modificar == false) {
        this.mostrar_modificar = true;
        this.code_seleccionado = '';
        this.disabledCode = false;
        scroll(0, 0);
    } else {
        this.mostrar_modificar = false;
    }

  }

  //Metodo que se trae todos los departamentos de la base de datos
  list_departamentos(){
    console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
    this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log('Departamentos conseguidos con exito')
                this.lista_departamentos = response['data'];
                console.log(this.lista_departamentos);

            } else {
                console.log("Los departamentos no se han podido conseguir");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios(){

    // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
    if ( GLOBAL.rol !== "admin" ) {

        let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
        this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
        this.lista_usuarios.push(user);

    } else {

        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];
                    console.log(this.lista_usuarios);

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

  }


  activeFiltrar(index){
    if(index == 2) { // crear fichaje
        // this.codeModificar = null;
        this.codeModificar = '';
        this.modificarInicio_vacaciones = '';
        this.modificarFin_vacaciones = '';
        this.modificarObservaciones = '';
        this.filtro = 2;
    } else if (index == 1) { // filtros de busqueda
        this.mostrar_modificar = false;
        if (this.filtro == 1) {
            this.filtro = 0; // ya se veía el filtro, lo oculto....
        } else {
            this.filtro = 1;
        }
        // console.log(this.filtro + ' ' + index);
    } else if(index == 0) {
        this.idModificar == 0;
        this.filtro = 0;
    }
    else {
        // console.log(this.filtro + ' ' + index);
    }
    /*if (this.mostrar_filtro == true){
        this.mostrar_filtro = false;
        this.filtro = index;
    } else {
        this.mostrar_filtro = true;
        this.filtro = 0;
    }*/
  }


  active() {
  
      if(this.filtro == 0) {
          this.filtro = 0;
      }
  }

  cancelarModificarFichaje(){
    this.mostrar_modificar = false;
  }

  CrearModificarVacaciones(){
    window.scrollTo(0, 0);
    if(this.code_seleccionado != null) {
      //Si me llega un codigo
      if(this.fin_vac_sel == '9999-12-31'){
        this.fin_vac_sel = '';
      }
      this.modificarInicio_vacaciones = this.inicio_vac_sel;
      this.modificarFin_vacaciones = this.fin_vac_sel;
      this.modificarObservaciones = this.observaciones;
      console.log(this.modificarObservaciones);
      this.codeModificar = this.code_seleccionado;
      this.mostrar_modificar = true;
    }
    this.code_seleccionado = '';
  }

  delete(id: number, code: string, inicio_vacaciones: string, fin_vacaciones: string){
    this._vacacionesService.deleteVacaciones(id,code,inicio_vacaciones,fin_vacaciones).subscribe(
      response => {
        console.log(response);
        if (response['code'] == 200){
            console.log("Eliminado con exito");
            this.toastr.success('Vacaciones eliminadas correctamente.', 'Eliminar vacaciones');
            this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
        } else {
          if (response["messageModificacion"]) {
            this.toastr.warning( response["messageModificacion"])
            return
          }
            this.toastr.error('Se ha producido un error.', 'Eliminar vacaciones');
            console.log("No se ha podido eliminar vacaciones");
        }
        this.getVacaciones();
      }, error => {
          this.toastr.error('Se ha producido un error.', 'Eliminar vacaciones');
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
    );
  }

  modificarVacaciones(code: string,inicio_vacaciones: string,fin_vacaciones: string, nombre:string, apellidos: string, observaciones: string){
    console.log(inicio_vacaciones+ ' '+ fin_vacaciones);
    this.modificar = true;
    this.code_seleccionado = code;
    this.nombreModificar = nombre;
    this.apellidosModificar = apellidos;
    this.inicio_vac_sel = inicio_vacaciones;
    this.modificarInicio_vacaciones_antes = inicio_vacaciones;
    this.modificarFin_vacaciones_antes = fin_vacaciones;
    this.modificarObservaciones = observaciones;
    this.observaciones = observaciones;
    this.fin_vac_sel = fin_vacaciones;
    this.CrearModificarVacaciones();
  }

  public msgError(msg: string) {
    this.msgErrorModificar = msg;
    setTimeout(() =>
    {
        this.msgErrorModificar = '';
    },
    2000);
  }
  
  public msgModificado(msg: string) {
      this.msgModificar = msg;
      setTimeout(() =>
      {
          this.msgModificar = '';
          this.mostrar_modificar = false;
          //this.seleccionarEmpleados();
          this.getVacaciones();
      },
      2000);
  }


  nullerValues(index){

    console.log("Llamando metodo nullerValues");

    if (index == 1) {
        if (!this.disabledCode) { // si tenemos code bloqueado, no permitimos quitar el filtro ( para correcciones desde el usuario, no desde el admin)
            this.filterCode = '';
        }
    }
    else if (index == 2) {
        this.filterFecha = '';
        
    }
    else if (index == 3) {
        this.filterFechaHasta = '';
        
    }
    else if (index == 4) {
        this.filterNombre = '';
        
    }
    else if (index == 5) {
        this.filterDepartamento = '';
        
    }
    else if (index == 6) {
      // this.filterHoras_contrato = 0;
      this.filterHoras_contrato = '';
      
  }

  }

  aplicarVacaciones(){
    let persona = JSON.parse(localStorage.getItem('identity'))['code'];
    if(this.codeModificar == null || this.codeModificar == ''){
      this.toastr.error("Falta usuario", "Aplicar Vacaciones");
      return;
    }else if(this.modificarInicio_vacaciones == null || this.modificarInicio_vacaciones == ''){
      this.toastr.error("Falta fecha de inicio", "Aplicar Vacaciones");
      return;
    }else if(this.modificarFin_vacaciones < this.modificarInicio_vacaciones && (this.modificarFin_vacaciones != null && this.modificarFin_vacaciones != '')){
      this.toastr.error("Fechas incorrectas", "Aplicar Vacaciones");
      return;
    }else{
      if(this.modificar){ //Se trata de un update
      this.vacaciones.code = this.codeModificar;
      this.vacaciones.inicio_vacaciones = this.modificarInicio_vacaciones;
      this.vacaciones.fin_vacaciones = this.modificarFin_vacaciones;
      this.vacaciones.observaciones = this.modificarObservaciones;
      let code_upd = this.item['code'];
      console.log('Update vacaciones:');
      console.log(this.vacaciones);
      // Update vacaciones en la base de datos
      this._vacacionesService.updateVacaciones(this.vacaciones,this.modificarInicio_vacaciones_antes, this.modificarFin_vacaciones_antes, persona).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  console.log("Modificado con exito");
                  this.toastr.success('Vacaciones modificadas correctamente.', 'Modificar vacaciones');
                  this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
              } else {
                if (response["messageModificacion"]) {
                  this.toastr.warning( response["messageModificacion"])
                  return
                }
                  this.toastr.error('Se ha producido un error.', 'Modificar vacaciones');
                  console.log("No se ha podido modificar vacaciones");
              }
              this.getVacaciones();
          }, error => {
              this.toastr.error('Se ha producido un error2.', 'Modificar vacaciones');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
      );
      
      this.mostrar_modificar = false;
    }else{ //Se trata de un insert
      
      let fdesde = new Date(this.modificarInicio_vacaciones);
      let fhasta = new Date(this.modificarFin_vacaciones);
      if(this.codeModificar == null || this.codeModificar == ''){
        this.toastr.error('Datos no válidos.', 'Aplicar vacaciones');
        return;
      }

      //Componer Vacaciones
      this.vacaciones.code = this.codeModificar;
      this.vacaciones.fin_vacaciones = this.modificarFin_vacaciones;
      this.vacaciones.inicio_vacaciones = this.modificarInicio_vacaciones;
      this.vacaciones.observaciones = this.modificarObservaciones;

      console.log('Añadir vacaciones:');
      console.log(this.vacaciones);
      //Insertar vacaciones en la base de datos
      this._vacacionesService.addVacaciones(this.vacaciones, persona).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  console.log("Insertado con exito");
                  this.toastr.success('Vacaciones aplicadas correctamente.', 'Aplicar vacaciones');
                  this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
              } else {
                if (response["messageModificacion"]) {
                  this.toastr.warning( response["messageModificacion"])
                  return
                }
                  this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
                  console.log("No se ha podido insertar vacaciones");
              }
              this.getVacaciones();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
      );
      this.getVacaciones();
      this.mostrar_modificar = false;
    }}
    
  }
  
// if (response["messageModificacion"]) {
//     this.toastr.warning( response["messageModificacion"])
// } Jairo

  controlVacaciones() {
    console.log('controlVacaciones ...');
    this._vacacionesService.automatic_update_vacaciones(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
          console.log(response);
          if (response['code'] === 200) {
                // console.log('Se ha realizado la actualizacion automática de vacaciones');
                console.log(response['message']);
          } else {
                console.log('Error realizando la actualizacion automática de vacaciones');
          }
      }, error => {
            console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
            console.log(error as any);
      }
    );
  }

}
