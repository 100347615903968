import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Bajas } from '../models/bajas';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public url: string;

  constructor(public _http: HttpClient) {
      this.url = GLOBAL.url;
  }

  getConfigParam(codigo_empresa, seccion, clave) {
    // this.getConfigParam(null, 'GENERAL PARA TODAS EMPRESAS', 'VERSION', '0', GLOBAL.config_version);
    // if ( codigo_empresa == null && seccion == 'GENERAL PARA TODAS EMPRESAS' && clave == 'VERSION') {
      // no puede estar logado todavia, por eso usamos otra url
      // return this._http.get(this.url + '/config-version/' + seccion + '/' + clave);
    // } else {
      return this._http.get(this.url + '/config/' + seccion + '/' + clave);
    // }
  }

  setConfigParam(codigo_empresa, seccion, clave, valor){
    return this._http.get(this.url + '/config-update/' + seccion + '/' + clave + '/' + valor);
  }

  getFechaRenovacion(){
    return this._http.get(this.url + '/fecha-renovacion');
  }

}
