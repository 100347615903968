import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/* para suscribirse: */
/*
      this.notificacionesService.notify.subscribe((result) => {
        console.log('notificacion recibida: ', result);
        console.log(result);
      });
*/

@Injectable({
  providedIn: 'root' // Así se establece a partir de Angular 6 el ámbito de la instancia del servicio
})
export class NotificacionesService {
  notify: Subject<boolean> = new Subject<boolean>();
  onNotify(event) {
          this.notify.next(true);
          console.log('Se ha enviado la notificación: ');
          console.log(true);
  }
}
