import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { GLOBAL } from '../../services/global.service';
import { DepartamentosService } from '../../services/departamentos.service';
import { UsuarioService } from '../../services/usuario.service';

import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { EmpleadoService } from 'src/app/services/empleado.service';
import { Router } from '@angular/router';
import { VacacionesService } from 'src/app/services/vacaciones.service';
import { Vacaciones } from 'src/app/models/vacaciones';
import { ToastrService } from 'ngx-toastr';
import { SolicitudVacacionesService } from 'src/app/services/solicitud-vacaciones.service';
import { MensajesService } from 'src/app/services/mensajes.service';
// En styles.css:
/*
div.fc-license-message{
  visibility: hidden !important;
}

@import '~@fullcalendar/resource-timeline/main.css';
@import '~@fullcalendar/timeline/main.css';
*/

@Component({
  selector: 'solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css', '../../../style.scss'],
  providers: [DepartamentosService, UsuarioService],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )
  ]
})
export class SolicitudesComponent implements OnInit {

  public lista_departamentos: any = [];
  public lista_usuarios: any = [];
  public lista_admins: any = []
  public codigo_empresa: string;
  public solicitudes: any = [];
  public vacaciones: Vacaciones;
  public es_admin = false;
  public dialogPendiente: any = {
    abierto: false,
    code: null,
    start: null,
    end: null,
  }
  public dialogoAsignarVar: any = {
    title: null,
    id: null
  }
  //Filtros
  public objTemp: any = []
  public permisosTemp: any = []
  public filtro: boolean = false;
  public currentFilters: any = { date: "", departamento: "", empleado: [] }
  //relacionados con la grafica
  public calendar: Calendar = null;
  public calendarEl: any;
  public permisos: any = [];
  public obj: any = []
  public colors = { "vacaciones": '#7FD028', "bajas": "#559AEC", "ausencias": "#F1F", "solicitudes": "#8f8f8f" }
  public texto_ayuda = ""
  constructor(
    private _departamentosService: DepartamentosService,
    private _empleadoService: EmpleadoService,
    private _vacacionesService: VacacionesService,
    protected _vacacionesSolicitudService: SolicitudVacacionesService,
    private mensajesService: MensajesService,
    private router: Router,
    protected toastr: ToastrService) {
    if (GLOBAL.rol == "admin") {
      this.es_admin = true;
      this.codigo_empresa = GLOBAL.codigo_empresa;
      //Antes de cargar el componente recogemos las solicitudes ya solicitadas por el app-component (numero de solicitudes PANEL ADMIN)
      //app-comonent necesita hacer esa llamada para mostrar la notificacion de las solicitudes en el panel principal
      this.solicitudes = this.router.getCurrentNavigation().extras.state["datosPendientes"]
    }
    else {
      this.solicitudesPendientes();
    }
  }

  ngOnInit() {
    this.cargangoDatos();
    this.texto_ayuda= "Aquí podrá consultar de forma visual las excedencias de otros trabajadores\
     y hacer solicitudes de vacaciones conforme a las fechas que considere disponibles a partir de los datos de la gráfica, quedando su solicitud como pendiente,\
     resaltada con el color asignado a 'Pendientes' en la historia.\
     Cuando su solicitud quede como APROBADA se verá de color asignado en la historia como 'Vacaciones', de lo contrario desaparecerá de la gráfica."
    if (this.es_admin) {
      this.texto_ayuda = "En esta sección podrá consultar todas las excedencias de sus empleados de forma visual mediante la gráfica,\
      así como resolver solicitudes clicando en las fechas de la gráfica asignadas en la historia con el color de 'Pendientes'. \
      Si dispone mas de una cuenta administrativa podra asignar el encargado de cada empleado clicando sobre los nombres que puede ver junto a la gráfica a la izquierda.\
      De esta forma solo los encargados verán las solicitudes de esos empleados.";
    }
  }

  async refrescar() {
    //Si refrescamos la grafica desde otro componente, las solicitudes deben pedirse de esta forma y no por url
    await this.solicitudesPendientes();
    this.cargangoDatos();
  }

  async cargangoDatos() {
    this.permisos = []
    this.obj = []
    await this.getVacaciones();
    await this.getBajas();
    await this.getAusencias();
    //------- hasta aqui esperamos a que las peticiones nos respondan
    this.rellenarPendientes();
    this.list_departamentos();
    this.list_usuarios();
    if (this.calendar) {
      this.calendar.destroy()
    }
    this.loadCalendar(this.obj, this.permisos);
  }

  rellenarPendientes() {
    if (this.solicitudes) {
      this.solicitudes.forEach(element => {
        this.addDataGrafica(element["solicitante"], this.colors["solicitudes"], element['fecha_inicio'], element['fecha_final'], element["solicitante"], element["puesto"], "Pendientes");
      });
    }
  }

  addDataGrafica(id, color, start, end, title, depart, tipo_evento) {//menos el depart, todos los demas son necesarios para la grafica
    this.permisos.push({ "resourceId": id, "color": color, "start": start, "end": end, "departamento": depart, "tipo_evento": tipo_evento })
    this.obj.push({ "title": title, "id": id })
  }

  loadCalendar(obj, perm) {
    //  ESTE CALLBACK ES NECESARIO PARA RECUPERAR LA VARIAVLE this.dialogPendiente, YA QUE CUANDO INTENTAMOS AACCEDER A ESTA
    //  VARIABLE DENTRO DEL CONSTRUCTOR DE CALENDAR MAS ABAJO, "THIS" ES CALENDDAR Y NO PODEMOS ALCANZAR NUESTRA VARIABLE
    //-------------------  CALLBACKS PARA LOS EVENTOS EN EL CONSTRUCTOR DE CALENDAR -------------------
    let variablesDelEntorno = () => {
      this.lista_admins = []
      return [this.lista_admins, this.lista_usuarios, this.dialogPendiente, this.es_admin, this.dialogoAsignarVar]
    }
    let dateformatYMD = (DATE) => {
      var newDate = new Date(DATE);
      let month = '' + (newDate.getMonth() + 1);
      if (parseInt(month) < 10) {
        month = "0" + month;
      }
      let day = '' + newDate.getDate();
      if (parseInt(day) < 10) {
        day = "0" + day;
      }
      let year = newDate.getFullYear();
      let date = year + "-" + month + "-" + day
      return date
    }
    //-------------------  FIN CALLBACKS -------------------

    this.calendarEl = document.getElementById('calendar');
    var date = new Date();
    var curTime = date.getHours() + ":00:00";
    this.calendar = new Calendar(this.calendarEl, {
      plugins: [resourceTimelinePlugin, interactionPlugin],
      eventClick: function (info) {// --------> ESTE ES EL CALLBACK PARA CUANDO CLICAMOS EN NUESTRO LAPSO DE TIEMPO EN LA GRAFICA ( resolver consulta )
        if (variablesDelEntorno()[3]) { // solo el admin tiene esta funcionalidad
          let tipo_evento = info.event._def.extendedProps.tipo_evento
          if (tipo_evento == "Pendientes") {
            let dialogoPend = variablesDelEntorno()[2];
            dialogoPend.abierto = true;
            dialogoPend.code = info.event._def.resourceIds[0];
            dialogoPend.start = dateformatYMD(info.event._instance.range.start);
            dialogoPend.end = dateformatYMD(info.event._instance.range.end);
            document.getElementById("bmod").click();
          }
        }
      },
      resourceRender: function (renderInfo) { //Esto es los nombres de las etiquetas en la grafica, en esta funcion les asignamos eventos como click o mouseover
        renderInfo.el.style.cursor = "pointer";
        renderInfo.el.onmouseover = () => {
          renderInfo.el.style.backgroundColor = "#299bec"
          renderInfo.el.style.color = "white"
        }
        renderInfo.el.onmouseout = () => {
          renderInfo.el.style.backgroundColor = "white"
          renderInfo.el.style.color = "black"
        }

        if (variablesDelEntorno()[3]) { // solo el admin puede cambiar el encargado
          renderInfo.el.addEventListener("click", function () {
            document.getElementById("bmod2").click()
            console.clear()
            let vectorUsers = variablesDelEntorno()
            for (let i = 0; i < vectorUsers[1].length; i++) {
              if (vectorUsers[1][i]["rol"] === "admin") {
                vectorUsers[0].push(vectorUsers[1][i])
              }
            }
            let dialogoAsignarVar = vectorUsers[4]
            dialogoAsignarVar.title = renderInfo.resource._resource.title
            dialogoAsignarVar.id = renderInfo.resource._resource.id
            console.log(dialogoAsignarVar)
          });
        }
      },
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'null'
      },
      aspectRatio: 5,
      slotWidth: 50,
      height: "auto",
      minTime: curTime,
      nowIndicator: true,
      firstDay: 1,
      defaultTimedEventDuration: 900000,
      defaultView: 'resourceTimelineMonth',
      resourceLabelText: 'Personal',
      resources: obj,
      events: perm
    });
    this.calendar.setOption('locale', 'es');
    this.calendar.render();
  }

  async confirmarVacaciones(code, start) {
    /*
      1. En la tabla vacaciones_solicitadas ponemos la instacia como resuelta y aprobada
      2. hacemos una segunada peticion añadiendo los datos a la tabla vacaciones
    */
    this.vacaciones = new Vacaciones(0, null, null, null, null, null);
    let encontrado = false
    let identityUser = JSON.parse(localStorage.getItem('identity'))
    let persona = identityUser['code'];
    let user_info: Object = {};
    for (const i in this.permisos) {
      if (this.permisos[i]["resourceId"] == code && this.permisos[i]["start"] == start) {
        this.vacaciones.code = this.permisos[i]["resourceId"];
        this.vacaciones.fin_vacaciones = this.permisos[i]["end"];
        this.vacaciones.inicio_vacaciones = this.permisos[i]["start"];
        this.vacaciones.observaciones = "Vacaciones solicitadas por el empleado";
        user_info = { code: this.permisos[i]["resourceId"], start: start, aprobada: true };
        encontrado = true;
        break
      }
    }
    if (encontrado) {
      this._vacacionesSolicitudService.resolver_solicitud(user_info, persona).subscribe(
        response => {
          if (response["status"] == "OK" && response["code"] == 200) {
            this._vacacionesService.addVacaciones(this.vacaciones, persona).subscribe(
              response2 => {
                console.log(response);
                if (response2['code'] == 200) {
                  console.log("Insertado con exito");
                  this.toastr.success('La peticion ha sido resuelta');
                  this.solicitudesPendientes();
                  this.cargangoDatos();
                } else {
                  this.toastr.error('Se ha producido un error.');
                  console.log("No se ha podido insertar vacaciones");
                }
              }, error2 => {
                this.toastr.error('Se ha producido un error.');
                console.log('Ha sucedido un error');
                console.log(<any>error2);
              }
            );
          }
        }, error => {
          this.toastr.error("Error: La consulta no se ha podido realizar");
        }
      );
    }
  }

  rechazarVacaciones(code, start) {
    /*
      1. En la tabla vacaciones_solicitadas ponemos la instacia como resuelta y rechazada
      2. hacemos una segunada peticion añadiendo los datos a la tabla vacaciones
    */
    let encontrado = false
    let identityUser = JSON.parse(localStorage.getItem('identity'))
    let persona = identityUser['code'];
    let user_info: Object = {};

    for (const i in this.permisos) {
      if (this.permisos[i]["resourceId"] == code && this.permisos[i]["start"] == start) {
        user_info = { code: this.permisos[i]["resourceId"], start: start, aprobada: false }
        encontrado = true;
        break
      }
    }

    if (encontrado) {
      this._vacacionesSolicitudService.resolver_solicitud(user_info, persona).subscribe(
        response => {
          console.log(response)
          this.toastr.success(response["result"]);
          this.solicitudesPendientes();
          this.cargangoDatos();
        }, error => {
          console.log(error)
          this.toastr.error("Error: La consulta no se ha podido realizar");
        }
      );
    }
  }

  asignarEncargado(encargado, empleado) {
    /*
      1. asignamos nuevo encargado al empleado
    */
    let identityUser = JSON.parse(localStorage.getItem('identity'))
    let persona = identityUser['code'];
    let user_info: Object = { "nuevo_encargado": encargado, "empleado": empleado };
    this._vacacionesSolicitudService.asignar_encargado(user_info, persona).subscribe(
      response => {
        this.toastr.success("Se ha asignado el nuevo encargado a " + empleado);
      }, error => {
        this.toastr.error("Error: No se ha podido recuperar las solicitudes pendientes ");

      }
    );
  }

  calendarChangeDate(date) {
    this.calendar.gotoDate(date) //Nos situamos en la fecha solicitada dentro de la grafica
  }

  activeFiltrar() {
    (this.filtro) ? this.filtro = false : this.filtro = true //muestra o oculta la vista delfiltro
  }

  setFilterUsers(filter, e) {
    let valor = e.target.value
    if (filter == "empleado") {
      if (!this.currentFilters.empleado.includes(valor)) {
        this.currentFilters.empleado.push(valor)
      }
    }
    else {
      this.currentFilters[filter] = valor
    }
    this.applyFilter();
  }

  removeFilter(filter, value = "") {
    if (filter == "empleado") {
      this.currentFilters[filter].splice(this.currentFilters[filter].indexOf(value), 1)
      this.applyFilter()
      return
    }
    this.currentFilters[filter] = null;
    this.applyFilter()
  }


  filter_empleado() {
    if (this.currentFilters.empleado.length > 0) {
      for (let i in this.permisos) {
        for (let x = 0; x < this.currentFilters.empleado.length; x++) {
          if (this.permisos[i]["resourceId"] == this.currentFilters.empleado[x]) {
            this.permisosTemp.push(this.permisos[i])
          }
        }
      }
    }
    else {
      this.permisosTemp = Object.assign([], this.permisos)
    }
  }

  filter_depart() {
    if (this.currentFilters.departamento) {
      let i = 0
      while (i < this.permisosTemp.length) {
        if (this.permisosTemp[i].departamento != this.currentFilters.departamento) {
          this.permisosTemp.splice(i, 1)
          continue
        }
        i++
      }
    }
  }

  filter_date() {
    if (this.currentFilters.date) {
      let date = this.currentFilters.date.substring(0, 7);
      let i = 0
      while (i < this.permisosTemp.length) {
        if (this.permisosTemp[i]["start"].substring(0, 7) != date) {
          this.permisosTemp.splice(i, 1)
          continue
        }
        i++
      }
    }
  }

  applyFilter() { //SE APLICA CADA VEZ QUE UN FILTRO ES AÑADIDO O REMOVIDO
    this.objTemp = []
    this.permisosTemp = []
    //----------------------------------- ** IMPORTANTE **
    //para que los filtros funciones correctamente deben ejecutarse en el siguiente orden
    this.filter_empleado()
    this.filter_depart()
    this.filter_date()
    //filtrando obj
    for (let x in this.obj) {
      for (let i = 0; i < this.permisosTemp.length; i++) {
        if (this.obj[x]["id"] == this.permisosTemp[i]["resourceId"]) {
          this.objTemp.push(this.obj[x])
          break
        }
      }
    };
    console.clear()
    console.log("TEMP obj: --->", this.objTemp)
    console.log("TEMP permisos: --->", this.permisosTemp)
    console.log(this.currentFilters)

    this.calendar.destroy()
    this.loadCalendar(this.objTemp, this.permisosTemp)
    if (this.currentFilters.date) { //si la fecha ha sido establecida entonces esque ya se ha filtrado (filter_date()), de lo contrario nos saltamos este paso
      this.calendarChangeDate(this.currentFilters.date)
    }
  }

  //Metodo que se trae todos los departamentos de la base de datos
  list_departamentos() {
    console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
    this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
      response => {
        if (response['code'] == 200) {
          console.log('Departamentos conseguidos con exito')
          this.lista_departamentos = response['data'];
          console.log(this.lista_departamentos);
        } else {
          console.log("Los departamentos no se han podido conseguir");
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(<any>error);
      }
    );
  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios() {
    this._empleadoService.getEmpleadosBasicInfo().subscribe(
      response => {
        if (response['code'] == 200) {
          this.lista_usuarios = response["data"]
          console.log("Usuarios de esta empresa: ", this.lista_usuarios)
        } else {
          console.log("La peticion para recuperar empleados ha fallado");
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(<any>error);
      }
    );
  }

  //------------------  llamadas que necesitan AWAIT ------------------------

  solicitudesPendientes() {
    return new Promise<void>((resolve, reject) => {
      let identityUser = JSON.parse(localStorage.getItem('identity'))
      let persona = identityUser['code'];
      let user_info: Object = { codigo_empresa: identityUser["codigo_empresa"] }
      this._vacacionesSolicitudService.solicitudes_pendientes(user_info, persona).subscribe(
        response => {
          this.solicitudes = response["result"]["solicitudes"];
          this.mensajesService.emite({
            categoria: 'solicitudesPendientes',
            contenido: this.solicitudes.length.toString()
          });
          resolve()
        }, error => {
          this.toastr.error("Error: No se ha podido recuperar las solicitudes pendientes ");
          reject()
        }
      );
    });

  }

  getVacaciones() {
    return new Promise<void>((resolve, reject) => {
      this._empleadoService.getEmpleadosVacaciones(this.codigo_empresa).subscribe(
        response => {
          if (response['code'] == 200) {
            response['data'].forEach(element => {
              this.addDataGrafica(element["code"], this.colors["vacaciones"], element['inicio_vacaciones'], element['fin_vacaciones'], element["code"], element["puesto"], "Vacaciones");
            });
            resolve()
          } else {
            console.log("Vacaciones de empleados no encontradas");
            resolve()
          }
        }, error => {
          console.log('Ha sucedido un error');
          console.log(<any>error);
          reject()
        }
      );
    })
  }

  getBajas() {
    return new Promise<void>((resolve, reject) => {
      this._empleadoService.getEmpleadosBajas(this.codigo_empresa).subscribe(
        response => {
          if (response['code'] == 200) {
            response['data'].forEach(element => {
              this.addDataGrafica(element["code"], this.colors["bajas"], element['inicio_baja'], element['fin_baja'], element["code"], element["puesto"], "Bajas")
            });
            resolve()
          } else {
            console.log("Bajas de empleados no encontradas");
            resolve()
          }
        }, error => {
          console.log('Ha sucedido un error');
          console.log(<any>error);
          reject()
        }
      );
    })
  }

  getAusencias() {
    return new Promise<void>((resolve, reject) => {
      this._empleadoService.getEmpleadosAusencias(this.codigo_empresa).subscribe(
        response => {
          if (response['code'] == 200) {
            response['data'].forEach(element => {
              element['resourceId'] = element['code'];
              element['start'] = element['inicio_ausencias'];
              let color = '#F1F';
              this.addDataGrafica(element["code"], this.colors["ausencias"], element['inicio_ausencias'], element['fin_ausencias'], element["code"], element["puesto"], "Ausencias")
            });
            resolve()
          } else {
            console.log("Ausencias de empleados no encontradas");
            resolve()
          }
        }, error => {
          console.log('Ha sucedido un error');
          console.log(<any>error);
          reject()
        }
      );
    })
  }
}
