import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global.service';
import { CalendariosLaboralesService } from '../../services/calendarios-laborales.service';
import { CalendariosTrabajoService } from '../../services/calendarios-trabajo.service';
import { CalendariosTrabajo } from 'src/app/models/calendarios_trabajo';
import { CalendariosLaborales } from 'src/app/models/calendarios_laborales';
import { DiasFestivos } from 'src/app/models/dias_festivos';
import { DatePipe } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';


declare var $: any;

// https://fullcalendar.io/docs/plugin-index
// npm install --save @fullcalendar/core @fullcalendar/daygrid
// npm install --save @fullcalendar/interaction -> Required to detect dateClick actions, selectable actions, and event drag-n-drop & resizing
// importar en styles.css:
// @import '@fullcalendar/core/main.css';
// @import '@fullcalendar/daygrid/main.css';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // para dateClick

// import { FullCalendarComponent } from '@fullcalendar/angular';
// https://fullcalendar.io/docs/angular
// https://stackblitz.com/edit/angular-gcwzaq?file=src%2Fapp%2Fapp.component.ts
// https://medium.com/aubergine-solutions/how-to-integrate-jquery-fullcalendar-in-angular-c35728cfeeb2

import { EventInput } from '@fullcalendar/core';
// import timeGrigPlugin from '@fullcalendar/timegrid';


@Component({
  selector: 'app-calendarios',
  templateUrl: './calendarios.component.html',
  styleUrls: ['./calendarios.component.css', '../../../style.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
],
})
export class CalendariosComponent implements OnInit {

  public codigo_empresa: string;
  public tab: number;
  public calendar;
  public fecha_actual;


  // @ViewChild('mycal', { read: ElementRef }) myCal: ElementRef;
  // @ViewChildren('myCal') cal: QueryList < ElementRef > ;
  @ViewChild('micalendario') micalendar: ElementRef;
  myHtmlCalendar: HTMLElement;
  // @ViewChild('modal-dia-festivo') modal_dia_festivo;
  @ViewChild('modalDiaFestivo') modal_dia_festivo: ElementRef;
  myHtmlModal_dia_festivo: HTMLElement;

  public dias_festivos: Array<any>;
  public lista_calendarios_trabajo: Array<CalendariosTrabajo>;
  public lista_calendarios_laborales: Array<any>;
  public id_calendarios_trabajo;
  public id_calendarios_laborales;

  public calendario_laboral: CalendariosLaborales;
  public new_calendario_trabajo: CalendariosTrabajo;
  public update_calendario_trabajo: CalendariosTrabajo;
  public idAddUpdateCalendarioLaboral: number;
  public idAddUpdateCalendarioTrabajo: number;
  public titulo_AddUpdate;
  public mostrar_add_update_calendarios_trabajo: boolean;
  public mostrar_add_update_calendarios_laborales: boolean;
  public msgErrorModificar: string;
  public msgModificar: string;
  public confirmado;
  public editar_calendario_laboral: boolean; // para no permitir editar los generales ( los de codigo_empresa = null)

  public dia_festivo: DiasFestivos;
  public mostrarbtnDeleteDiaFestivo: boolean;
  public fechaValida: boolean;
  public tituloValido: boolean;

  // Variables para añadir/modificar calendarios laborales
  public nombreCalendarioLaboral: string;
  public nombreValido: boolean;
  public deleteCalendario: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private datePipe: DatePipe,
    private _calendarioslaboralesService: CalendariosLaboralesService,
    private _calendarios_trabajoService: CalendariosTrabajoService,
    protected toastr: ToastrService
  ) {
    this.tab = 1;
    this.dias_festivos = [];
    this.lista_calendarios_trabajo = null;
    this.lista_calendarios_laborales = null;
    this.id_calendarios_trabajo = 0;
    this.id_calendarios_laborales = 0;
    this.fecha_actual = '';
    this.mostrar_add_update_calendarios_trabajo = false;
    this.mostrar_add_update_calendarios_laborales = false;
    this.idAddUpdateCalendarioLaboral = 0;
    this.idAddUpdateCalendarioTrabajo = 0;
    this.msgErrorModificar = '';
    this.msgModificar = '';
    this.calendario_laboral = new CalendariosLaborales(null, null, null);
    this.new_calendario_trabajo = new CalendariosTrabajo(null, null, null, null);
    this.update_calendario_trabajo = new CalendariosTrabajo(null, null, null, null);
    this.confirmado = null;
    // this.dia_festivo = null;
    this.dia_festivo = new DiasFestivos(null, null, null, null, null );
    this.mostrarbtnDeleteDiaFestivo = false;
    this.fechaValida = true;
    this.tituloValido = true;
    this.editar_calendario_laboral = false;

    this.nombreValido = true;
    this.deleteCalendario = false;
  }

  ngOnInit() {

    this._route.params.forEach((params: Params) => {
        this.codigo_empresa = params['codigo_empresa'];
    });

    this.myHtmlCalendar = this.micalendar.nativeElement;
    this.myHtmlModal_dia_festivo = this.modal_dia_festivo.nativeElement;
    

    this.list_calendarios_trabajo();
    this.list_calendarios_laborales();
    this.getDiasFestivosFullCalendar(this.id_calendarios_laborales); // sólo para mostrar el calendario , aqui todavia no carga días festivos ( id=0)
    // this.inicializaCalendarioFestivos();

    this.idAddUpdateCalendarioLaboral = 0;
    this.idAddUpdateCalendarioTrabajo = 0;

    console.log('isLandscape: ' + this.isLandscape());

  }

  isLandscape() // prueba
  {
    return $(window).width() >= $(window).height();
  }

  // Activa o desactiva el div que muestra cada tab
  activeTab(index) {

    if ( index === this.tab) {
      return;
    }

    this.tab = index;

    if (index === 1) {
      this.list_calendarios_trabajo();
    } else if (index === 2) {
      this.mostrar_add_update_calendarios_trabajo = false;

    } else {

    }

  }

    // Metodo que se trae todos los calendarios de trabajo de la empresa de la base de datos
    list_calendarios_trabajo() {
      // console.log('Buscar calendarios de trabajo de la empresa: ' + this.codigo_empresa);
      this._calendarios_trabajoService.getCalendariosTrabajo(this.codigo_empresa).subscribe(
          response => {
              // console.log(response);
              if (response['code'] == 200){
                  console.log('Calendarios de trabajo conseguidos con exito:');
                  this.lista_calendarios_trabajo = response['data'];
                  console.log(this.lista_calendarios_trabajo);

              } else {
                  console.log("Los calendarios de trabajo no se han podido conseguir");
              }
          }, error => {
              console.log('Ha sucedido un error');
              console.log(error as any);
          }
      );

  }

    // Metodo que se trae todos los calendarios laborales de la empresa y los comunes de la base de datos
    list_calendarios_laborales() {
      // console.log('Buscar calendarios laborales de la empresa y comunes: ' + this.codigo_empresa);
      this._calendarioslaboralesService.getCalendariosLaborales(this.codigo_empresa, '1').subscribe( // '1' para recuperar también los comunes
          response => {
              // console.log(response);
              if (response['code'] == 200){
                  console.log('Calendarios laborales conseguidos con exito:');
                  this.lista_calendarios_laborales = response['data'];
                  console.log(this.lista_calendarios_laborales);

              } else {
                  console.log("Los calendarios laborales no se han podido conseguir");
              }
          }, error => {
              console.log('Ha sucedido un error');
              console.log(error as any);
          }
      );

  }

 updateCalendarioLaboral() {
    // this.id_calendarios_laborales = this.calendario_laboral.id;
    // this.getDiasFestivosFullCalendar(this.id_calendarios_laborales);
    console.log('Id Calendario Festivo:');
    console.log(this.id_calendarios_laborales);

    this.getDiasFestivosFullCalendar(this.id_calendarios_laborales);
    this.calendario_laboral = this.lista_calendarios_laborales.find(x => x.id == this.id_calendarios_laborales);

    console.log('Calendario laboral seleccionado:');
    console.log(this.calendario_laboral);

    if (this.calendario_laboral == undefined) { // Opción "Seleccione.."
      console.log('Calendario no seleccionado');
      this.editar_calendario_laboral = false;
      return;
    }

    if (this.calendario_laboral.codigo_empresa == null) {
      console.log('Calendario General, NO editable');
      this.editar_calendario_laboral = false;
    } else {
      console.log('Calendario creado por la empresa, SI editable');
      this.editar_calendario_laboral = true;
    }

  }

  getDiasFestivosFullCalendar(id_calendarios_laborales) {
    this.dias_festivos = [];
    // if (this.calendar) { this.calendar.fullCalendar('removeEvents'); }
    this._calendarioslaboralesService.getDiasFestivosFullCalendar(id_calendarios_laborales).subscribe(
        response => {
          // console.log(response)
          if (response['code'] == 200){
            console.log("Dias festivos encontrados:");
            this.dias_festivos = response['data'];
            console.log(this.dias_festivos);
          } else {
            console.log("Dias festivos no encontrados");
          }
          this.inicializaCalendarioFestivos();
        }, error => {
          console.log('Ha sucedido un error');
          console.log(error as any);
          this.inicializaCalendarioFestivos();
        }
    );

  }

  inicializaCalendarioFestivos() {
    var self = this;
    var calendarEl;

    if ( !this.dias_festivos || this.dias_festivos.length === 0) {

      this.dias_festivos = [
        /*{
          // id: 999, // si repito el id, es el mismo evento
          // groupId: 'blueEvents', // recurrent events in this group move together
          title: 'Mi cumple',
          start: '2019-12-11',
          allDay: true,
          // url,
          // color,
          // textColor,
          // repeat: 1, // Monthly event // no va
          // repeat: 2, // Annual avent // no va
          // daysOfWeek: [1, 3, 5], // 0: domingo.  If omitted, the event is assumed to repeat every day.
          // startRecur // fecha inicio repeticiones
          // endRecur // fecha fin repeticiones
          description: 'Mi cumple !!!', // no sé para qué
          // className: 'scheduler_basic_event'
        }*/
      ];

    }

    // document.addEventListener('DOMContentLoaded', function() {
    // if (!this.calendar) {
    this.myHtmlCalendar.innerHTML = ''; // para q al crearse el calendario no se vayan agregando, eliminamos el anterior creado
    calendarEl = document.getElementById('calendario');
    // var calendar = new Calendar(calendarEl, {
    this.calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, interactionPlugin ],
      defaultView: 'dayGridMonth',
      locale: 'es',
      contentHeight:"auto", // para quitar scroll vertical
      views: {
        dayGridMonth: { // name of view
          // titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' } // muestra: 01/11/2019 - 31/11/2019
          titleFormat: { year: 'numeric', month: 'short'}
          // other view-specific options here
        }
      },
      /*
        // { year: 'numeric', month: 'long' }                  // like 'September 2009', for month view
        // { year: 'numeric', month: 'short', day: 'numeric' } // like 'Sep 13 2009', for week views
        // { year: 'numeric', month: 'long', day: 'numeric' }  // like 'September 8 2009', for day views
      titleFormat: {
        month: 'MMMM YYYY',
        week: 'MMMM YYYY',
        day: 'MMMM YYYY'
      },
      */
      // contentHeight: 300,
      // height: 200,
      firstDay: 1, // lunes
      buttonText: {
        // month: 'Month',
        // agendaDay: 'Day',
        // agendaWeek: 'Week',
        today: 'Hoy'
      },
      header: {
        left: 'title',
        center: '',
        right: 'prevYear,prev,next,nextYear today',
        //right: 'month,agendaWeek,agendaDay' otros: listDay
      },
      editable: true,
      eventLimit: true, // allow "more" link when too many events
      // defaultDate: '2016-01-12',
      // navLinks: true, // can click day/week names to navigate views
      // timeZone: 'UTC',
      // allDay: true, // por defecto, true afecta al mostrar los eventos
      // url: 'http://google.com/',
      events: this.dias_festivos, // https://fullcalendar.io/docs/v3/event-object
      // dateClick: function(info) { // https://fullcalendar.io/docs/dateClick
      dateClick: (info) => {
        console.log('this.calendar (dateClick):');
        console.log('Day clicked: ' + info.dateStr);  // si el evento no tiene end (dia completo), entra por aqui y por eventClick (por los dos!!!), deberia comprobar si existe ya o no para dejar crearlo
        // change the day's background color just for fun
        // info.dayEl.style.backgroundColor = 'red';

        // si es un calendario general, no de una empresa en particular, no dejo añadir/modificar/eliminar sus días festivos
        if (this.editar_calendario_laboral) {
          // self.chekEventDay(info.dateStr); // sin usar funcion de flecha
          this.chekEventDay(info.dateStr); // usando funcion de flecha
        } else {
          this.toastr.warning('Sólo puede editar los calendarios creados por su empresa. Este es del Sistema', 'Editar Calendario Festivo');
        }
      },
      eventClick: (info) => {
        // console.log(info.event);
        console.log('this.calendar (eventClick):');
        // si entro por aqui, existe, podria editar o eliminar el evento (dia festivo)
        // pero voy a hacerlo entrando por dateClick porque siempre se entra por ahí, tenga o no evento el día
        // console.log('Editar o eliminar el evento (día festivo): ');
        // console.log(info.event.id);
      },
      datesRender: ($event) => { // cada vez que reffresca los datos. lo uso para filtrar la lista de dias festivos del mes q se visualiza
        this.fecha_actual = this.datePipe.transform(this.calendar.getDate(), 'yyyy-MM-dd');
        // console.log('Fecha mostrada: '); // selecciona el primer dia del mes mostrado
        // console.log(this.fecha_actual);
        console.log('this.calendar (datesRender):');
      }

    });
    // }

    if (this.calendar) {

      // this.calendar.setOption('locale', 'es');
      this.calendar.render();
    }
    // });

    /*
    //remove old data
    $('#fullCalendar').fullCalendar('removeEvents');
    //Getting new event json data
    $("#fullCalendar").fullCalendar('addEventSource', response);
    //Updating new events
    $('#fullCalendar').fullCalendar('rerenderEvents');
    //getting latest Events
    $('#fullCalendar').fullCalendar( 'refetchEvents' );
    //getting latest Resources
    $('#fullCalendar').fullCalendar( 'refetchResources' );
    */

    // ejemplos de eventos:
    // https://codepen.io/gracecarey/pen/ZQZRZN

    /* un dia color fondo rojo y los 7 siguientes, amarillo: */
    /*
      dayRender: function (date, cell) {

        var today = new Date();
        var end = new Date();
        end.setDate(today.getDate()+7);

        if (date.getDate() === today.getDate()) {
            cell.css("background-color", "red");
        }

        var start = new Date();
        start.setDate(today.getDate()+1);

        while(start <= end){

          //alert(start + "\n" + tomorrow);
            if(start.getDate() == date.getDate()){
                cell.css("background-color", "yellow");
            }

           var newDate = start.setDate(start.getDate() + 1);
           start = new Date(newDate);
        }
      }
    */

  }

  /* ejemplo de calendario timeline de FHR */
  /*
  loadCalendar() {
    var calendarEl = document.getElementById('calendar');

    var calendar = new Calendar(calendarEl, {
      plugins: [ resourceTimelinePlugin ],
      header: {
        left: 'null',
        center: 'title',
        right: 'null'
      },
      aspectRatio: 5,
      slotWidth: 100,
      minTime: "08:00:00",
      nowIndicator: true,
      defaultTimedEventDuration: 900000,
      defaultView: 'resourceTimeline',
      resourceLabelText: 'Personal',
      resourceGroupField: 'especialidad',
      resources: GLOBAL.url + 'personal-calendario',
      events: GLOBAL.url + 'home-citas-calendario',
      eventClick: (info) => {
        console.log(info.event);

        this.citas.forEach(cita => {
          if (cita.id == parseInt(info.event.id)) {
            this.citaSeleccionada = cita;
          }
        });

        this.open(this.citaModal);
      }
    });

    calendar.render();
  }
  */

  checkDayHasEvent(day, jsonObject) {
    let id = 0;
    let evento;
    jsonObject.forEach(m => {
      if (m.start === day) {
        evento = m;
        id = m.id;
        return;
      }
    } );
    if ( id === 0 ) {
      // console.log('El día no tiene eventos');
      // this.dia_festivo = new DiasFestivos(evento.id, evento.id_calendarios_laborales, evento.start, evento.title, evento.tipo );
      this.dia_festivo = new DiasFestivos(null, null, day, null, 'N' );
      this.addDiaFestivo();
    } else {
      // console.log('El día tiene el evento:');
      // console.log ('evento: ' + evento.id + ' ' + evento.id_calendarios_laborales + ' ' + evento.title + ' ' + evento.start + ' ' + evento.tipo );
      this.dia_festivo = new DiasFestivos(evento.id, evento.id_calendarios_laborales, evento.start, evento.title, evento.tipo );
      this.updateDiaFestivo();
    }
    return id;
}

  chekEventDay(day: string) {
    console.log('chekEventDay: ' + day);
    console.log(this.dias_festivos);

    let eventId = this.checkDayHasEvent(day, this.dias_festivos);
    if ( eventId !== 0) {
      // editar o eliminar

    } else {
      // crear

    }

  }

  addDiaFestivo() {
    if (this.id_calendarios_laborales == null || this.id_calendarios_laborales === 0) { return; }
    this.dia_festivo.id_calendarios_laborales =  this.id_calendarios_laborales;
    this.fechaValida = true;
    this.tituloValido = true;
    this.openModalDiaFestivo();
    console.log('festivo:');
    console.log(this.dia_festivo);
    // $("#btnAceptar").prop("value", "Prop Click");
    document.getElementById("btnAceptarDiaFestivo").innerHTML = "<i class='fas fa-check'></i>  Añadir ";
    // document.getElementById("btnDeleteDiaFestivo").innerHTML = "";
    this.mostrarbtnDeleteDiaFestivo = false;
  }

  updateDiaFestivo() {

    if (!this.dia_festivo) { return; }
    if (this.id_calendarios_laborales == null || this.id_calendarios_laborales === 0) { return; }
    this.dia_festivo.id_calendarios_laborales =  this.id_calendarios_laborales;
    this.fechaValida = true;
    this.tituloValido = true;
    this.openModalDiaFestivo();
    console.log('festivo:');
    console.log(this.dia_festivo);
    document.getElementById("btnAceptarDiaFestivo").innerHTML = "<i class='fas fa-check'></i>  Modificar";
    // document.getElementById("btnDeleteDiaFestivo").innerHTML = "<i class='fas fa-trash-alt'></i> Eliminar";
    this.mostrarbtnDeleteDiaFestivo = true;
  }

  addUpdateDiaFestivo() {

    console.log('festivo:');
    console.log(this.dia_festivo);

    // validamos datos
    if (this.dia_festivo.id_calendarios_laborales === null) {
      console.log('Errror: id_calendarios_laborales no puede ser null');
      return;
    }
    if (this.dia_festivo.fecha === '' || this.dia_festivo.fecha === null) {
      this.fechaValida = false;
      return;
    }
    if (this.dia_festivo.titulo === '' || this.dia_festivo.titulo === null) {
      this.tituloValido = false;
      return;
    }

    if (this.dia_festivo.id === null) { // añadir

      this._calendarioslaboralesService.addDiasFestivos(this.dia_festivo).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Día festivo creado");
                // this.msgModificado('Día festivo creado correctamente');
                this.toastr.success('Día creado correctamente.', 'Crear día festivo');
                // this.calendar.rerenderEvents(); // si updateCalendarioLaboral() no creara de nuevo el calendario, ahora mismo lo crea de nuevo
                // this.updateCalendarioLaboral(this.calendario_laboral);
                this.updateCalendarioLaboral();
                this.closeModalDiaFestivo();
            } else if (response['code'] == 404) {
                console.log('Error: Día festivo no creado');
                // this.msgError('Error: Día festivo no creado');
                // this.msgError(response['message']);
                this.toastr.error('Error: Día festivo no creado.', 'Crear día festivo');
            } else {
                console.log('Error: Día festivo no creado');
                // this.msgError('Error: Día festivo no creado');
                // this.msgError('Error: Día festivo no creado');
                this.toastr.error('Error: Día festivo no creado.', 'Crear día festivo');
            }
        }, error => {
            console.log('Ha sucedido un error:');
            console.log(error as any);
            // this.msgError('Error: Día festivo no creado');
            this.toastr.error('Error: Día festivo no creado.', 'Crear día festivo');
        }
      );

    } else { // modificar

      this._calendarioslaboralesService.updateDiasFestivos(this.dia_festivo).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Día festivo modificado");
                // this.msgModificado('Día festivo modificado correctamente');
                this.toastr.success('Día modificado correctamente.', 'Modificar día festivo');
                // this.calendar.rerenderEvents(); // si updateCalendarioLaboral() no creara de nuevo el calendario, ahora mismo lo crea de nuevo
                // this.updateCalendarioLaboral(this.calendario_laboral);
                this.updateCalendarioLaboral();
                this.closeModalDiaFestivo();
            } else if (response['code'] == 404) {
                console.log('Error: Día festivo no modificado');
                // this.msgError('Error: Día festivo no modificado');
                // this.msgError(response['message']);
                this.toastr.error('Error: Día festivo no modificado.', 'Modificar día festivo');
            } else {
                console.log('Error: Día festivo no modificado');
                // this.msgError('Error: Día festivo no modificado');
                // this.msgError('Error: Día festivo no modificado');
                this.toastr.error('Error: Día festivo no modificado.', 'Modificar día festivo');
            }
        }, error => {
            console.log('Ha sucedido un error:');
            console.log(error as any);
            // this.msgError('Error: Día festivo no modificado');
            this.toastr.error('Error: Día festivo no modificado.', 'Modificar día festivo');
        }
      );

    }


  }

  deleteDiaFestivo() {

    this._calendarioslaboralesService.deleteDiasFestivos(this.dia_festivo.id).subscribe(
      response => {
          console.log(response);
          if (response['code'] == 200){
              console.log("Día festivo eliminado");
              // this.msgModificado('Día festivo eliminado correctamente');
              this.toastr.success('Día eliminado correctamente.', 'Eliminar día festivo');
              // this.calendar.rerenderEvents(); // si updateCalendarioLaboral() no creara de nuevo el calendario, ahora mismo lo crea de nuevo
              // this.updateCalendarioLaboral(this.calendario_laboral);
              this.updateCalendarioLaboral();
              this.closeModalDiaFestivo();
          } else if (response['code'] == 404) {
              console.log('Error: Día festivo no eliminado');
              // this.msgError('Error: Día festivo no eliminado');
              // this.msgError(response['message']);
              this.toastr.error('Error: Día festivo no eliminado.', 'Eliminar día festivo');
          } else {
              console.log('Error: Día festivo no eliminado');
              // this.msgError('Error: Día festivo no eliminado');
              // this.msgError('Error: Día festivo no eliminado');
              this.toastr.error('Error: Día festivo no eliminado.', 'Eliminar día festivo');
          }
      }, error => {
          console.log('Ha sucedido un error:');
          console.log(error as any);
          // this.msgError('Error: Día festivo no eliminado');
          this.toastr.error('Error: Día festivo no eliminado.', 'Eliminar día festivo');
      }
    );

  }

  /*
  openModalDiaFestivo() {
    $(document).ready(function(){
       $('#modal-dia-festivo').modal('show'); // NO VA
    });
  }

  closeModalDiaFestivo () {
    $(document).ready(function(){
       $("#modal-dia-festivo").modal('hide'); // NO VA
    });
  }
  */

  openModalDiaFestivo() {
    // let modal = document.getElementById('modal-dia-festivo');
    // console.log('modal: ' + modal);
    // modal.className = 'modal fade show'; // NO VA

    // $('#modal-dia-festivo').modal('show'); // NO VA
    // $('#modalDiaFestivo').modal('show'); // NO VA

    // this.myHtmlModal_dia_festivo.className = 'modal fade show'; // NO VA

    document.getElementById("openModalDiaFestivoButton").click(); // truquillo con botón escondido

  }
  closeModalDiaFestivo() {
    document.getElementById("closeModalDiaFestivoButton").click();
  }

  add_update_calendario_trabajo(idAddUpdateCalendarioTrabajo, titulo_AddUpdate, idAddUpdateCalendarioLaboral) {

    if (this.mostrar_add_update_calendarios_trabajo === false) {
        this.mostrar_add_update_calendarios_trabajo = true;

        scroll(0, 0);

        this.idAddUpdateCalendarioTrabajo = idAddUpdateCalendarioTrabajo; // llega null si estamos añadiendo
        this.idAddUpdateCalendarioLaboral = idAddUpdateCalendarioLaboral; // llega 0 si estamos añadiendo
        this.titulo_AddUpdate = titulo_AddUpdate; // llega null si estamos añadiendo

        if (this.idAddUpdateCalendarioTrabajo == null) {
        // crear

        } else {
        // modificar

        }


    } else {
        this.mostrar_add_update_calendarios_trabajo = false;
    }

  }

  borrarConfirm(id){
    this.confirmado = id;
  }

  cancelarConfirm(){
    this.confirmado = null;
  }

  cancelSave() {
    console.log('cancelSave');
    this.mostrar_add_update_calendarios_trabajo = false;
  }

  save() {

    if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

    console.log('save');
    console.log ('idAddUpdateCalendarioTrabajo: ' + this.idAddUpdateCalendarioTrabajo);
    console.log ('idAddUpdateCalendarioLaboral: ' + this.idAddUpdateCalendarioLaboral);
    console.log ('titulo_AddUpdate: ' + this.titulo_AddUpdate);

    // validamos
    if (this.titulo_AddUpdate == null || this.titulo_AddUpdate === '') {
      this.msgError('Nombre no válido');
      return;
    }
    if (this.idAddUpdateCalendarioLaboral == null || this.idAddUpdateCalendarioLaboral === 0) {
      this.msgError('Calendario Festivo no válido');
      return;
    }

    // insert / update
    if (this.idAddUpdateCalendarioTrabajo == null) { // crear

      this.new_calendario_trabajo.id = null;
      this.new_calendario_trabajo.codigo_empresa = this.codigo_empresa;
      this.new_calendario_trabajo.titulo = this.titulo_AddUpdate;
      this.new_calendario_trabajo.id_calendarios_laborales = this.idAddUpdateCalendarioLaboral;
      console.log('Añadir calendario de trabajo:');
      console.log(this.new_calendario_trabajo);

      this._calendarios_trabajoService.addCalendariosTrabajo(this.new_calendario_trabajo).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Calendario de Trabajo creado");
                this.msgModificado('Calendario creado correctamente');
            } else if (response['code'] == 404) {
                console.log('Error: Calendario no creado');
                // this.msgError('Error: Calendario no creado');
                this.msgError(response['message']);
            } else {
                console.log('Error: Calendario no creado');
                // this.msgError('Error: Calendario no creado');
                this.msgError('Error: Calendario no creado');
            }
        }, error => {
            console.log('Ha sucedido un error:');
            console.log(error as any);
            this.msgError('Error: Calendario no creado');
        }
      );


    } else if (this.idAddUpdateCalendarioTrabajo != null) { // modificar

      this.update_calendario_trabajo.id = this.idAddUpdateCalendarioTrabajo;
      this.update_calendario_trabajo.codigo_empresa = this.codigo_empresa;
      this.update_calendario_trabajo.titulo = this.titulo_AddUpdate;
      this.update_calendario_trabajo.id_calendarios_laborales = this.idAddUpdateCalendarioLaboral;
      console.log('Actualizar calendario de trabajo:');
      console.log(this.update_calendario_trabajo);

      this._calendarios_trabajoService.updateCalendariosTrabajo(this.update_calendario_trabajo).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Calendario de Trabajo actualizado");
                this.msgModificado('Calendario actualizado correctamente');
            } else if (response['code'] == 404) {
                console.log('Error: Calendario no actualizado');
                // this.msgError('Error: Calendario no actualizado');
                this.msgError(response['message']);
            } else {
                console.log('Error: Calendario no actualizado');
                // this.msgError('Error: Calendario no actualizado');
                this.msgError('Error: Calendario no actualizado');
            }
        }, error => {
            console.log('Ha sucedido un error:');
            console.log(error as any);
            this.msgError('Error: Calendario no actualizado');
        }
      );

    }

  }

  public msgError(msg: string) {
    console.log('Mensaje error: ' + msg)
    this.msgErrorModificar = msg;
    setTimeout(() =>
    {
        this.msgErrorModificar = '';
    },
    2000);
  }

  public msgModificado(msg: string) {
    console.log('Mensaje: ' + msg)
    this.msgModificar = msg;
    setTimeout(() =>
    {
        this.msgModificar = '';
        this.mostrar_add_update_calendarios_trabajo = false;
        if (this.tab == 1) {
          this.list_calendarios_trabajo();
        }
    },
    1000);
  }

  eliminarCalendarioTrabajo(calendario_trabajo_id) {

    if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

    this._calendarios_trabajoService.deleteCalendariosTrabajo(calendario_trabajo_id).subscribe(
      response => {
          console.log(response);
          if (response['code'] == 200){
              console.log("Calendario de Trabajo eliminado");
              // this.msgModificado('Calendario eliminado correctamente');
              this.toastr.success('Calendario eliminado correctamente.', 'Eliminar calendario');
              if (this.tab == 1) {
                this.list_calendarios_trabajo();
              }
          } else if (response['code'] == 404) {
              console.log('Error: Calendario no eliminado');
              // this.msgError('Error: Calendario no actualizado');
              // this.msgError(response['message']);
              // this.toastr.error('Se ha producido un error: ' + response['message'], 'Eliminar calendario');
              this.toastr.error('No se ha elimado el calendario: posiblemente esté asignado', 'Eliminar calendario');
              this.cancelarConfirm();
          } else {
              console.log('Error: Calendario no eliminado');
              // this.msgError('Error: Calendario no actualizado');
              // this.msgError('Error: Calendario no eliminado');
              this.toastr.error('Se ha producido un error', 'Eliminar calendario');
              this.cancelarConfirm();
          }
      }, error => {
          console.log('Ha sucedido un error:');
          console.log(error as any);
          // this.msgError('Error: Calendario no eliminado');
          this.toastr.error('Se ha producido un error: ' + error as any, 'Eliminar calendario');
          this.cancelarConfirm();
      }
    );

  }

  openModalNuevoCalendarioLaboral() {
    this.nombreValido = true;
    document.getElementById("openModalNuevoCalendarioButton").click(); // truquillo con botón escondido
    this.nombreCalendarioLaboral = "";
  }
  closeModalNuevoCalendarioLaboral() {
    document.getElementById("openModalNuevoCalendarioButton").click();
  }

  addNuevoCalendarioLaboral() {
    this.lista_calendarios_laborales.forEach(el => {
      if (el.titulo == this.nombreCalendarioLaboral) {
        this.nombreValido = false;
      }
    });

    if (this.nombreValido) {
      this.closeModalNuevoCalendarioLaboral();
      this._calendarioslaboralesService.addCalendarioLaboral(this.nombreCalendarioLaboral).subscribe(
        result => {
          if (result['code'] == 200) {
            this.list_calendarios_laborales();
            this.id_calendarios_laborales = result['id'];
            this.toastr.info("Calendario laboral creado con exito", "Nuevo calendario laboral");
            this.nombreValido = true;
            this.editar_calendario_laboral = true;
          }
        }
      );
    } else {
      setTimeout(() => {
        this.nombreValido = true;
      }, 2000);
    }
  }

  openModalEditCalendarioLaboral() {
    if (this.editar_calendario_laboral) {
      this.nombreValido = true;
      document.getElementById("openModalEditCalendarioButton").click(); // truquillo con botón escondido
      this.nombreCalendarioLaboral = this.calendario_laboral.titulo;
    } else {
      this.toastr.warning('Sólo puede editar los calendarios creados por su empresa. Este es del Sistema', 'Editar Calendario Festivo');
    }
  }
  closeModalEditCalendarioLaboral() {
    document.getElementById("openModalEditCalendarioButton").click();
  }

  editCalendarioLaboral() {
    this.lista_calendarios_laborales.forEach(el => {
      if (el.titulo == this.nombreCalendarioLaboral) {
        this.nombreValido = false;
      }
    });

    if (this.nombreValido) {
      this.closeModalEditCalendarioLaboral();
      this._calendarioslaboralesService.editCalendarioLaboral(this.nombreCalendarioLaboral, this.id_calendarios_laborales).subscribe(
        result => {
          if (result['code'] == 200) {
            console.log(result['message']);
            this.list_calendarios_laborales();
            this.nombreCalendarioLaboral = "";
            this.toastr.success("Nombre del calendario modificado con exito", "Modificacion de calendario");
            this.nombreValido = true;
          }
        }
      );
    } else {
      setTimeout(() => {
        this.nombreValido = true;
      }, 2000);
    }
  }

  deleteCalendarioConfirm() {
    this.deleteCalendario = !this.deleteCalendario;
  }

  deleteCalendarioLaboral() {
      this.closeModalEditCalendarioLaboral();
      this._calendarioslaboralesService.deleteCalendarioLaboral(this.id_calendarios_laborales).subscribe(
        result => {
          if (result['code'] == 200) {
            console.log(result['message']);
            this.list_calendarios_laborales();
            this.nombreCalendarioLaboral = "";
            this.id_calendarios_laborales = 0;
            this.toastr.success("Calendario eliminado con exito", "Modificacion de calendario");
          } else {
            this.toastr.error("Ha habido un error al modificar el calendario", "Modificacion de calendario");
          }
        }
      );
  }
}
