import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Empleado } from '../models/empleado';
import { FichajeBusqueda } from '../models/fichaje-busqueda';
import { GLOBAL } from './global.service';
import { ImagenFichaje } from 'src/app/models/img_fichaje';

@Injectable()
export class EmpleadoService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    updateEstadoEmpleado(code, activo: boolean, codigo_empresa){
        return this._http.get(this.url + '/empleado-activo-update/' + code + '/' + activo);
    }

    getEmpleadoFichar(code, password){
        return this._http.get(this.url+'/empleado-fichar/'+code+'/'+password);
    }

    getEmpleado(code, codigo_empresa){
        // code = encodeURIComponent(code);
        console.log(this.url+'/empleado/'+code);
        return this._http.get(this.url+'/empleado/'+code);
    }

    getEmpleados(codigo_empresa){
        return this._http.get(this.url+'/empleados-empresa');
    }

    getEmpleadosBasicInfo(){
        return this._http.get(this.url+'/empleados-basic-info');
    }

    getEmpleadosBajas(codigo_empresa){
        return this._http.get(this.url+'/empleados-bajas');
    }

    getEmpleadosAusencias(codigo_empresa){
        return this._http.get(this.url+'/empleados-ausencias');
    }    

    getEmpleadosVacacionesAhora(codigo_empresa){
        return this._http.get(this.url+'/empleados-vacaciones-ahora');
    }

    getEmpleadosWorking(codigo_empresa){
        return this._http.get(this.url+'/empleados-work_now');
    }

    addEmpleado(empleado: Empleado){
        let json = JSON.stringify(empleado);
        let params = 'json=' + encodeURIComponent(json);
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/empleado', params, {headers: headers});
    }

    updateEmpleado(empleado: Empleado){
        let json = JSON.stringify(empleado);
        let params = 'json=' + encodeURIComponent(json);
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/empleado-update', params, {headers: headers});
    }

    deleteEmpleado(code){
        return this._http.get(this.url+'/empleado-delete/'+code);
    }

    updateEstadoWorking(code, estado: boolean){
        return this._http.get(this.url+'/empleado-work-update/'+code+'/'+estado);
    }

    updateEmpleadoBaja(code, estado: boolean, codigo_empresa){
        return this._http.get(this.url+'/empleado-baja-update/'+code+'/'+estado);
    }

    updateEmpleadoVacaciones(code, estado: boolean, codigo_empresa){
        return this._http.get(this.url+'/empleado-vacaciones-update/'+code+'/'+estado);
    }

    getEmpleadosDepartamento(departamento, codigo_empresa){
        return this._http.get(this.url+'/empleados-departamento/'+departamento);
    }

    getEmpleadosFichaje_dia(fecha, codigo_empresa){
        return this._http.get(this.url+'/fichaje-dia/'+fecha);
    }

    // solo se usa en menu fichajes del menu admin
    getEmpleadosFichajes(fichajeBusqueda: FichajeBusqueda){

        let json = JSON.stringify(fichajeBusqueda);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/fichajes', params, {headers: headers});

    }

    getEmpleadosMenos_horas(codigo_empresa){ // no le veo sentido lo q hace
        return this._http.get(this.url+'/empleados-menos-horas');
    }

    getEmpleadosMas_horas(codigo_empresa){ // no le veo sentido lo q hace
        return this._http.get(this.url+'/empleados-mas-horas');
    }

    getEmpleadosVacaciones(codigo_empresa){
        return this._http.get(this.url+'/empleados-vacaciones');
    }

    makeFileRequest(url: string, params: Array<string>, files: Array<File>){

        

        return new Promise((resolve, reject)=>{

            var formData: any = new FormData();
            var xhr = new XMLHttpRequest();
            
            for(let i = 0; i < files.length; i++) {
                formData.append('uploads[]', files[i], files[i].name);
            }
            
            xhr.onreadystatechange = function(){
                if(xhr.readyState == 4) {
                    if(xhr.status == 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.open("POST", url, true);
            xhr.setRequestHeader('Authorization', localStorage.getItem('token'));
            xhr.send(formData);

        });
    }

    updatePassword(empleado:Empleado) {
        let json = JSON.stringify(empleado);
        let params = 'json=' + encodeURIComponent(json);
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url + '/update-empleado-password', params, {headers: headers});
    }

    sendEmailPassword(empleado:Empleado) {
          let json = JSON.stringify(empleado);
          let params = 'json='+json;
          let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
          // mando el host para poder elegir la cuenta desde donde se manda el correo (no deja mandar desde una q no sea del propio dominio)
          // realmente no haria falta ya, lo tenemos también ya en la avariable $dominio en php
          console.log('/send-email-password/' + GLOBAL.host);
          return this._http.post(this.url + '/send-email-password/' + GLOBAL.host, params, {headers: headers});
    }

    getFotoEmpleado(id) {
        // console.log('Cargar foto horario: ' + id + '. ' + es);
        // return this._http.get(this.url + '/get-foto-fichaje/' + id + '/' + es);

        let fotoEmpleado = new ImagenFichaje(); // uso este objeto mismo, solo necesito pasar el id
        fotoEmpleado.id = id;
        // fotoFichaje.es = es;

        let json = JSON.stringify(fotoEmpleado);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/foto-empleado' , params, {headers: headers});

    }

    getEmpleadosActivos(){
        return this._http.get(this.url + '/empleados-activos-now');
    }

}
