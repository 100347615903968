export class CalendariosTrabajo {

    public id: number;
    public codigo_empresa: string;
    public titulo: string;
    public id_calendarios_laborales: number;

    //Constructor por parametros
    constructor(id: number, codigo_empresa: string, titulo: string, id_calendarios_laborales: number) {
        this.id = id;
        this.codigo_empresa = codigo_empresa;
        this.titulo = titulo;
        this.id_calendarios_laborales = id_calendarios_laborales;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getIdCalendariosLaborales(){
        return this.id_calendarios_laborales;
    }

    setIdCalendariosLaborales(id_calendarios_laborales: number){
        this.id_calendarios_laborales = id_calendarios_laborales;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getTitulo(){
        return this.titulo;
    }

    setTitulo(titulo: string){
        this.titulo = titulo;
    }

}