export class CalculoIncidencias{

    public id: number;
    public codigo_empresa: string;
    public fecha: Date;
    public tipo_incidencias: string;
    public visto: boolean;
    public code: string;

    constructor(id: number, codigo_empresa: string, fecha: Date, tipo_incidencias: string, visto: boolean, code: string){
        this.id = id;
        this.codigo_empresa = codigo_empresa;
        this.fecha = fecha;
        this.tipo_incidencias = tipo_incidencias;
        this.visto = visto;
        this.code = code;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: Date){
        this.fecha = fecha;
    }

    getTipo_incidencias(){
        return this.tipo_incidencias;
    }

    setTipo_incidencias(tipo_incidencias: string){
        this.tipo_incidencias = tipo_incidencias;
    }

    getVisto(){
        return this.visto;
    }

    setVisto(visto: boolean){
        this.visto = visto;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

}