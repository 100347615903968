export class Empleado{

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public password: string;
    public DNI: string;
    public nombre: string;
    public apellidos: string;
    public puesto: string;
    public inicio_contrato: Date;
    public fin_contrato: Date;
    public foto: string;
    public direccion: string;
    public num_seg_social: string;
    public codi_postal: string;
    public provincia: string;
    public localidad: string;
    public email: string;
    public telefono: string;
    public persona_contacto_uno: string;
    public movil_uno: string;
    public persona_contacto_dos: string;
    public movil_dos: string;
    public num_cuenta: string;
    public fecha_nacimiento: Date;
    public genero: string;
    public dni_doble_cara: boolean;
    public tarjeta_seg_social: boolean;
    public documento_banco: boolean;
    public normas_seguridad: boolean;
    public pantalon_talla: string;
    public camiseta_talla: string;
    public zapato_talla: string;
    public num_taquilla: string;
    public entrega_epis: boolean;
    public informacion_formacion: boolean;
    public cuestionario: boolean;
    public vigilancia_salud_rrrmm: boolean;
    public manipulador_alimentos: boolean;
    public carnet_carretillero: boolean;
    public firma_trabajador: boolean;
    public contrato: string;
    public activo: boolean;
    public baja: boolean;
    public vacaciones: boolean;
    public ausencia: boolean;
    public horas_contrato: number;
    public work_now: boolean;
    public camara: boolean;
    public localizacion: boolean;
    public id_calendarios_trabajo: number;
    public created_by: string;
    public updated_by: string;

    constructor(
    id: number,
    code: string,
    codigo_empresa: string,
    password: string,
    DNI: string,
    nombre: string,
    apellidos: string,
    puesto: string,
    inicio_contrato: Date,
    fin_contrato: Date,
    foto: string,
    direccion: string,
    num_seg_social: string,
    codi_postal: string,
    provincia: string,
    localidad: string,
    email: string,
    telefono: string,
    persona_contacto_uno: string,
    movil_uno: string,
    persona_contacto_dos: string,
    movil_dos: string,
    num_cuenta: string,
    fecha_nacimiento: Date,
    genero: string,
    dni_doble_cara: boolean,
    tarjeta_seg_social: boolean,
    documento_banco: boolean,
    normas_seguridad: boolean,
    pantalon_talla: string,
    camiseta_talla: string,
    zapato_talla: string,
    num_taquilla: string,
    entrega_epis: boolean,
    informacion_formacion: boolean,
    cuestionario: boolean,
    vigilancia_salud_rrrmm: boolean,
    manipulador_alimentos: boolean,
    carnet_carretillero: boolean,
    firma_trabajador: boolean,
    contrato: string,
    activo: boolean,
    baja: boolean,
    vacaciones: boolean,
    ausencia: boolean,
    horas_contrato: number,
    work_now: boolean,
    camara: boolean,
    localizacion: boolean,
    id_calendarios_trabajo: number,
    created_by: string,
    updated_by: string) {

        this.id = id;
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.password = password;
        this.DNI = DNI;
        this.nombre = nombre;
        this.apellidos = apellidos;
        this.puesto = puesto;
        this.inicio_contrato = inicio_contrato;
        this.fin_contrato = fin_contrato;
        this.foto = foto;
        this.direccion = direccion;
        this.num_seg_social = num_seg_social;
        this.codi_postal = codi_postal;
        this.provincia = provincia;
        this.localidad = localidad;
        this.email = email;
        this.telefono = telefono;
        this.persona_contacto_uno = persona_contacto_uno;
        this.movil_uno = movil_uno;
        this.persona_contacto_dos = persona_contacto_dos;
        this.movil_dos = movil_dos;
        this.num_cuenta = num_cuenta;
        this.fecha_nacimiento = fecha_nacimiento;
        this.genero = genero;
        this.dni_doble_cara = dni_doble_cara;
        this.tarjeta_seg_social = tarjeta_seg_social;
        this.documento_banco = documento_banco;
        this.normas_seguridad = normas_seguridad;
        this.pantalon_talla = pantalon_talla;
        this.camiseta_talla = camiseta_talla;
        this.zapato_talla = zapato_talla;
        this.num_cuenta = num_cuenta;
        this.num_taquilla = num_taquilla;
        this.entrega_epis = entrega_epis;
        this.informacion_formacion = informacion_formacion;
        this.cuestionario = cuestionario;
        this.vigilancia_salud_rrrmm = vigilancia_salud_rrrmm;
        this.manipulador_alimentos = manipulador_alimentos;
        this.carnet_carretillero = carnet_carretillero;
        this.firma_trabajador = firma_trabajador;
        this.contrato = contrato;
        this.activo = activo;
        this.baja = baja;
        this.vacaciones = vacaciones;
        this.ausencia = ausencia;
        this.horas_contrato = horas_contrato;
        this.work_now = work_now;
        this.camara = camara;
        this.localizacion = localizacion;
        this.id_calendarios_trabajo = id_calendarios_trabajo;
        this.created_by = created_by;
        this.updated_by = updated_by;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    setIdCalendariosTrabajo(id_calendarios_trabajo: number){
        this.id_calendarios_trabajo = id_calendarios_trabajo;
    }

    getIdCalendariosTrabajo(){
        return this.id_calendarios_trabajo;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getPassword(){
        return this.password;
    }

    setPassword(password: string){
        this.password = password;
    }

    getNombre(){
        return this.nombre;
    }

    setNombre(nombre: string){
        this.nombre = nombre;
    }

    getApellidos(){
        return this.apellidos;
    }

    setApellidos(apellidos: string){
        this.apellidos = apellidos;
    }

    getPuesto(){
        return this.puesto;
    }

    setPuesto(puesto: string){
        this.puesto = puesto;
    }

    getInicio_contrato(){
        return this.inicio_contrato;
    }

    setInicio_contrato(inicio_contrato: Date){
        this.inicio_contrato = inicio_contrato;
    }

    getFin_contrato(){
        return this.fin_contrato;
    }

    setFin_contrato(fin_contrato: Date){
        this.fin_contrato = fin_contrato;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getDNI(){
        return this.DNI;
    }

    setDNI(DNI: string){
        this.DNI = DNI;
    }

    getFoto(){
        return this.foto;
    }

    setFoto(foto: string){
        this.foto = foto;
    }

    getDireccion(){
        return this.direccion;
    }

    setDireccion(direccion: string){
        this.direccion = direccion;
    }

    getNum_seg_social(){
        return this.num_seg_social;
    }

    setNum_seg_social(num_seg_social: string){
        this.num_seg_social = num_seg_social;
    }

    getCodi_postal(){
        return this.codi_postal;
    }

    setCodi_postal(codi_postal: string){
        this.codi_postal = codi_postal;
    }

    getProvincia(){
        return this.provincia;
    }

    setProvincia(provincia: string){
        this.provincia = provincia;
    }

    getLocalidad(){
        return this.localidad;
    }

    setLocalidad(localidad: string){
        this.localidad = localidad;
    }

    getEmail(){
        return this.email;
    }

    setEmail(email: string){
        this.email = email;
    }

    getTelefono(){
        return this.telefono;
    }

    setTelefono(telefono: string){
        this.telefono = telefono;
    }

    getPersona_contacto_uno(){
        return this.persona_contacto_uno;
    }

    setPersona_contacto_uno(persona_contacto_uno: string){
        this.persona_contacto_uno = persona_contacto_uno;
    }

    getMovil_uno(){
        return this.movil_uno;
    }

    setMovil_uno(movil_uno: string){
        this.movil_uno = movil_uno;
    }

    getPersona_contacto_dos(){
        return this.persona_contacto_dos;
    }

    setPersona_contacto_dos(persona_contacto_dos: string){
        this.persona_contacto_dos = persona_contacto_dos;
    }

    getMovil_dos(){
        return this.movil_dos;
    }

    setMovil_dos(movil_dos: string){
        this.movil_dos = movil_dos;
    }

    getNum_cuenta(){
        return this.num_cuenta;
    }

    setNum_cuenta(num_cuenta: string){
        this.num_cuenta = num_cuenta;
    }

    getFecha_nacimiento(){
        return this.fecha_nacimiento;
    }

    setFecha_nacimiento(fecha_nacimiento: Date){
        this.fecha_nacimiento = fecha_nacimiento;
    }

    getGenero(){
        return this.genero;
    }

    setGenero(genero: string){
        this.genero = genero;
    }

    getDni_doble_cara(){
        return this.dni_doble_cara;
    }

    setDni_doble_cara(dni_doble_cara: boolean){
        this.dni_doble_cara = dni_doble_cara;
    }

    getTarjeta_seg_social(){
        return this.tarjeta_seg_social;
    }

    setTarjeta_seg_social(tarjeta_seg_social: boolean){
        this.tarjeta_seg_social = tarjeta_seg_social;
    }

    getDocumento_banco(){
        return this.documento_banco;
    }

    setDocumento_banco(documento_banco: boolean){
        this.documento_banco = documento_banco;
    }

    getNormas_seguridad(){
        return this.normas_seguridad;
    }

    setNormas_seguridad(normas_seguridad: boolean){
        this.normas_seguridad = normas_seguridad;
    }

    getPantalon_talla(){
        return this.pantalon_talla;
    }

    setPantalon_talla(pantalon_talla: string){
        this.pantalon_talla = pantalon_talla;
    }

    getCamiseta_talla(){
        this.camiseta_talla;
    }

    setCamiseta_talla(camiseta_talla: string){
        this.camiseta_talla = camiseta_talla;
    }

    getZapato_talla(){
        this.zapato_talla;
    }

    setZapato_talla(zapato_talla: string){
        this.zapato_talla = zapato_talla;
    }

    getNum_taquilla(){
        return this.num_taquilla;
    }

    setNum_taquilla(num_taquilla: string){
        this.num_taquilla = num_taquilla;
    }

    getEntrega_epis(){
        return this.entrega_epis;
    }

    setEntrega_epis(entrega_epis: boolean){
        this.entrega_epis = entrega_epis;
    }

    getInformacion_formacion(){
        return this.informacion_formacion;
    }

    setInformacion_formacion(informacion_formacion: boolean){
        this.informacion_formacion = informacion_formacion;
    }

    getCuestionario(){
        return this.cuestionario;
    }

    setCuestionario(cuestionario: boolean){
        this.cuestionario = cuestionario;
    }

    getVigilancia_salud_rrrmm(){
        return this.vigilancia_salud_rrrmm;
    }

    setVigilancia_salud_rrrmm(vigilancia_salud: boolean){
        this.vigilancia_salud_rrrmm = vigilancia_salud;
    }

    getManipulador_alimentos(){
        return this.manipulador_alimentos;
    }

    setManipulador_alimentos(manipulador_alimentos: boolean){
        this.manipulador_alimentos = manipulador_alimentos;
    }

    getCarnet_carretillero(){
        return this.carnet_carretillero;
    }

    setCarnet_carretillero(carnet_carretillero: boolean){
        this.carnet_carretillero = carnet_carretillero;
    }

    getFirma_trabajador(){
        return this.firma_trabajador;
    }

    setFirma_trabajador(firma_trabajador: boolean){
        this.firma_trabajador = firma_trabajador;
    }

    getContrato(){
        return this.contrato;
    }

    setContrato(contrato: string){
        this.contrato = contrato;
    }

    getActivo(){
        return this.activo;
    }

    setActivo(activo: boolean){
        this.activo = activo;
    }

    getCamara(){
      return this.camara;
    }

    setCamara(camara: boolean){
      this.camara = camara;
    }

    getLocalizacion(){
      return this.localizacion;
    }

    setLocalizacion(localizacion: boolean){
      this.localizacion = localizacion;
    }

    getBaja(){
        return this.baja;
    }

    setBaja(baja: boolean){
        this.baja = baja;
    }

    getVacaciones(){
        return this.vacaciones;
    }

    setVacaciones(vacaciones: boolean){
        this.vacaciones = vacaciones;
    }

    getAusencia(){
        return this.ausencia;
    }

    setAusencia(ausencia: boolean){
        this.ausencia = ausencia;
    }    

    getHoras_contrato(){
        return this.horas_contrato;
    }

    setHoras_contrato(horas_contrato: number){
        this.horas_contrato = horas_contrato;
    }

    getWork_now(){
        return this.work_now;
    }

    setWork_now(work_now: boolean){
        this.work_now = work_now;
    }

}
