import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';


@Component({
    selector : 'registro',
    styleUrls : ['./registro.component.css', '../../../style.scss'],
    templateUrl : './registro.component.html'
})

export class RegistroComponent  {
    public titulo: string;
    public app: AppComponent;

    constructor()
     {}

    ngOnInit(){
        //console.log('Se ha cargado el componente registro');
    }

}