export class DiasFestivos {

    public id: number;
    public id_calendarios_laborales: number;
    public fecha: string;
    public titulo: string;
    public tipo: string;

    //Constructor por parametros
    constructor(id: number, id_calendarios_laborales: number, fecha: string, titulo: string, tipo: string ) {
        this.id = id;
        this.id_calendarios_laborales = id_calendarios_laborales;
        this.fecha = fecha;
        this.titulo = titulo;
        this.tipo = tipo;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: string){
        this.fecha = fecha;
    }

    getIdCalendariosLaborales(){
        return this.id_calendarios_laborales;
    }

    setIdCalendariosLaborales(id_calendarios_laborales: number){
        this.id_calendarios_laborales = id_calendarios_laborales;
    }

    getTipo(){
        return this.tipo;
    }

    setTipo(tipo: string){
        this.tipo = tipo;
    }

    getTitulo(){
        return this.titulo;
    }

    setTitulo(titulo: string){
        this.titulo = titulo;
    }

}