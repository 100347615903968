export class Empresa{

    
    public codigo_empresa: string;
    public nombre_empresa: string;
    public correo: string;
    public password: string; // no se usa
    public num_licencias: number;
    public fecha_renovacion: Date;
    public created_by: string;
    public created_at: Date;
    public updated_by: string;
    public updated_at: Date;
    public activa;
    public contacto: string;
    public telefono: string;
    public CIF: string;
    public direccion: string;
    public CP: string;
    public localidad: string;
    public provincia: string;

    constructor(codigo_empresa: string, nombre_empresa: string, correo: string, num_licencias: number, fecha_renovacion: Date, activa){
        this.codigo_empresa = codigo_empresa;
        this.nombre_empresa = nombre_empresa;
        this.correo = correo;
        this.num_licencias = num_licencias;
        this.fecha_renovacion = fecha_renovacion;
        this.activa = activa;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getNombre_empresa(){
        return this.nombre_empresa;
    }

    setNombre_empresa(nombre_empresa: string){
        this.nombre_empresa = nombre_empresa;
    }

}