import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentosFilter'
})
export class DocumentosFilterPipe implements PipeTransform {

  transform(items:any, filterNombre: string, filterDepartamento: string, filterGenero:string){
    if (items && items.length){
        return items.filter(item => {
            /*
            console.log('items: ');
            console.log(items);
            console.log('filterNombre: ');
            console.log(filterNombre);
            */
           
            /*
            if (filterNombre && item.nombre.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1){
                return false;
            }
            */
            let nombre_apellidos = item.nombre + ' ' + item.apellidos;
            nombre_apellidos = this.filtrar_acentos(nombre_apellidos);
            filterNombre = this.filtrar_acentos(filterNombre);
            if ( (filterNombre && nombre_apellidos.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1) )
            {
                return false;
            }

            if (filterDepartamento && item.puesto.toLowerCase().indexOf(filterDepartamento.toLowerCase()) === -1){
                return false;
            }

            if (filterGenero && item.genero.toLowerCase().indexOf(filterGenero.toLowerCase()) === -1){
                return false;
            }

            return true;
       } );

    } else {
        return items;
    }

}

filtrar_acentos(input) {

    // no sólo acentos; ñ -> n
    // var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
    // var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
    // no sólo acentos; Quito: ñ -> n
    var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç";
    var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc";

    for (var i = 0; i < acentos.length; i++) {
        input = input.replace(acentos.charAt(i), original.charAt(i)).toLowerCase();
    }
    return input;
}

}
