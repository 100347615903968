export class Vacaciones{

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public inicio_vacaciones: Date;
    public fin_vacaciones: Date;
    public observaciones: string;

    constructor(id: number, code: string, codigo_empresa: string, inicio_vacaciones: Date, fin_vacaciones: Date, observaciones: string){
        this.id = id;
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.inicio_vacaciones = inicio_vacaciones;
        this.fin_vacaciones = fin_vacaciones;
        this.observaciones = observaciones;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getObservaciones(){
        return this.observaciones;
    }

    setObservaciones(observaciones: string){
        this.observaciones = observaciones;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getInicio_vacaciones(){
        return this.inicio_vacaciones;
    }

    setInicio_vacaciones(inicio_vacaciones: Date){
        this.inicio_vacaciones = inicio_vacaciones;
    }

    getFin_vacaciones(){
        return this.fin_vacaciones;
    }

    setFin_vacaciones(fin_vacaciones: Date){
        this.fin_vacaciones = fin_vacaciones;
    }

}    