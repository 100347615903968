import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logsFilter'
})
export class LogsFilterPipe implements PipeTransform {

  transform(items: any, filterCode:string, filterFecha:string, filterFechaHasta:string, filterTabla:string, filterDescripcion:string, filterObservaciones: string): any {
    if(items && items.length){
      return items.filter(item => {
        if(filterCode != '' && filterCode != item.usuario){
          return false;
        }
        if(filterFecha != '' && item.fecha < filterFecha){
          return false;
        }
        if(filterFechaHasta != '' && item.fecha >= filterFechaHasta){
          return false;
        }
        if(filterTabla != '' && item.tabla.toLowerCase() != filterTabla.toLowerCase()){
          return false;
        }
        if (filterDescripcion != '' && (item.descripcion.toLowerCase().indexOf(filterDescripcion.toLowerCase()) === -1))  {
          return false;
        }
        if (filterObservaciones != '' && (item.observaciones1.toLowerCase().indexOf(filterObservaciones.toLowerCase()) === -1) && (item.observaciones2.toLowerCase().indexOf(filterObservaciones.toLowerCase()) === -1))  {
          return false;
        }
        return true;
      });
    }else{
      return items;
    }
  }

}
