export class FichajeDia{

    public code: string;
    public codigo_empresa: string;
    public nombre: string;
    public apellidos: string;
    public hora_entrada: number;
    public hora_salida: number;
    public total_horas: number;
    public fecha: Date;
    public incidencias: string;
    public incidencias_salida: string;
    public id:number;
    public latitud_salida: any;
    public longitud_salida: any;
    public foto_salida:string;
    public latitud_entrada: any;
    public longitud_entrada: any;
    public foto:string;
    public hora_entrada_updated_by: string;
    public hora_entrada_updated_at: Date;
    public hora_salida_updated_by: string;
    public hora_salida_updated_at: Date;

    constructor(code: string, codigo_empresa: string, nombre: string, apellidos: string,
        hora_entrada: number, hora_salida: number, total_horas: number, fecha: Date, incidencias: string, id: number, incidencias_salida: string, foto: string, latitud_entrada: any, longitud_entrada: any, foto_salida: string, latitud_salida: any, longitud_salida: any,
        hora_entrada_updated_by: string, hora_entrada_updated_at: Date, hora_salida_updated_by: string, hora_salida_updated_at: Date) {
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.nombre = nombre;
        this.apellidos = apellidos;
        this.hora_entrada = hora_entrada;
        this.hora_salida = hora_salida;
        this.total_horas = total_horas;
        this.fecha = fecha;
        this.incidencias = incidencias;
        this.incidencias_salida = incidencias_salida;
        this.id = id;
        this.foto = foto;
        this.latitud_entrada = latitud_entrada;
        this.longitud_entrada = longitud_entrada;
        this.foto_salida = foto_salida;
        this.latitud_salida = latitud_salida;
        this.longitud_salida = longitud_salida;
        this.hora_entrada_updated_by = hora_entrada_updated_by;
        this.hora_entrada_updated_at = hora_entrada_updated_at;
        this.hora_salida_updated_by = hora_salida_updated_by;
        this.hora_salida_updated_at = hora_salida_updated_at;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    getLogintud() {
        return this.longitud_entrada;
    }

    getLatitud() {
        return this.latitud_entrada;
    }

    getLogintudSalida() {
        return this.longitud_salida;
    }

    getLatitudSalida() {
        return this.latitud_salida;
    }

    getFotoSalida(){
        return this.foto_salida;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getId(){
        return this.id;
    }

    getFoto(){
        return this.foto;
    }


    getNombre(){
        return this.nombre;
    }

    getIncidenciasSalida() {
        return this.incidencias_salida;
    }

    setNombre(nombre: string){
        this.nombre = nombre;
    }

    getApellidos(){
        return this.apellidos;
    }

    setApellidos(apellidos: string){
        this.apellidos = apellidos;
    }

    getHora_entrada(){
        return this.hora_entrada;
    }

    setHora_entrada(hora_entrada: number){
        this.hora_entrada = hora_entrada;
    }

    getHora_salida(){
        return this.hora_salida;
    }

    setHora_salida(hora_salida: number){
        this.hora_salida = hora_salida;
    }

    getTotal_horas(){
        return this.total_horas;
    }

    setTotal_horas(total_horas: number){
        this.total_horas = total_horas;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: Date){
        this.fecha = fecha;
    }

    getIncidencias(){
        return this.incidencias;
    }

    getHora_entrada_updated_by(){
        return this.hora_entrada_updated_by;
    }

    setHora_entrada_updated_by(hora_entrada_updated_by: string){
        this.hora_entrada_updated_by = hora_entrada_updated_by;
    }

    getHora_salida_updated_by(){
        return this.hora_salida_updated_by;
    }

    setHora_salida_updated_by(hora_salida_updated_by: string){
        this.hora_salida_updated_by = hora_salida_updated_by;
    }

    getHora_salida_updated_at(){
        return this.hora_salida_updated_at;
    }

    setHora_salida_updated_at(hora_salida_updated_at: Date){
        this.hora_salida_updated_at = hora_salida_updated_at;
    }

    getHora_entrada_updated_at(){
        return this.hora_entrada_updated_at;
    }

    setHora_entrada_updated_at(hora_entrada_updated_at: Date){
        this.hora_entrada_updated_at = hora_entrada_updated_at;
    }

}
