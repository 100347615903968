import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Google Maps
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';

// interceptor
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';

// Rutas y otros muodulos
import { routing, appRoutingProviders } from './app.routing';
import { WebcamModule } from 'ngx-webcam';
import { ColorPickerModule } from 'ngx-color-picker';

// Guards
import { IdentityGuard } from './services/identity.guard.service';

// Componentes
import { RegistroComponent } from './componentes/registro/registro.component';
import { EntradaComponent } from './componentes/entrada/entrada.component';
import { SalidaComponent } from './componentes/salida/salida.component';
import { ErrorComponent } from './componentes/error/error.component';
import { CorrectComponent } from './componentes/correct/correct.component';
import { EmpleadosComponent } from './componentes/empleados/empleados.component';
import { EmpleadoDetailComponent } from './componentes/empleado-detail/empleado-detail.component';
import { InformesComponent } from './componentes/informes/informes.component';
import { FichajediaComponent } from './componentes/fichajedia/fichajedia.component';
import { CreaempleadosComponent } from './componentes/creaempleados/creaempleados.component';
import { ConfiguracionComponent } from './componentes/configuracion/configuracion.component';
import { AvisosComponent } from './componentes/avisos/avisos.component';
import { DocumentosComponent } from './componentes/documentos/documentos.component';
import { FilterPipe } from './pipes/filter.pipe';
import { EmpleadoModificarComponent } from './componentes/empleado-modificar/empleado-modificar.component';
import { DocumentosFilterPipe } from './pipes/documentos-filter.pipe';
import { DepartamentoComponent } from './componentes/departamentos/departamento.component';
import { CalendariosComponent } from './componentes/calendarios/calendarios.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NotificacionesService } from './services/notificaciones.service';
import { MensajesService } from './services/mensajes.service';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FestivosFilterPipe } from './pipes/festivos-filter.pipe';
import { ConfigEmpresaComponent } from './componentes/config-empresa/config-empresa.component';
import { VacacionesComponent } from './componentes/vacaciones/vacaciones.component';
import { BajasComponent } from './componentes/bajas/bajas.component';
import { AusenciasComponent } from './componentes/ausencias/ausencias.component';
import { VacacionesFilterPipe } from './pipes/vacaciones-filter.pipe';
import { HorasContratoPipe } from './pipes/horas-contrato.pipe';
/* import { FullCalendarModule } from '@fullcalendar/angular'; */ /* https://fullcalendar.io/docs/angular - npm install --save @fullcalendar/angular @fullcalendar/core @fullcalendar/daygrid */

import { UserIdleModule } from "angular-user-idle";
import { LogComponent } from './componentes/log/log.component';
import { LogsFilterPipe } from './pipes/logs-filter.pipe';
import { SolicitudesComponent } from './componentes/solicitudes/solicitudes.component';
import { EmpresasComponent } from './componentes/empresas/empresas.component';
import { DateTimeFormatFilterPipe } from './pipes/date-time-format-filter.pipe';
import { SolicitudVacacionesComponent } from './componentes/solicitud-vacaciones/solicitud-vacaciones.component';
import { InformeConfirmacionComponent } from './componentes/informe-confirmacion/informe-confirmacion.component';

@NgModule({
  declarations: [
    AppComponent,
    RegistroComponent,
    EntradaComponent,
    SalidaComponent,
    ErrorComponent,
    CorrectComponent,
    EmpleadosComponent,
    EmpleadoDetailComponent,
    InformesComponent,
    FichajediaComponent,
    CreaempleadosComponent,
    ConfiguracionComponent,
    AvisosComponent,
    DocumentosComponent,
    FilterPipe,
    EmpleadoModificarComponent,
    DocumentosFilterPipe,
    DepartamentoComponent,
    CalendariosComponent,
    FestivosFilterPipe,
    ConfigEmpresaComponent,
    VacacionesComponent,
    BajasComponent,
    AusenciasComponent,
    VacacionesFilterPipe,
    HorasContratoPipe,
    LogComponent,
    LogsFilterPipe,
    SolicitudesComponent,
    EmpresasComponent,
    DateTimeFormatFilterPipe,
    SolicitudVacacionesComponent,
    InformeConfirmacionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    routing,
    ColorPickerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8Iq4aWzuiXbhIrnFQR3is6pU8FNQSJi4' // control bejornada creada: 14/10/2019 https://developers.google.com/maps/gmp-get-started  https://console.cloud.google.com/project/_/billing/enable (besoftwarebsw@gmail.com)
    }),
    UserIdleModule.forRoot({idle: 1800, timeout: 300, ping: 3600}),// Idle(900) indica cuantos segundos hasta empezar la cuenta atras, timeout segundos de cuenta atras hasta terminar el proceso, ping se repite cada x segundos que indiquemos
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes), `ping` is 120 (2 minutes).
  ],
  providers: [
    DatePipe,
    NotificacionesService,
    MensajesService,
    appRoutingProviders,
    IdentityGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      // tslint:disable-next-line: max-line-length
      multi: true // true: esto permitirá agregar más interceptors si lo requerímos y no sobre escribir nuestro interceptor. En otras palabras, crean un array con el mismo provider token (HTTP_INTERCEPTORS)
    },

  ],

  bootstrap: [AppComponent]
})

export class AppModule { }
// export {NotificacionesService} from './services/notificaciones.service';
// export {MensajesService} from './services/mensajes.service';

