import { Component } from '@angular/core';
import { Empleado } from 'src/app/models/empleado';
import { Usuario } from 'src/app/models/usuario';
import { EmpleadoService } from '../../services/empleado.service';
import { EmpresaService } from '../../services/empresa.service';
import { UsuarioService } from '../../services/usuario.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { GLOBAL } from 'src/app/services/global.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TiposContrato } from 'src/app/models/tipos_contrato';
import { TiposContratoService } from '../../services/tipos-contrato.service';
import { CalendariosTrabajo } from 'src/app/models/calendarios_trabajo';
import { CalendariosTrabajoService } from '../../services/calendarios-trabajo.service';

@Component({
    selector: 'creaempleados',
    templateUrl: './creaempleados.component.html',
    styleUrls : ['./creaempleados.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, UsuarioService, DepartamentosService ]
})

export class CreaempleadosComponent {
    public titulo: string;
    public new_empleado: Empleado;
    public new_usuario: Usuario;
    public codigo_empresa: string;
    public filesToUpload;
    public resultUpload;
    public exito: boolean; //Variable booleana qie indica si la operacion ha tenido exito
    public departamento: Departamentos;
    public file: any;
    public filename:any;
    public contrato;
    public lista_tipos_contrato: Array<TiposContrato>;
    public lista_calendarios_trabajo: Array<CalendariosTrabajo>;
    public empresa:any;
    public empleados;

    constructor(
        private router: Router,
        private _tipos_contratoService: TiposContratoService,
        private _calendarios_trabajoService: CalendariosTrabajoService,
        private _empleadoService: EmpleadoService,
        private _usuarioService: UsuarioService,
        private _departamentosService: DepartamentosService,
        private _route: ActivatedRoute,
        private _empresaService: EmpresaService,
        protected toastr: ToastrService
        ){
        this.titulo = "Creación de Empleados";
        this.new_empleado = new Empleado(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, false, null, null, null, null, false, false, false, false, false, false, false, null, true, false, false, false, null, false, true, true, null, null, null);
        this.new_empleado.password = this.randomString();
        this.new_usuario = new Usuario(0, null, null, null);
        this.empleados = [];
        this.departamento = null;
        this.new_empleado.genero = '';
        this.new_empleado.puesto = '';
        this.new_empleado.contrato = '';
        this.file = '';
        this.lista_tipos_contrato = null;
        this.lista_calendarios_trabajo = null;
        this.empresa = '';
    }

    ngOnInit(){
        // this.getEmpleados();
        this.getEmpresa();
        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });

        this.list_departamentos();
        this.list_tipos_contrato();
        this.list_calendarios_trabajo();
        this.filesToUpload = null;
    }

    randomString() {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
        const stringLength = 15;
        let randomstring = '';
        for (let i = 0; i < stringLength; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
        }
        // console.log('randomstring: ');
        // console.log(randomstring);
        return randomstring;
    }

    //Metodo que se trae todos los departamentos de la base de datos
    list_departamentos(){
        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log('Departamentos conseguidos con exito')
                    this.departamento = response['data'];
                    console.log(this.departamento);

                } else {
                    console.log("Los departamentos no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // Metodo que se trae todos los tipos de contarto de la base de datos
    list_tipos_contrato() {
        console.log('Buscar tipos de contrato de la empresa: ' + this.codigo_empresa);
        this._tipos_contratoService.getTiposContrato(this.codigo_empresa, '1').subscribe( // 1: incluye las generales, no solo las de la empresa
            response => {
                // console.log(response);
                if (response['code'] == 200){
                    console.log('Tipos de contrato conseguidos con exito:');
                    this.lista_tipos_contrato = response['data'];
                    console.log(this.lista_tipos_contrato);

                } else {
                    console.log("Los tipos de contrato no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // Metodo que se trae todos los calendarios de trabajo de la empresa de la base de datos
    list_calendarios_trabajo() {
        console.log('Buscar calendarios de trabajo de la empresa: ' + this.codigo_empresa);
        this._calendarios_trabajoService.getCalendariosTrabajo(this.codigo_empresa).subscribe(
            response => {
                // console.log(response);
                if (response['code'] == 200){
                    console.log('Calendarios de trabajo conseguidos con exito:');
                    this.lista_calendarios_trabajo = response['data'];
                    console.log(this.lista_calendarios_trabajo);

                } else {
                    console.log("Los calendarios de trabajo no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    // Método que devuelve la empresa actual
    getEmpresa(){
        this._empresaService.getEmpresa(GLOBAL.codigo_empresa).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    this.empresa = response['data'];
                    console.log('Empresa cargada:');
                    console.log(this.empresa);
                } else {
                    console.log('Empresa no encontrada');
                }
            }, error => {
                console.log(this.empresa);
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    //Se trae todos los empleados de la base de datos
    getEmpleados(){
        this._empleadoService.getEmpleados(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Empleados encontrados");
                    this.empleados = response['data'];
                    console.log(this.empleados);
                    console.log(this.empleados.length);
                } else {
                    console.log("Empleados no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    esNumero = (val: string): boolean => {

        // isNaN(+'111')
        // Number('9BX9') // NaN
        // string.indexOf(".") == -1;
        console.log('Verificar es numero: ' + val);
        let resultado = !isNaN(Number(val));
        console.log('es numero: ');
        console.log(resultado);
        return resultado;
     }

    valida_horas_contrato(): boolean {

        if (!this.esNumero(String(this.new_empleado.horas_contrato))) {
            this.toastr.error('Las Horas de Contrato deben ser un valor numérico.', 'Modificar Empleado');
            return false;
        } else if (+this.new_empleado.horas_contrato <= 0)  {
            this.toastr.error('Las Horas de Contrato deben ser superiores a 0.', 'Modificar Empleado');
            return false;
        } else {
            return true;
        }

    }

    crear() {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        // validaciones
        console.log('Horas contrato:');
        console.log(this.new_empleado.horas_contrato);
        if ( !this.valida_horas_contrato() ) {
            // this.toastr.warning('NO GUARDO', 'Modificar Empleado');
            return;
        } else {
            // this.toastr.success('SI GUARDO', 'Modificar Empleado');
        }

        //Asignacion valores usuario
        this.new_usuario.code = this.new_empleado.code;
        this.new_usuario.rol = "empleado"; // valores posibles: 'admin' / 'empleado'
        this.new_usuario.codigo_empresa = this.codigo_empresa;

        //Añades a la tabla de usuarios
        this._usuarioService.addUsuario(this.new_usuario).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){

                    console.log("Usuario creado");

                    //Componer los datos que faltan del empleado
                    this.new_empleado.codigo_empresa = this.codigo_empresa;
                    this.new_empleado.created_by = GLOBAL.code;
                    // this.new_empleado.foto = "uploads-"+this.new_empleado.code+"-"+this.new_empleado.codigo_empresa+".png";
                    // this.new_empleado.foto = 'avatar-' + this.codigo_empresa + '-' + this.new_empleado.code + '.jpg';

                    // quito para compatibilidad con mariadb ultimas versiones ( ver nota funcion conversion)
                    // this.new_empleado.fecha_nacimiento = this.conversion(this.new_empleado.fecha_nacimiento);
                    // this.new_empleado.inicio_contrato = this.conversion(this.new_empleado.inicio_contrato);
                    // this.new_empleado.fin_contrato = this.conversion(this.new_empleado.fin_contrato);

                    if (this.new_empleado.contrato == 'null') { this.new_empleado.contrato = null; }

                    console.log('Crear empleado:');
                    console.log(this.new_empleado);
                    //Añades a la tabla de empleado
                    this._empleadoService.addEmpleado(this.new_empleado).subscribe(
                        response => {
                            console.log(response);
                            if (response['code'] == 200) {
                                console.log("Empleado creado");
                                this.exito = true;

                                // enviamos email con la password
                                this._empleadoService.sendEmailPassword(this.new_empleado).subscribe(
                                    result => {
                                        if(result['code'] == 200) {
                                            console.log('Contraseña del usuario enviada por email');
                                            //console.log(result);                                            
                                            this.toastr.success('Contraseña del usuario enviada por email.', 'Crear empleado');
                                        } else {                                            
                                            console.log('Error: ' + result['message']);
                                            this.toastr.warning('No ha sido posible enviar la nueva contraseña al usuario.', 'Crear empleado');
                                        }
                                    },
                                        error => {
                                            this.toastr.warning('No ha sido posible enviar la nueva contraseña al usuario.', 'Crear empleado');
                                            console.log(error as any);
                                        }
                                    );

                                // subimos el avatar si existe
                                if (this.filesToUpload) {
                                    // this._empleadoService.makeFileRequest(GLOBAL.url+'/upload-file/'+this.new_empleado.code+'/'+this.codigo_empresa, [], this.filesToUpload).then(
                                    this._empleadoService.makeFileRequest(GLOBAL.url+'/upload-file-avatar/'+this.codigo_empresa + '/' + GLOBAL.url_fotos_empleados + '/' + this.new_empleado.foto, [], this.filesToUpload).then(
                                        (result) => {
                                            console.log(result);
                                        }, (error) => {
                                            console.log(error);
                                        }
                                    );
                                }

                                // limpiar formulario
                                //this.new_empleado.fin_contrato = null;
                                this.new_empleado = new Empleado(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, false, null, null, null, null, false, false, false, false, false, false, false, null, true, false, false, false, null, false, true, true, null, null, null);
                                this.new_empleado.password = this.randomString();
                                this.new_usuario = new Usuario(0, null, null, null);
                                this.departamento = null;
                                this.list_departamentos();
                                this.toastr.success('El empleado se ha creado correctamente.', 'Crear empleado');
                                this.router.navigate(['/empleados/'+this.codigo_empresa]);
                            } else {
                                this.toastr.error('Se ha producido un error: ' + response['message'], 'Crear empleado');
                                console.log("Empleado no creado");
                                this.exito = false;
                            }
                        }, error => {
                            this.toastr.error('Se ha producido un error: ' + error as any, 'Crear empleado');
                            console.log('Ha sucedido un error');
                            console.log(<any>error);
                            this.exito = false;
                        }
                    );

                } else {

                    console.log("Usuario no creado");
                    let n:number = response['message'].indexOf("Duplicate"); // 'code' duplicado en tabla 'usuario'
                    if ( n === -1) {
                        this.toastr.error('Se ha producido un error: ' + response['message'], 'Crear empleado');
                    } else {
                        this.toastr.error('Código de usuario ya existente ', 'Crear empleado');
                    }
                }
            }, error => {
                this.toastr.error('Se ha producido un error: ' + error as any, 'Crear empleado');
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );

    }

    fileChangeEvent(fileInput: any) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        this.filesToUpload = <Array<File>>fileInput.target.files;
        this.file = fileInput.target.files[0].name;
        console.log(this.file);
        this.new_empleado.foto = 'avatar-' + this.codigo_empresa + '-' + this.new_empleado.code + '.jpg';
        console.log(this.new_empleado.foto);
    }

    conversion(fecha: Date){

        // no lo llamo porque en mariadb ultimas versiones  si hago esto falla porque:
        // manda la fecha en lugar de '2019-07-01' asi: '2019-07-01 formato utc Z' y Z no lo admite la bd

        var caracteres = String(fecha);

        var parts: string[];
        parts = caracteres.split("/");

        var dia = parts[0]

        var diaModificado = dia
        console.log('Dia que saco: ' + diaModificado)

        var conversion = new Date(diaModificado);
        return conversion;
    }

    cambiarBoolean(indice: number, estado: boolean){
        console.log('Funcion: ' + indice + ' -- ' + estado)
        if ( indice == 1 ) {
            this.new_empleado.dni_doble_cara = estado;
        }
        if ( indice == 2 ) {
            this.new_empleado.entrega_epis = estado

        }
        if ( indice == 3 ) {
            this.new_empleado.tarjeta_seg_social = estado

        }
        if ( indice == 4 ) {
            this.new_empleado.informacion_formacion = estado

        }
        if ( indice == 5 ) {
            this.new_empleado.documento_banco = estado

        }
        if ( indice == 6 ) {
            this.new_empleado.cuestionario = estado

        }
        if ( indice == 7 ) {
            this.new_empleado.normas_seguridad = estado

        }
        if ( indice == 8 ) {
            this.new_empleado.vigilancia_salud_rrrmm = estado

        }
        if ( indice == 9 ) {
            this.new_empleado.manipulador_alimentos = estado

        }
        if ( indice == 10 ) {
            this.new_empleado.carnet_carretillero = estado
        }
        if ( indice == 11 ) {
            this.new_empleado.firma_trabajador = estado
        }
    }


}
