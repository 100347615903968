// documentacion
// https://mariogl.com/como-programar-un-proveedor-de-mensajes-para-angular-con-rxjs/

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs'; // Tenemos que importar los observables de la librería RxJS

// Interfaz para la forma del objeto mensaje
interface Mensaje {
  categoria: string;
  contenido: string;
}

@Injectable({
  providedIn: 'root' // Así se establece a partir de Angular 6 el ámbito de la instancia del servicio
})
export class MensajesService {
  private mensajero: BehaviorSubject<Mensaje> = new BehaviorSubject({
    categoria: 'Bienvenida',
    contenido: 'Te has suscrito al sistema de mensajes'
  });

  constructor() { }

  // Método público para quien se quiera suscribir a los mensajes
  public escucha(): Observable<Mensaje> {
    return this.mensajero.asObservable();
  }

  // Método público para quien quiera emitir un mensaje
  public emite(msj: Mensaje): void {
    this.mensajero.next(msj);
    console.log('Se ha emitido el mensaje: ');
    console.log(msj);
  }
}

