import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmpleadoService } from 'src/app/services/empleado.service';
import { Empleado } from 'src/app/models/empleado';
import { TiposContratoService } from '../../services/tipos-contrato.service';
import { TiposContrato } from 'src/app/models/tipos_contrato';
import { CalendariosTrabajo } from 'src/app/models/calendarios_trabajo';
import { CalendariosTrabajoService } from '../../services/calendarios-trabajo.service';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { GLOBAL } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser'; // para mostrar la foto en src cargada por api
import { LogService } from 'src/app/services/log.service';

@Component({
    selector: 'empleado-modificar',
    templateUrl: '../empleado-modificar/empleado-modificar.component.html',
    styleUrls: ['../empleado-modificar/empleado-modificar.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, DepartamentosService ]
})

export class EmpleadoModificarComponent {
    public titulo: string;
    public empleado: Empleado;
    public parametro;
    public codigo_empresa: string;
    public exito: boolean;
    public filesToUpload;
    public global_url_fotos_empleados;
    fecha = new Date();
    public departamento: Departamentos;
    public file: any;
    public activadoOld: boolean;
    public photoLoaded;
    public foto_avatar: any;
    public avatar_activo: number;
    public imageSrc: any;
    public imagen_avatar: string;
    public avatar_aux:string;
    public lista_tipos_contrato: Array<TiposContrato>;
    public lista_calendarios_trabajo: Array<CalendariosTrabajo>;
    public modificarP: boolean;
    public accesoanterior: string;
    public accesoActual: string;
    public empleadosAct: any;

    constructor(
        private _tipos_contratoService: TiposContratoService,
        private _calendarios_trabajoService: CalendariosTrabajoService,
        // tslint:disable-next-line: variable-name
        private _route: ActivatedRoute,
        // tslint:disable-next-line: variable-name
        private _empleadoService: EmpleadoService,
        // tslint:disable-next-line: variable-name
        private _departamentosService: DepartamentosService,
        // tslint:disable-next-line: variable-name
        protected _router: Router,
        private domSanitizer: DomSanitizer,
        protected toastr: ToastrService,
        private _logService: LogService,
        ){
            this.titulo = 'Empleado detail';
            // tslint:disable-next-line: max-line-length
            this.empleado = new Empleado(0, null, null, null,  null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, false, null, null, null, null, false, false, false, false, false, false, false, null, true, false, false, false, null, false, true, true, null, null, null);
            this.departamento = null;
            this.global_url_fotos_empleados = null;
            this.foto_avatar = this.empleado.foto;
            this.lista_tipos_contrato = null;
            this.lista_calendarios_trabajo = null;
            this.photoLoaded = null;
            this.activadoOld = this.empleado.activo;
            this.accesoanterior = '';
            this.accesoActual = '';
            this.modificarP = true;
            this.empleadosAct = [];
        }

    ngOnInit(){
        this._route.params.forEach((params: Params) => {
            this.parametro = params['code'];
            this.codigo_empresa = params['codigo_empresa'];
        });
        this.getEmpleado();
        this.list_departamentos();
        this.list_tipos_contrato();
        this.list_calendarios_trabajo();
        this.global_url_fotos_empleados = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_fotos_empleados;
        console.log(this.global_url_fotos_empleados);
        this.avatar_aux = 'assets/imagenes/silueta-empleado.png';
        this.empleadosActivos();
    }

    //Metodo que se trae todos los departamentos de la base de datos
    list_departamentos(){
        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log('Departamentos conseguidos con exito')
                    this.departamento = response['data'];
                    console.log(this.departamento);

                } else {
                    console.log('Los departamentos no se han podido conseguir');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    // Metodo que se trae todos los tipos de contarto de la base de datos
    list_tipos_contrato() {
        console.log('Buscar tipos de contrato de la empresa: ' + this.codigo_empresa);
        this._tipos_contratoService.getTiposContrato(this.codigo_empresa, '1').subscribe( // 1: incluye las generales, no solo las de la empresa
            response => {
                // console.log(response);
                if (response['code'] == 200){
                    console.log('Tipos de contrato conseguidos con exito:');
                    this.lista_tipos_contrato = response['data'];
                    console.log(this.lista_tipos_contrato);

                } else {
                    console.log("Los tipos de contrato no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // Metodo que se trae todos los calendarios de trabajo de la empresa de la base de datos
    list_calendarios_trabajo() {
        console.log('Buscar calendarios de trabajo de la empresa: ' + this.codigo_empresa);
        this._calendarios_trabajoService.getCalendariosTrabajo(this.codigo_empresa).subscribe(
            response => {
                // console.log(response);
                if (response['code'] == 200){
                    console.log('Calendarios de trabajo conseguidos con exito:');
                    this.lista_calendarios_trabajo = response['data'];
                    console.log(this.lista_calendarios_trabajo);

                } else {
                    console.log("Los calendarios de trabajo no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    deleteAvatar() {

      if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

      this.empleado.foto = null;
      this.file = null;
      this.avatar_aux = 'assets/imagenes/silueta-empleado.png';
      this.avatar_activo = 2;
      console.log(this.avatar_activo);
    }

    getEmpleado() {
        this._empleadoService.getEmpleado(this.parametro, this.codigo_empresa).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    this.empleado = response['data'];
                    this.empleado.password = null;
                    console.log('Empleado cargado:');
                    console.log(this.empleado);
                    if (this.empleado.activo) {
                        this.accesoanterior = "Activado";
                    } else {
                        this.accesoanterior = "Desactivado";
                    }
                    console.log('ACCESO ANTERIOR: ' + this.accesoanterior);
                    console.log('Foto empleado ' + this.empleado.foto);
                    // version cargando la foto por url, ahora lo hago por la api
                    // this.imagen_avatar = this.global_url_fotos_empleados + '/' + this.empleado.foto;
                    if (this.empleado.foto === null || this.empleado.foto === '') {
                        this.avatar_aux = 'assets/imagenes/silueta-empleado.png';
                        this.avatar_activo = 2;
                    } else if (this.empleado.foto !== '') {
                        this.avatar_activo = 1;
                        // cargamos la foto por api:
                        this.getFotoEmpleado(this.empleado.id);
                    }

                } else {
                    console.log('Empleado no encontrado');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    getFotoEmpleado(id) {
        this.photoLoaded = null;
        this._empleadoService.getFotoEmpleado(id).subscribe(
            response => {
                // console.log(response);
                if (response['code'] == 200) {
                    let img = 'data:image/jpeg;base64,' + response['data'];
                    this.photoLoaded = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
                    // console.log('this.photoLoaded: ' + this.photoLoaded);
                    console.log('La foto se ha cargado correctamente');
                } else {
                    console.log('La foto no se ha podido cargar');
                    // muestro imagen alternativa en lugar de toast
                    // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
                }

            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
                // muestro imagen alternativa en lugar de toast
                // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
            }
        );
    }

    cambiarBoolean(indice: number, estado: boolean){
        console.log('Funcion: ' + indice + ' -- ' + estado);
        // tslint:disable-next-line: triple-equals
        if ( indice == 1 ) {
            this.empleado.dni_doble_cara = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 2 ) {
            this.empleado.entrega_epis = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 3 ) {
            this.empleado.tarjeta_seg_social = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 4 ) {
            this.empleado.informacion_formacion = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 5 ) {
            this.empleado.documento_banco = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 6 ) {
            this.empleado.cuestionario = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 7 ) {
            this.empleado.normas_seguridad = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 8 ) {
            this.empleado.vigilancia_salud_rrrmm = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 9 ) {
            this.empleado.manipulador_alimentos = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 10 ) {
            this.empleado.carnet_carretillero = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 11 ) {
            this.empleado.firma_trabajador = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 12 ) {
            this.empleado.activo = estado;
            console.log('this.empleadosAct.length:');
            console.log(this.empleadosAct.length);
            console.log('Licencias: ');
            console.log(this.empleadosAct[0]['num_licencias']);
            // if(this.empleadosAct[0]['num_licencias'] < (this.empleadosAct.length + 1) && this.empleado.activo){ // tenemos en cuenta el administrador creado por defecto
            if( (this.empleadosAct.length >  this.empleadosAct[0]['num_licencias'] ) && this.empleado.activo){ // tenemos en cuenta el administrador creado por defecto
                this.modificarP = false;
                this.toastr.warning("No se pueden activar más usuarios. Verifique la licencia","Activación");

            }else{
                this.modificarP = true;
                // this.toastr.warning("Activación permitida.","Activación");
            }
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 13 ) {
            this.empleado.camara = estado;
        }
        // tslint:disable-next-line: triple-equals
        if ( indice == 14 ) {
            this.empleado.localizacion = estado;
        }
    }

    fileChangeEvent(fileInput: any) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        if (fileInput.target.files && fileInput.target.files[0]) {
            const file = fileInput.target.files[0];

            const reader = new FileReader();
            reader.onload = e => this.imageSrc = reader.result;

            reader.readAsDataURL(file);
        }
        this.filesToUpload = <Array<File>>fileInput.target.files;
        this.file = fileInput.target.files[0].name;
        console.log(this.file);
        this.avatar_activo = 0;
        this.empleado.foto = 'avatar-' + this.codigo_empresa + '-' + this.empleado.code + '.jpg';
        console.log(this.empleado.foto);
    }

    esNumero = (val: string): boolean => {

        // isNaN(+'111')
        // Number('9BX9') // NaN
        // string.indexOf(".") == -1;
        console.log('Verificar es numero: ' + val);
        let resultado = !isNaN(Number(val));
        console.log('es numero: ');
        console.log(resultado);
        return resultado;
     }

    valida_horas_contrato(): boolean {

        if (!this.esNumero(String(this.empleado.horas_contrato))) {
            this.toastr.error('Las Horas de Contrato deben ser un valor numérico.', 'Modificar Empleado');
            return false;
        } else if (+this.empleado.horas_contrato <= 0)  {
            this.toastr.error('Las Horas de Contrato deben ser superiores a 0.', 'Modificar Empleado');
            return false;
        } else {
            return true;
        }

    }

    modificar() {
        // Añades a la tabla de empleado

        // quito para compatibilidad con mariadb ultimas versiones ( ver nota funcion conversion)
        // this.empleado.fecha_nacimiento = this.conversion(this.empleado.fecha_nacimiento);
        // this.empleado.inicio_contrato = this.conversion(this.empleado.inicio_contrato);
        // this.empleado.fin_contrato = this.conversion(this.empleado.fin_contrato);
        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }
        

        if (this.empleado.contrato == 'null') { this.empleado.contrato = null; }
        if(!this.modificarP){
            this.toastr.error("No se puede activar este usuario. Verifique la licencia","Error al activar");
            return;
        }

        // validaciones
        console.log('Horas contrato:');
        console.log(this.empleado.horas_contrato);
        if ( !this.valida_horas_contrato() ) {
            // this.toastr.warning('NO GUARDO', 'Modificar Empleado');
            return;
        } else {
            // this.toastr.success('SI GUARDO', 'Modificar Empleado');
        }

        //if (this.empleado.id_calendarios_trabajo == null) { this.empleado.id_calendarios_trabajo = null; }
        this.empleado.updated_by = GLOBAL.code;
        console.log('Actualizar empleado:');
        console.log(this.empleado);
        if (this.empleado.activo) {
            this.accesoActual = "Activado";
        } else {
            this.accesoActual = "Desactivado";
        }
        console.log('ACCESO ACTUAL: ' + this.accesoActual);

        this._empleadoService.updateEmpleado(this.empleado).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Empleado actualizado');
                    this.exito = true;
                    this.toastr.success('El empleado se ha actualizado correctamente.', 'Actualizar empleado');

                    if (this.empleado.password !== null) {
                        this.toastr.info('La contraseña se ha actualizado.', 'Actualizar empleado');
                        this.sendEmailPassword();
                    }

                    console.log('ACCESO ANTERIOR: ' + this.accesoanterior);
                    console.log('ACCESO ACTUAL: ' + this.accesoActual);
                    if (this.accesoActual != this.accesoanterior) {
                        this._logService.addLogs('Acceso', 'Acceso cambiado a: ' + this.empleado.code, 'Anterior: ' + this.accesoanterior,'Actual: ' + this.accesoActual).subscribe( result => {
                            if(result['code'] == 200){
                                console.log('LOG CAMBIO ACCESO REGISTRADO');
                            } else {
                                console.log('ERROR EN LOG CAMBIO ACCESO REGISTRADO');
                            }
                            this.getEmpleado();
                        });
                    } else {
                        console.log('ACCESO NO CAMBIADO');
                        this.getEmpleado();
                    }                    
                    
                } else if(response['code'] == 202){
                    this.exito = true;
                    this.toastr.warning('No se ha realizado ningun cambio.', 'Actualizar empleado');
                } else {
                    console.log('Empleado no actualizado');
                    this.exito = false;
                    this.toastr.error('Se ha producido un error: ' + response['message'], 'Actualizar empleado');
                }
            }, error => {
                this.toastr.error('Se ha producido un error: ' + error as any, 'Actualizar empleado');
                console.log('Ha sucedido un error');
                console.log(<any> error);
                this.exito = false;
            }
        );

        if (this.filesToUpload) {
                // tslint:disable-next-line: max-line-length
                this._empleadoService.makeFileRequest(GLOBAL.url + '/upload-file-avatar/' + this.codigo_empresa + '/' + GLOBAL.url_fotos_empleados + '/' + this.empleado.foto, [], this.filesToUpload).then((result) => {
                console.log(result);
                this.toastr.success('Foto cambiada.', 'Actualizar empleado');
            }, (error) => {
                console.log(error);
            });
        }
        this._router.navigate(['/empleado-detail', this.parametro, this.codigo_empresa]);
    }

    sendEmailPassword() {
        this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
        this._empleadoService.sendEmailPassword(this.empleado).subscribe(
        result => {
            console.log(result);
            if(result['code'] == 200) {
                console.log('La nueva contraseña se ha enviado por email');
                this.toastr.info('La nueva contraseña se ha enviado por email.', 'Actualizar empleado');
                //console.log(result);
                // this.msgInfo = 'Contraseña del usuario actualizada y enviada por email';
            } else {
                // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
                console.log('Error: ' + result['message']);
                this.toastr.error('Error: no se ha podido enviar la nueva contraseña por email.', 'Actualizar empleado');
            }
        },
            error => {
                // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
                console.log(error as any);
            }
        );
    }

    conversion(fecha: Date){

        // no lo llamo porque en mariadb ultimas versiones  si hago esto falla porque:
        // manda la fecha en lugar de '2019-07-01' asi: '2019-07-01 formato utc Z' y Z no lo admite la bd
        console.log('Convierto fecha:');
        console.log(fecha);

        var caracteres = String(fecha);

        var parts: string[];
        parts = caracteres.split('/');

        var dia = parts[0];

        var diaModificado = dia;
        console.log('Fecha transformada: ' + diaModificado);

        var conversion = new Date(diaModificado);
        return conversion;
    }

    empleadosActivos(){
        this._empleadoService.getEmpleadosActivos().subscribe( result => {
            if(result['code'] == 200){
                this.empleadosAct = result['data'];
            }
        });
    }

}
