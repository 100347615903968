import { Component, OnInit } from '@angular/core';
import { BajaService } from '../../services/baja.service';
import { Bajas } from '../../models/bajas';
import { EmpleadoService } from 'src/app/services/empleado.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { HorarioService } from 'src/app/services/horario.service';
import { DepartamentosService } from 'src/app/services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { Usuario } from '../../models/usuario';
import { DatePipe } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { GLOBAL } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bajas',
  templateUrl: './bajas.component.html',
  styleUrls: ['./bajas.component.css', '../../../style.scss'],
  providers : [EmpleadoService, UsuarioService, HorarioService, DepartamentosService, BajaService, DatePipe],
  animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          ]),
          transition(':leave', [
            style({transform: 'translateX(0)', opacity: 1}),
            animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
          ])
        ]
      )
  ]
})
export class BajasComponent implements OnInit {

  public mostrar_filtro: boolean;
  public filtro: any;
  public codigo_empresa: string;
  public globaladmin;
  public mostrar_modificar: boolean;

  public lista_departamentos: Array<Departamentos>;
  public lista_usuarios: Array<Usuario>;
  public baja: Bajas;
  public fecha_actual;
  public vec_Bajas: Array<any>;

  public modificarInicio_Bajas: any;
  public modificarFin_Bajas: any;
  public nombreModificar: string;
  public apellidosModificar: string;
  public borrar: boolean;

  public modificarObservaciones: string;
  public observaciones: string;

  public modificar: boolean;
  public modificarInicio_Bajas_ant;
  public modificarFin_Bajas_ant;

  //Variables de busqueda y parametro para la llamada desde el menu admin->avisos
  public filterCode = '';
  public filterUsuario = '';
  public filterNombre = '';
  public filterDepartamento = '';
  public filterFecha: any;
  public filterFechaHasta: any;
  public filterHoras_contrato;
  public codeModificar;
  public item;

  constructor(private _bajasService: BajaService, protected toastr: ToastrService, private _empleadoService: EmpleadoService,
    private _usuarioService: UsuarioService,
    private _departamentosService: DepartamentosService) {
      
      this.filterFecha = '';
      this.filterFechaHasta = '';
      this.globaladmin = GLOBAL.esAdmin;
      // this.filterHoras_contrato = 0;
      this.filterHoras_contrato = '';
      this.vec_Bajas = [];
      this.codeModificar = '';
      this.baja = new Bajas(0,null,null,null,null, null);
      this.item = JSON.parse(localStorage.getItem('identity'));
  }

  ngOnInit() {
    this.getBajas();
    this.list_usuarios();
    this.list_departamentos();
  }

  delete(id: number, code: string, inicio_Bajas: string, fin_Bajas: string){
    this._bajasService.deleteBajas(id, code, inicio_Bajas,fin_Bajas).subscribe(
      response => {
        console.log(response);
        if (response['code'] == 200){
            console.log("Eliminado con exito");
            this.toastr.success('Bajas eliminada correctamente.', 'Eliminar Bajas');
            this.controlBajas(); // actualizo el estado de las vacaciones de los empleados
        } else {
          if (response["messageModificacion"]) {
            this.toastr.warning( response["messageModificacion"])
            return
          }
            this.toastr.error('Se ha producido un error.', 'Eliminar Bajas');
            console.log("No se ha podido eliminar Bajas");
        }
        this.getBajas();
      }, error => {
          this.toastr.error('Se ha producido un error.', 'Eliminar Bajas');
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
    );
  }

  getBajas(){
    this.vec_Bajas = [];
    this._empleadoService.getEmpleadosBajas(this.codigo_empresa).subscribe(
      response => {
          if (response['code'] == 200){
              this.vec_Bajas = response['data'];
              console.log("Bajas de empleados encontradas: ");
              console.log(this.vec_Bajas);
          } else {
              console.log("Bajas de empleados no encontradas");
          }
      }, error => {
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
  );
  }

  controlBajas(){
    this._bajasService.automatic_update_bajas(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
          console.log(response);
          if (response['code'] === 200) {
                // console.log('Se ha realizado la actualizacion automática de vacaciones');
                console.log(response['message']);
          } else {
                console.log('Error realizando la actualizacion automática de vacaciones');
          }
      }, error => {
            console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
            console.log(error as any);
      }
    );
  }

  activeFiltrar(index: number) {
    if ( index === 1){ // Muestra filtros
      this.filtro = !this.filtro;
    } else if ( index === 2) { // Muestra crear/modificar Bajas
      this.mostrar_modificar = !this.mostrar_modificar;
    }
  }

  modificarBajas(code, inicio_Bajas , fin_Bajas , nombre , apellidos, observaciones: string) {
    window.scrollTo(0, 0);
    console.log('observaciones: ' + observaciones);
    if (code != null) {
      this.mostrar_modificar = true;
      this.codeModificar = code;
      this.modificarInicio_Bajas_ant = inicio_Bajas;
      this.modificarFin_Bajas_ant = fin_Bajas;
      this.modificarInicio_Bajas = inicio_Bajas;
      this.modificarFin_Bajas = fin_Bajas;
      this.nombreModificar = nombre;
      this.apellidosModificar = apellidos;
      this.modificarObservaciones = observaciones;
      console.log(this.modificarObservaciones);
      this.modificar = true;
      if(this.modificarFin_Bajas == '9999-12-31'){
        this.modificarFin_Bajas = '';
      }
    } else {
      this.cleanForm();
      this.codeModificar = '';
      this.modificar = false;
      this.observaciones = '';
    }
  }

  aplicarBajas(){
    if(this.codeModificar == null || this.codeModificar == ''){
      this.toastr.error("Falta usuario", "Aplicar Bajas");
      return;
    }else if(this.modificarInicio_Bajas == '' || this.modificarInicio_Bajas == null){
      this.toastr.error("Falta fecha de inicio", "Aplicar Bajas");
      return;
    }else if(this.modificarFin_Bajas < this.modificarInicio_Bajas && (this.modificarFin_Bajas != null && this.modificarFin_Bajas != '')){
      this.toastr.error("Fechas incorrectas", "Aplicar Bajas");
      return;
    }else{
      if(this.modificar){
        let Bajas = [this.codeModificar,this.modificarInicio_Bajas_ant,this.modificarFin_Bajas_ant,this.modificarObservaciones];
        let code_upd = this.item['code'];
        this._bajasService.updateBajas(Bajas, this.modificarInicio_Bajas, this.modificarFin_Bajas, code_upd).subscribe(
          response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Eliminado con exito");
                this.toastr.success('Baja modificada correctamente.', 'Modificar Bajas');
                this.controlBajas(); // actualizo el estado de las vacaciones de los empleados
            } else {
              if (response["messageModificacion"]) {
                this.toastr.warning( response["messageModificacion"])
                return
              }
                this.toastr.error('Se ha producido un error.', 'Modificar Bajas');
                console.log("No se ha podido modificar Bajas");
            }
            this.getBajas();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Modificar Bajas');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
        );
      }else{
        let persona = JSON.parse(localStorage.getItem('identity'))['code'];
        this.baja.codigo_empresa = null;
        this.baja.code = this.codeModificar;
        this.baja.inicio_baja = this.modificarInicio_Bajas;
        this.baja.fin_baja = this.modificarFin_Bajas;
        this.baja.id = 0;
        this.baja.observaciones = this.modificarObservaciones;
        this._bajasService.addBaja(this.baja, persona).subscribe(
          response => {
            console.log(response);
            if (response['code'] == 200){
                console.log("Añadida baja con exito");
                this.toastr.success('Bajas añadida correctamente.', 'Añadir Bajas');
                this.controlBajas(); // actualizo el estado de las vacaciones de los empleados
            } else {
              if (response["messageModificacion"]) {
                this.toastr.warning( response["messageModificacion"])
                return
              }
                this.toastr.error('Se ha producido un error.', 'Añadir Bajas');
                console.log("No se ha podido añadir Bajas");
            }
            this.getBajas();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Añadir Bajas');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
        );
      }
      this.cleanForm();
      this.activeFiltrar(2);
    }
  }

  cleanForm(){
    this.codeModificar = null;
    this.modificarInicio_Bajas = '';
    this.modificarFin_Bajas = '';
    this.nombreModificar = '';
    this.apellidosModificar = '';
    this.modificarObservaciones = '';
    console.log('cleanForm');
  }

  nullerValues(index){

    console.log("Llamando metodo nullerValues");

    if (index == 1) {
      this.filterCode = '';
    }
    else if (index == 2) {
      this.filterFecha = '';
    }
    else if (index == 3) {
      this.filterFechaHasta = '';
    }
    else if (index == 4) {
      this.filterNombre = '';
    }
    else if (index == 5) {
      this.filterDepartamento = '';
    }
    else if (index == 6) {
      // this.filterHoras_contrato = 0;
      this.filterHoras_contrato = '';
    }

  }

  //Metodo que se trae todos los departamentos de la base de datos
  list_departamentos(){
    console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
    this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log('Departamentos conseguidos con exito')
                this.lista_departamentos = response['data'];
                console.log(this.lista_departamentos);

            } else {
                console.log("Los departamentos no se han podido conseguir");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios(){

    // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
    if ( GLOBAL.rol !== "admin" ) {

        let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
        this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
        this.lista_usuarios.push(user);

    } else {

        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];
                    console.log(this.lista_usuarios);

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

  }

  
}
