import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Style } from '../models/style';
import { GLOBAL } from './global.service';

@Injectable()
export class StyleService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    getStyle(codigo_empresa){
        return this._http.get(this.url+'/style/'+codigo_empresa);
    }

    updateStyle(style: Style, parte: string){
        let json = JSON.stringify(style);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        if ( parte == 'cabecera' ) {
            return this._http.post(this.url+'/style-cabecera-update', params, {headers: headers});
        }
        if ( parte == 'pie' ) {
            return this._http.post(this.url+'/style-pie-update', params, {headers: headers});
        }
        if ( parte == 'fondo' ) {
            return this._http.post(this.url+'/style-fondo-update', params, {headers: headers});
        }
        if ( parte == 'general' ) {
            return this._http.post(this.url+'/style-general-update', params, {headers: headers});
        }      
    }

    updateIcono(style: Style){
        let json = JSON.stringify(style);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/style-icono-update', params, {headers: headers});
    }

}    