import { Component, ViewChild, ElementRef } from '@angular/core';
import { Empleado } from 'src/app/models/empleado';
import { Router, ActivatedRoute, Params, Event } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { HorarioService } from '../../services/horario.service';
import { ReportesService } from '../../services/reportes.service';
import { Horario } from 'src/app/models/horario';
import { Usuario } from '../../models/usuario';
import { UsuarioService } from '../../services/usuario.service';
import { EmpleadosMas } from 'src/app/models/empleados_mas';
import { EmpleadosMenos } from 'src/app/models/empleados_menos';
import { EmpleadosVacaciones } from '../../models/empleados_vacaciones';
import { EmpleadosAusencias } from '../../models/empleados_ausencias';
import { EmpleadoBaja } from '../../models/empleado_baja';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { GLOBAL } from '../../services/global.service';
import { DatePipe } from '@angular/common';

import { FilterPipe } from '../../pipes/filter.pipe';

import html2canvas from 'html2canvas'; // npm install html2canvas


import * as jsPDF from 'jspdf';
// import jsPDF from 'jspdf';
import 'jspdf-autotable'; // npm install jspdf-autotable
import autoTable from 'jspdf-autotable';
// declare var jsPDF: any;

import * as html2pdf from 'html2pdf.js'; // install: npm i html2pdf.js https://ekoopmans.github.io/html2pdf.js/#:~:text=here%20for%20usage).-,Page%2Dbreaks,By%20default%2C%20html2pdf.
import { FichajeBusqueda } from '../../models/fichaje-busqueda';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, style, animate, transition } from '@angular/animations'; // npm install @angular/animations@latest --save
import { buffer } from 'rxjs/operators';

@Component({
    selector: 'informes',
    templateUrl: './informes.component.html',
    styleUrls: ['./informes.component.css', '../../../style.scss'],
    providers: [EmpleadoService, HorarioService, ReportesService, DepartamentosService, FilterPipe]
})

export class InformesComponent {

    @ViewChild('dataTable') table: ElementRef;
    @ViewChild('logoEmpresa') logoEmpresa: ElementRef;
    // divElement: HTMLElement;

    dataTable: any;
    dtOptions: any;

    public tituloInforme: string = "Informes";
    public lista_usuarios: Array<Usuario>;
    public titulo: string;
    public empleados: Array<Empleado>;
    public departamento: Departamentos;
    public Bajas: Array<EmpleadoBaja>;
    public Vacaciones: Array<EmpleadosVacaciones>;
    public Ausencias: Array<EmpleadosAusencias>;
    public empleados_vacaciones: Array<Empleado>;
    public empleados_working: Array<Empleado>;
    public horarios: Array<Horario>;
    public trabajadores_menos_horas: Array<EmpleadosMenos>;
    public trabajadores_mas_horas: Array<EmpleadosMas>;
    public codigo_empresa: string;
    public pulsacion: boolean;
    public icono: number;
    public mostrar_filtro: boolean;
    public global_php: string;
    public fecha_actual; // facha por defecto -hoy- para los filtros
    public fecha: Date; // fecha y hora para los pie de pagina de los informes
    public fecha_inicio_semana: Date;
    public fecha_fin_semana: Date;
    public global_url_logos: string;
    public logo: string;
    public sNavegador;
    public content_informe;
    public cargandoTabla: boolean;
    public cargandoPDF: boolean;
    public fichajes_resumen: Array<any>;
    public informes_confirmados: true;

    public anchoimgLogo: number = 0; // ancho original
    public altoimgLogo: number = 0; // alto original

    public licencias_superadas;

    // Variables de busqueda
    public filterCode;
    public filterNombre;
    public filterDepartamento;
    public filterGenero;
    public filterFecha;
    public filterFechaHasta;
    public filterHoras_contrato;
    public filterConfirmados;
    public sFiltrosAplicados: string = '';
    public informe_fichajes_detallado: boolean
    public informe_fichajes_resumen: boolean

    public QUITAR_ESTO: boolean; // para ocultar provisionalmente tipos de informes
    public ver_pdf: boolean;

    public checkConfirmado: boolean;
    public confirmados = [];

    constructor(
        private filterPipe: FilterPipe,
        private sanitizer: DomSanitizer,
        protected toastr: ToastrService,
        private datePipe: DatePipe,
        private _usuarioService: UsuarioService,
        private _horarioService: HorarioService,
        private _empleadoService: EmpleadoService,
        private _reportesService: ReportesService,
        private _departamentosService: DepartamentosService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        this.titulo = "Informes";
        this.empleados = [];
        this.Bajas = [];
        this.Vacaciones = [];
        this.empleados_vacaciones = [];
        this.empleados_working = [];
        this.trabajadores_menos_horas = [];
        this.trabajadores_mas_horas = [];
        this.codigo_empresa = null;
        this.pulsacion = false;
        this.icono = 0;
        this.mostrar_filtro = false;
        this.filterCode = '';
        this.filterNombre = '';
        this.filterDepartamento = '';
        this.filterGenero = '';
        this.filterFecha = '';
        this.filterFechaHasta = '';
        this.filterHoras_contrato = '';
        this.filterConfirmados = '';
        this.sFiltrosAplicados = '';
        this.departamento = null;
        this.QUITAR_ESTO = false; // para ocultar provisionalmente tipos de informes
        this.ver_pdf = false;
        this.fecha = new Date();
        this.global_url_logos = '';
        this.logo = '';
        this.content_informe = null;
        this.cargandoPDF = false;
        this.cargandoTabla = false;
        this.lista_usuarios = null;
        this.fichajes_resumen = [];
        this.licencias_superadas = GLOBAL.licencias_superadas;
        this.checkConfirmado = false;
        this.informe_fichajes_detallado =true;
        this.informe_fichajes_resumen = false;
        

    }

// @@@@@@@@@@@@@@  llamar descomentar estas funciones en el caso de que se quiera coger la hora desde el cliente en vez de servidor

//     getMondayToSunday() {
//         var curr = new Date; 
//         var first = curr.getDate() - curr.getDay() +1; 
//         var last = first + 6;
    
//         var firstday = new Date(curr.setDate(first)).toUTCString();
//         var lastday = new Date(curr.setDate(last)).toUTCString();
        
//         this.filterFecha = this.dateformatYMD(firstday)
//         this.filterFechaHasta =  this.dateformatYMD(lastday)
//         this.fecha_actual =  this.dateTimeYMD_HMS(curr)
//         this.list_departamentos();
//         console.clear()
//         console.log(this.filterFecha + "  " +this.filterFechaHasta)
    
//     }
    
//     dateformatYMD(DATE){
//           var newDate = new Date(DATE);
//           let month = '' + (newDate.getMonth() + 1);
//           if (parseInt(month) < 10) {
//             month = "0" + month;
//           }
//           let day = '' + newDate.getDate();
//           if (parseInt(day) < 10) {
//             day = "0" + day;
//           }
//           let year = newDate.getFullYear();
//           let date = year + "-" + month + "-" + day
//           return date
//     }

//     dateTimeYMD_HMS(DATE){
//         var newDate = new Date(DATE);
//         let month = '' + (newDate.getMonth() + 1);
//         if (parseInt(month) < 10) {
//           month = "0" + month;
//         }
//         let day = '' + newDate.getDate();
//         if (parseInt(day) < 10) {
//           day = "0" + day;
//         }
        
//         let hour :any= newDate.getHours()
//         if (hour < 10) {
//             hour = "0"+hour
//         }

//         let min :any= newDate.getMinutes()
//         if (min < 10) {
//             min = "0"+min
//         }

//         let sec :any= newDate.getSeconds()
//         if (sec < 10) {
//             sec = "0"+sec
//         }
        
//         let year = newDate.getFullYear();
//         let date = year + "-" + month + "-" + day+" " +hour + ":" + min + ":" + sec;
//         return date
//   }

    ngOnInit() {

        this._route.params.forEach((params: Params) => {
            this.codigo_empresa = params['codigo_empresa'];
        });

        this.global_php = GLOBAL.url;
        this.global_url_logos = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_logos;
        this.logo = this.global_url_logos + '/' + GLOBAL.logo;
        console.log('Logo empresa: ' + this.logo);

        this.fechaActual();
        this.getFechayHoraActual(); // -> this.fecha
        // this.list_departamentos(); lo llamo ahora al recuperar la fecha
        this.list_usuarios();

        /*
        setTimeout(() =>
        {
            // ignoramos por ahora estos 2 informes, no lo hace bien. en OnInit() supongo porque tarda mas
            this.controlHorasTrabajador();
        },
        500);
        */

    }

    // Metodo que se trae todos los departamentos de la base de datos
    list_departamentos() {

        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log('Departamentos conseguidos con exito')
                    this.departamento = response['data'];

                } else {
                    console.log("Los departamentos no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }


    //Metodo que se trae todos los usuarios de la base de datos
    list_usuarios() {
        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];
                    console.log(this.lista_usuarios);

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    inicializa_filtros() {

        if (this.icono === 8) { // informe de fichajes, fechas por defecto inicio y final de la semana actual
            this.filterFecha = this.fecha_inicio_semana;
            this.filterFechaHasta = this.fecha_fin_semana;
        } else if (this.icono === 4) { // trabajando actualmente
        } else {
            this.filterFecha = this.fecha_actual;
            this.filterFechaHasta = this.fecha_actual;
        }

        this.filterCode = '';
        this.filterNombre = '';
        this.filterDepartamento = '';
        this.filterGenero = '';
        this.filterHoras_contrato = '';
        this.filterConfirmados = '';
        this.sFiltrosAplicados = '';
    }

    fechaActual() {
        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    this.fecha_actual = response['data'];
                    console.log('Fecha actual recuperada: ' + response['data']);

                    // obtengo las fechas del primer y ultimo dia de la semana
                    this._horarioService.getFechasInicioFinSemana(this.fecha_actual).subscribe(
                        response2 => {
                            if (response2['code'] === 200) {
                                console.log('Fechas inicio y fin de semana conseguidas:');
                                // console.log(response2);
                                //this.fecha_actual = response['data'];
                                this.fecha_inicio_semana = response2['data']['fechaInicioSemana'];
                                console.log('Inicio Semana:');
                                console.log(this.fecha_inicio_semana);
                                this.fecha_fin_semana = response2['data']['fechaFinSemana'];
                                console.log('Fin Semana:');
                                console.log(this.fecha_fin_semana);
                            } else {
                                console.log('Las fechas inicio y fin de la semana no se han podido conseguir');
                            }
                        }, error => {
                            console.log('Ha sucedido un error en fechaInicioFinSemana');
                            console.log(error as any);
                            // alert('No hay conexión con el Servidor');
                        }

                    );

                } else {
                    console.log('No se ha podido conseguir la fecha actual');
                }
                this.list_departamentos();
            },
            error => {
                console.log(error as any);
                this.list_departamentos();
            }
        );
    }

    getFechayHoraActual() {

        this._horarioService.getFechayHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('fecha y hora actual recuperada: ' + response['data']);
                    this.fecha = response['data'];
                    console.log('response: this.fecha: ' + this.fecha);
                } else {
                    console.log('No se ha podido conseguir la fecha y hora actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    // Metodo que limpia el valor de las variables de busqueda 
    nullerValues(index) {

        if (index == 1) {
            this.filterNombre = '';
        }
        else if (index == 2) {
            this.filterDepartamento = '';
        }
        else if (index == 3) {
            this.filterGenero = ''; // se ha ocultado, no lo usamos
        }
        else if (index == 4) {
            this.filterFecha = '';
        }
        else if (index == 5) {
            this.filterHoras_contrato = '';
        }
        else if (index == 6) {
            this.filterFechaHasta = '';
        }
        else if (index == 7) {
            this.filterCode = '';
        }
    }

    // Activa o desactiva el div que muestra los filtros de busqueda
    activeFiltrar() {
        if (this.mostrar_filtro == true) {
            this.mostrar_filtro = false;
        } else {
            this.mostrar_filtro = true;
        }
    }

    iconoPulsado(pulsacion, icono) {
        this.pulsacion = pulsacion;
        this.icono = icono;
        this.ver_pdf = false;
        this.cargandoTabla = false;
        this.cargandoPDF = false;

        this.inicializa_filtros();

        if (icono == 0) {
            this.tituloInforme = "Informes";
        } else if (icono == 1) {
            this.tituloInforme = "Informe Vacaciones";
            this.getVacaciones();
        } else if (icono == 2) {
            this.tituloInforme = "Informe Bajas";
            this.getBajas();
        } else if (icono == 3) {
            this.tituloInforme = "Informe Ausencias";
            this.getAusencias();
        } else if (icono == 4) {
            this.tituloInforme = "Informe Trabajando";
            this.getEmpleadosWorking();
        } else if (icono == 5) {
            // lo carga en init(). ahora mismo desactivado. getEmpleadosMenos_horas
        } else if (icono == 6) {
            // lo carga en init(). ahora mismo desactivado. getEmpleadosMas_horas
        } else if (icono == 7) {
            this.getEmpleadosVacacionesAhora();
        } else if (icono == 8) {
            this.tituloInforme = "Informe Registros E/S";
            // this.getEmpleadosVacacionesAhora(); // probamos a generar el pdf en php
        }
    }


    cambiarBoolean(indice: number) {
        // tslint:disable-next-line: triple-equals
        if (indice == 1) {
            this.informe_fichajes_resumen = false; //si clicamos en el detallado el resumen es false

        } else if (indice == 2) {
            this.informe_fichajes_detallado = false // contrario al arriba
        } 
    }


    // Metodo que trae de la base de datos las vacaciones de empleados
    getVacaciones() {

        // $sql = "SELECT empleado.code, empleado.codigo_empresa, empleado.nombre, empleado.apellidos, empleado.puesto, empleado.horas_contrato, empleado.genero,
        //                vacaciones.inicio_vacaciones, vacaciones.fin_vacaciones
        //         FROM empleado, vacaciones
        //               WHERE empleado.activo = 1 AND empleado.code=vacaciones.code AND empleado.codigo_empresa='$codigo_empresa' AND vacaciones.codigo_empresa = '$codigo_empresa'
        //                  order by vacaciones.inicio_vacaciones desc;";
        this._empleadoService.getEmpleadosVacaciones(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    this.Vacaciones = response['data'];
                    console.log("Vacaciones de empleados encontradas: ");
                    console.log(this.Vacaciones);

                } else {
                    console.log("Vacaciones de empleados no encontradas");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que trae de la base de datos las bajas de empleados
    getBajas() {

        // $sql = "SELECT empleado.id, empleado.code, empleado.codigo_empresa, empleado.nombre, empleado.apellidos, empleado.puesto,
        //                bajas.inicio_baja, bajas.fin_baja
        //         FROM empleado, bajas
        //              WHERE empleado.activo = 1 AND empleado.code = bajas.code
        //              AND   empleado.codigo_empresa = '$codigo_empresa' AND bajas.codigo_empresa = '$codigo_empresa' order by inicio_baja desc;";
        this._empleadoService.getEmpleadosBajas(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log("Bajas de empleados encontradas: ");
                    this.Bajas = response['data'];
                    console.log(this.Bajas);
                } else {
                    console.log("Bajas de empleados no encontradas");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que trae de la base de datos las ausencias de empleados
    getAusencias() {

        // $sql = "SELECT empleado.id, empleado.code, empleado.codigo_empresa, empleado.nombre, empleado.apellidos, empleado.puesto, 
        //          ausencias.inicio_ausencias, ausencias.fin_ausencias " .
        //	    	" FROM empleado, ausencias " . 
        //		    " WHERE empleado.activo = 1 AND empleado.code = ausencias.code 
        //                AND empleado.codigo_empresa = '$codigo_empresa' AND bajas.codigo_empresa = '$codigo_empresa' order by inicio_ausencias desc;";
        this._empleadoService.getEmpleadosAusencias(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log("Ausencias de empleados encontradas: ");
                    this.Ausencias = response['data'];
                    console.log(this.Ausencias);
                } else {
                    console.log("Ausencias de empleados no encontradas");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Se trae los empleados que estan en ese momento de vacaciones
    getEmpleadosVacacionesAhora() {

        // $sql = "SELECT * FROM empleado WHERE activo = 1 AND vacaciones = true AND codigo_empresa = '$codigo_empresa';";
        this._empleadoService.getEmpleadosVacacionesAhora(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log("Empleados de vacaciones encontrados");
                    this.empleados_vacaciones = response['data'];

                } else {
                    console.log("Empleados de vacaciones no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que trae de la base de datos los empleados que estan en ese momento trabajando
    getEmpleadosWorking() {

        // $sql = "SELECT * FROM empleado WHERE activo = 1 AND work_now = true AND codigo_empresa = '$codigo_empresa';";
        this._empleadoService.getEmpleadosWorking(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {
                    this.empleados_working = response['data'];
                    console.log("Empleados Working encontrados: ");
                    console.log(this.empleados_working);
                } else {
                    console.log("Empleados Working no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Control horas trabajador
    controlHorasTrabajador() {

        //Adquiere los empleados que echan menos horas de su contrato
        this._empleadoService.getEmpleadosMenos_horas(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log("Empleados menos horas encontrados");
                    this.trabajadores_menos_horas = response['data'];

                } else {
                    console.log("Empleados menos horas no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

        //Adquiere los empleados que echan mas horas de las de su contrato
        this._empleadoService.getEmpleadosMas_horas(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200) {

                    console.log("Empleados mas horas encontrados");
                    this.trabajadores_mas_horas = response['data'];

                } else {
                    console.log("Empleados mas horas no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // INFORME FICHAJES: USAMOS TCPDF EN API

    b64PDFtoBlob(dataURI) {

        // para convertir la respuesta de la api en b64 a Blog
        // var byteString = atob(dataURI.split(',')[1]);
        var byteString = atob(dataURI);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // return new Blob([ab], { type: 'image/jpeg' });
        return new Blob([ab], { type: 'application/pdf' });
    }

    cargarReportAPI(tituloInforme: string, cargarPDF, download) {
        console.log('Filtros:');
        console.log('code: ' + this.filterCode);
        console.log('nombre: ' + this.filterNombre);
        console.log('departamento: ' + this.filterDepartamento);
        console.log('fecha_desde: ' + this.filterFecha);
        console.log('fecha_hasta: ' + this.filterFechaHasta);
        console.log('horas_contrato: ' + this.filterHoras_contrato);
        this.cargandoPDF = true;

        console.log('cargarPDF: ');
        console.log(cargarPDF);

        if (download) {
            tituloInforme = 'Bejornada_Registros' + (this.informe_fichajes_resumen ? '_Resumen' : '');
            tituloInforme += (this.informe_fichajes_detallado ? '_detallado' : '');
            tituloInforme += (this.filterCode ? '_' + this.filterCode : '');
            tituloInforme += (this.filterDepartamento ? '_' + this.filterDepartamento : '');
            tituloInforme += (this.filterFecha ? '_desde_' + this.filterFecha : '');
            tituloInforme += (this.filterFechaHasta ? '_hasta_' + this.filterFechaHasta : '');
            tituloInforme += (this.filterHoras_contrato ? '_contrato_' + this.filterHoras_contrato : '');
        }

        let fichajeBusqueda = new FichajeBusqueda(null, null, null, null, null, null, null, null, null, null);
        fichajeBusqueda.codigo_empresa = this.codigo_empresa;
        fichajeBusqueda.code = this.filterCode;
        fichajeBusqueda.nombre = this.filterNombre;
        fichajeBusqueda.departamento = this.filterDepartamento;
        fichajeBusqueda.fecha = this.filterFecha;
        fichajeBusqueda.fechaHasta = this.filterFechaHasta;
        fichajeBusqueda.horas_contrato = this.filterHoras_contrato;
        fichajeBusqueda.confirmados = this.filterConfirmados;

        // fichajeBusqueda.incidencias_salida;
        // fichajeBusqueda.incidencias;

        console.log('Buscar fichajes: ');
        console.log('codigo_empresa: ' + this.codigo_empresa);
        console.log('tituloInforme: ' + tituloInforme);
        console.log('Resumen: ' + this.informe_fichajes_resumen);
        console.log('Detallado: ' + this.informe_fichajes_detallado);
        console.log('logo: ' + GLOBAL.logo);
        console.log(fichajeBusqueda);

        this._reportesService.createReportFichajes(this.codigo_empresa, fichajeBusqueda, tituloInforme, GLOBAL.logo, this.informe_fichajes_detallado, this.informe_fichajes_resumen).subscribe(
            response => {
                if (response['code'] == 200) {
                    // mostramos por pantalla el resumen siempre, queramos o no el pdf
                    let array_resumen:any = response['resumen']
                    try {
                        array_resumen = Object.values(response['resumen']);
                    } catch (error) {
                        
                    }
                    this.fichajes_resumen = array_resumen;
                    this.confirmados = response['confirmados'];
                    if (Object.keys(response["fecha_por_defecto"]).length > 0) {
                        this.filterFecha = response["fecha_por_defecto"]["inicio"];
                        this.filterFechaHasta = response["fecha_por_defecto"]["final"];
                    }
                    // mostramoos en una nueva ventana el pdf o lo descargamos directamente
                    if (cargarPDF) {
                        if (download) {
                            this.toastr.success('Si no se descarga, compruebe si el navegador lo bloquea', 'Informe PDF cargado.');
                        } else {
                            this.toastr.success('Si no se muestra, compruebe si tiene los Poupus bloqueados', 'Informe PDF cargado.');
                        }

                        let fblob = this.b64PDFtoBlob(response['data']);
                        // mostramos embebido: no va
                        // this.content_informe = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(fblob));
                        // mostramos en nueva ventana
                        // let download = false;
                        this.mostrarBLOB(fblob, tituloInforme, download);
                    }
                    this.cargandoPDF = false;

                } else {
                    console.log("Informe no creado");
                    this.toastr.error('Se ha producido un error', 'Cargar Informe PDF');
                    this.cargandoPDF = false;
                }

            }, error => {
                this.toastr.error('Se ha producido un error', 'Cargar Informe PDF');
                console.log('Ha sucedido un error');
                console.log(<any>error);
                this.cargandoPDF = false;
            }
        );

    }

    // RESTO INFORMES. USAMOS IMPRESION DESDE PANTALLA, NO LA API
    probarPDF() {
        console.log('probarPDF');
        var doc = new jsPDF();
        doc.text(20, 20, 'Hello world!');
        doc.text(20, 30, 'This is client-side Javascript, pumping out a PDF.');
        doc.addPage();
        doc.text(20, 20, 'Do you like that?');

        // Save the PDF
        doc.save('Test.pdf');
    }

    // JSPDF AUTO-TABLES
    headRows(informe: string) {
        if (informe === 'trabajando') {
            return [
                { apellidos: 'Apellidos', nombre: 'Nombre', departamento: 'Dpto.' },
            ];
        }
        else {
            return [
                { apellidos: 'Apellidos', nombre: 'Nombre', departamento: 'Dpto.', inicio: 'F. Inicio', fin: 'F. Fin', observaciones: 'Observ.' },
            ];
        }
    }

    /*
    function footRows() {
      return [
        { id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' },
      ]
    }

    function columns() {
      return [
        { header: 'ID', dataKey: 'id' },
        { header: 'Name', dataKey: 'name' },
        { header: 'Email', dataKey: 'email' },
        { header: 'City', dataKey: 'city' },
        { header: 'Exp', dataKey: 'expenses' },
      ]
    }
    */

    bodyRows(informe, data) {
        // var body = data;
        let body = [];

        if (informe === 'trabajando') {

            for (let elemento of data) {

                body.push({
                    nombre: elemento['nombre'],
                    apellidos: elemento['apellidos'],
                    departamento: elemento['puesto'],
                });
            }

        } else {

            for (let elemento of data) {
                let adesde: any;
                let ahasta: any;
                switch (informe) {
                    case 'vacaciones': {
                        adesde = elemento['inicio_vacaciones'].split(/[^0-9]/);
                        ahasta = elemento['fin_vacaciones'].split(/[^0-9]/);
                        break;
                    }
                    case 'bajas': {
                        adesde = elemento['inicio_baja'].split(/[^0-9]/);
                        ahasta = elemento['fin_baja'].split(/[^0-9]/);
                        break;
                    }
                    case 'ausencias': {
                        adesde = elemento['inicio_ausencias'].split(/[^0-9]/);
                        ahasta = elemento['fin_ausencias'].split(/[^0-9]/);
                        break;
                    }
                }

                let fdesde = new Date(+adesde[0], +adesde[1] - 1, +adesde[2]);
                let fhasta = new Date(+ahasta[0], +ahasta[1] - 1, +ahasta[2]);

                body.push({
                    nombre: elemento['nombre'],
                    apellidos: elemento['apellidos'],
                    departamento: elemento['puesto'],
                    inicio: this.datePipe.transform(fdesde, 'dd/MM/yy'),
                    fin: this.datePipe.transform(fhasta, 'dd/MM/yy'),
                    observaciones: elemento['observaciones']
                });
            }

        }

        return body;
    }

    generarPDF_JSPDF_AUTOTABLE(tituloInforme: string, element, download) {

        // const div = document.getElementById(element);
        const div = document.getElementById('print');
        const options: Partial<any> = {
            background: 'white',
            //scale: 1
            // size: '70px',
            pagesplit: true,
            // margin: 3,
            // width: 170
        };

        this.cargandoPDF = true;
        this.ver_pdf = true; // para ocultar iconos q no se soportan
        // mejor haber usado la etiqueta: <div data-html2canvas-ignore 

        // damos tiempo a q se oculten los iconos
        //setTimeout(() => {

        //html2canvas(div, options).then((canvas) => {

        var doc = new jsPDF('p', 'mm', 'a4');
        doc.setProperties({
            title: tituloInforme,
        });

        // https://github.com/simonbengtsson/jsPDF-AutoTable
        // https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js

        // var doc = new jsPDF();
        var totalPagesExp = '{total_pages_count_string}';

        // doc.autoTable({
        // autoTable(doc,{ html: '#my-table' })

        let data: any;
        let titulo: string;
        switch (element) {
            case 'vacaciones': {
                data = this.filterPipe.transform(this.Vacaciones, this.filterNombre, this.filterDepartamento, this.filterGenero, this.filterFecha, this.filterFechaHasta, this.filterHoras_contrato, 1);
                titulo = "VACACIONES";
                break;
            }
            case 'bajas': {
                data = this.filterPipe.transform(this.Bajas, this.filterNombre, this.filterDepartamento, this.filterGenero, this.filterFecha, this.filterFechaHasta, this.filterHoras_contrato, 2);
                titulo = "BAJAS";
                break;
            }
            case 'ausencias': {
                data = this.filterPipe.transform(this.Ausencias, this.filterNombre, this.filterDepartamento, this.filterGenero, this.filterFecha, this.filterFechaHasta, this.filterHoras_contrato, 3);
                titulo = "AUSENCIAS";
                break;
            }
            case 'trabajando': {

                data = this.filterPipe.transform(this.empleados_working, this.filterNombre, this.filterDepartamento, this.filterGenero, null, null, this.filterHoras_contrato, 4);
                titulo = "TRABAJANDO";
                break;
            }
        }

        autoTable(doc, {
            // html: element,
            head: this.headRows(element),
            body: this.bodyRows(element, data),
            rowPageBreak: 'avoid',
            didDrawPage: (data) => {

                // Header (icono+texto)
                /*
                doc.setFontSize(20);
                doc.setTextColor(40);
                if (base64Img) {
                  doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 10, 10);
                }
                doc.text('Report', data.settings.margin.left + 15, 22);
                */

                // Header Logo
                var imgLogo = new Image();
                // imgLogo.src = '../assets/imagenes/logo-bejornada.png';
                imgLogo.src = this.logo; // logo de la empresa logada
                let logoX = 0;
                let logoY = 0;
                let imgProps = (<any>doc).getImageProperties(imgLogo);

                // calculamos el alto de la imagen segun el ancho que queremos 
                // let pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // imagen ancho 100%
                /*
                let pdfWidth = doc.internal.pageSize.getWidth() / 2;
                logoX = (doc.internal.pageSize.getWidth() - pdfWidth) / 2; // imagen centrada
                let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                let logoWidth = pdfWidth;
                let logoHeight = pdfHeight;
                */

                // calculamos el ancho de la imagen segun el alto que queremos (28)
                let altura = 28;
                let ratio = imgProps.height / altura;
                let logoWidth = imgProps.width / ratio;
                let logoHeight = imgProps.height / ratio;
                logoX = (doc.internal.pageSize.getWidth() - logoWidth) / 2; // imagen centrada

                doc.addImage(imgLogo, 'PNG', logoX, logoY, logoWidth, logoHeight, undefined, 'FAST');


                // Header Titulo
                doc.setFontSize(12);
                doc.setTextColor(38);
                doc.text(data.settings.margin.left, 29, titulo);

                // Header fecha
                let fechaY = logoHeight + logoY;
                /* para compatibilidad con safari */
                let fFecha: Date;
                // asi suma una hora ¿¿¿????
                // fFecha = new Date(String(this.fecha).replace(/\s/, 'T')); // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
                let aFecha = String(this.fecha).split(/[^0-9]/);
                fFecha = new Date(+aFecha[0], +aFecha[1] - 1, +aFecha[2], +aFecha[3], +aFecha[4], +aFecha[5]);
                // doc.text(5, pdfHeight, this.datePipe.transform(this.fecha, 'dd/MM/yyyy  H:mm'));
                doc.setFontSize(8);
                doc.setTextColor(0);
                // doc.text(data.settings.margin.left, 28, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari                                        
                // doc.text(170, 33, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari


                this.sFiltrosAplicados = '';
                if (this.icono === 1 || this.icono === 2 || this.icono === 3) {
                    this.sFiltrosAplicados += (this.filterFecha ? '   Desde: ' + this.filterFecha : '');
                    this.sFiltrosAplicados += (this.filterFechaHasta ? '   Hasta: ' + this.filterFechaHasta : '');
                }
                this.sFiltrosAplicados += (this.filterCode ? '   Empleados: ' + this.filterCode : '');
                this.sFiltrosAplicados += (this.filterHoras_contrato ? '   Contrato: ' + this.filterHoras_contrato + ' Horas' : '');
                this.sFiltrosAplicados += (this.filterDepartamento ? '   Departamento: ' + this.filterDepartamento : '');

                // console.log('Filtros aplicados: ' + this.sFiltrosAplicados);
                if (this.icono === 1 || this.icono === 2 || this.icono === 3) {
                    doc.text(data.settings.margin.left, 33, 'Filtros: ' + this.sFiltrosAplicados);
                    doc.text(170, 33, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari
                }
                if (this.icono === 4) {
                    doc.text(data.settings.margin.left, 33, 'Ahora: ' + this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari
                    doc.text(data.settings.margin.left + 40, 33, 'Filtros: ' + this.sFiltrosAplicados);
                }

                // Footer
                var str = 'Página ' + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' de ' + totalPagesExp;
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
            margin: { top: 35 },
        });

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        //    return doc;
        //}).then((doc) => {

        // descargar pdf
        // doc.save(this.titulo + '.pdf');

        // this.sNavegador = navigator.userAgent.toString; // pruebas

        let fblob = doc.output("blob");
        // let download = false;
        this.mostrarBLOB(fblob, tituloInforme, download);

        this.ver_pdf = false; // mostramos de nuevo los elementos q ocultamos para imprimir
        this.cargandoPDF = false;
        //});

        //}, 1000);


    }

    generarPDF(tituloInforme: string, element, download) {

        // this.generarPDF_with_html2pdf(tituloInforme, element, download);
        // multipagina: OK, cabecera con logo: OK, pie de pagina numerado: OK, corte de pagina: regular
        // contras: lo muestra como "agrandado", si tiene muchas columnas, las solapa !!!
        // return;

        // this.generar_PDF_JSPDF_ONE_PAGE(tituloInforme, element, download);
        // ejemplo simple NO MULTIPAGINA
        // return;

        // ME QUEDO CON ESTE MÉTODO
        // PARA LISTAS, OK.
        this.generarPDF_JSPDF_AUTOTABLE(tituloInforme, element, download);
        return;

        // JSPF MULTIPAGINA
        // multipagina: OK, cabecera con logo: OK (sólo en la 1ª), pie de pagina numerado: OK,
        // corte de pagina: MAL, sobreescribe en pie de pagina
        // PARA LISTAS, MEJOR JSPDF_AUTOTABLE
        // PARA PAGINA HTML COMPLEJA, ESTE AUNQUE CON CORTE MAL

        // const div = document.getElementById(element);
        // const options: Partial<any> = {
        //   background: 'white',
        //   //scale: 1
        //   // size: '70px',
        //   pagesplit: true,
        //   // margin: 3,
        //   // width: 170
        // };

        // this.cargandoPDF = true;
        // this.ver_pdf = true; // para ocultar iconos q no se soportan
        // // mejor haber usado la etiqueta: <div data-html2canvas-ignore 

        // // damos tiempo a q se oculten los iconos
        // setTimeout(() => {

        //     html2canvas(div, options).then((canvas) => {

        //         var doc = new jsPDF('p', 'mm', 'a4');
        //         doc.setProperties({
        //             title: tituloInforme,
        //         });        

        //         // Header Logo
        //         var imgLogo = new Image();
        //         // imgLogo.src = '../assets/imagenes/logo-bejornada.png';
        //         imgLogo.src = this.logo; // logo de la empresa logada
        //         let logoX = 0;
        //         let logoY = 0;
        //         let imgProps = (<any>doc).getImageProperties(imgLogo);
        //         // let pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // imagen ancho 100%
        //         let pdfWidth = doc.internal.pageSize.getWidth() / 2;
        //         logoX = (doc.internal.pageSize.getWidth() - pdfWidth) / 2; // imagen centrada
        //         let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //         let logoWidth = pdfWidth;
        //         let logoHeight = pdfHeight;
        //         // doc.addImage(imgLogo, 'PNG', logoX, logoY, logoWidth, logoHeight, undefined, 'FAST');

        //         // Header fecha
        //         let fechaY = logoHeight + logoY;
        //         /* para compatibilidad con safari */
        //         let fFecha: Date;
        //         // asi suma una hora ¿¿¿????
        //         // fFecha = new Date(String(this.fecha).replace(/\s/, 'T')); // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
        //         let aFecha = String(this.fecha).split(/[^0-9]/);
        //         fFecha = new Date (+aFecha[0], +aFecha[1] - 1, +aFecha[2], +aFecha[3], +aFecha[4], +aFecha[5] );
        //         // doc.text(5, pdfHeight, this.datePipe.transform(this.fecha, 'dd/MM/yyyy  H:mm'));
        //         doc.setFontSize(8);
        //         doc.setTextColor(0);
        //         // doc.text(5, fechaY, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari

        //         // CONTENT
        //         let bufferX = 0;
        //         let bufferY = 2; // altura entre fecha y content

        //         // Content solo 1 pagina
        //         bufferY = fechaY + bufferY;
        //         let contentY = bufferY;
        //         /*
        //         var img = canvas.toDataURL("image/PNG");
        //         bufferX = 5;
        //         bufferY = pdfHeight + 1;
        //         imgProps = (<any>doc).getImageProperties(img);
        //         pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        //         pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //         doc.addImage(imgLogo, 'PNG', logoX, logoY, logoWidth, logoHeight, undefined, 'FAST');
        //         doc.text(5, fechaY, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari
        //         doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        //         */
        //         // Hasta aqui, perfecto con logo en cabecera pero sólo imprime 1 página

        //         // Content multipagina
        //         let imgWidth = 200;
        //         let pageHeight = 295;
        //         let imgHeight = canvas.height * imgWidth / canvas.width;
        //         let heightLeft = imgHeight;


        //         // position = bufferY + pdfHeight + position;
        //         let position = contentY;

        //         // imprime seguido todas las paginas
        //         doc.addImage(imgLogo, 'PNG', logoX, logoY, logoWidth, logoHeight, undefined, 'FAST');
        //         doc.text(5, fechaY, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari
        //         doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, undefined, 'FAST');
        //         // addImage(imageData, format, x, y, width, height, alias, compression, rotation)
        //         // alias: alias of the image (if used multiple times)
        //         // compresion: 'NONE', 'FAST', 'MEDIUM' and 'SLOW'
        //         // rotation: rotation of the image in degrees (0-359)


        //         let logoCabeceraSoloPrimeraPagina: boolean = true;

        //         if (logoCabeceraSoloPrimeraPagina) { // es lo q funciona ahora, aunque sin margen en el corte de página.
        //             console.log('CABECERA SOLO EN PRIMERA PAGINA');
        //             heightLeft = heightLeft - pageHeight + position;
        //             while (heightLeft >= 0) {
        //               position = heightLeft - imgHeight ;
        //               doc.addPage();
        //               doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, undefined, 'FAST'); // compresion: 'NONE', 'FAST', 'MEDIUM' and 'SLOW'
        //               heightLeft = heightLeft - pageHeight;
        //             }
        //         } else { // pruebas, todavia no funciona
        //             console.log('CABECERA EN TODAS LAS PAGINAS');
        //             var pagina = 1;
        //             heightLeft = heightLeft - pageHeight + position;
        //             while (heightLeft >= 0) {
        //               pagina = pagina + 1;
        //               doc.setPage(pagina);
        //               console.log('PAGINA: ' + pagina);
        //               position = position + pagina * pageHeight;

        //               // Header
        //               var imgLogo = new Image();
        //               // imgLogo.src = '../assets/images/logo-informe.jpg';
        //               imgLogo.src = this.logo; // logo de la empresa logada
        //               let bufferX = 0;
        //               let bufferY = 2; // margen superior para el logo
        //               let imgProps = (<any>doc).getImageProperties(imgLogo);
        //               // let pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // imagen ancho 100%
        //               let pdfWidth = doc.internal.pageSize.getWidth() / 4; // ancho imagen: 25% ancho de página
        //               bufferX = (doc.internal.pageSize.getWidth() - pdfWidth) / 2; // imagen centrada
        //               let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //               doc.addPage();
        //               doc.addImage(imgLogo, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        //               // compresion: 'NONE', 'FAST', 'MEDIUM' and 'SLOW'

        //               position = 2; // margen entre logo y cuerpo
        //               position = (bufferY + pdfHeight + position);
        //               // Body
        //               // position = heightLeft - imgHeight;

        //               // con la siguiente linea se repetiria en todas las paginas el principio del canvas
        //               // doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight, undefined, 'NONE');

        //               /****************/
        //               // pruebas para recortar canvas original y colocar en paginas desde la 2ª
        //               // por ahora, sale vacio en pdf pero en pantalla se crean los canvas, falta calcular los recortes para cada pagina 
        //               var namepag = 'pagina' + pagina;
        //               var alto = pageHeight - position;

        //               // doc.addImage(canvas, 'PNG', 5, position, div.clientWidth, alto, undefined, 'FAST');
        //               console.log('position: ' + position);
        //               console.log('alto: ' + alto);
        //               this.screenshot(namepag, div, 0, alto * (pagina-1) * 2.54, div.clientWidth, alto, true).then(canvas => {
        //                 // do whatever with the canvas
        //                 document.body.appendChild(canvas);
        //                 // var dataTmp = document.getElementById('canvas');
        //                 var imgDataTmp = canvas.toDataURL('image/png');
        //                 var imgHeightTmp = canvas.height * imgWidth / canvas.width;
        //                 doc.addImage(canvas, 'PNG', 5, position, div.clientWidth, alto, undefined, 'FAST');
        //                 // compresion: 'NONE', 'FAST', 'MEDIUM' and 'SLOW'
        //               });

        //               /******************/

        //               heightLeft = position - heightLeft - pageHeight;
        //             }

        //         }

        //         // Numeración de páginas
        //         var totalPages = doc.internal.getNumberOfPages();
        //         for (let i = 1; i <= totalPages; i++) {
        //             doc.setPage(i);
        //             doc.setFontSize(8);
        //             doc.setTextColor(0);
        //             // Header
        //             doc.text(doc.internal.pageSize.getWidth() - 30, 10, "Página " + doc.internal.getCurrentPageInfo().pageNumber + ' de ' + totalPages);          
        //             // Footer
        //             doc.text(doc.internal.pageSize.getWidth() - 40, doc.internal.pageSize.getHeight() - 5, "bejornada.es    Página " + i + ' de ' + totalPages);
        //         }

        //         this.cargandoPDF = false;
        //         this.toastr.success('Compruebe si tiene los Poupus bloqueados', 'Informe PDF cargado.');
        //         return doc;

        //     }).then((doc) => {

        //         // descargar pdf
        //         // doc.save(this.titulo + '.pdf');

        //         // OJO, EL NAVEGADOR PUEDE BLOQUEAR PESTAÑA NUEVA, DESACTIVAR ESO EN EL NAVEGADOR
        //         // mostrar en pestaña nueva - tiene la opcion de descargar e imprimir -
        //         // window.open(URL.createObjectURL(doc.output("blob")));

        //         /*
        //         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //             window.navigator.msSaveOrOpenBlob(doc.output("blob"),tituloInforme + '.pdf'); // para edge
        //         }
        //         else {
        //             var objectUrl = URL.createObjectURL(doc.output("blob"));
        //             window.open(objectUrl);
        //         }
        //         */

        //         this.sNavegador = navigator.userAgent.toString; // pruebas
        //         /*
        //         if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
        //             window.location.href = URL.createObjectURL(doc.output('blob'));
        //         } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
        //             window.navigator.msSaveOrOpenBlob(doc.output('blob'), tituloInforme + '.pdf');
        //         } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
        //             alert("Cannot display on FF iOS");
        //         } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
        //             var reader = new FileReader();
        //             reader.onloadend = function () { window.open(<string>reader.result);};
        //             reader.readAsDataURL(doc.output('blob'));
        //         } else {
        //             var objectUrl = URL.createObjectURL(doc.output("blob"));
        //             // mostramos embebido: no va
        //             // this.content_informe = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
        //             window.open(objectUrl);
        //         }
        //         */

        //         let fblob = doc.output("blob");
        //         // let download = false;
        //         this.mostrarBLOB(fblob, tituloInforme, download);

        //         this.ver_pdf = false; // mostramos de nuevo los elementos q ocultamos para imprimir
        //         this.cargandoPDF = false;
        //     });

        // }, 1000);


    }

    screenshot(namepag, element, x, y, width, height, useCORS) {
        // our cropping context
        // let cropper = document.createElement('canvas').getContext('2d');
        console.log('NOMBRE CANVAS: ' + namepag);
        let cropper = document.createElement('canvas').getContext('2d');
        // save the passed width and height
        let finalWidth = width || window.innerWidth;
        let finalHeight = height || window.innerHeight;
        // update the options value so we can pass it to h2c
        if (x) {
            width = finalWidth + x;
        }
        if (y) {
            height = finalHeight + y;
        }
        // chain h2c Promise
        return html2canvas(element).then(c => {
            // do our cropping
            cropper.canvas.width = finalWidth;
            cropper.canvas.height = finalHeight;
            console.log('finalWidth: ' + finalWidth);
            console.log('finalHeight: ' + finalHeight);
            console.log('x: ' + x);
            console.log('y: ' + y);
            cropper.drawImage(c, -(+x || 0), -(+y || 0));
            // return our canvas
            return cropper.canvas;
        });
    }

    onLoadLogo() {
        // para en HTML2PDF, tener la aultura de la cabecera con logo al definir el margen superior de la página
        console.log('LOGO CARGADO PARA CALCULAR DIMENSIONES');
        this.anchoimgLogo = (this.logoEmpresa.nativeElement as HTMLImageElement).naturalWidth;
        this.altoimgLogo = (this.logoEmpresa.nativeElement as HTMLImageElement).naturalHeight;
        console.log(this.anchoimgLogo);
        console.log(this.altoimgLogo);
        // document.getElementById('logoEmpresa').style.display = 'none';
        (this.logoEmpresa.nativeElement as HTMLImageElement).style.display = 'none';
    }

    // Al "aumentarlo" mucho, se pisan las columnas si hay muchas
    generarPDF_with_html2pdf(tituloInforme: string, element: string, download: boolean) {

        this.cargandoPDF = true;
        this.ver_pdf = true; // para ocultar iconos q no se soportan
        // mejor haber usado la etiqueta: <div data-html2canvas-ignore

        // titulo para cuando elijo descargarlo. al abrir en pestaña nueva como blob, no lo conserva
        // this.tituloInforme = 'PDF html2pdf';
        let ahora = new Date();
        console.log('HOY:');
        console.log(this.datePipe.transform(ahora, "yyyy-MM-dd"));
        // this.tituloInforme = "Historia-Clinica_" + this.paciente.historia + '_' + this.datePipe.transform(ahora,"yyyy-MM-dd") + '_' + this.datePipe.transform(ahora,"HH:mm");

        var data = document.getElementById(element);
        // etiqueta para ocultar elementos: data-html2canvas-ignore="true"
        // html2pdf(data); // descarga tal cual. multipagina.
        // return;

        let alturaCabecera = 0; // lo calculamos para definir la página. En él metemos el logo

        // LOGO PARA LA CABECERA
        let margenIzqLogo = 0; // lo calculamos luego para centrarlo
        var margenSupLogo = 7;
        var margenInfLogo = 15;

        let logoWidth; // ancho impreso
        let logoHeight; // alto impreso

        var anchoPag = 210; // mm
        var altoPag = 297 // mm

        // let pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // imagen ancho 100%
        logoWidth = anchoPag / 4; // ancho imagen: 25% ancho de página
        margenIzqLogo = (anchoPag - logoWidth) / 2; // imagen centrada
        logoHeight = (this.altoimgLogo * logoWidth) / this.anchoimgLogo;
        alturaCabecera = margenSupLogo + logoHeight + margenInfLogo;

        console.log('ancho logo: ' + logoWidth);
        console.log('alto logo: ' + logoHeight);
        console.log('altura cabecera: ' + alturaCabecera);

        var opt = {
            margin: [alturaCabecera, 5, 5, 5], // [top, left, bottom, right]
            filename: tituloInforme,
            //image:        { type: 'png'},
            image: { type: 'jpeg', quality: 0.98 },
            // html2canvas:  { dpi: 300, scale: 1, width: anchoHtml, height: altoHtml}, // con 0.9 no me aparece una linea horizontal en el pie de página al hacer el break
            html2canvas: { backgroundColor: 'white', dpi: 72, letterRendering: true }, // con 0.9 no me aparece una linea horizontal en el pie de página al hacer el break
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            // pagebreak:    { mode: 'avoid-all', avoid: '.unbreakable' }
            pagebreak: { avoid: '.unbreakable' }
        };

        // damos tiempo a q se oculte la parte que no quiero (antes usaba variable en lugar de la etiqueta "data-html2canvas-ignore")
        setTimeout(() => {

            // .from() -> .toContainer() -> .toCanvas() -> .toImg() -> .toPdf() -> .save()
            // html2pdf().set(opt).from(data).save();
            // html2pdf().from(element).toPdf().output('datauristring').then(function (pdfAsString) {
            // html2pdf().from(el).outputPdf().then(function(pdf) { console.log(btoa(pdf)); } // This logs the right base64

            /*
            html2pdf().from(element).toPdf().get('pdf').then(function (pdf) {
                // Your code to alter the pdf object.
            }).save();
            */

            // html2pdf().set(opt).from(data).toPdf().get('pdf').then(function (doc) {
            html2pdf().set(opt).from(data).toPdf().get('pdf').then((doc) => {
                // html2pdf().set(opt).from(data).outputPdf().then(function(pdf) {

                let imgProps;

                var imgLogo = new Image();
                // imgLogo.src = '../assets/images/logo-informe.jpg';
                imgLogo.src = this.logo; // logo de la empresa logada
                imgProps = (<any>doc).getImageProperties(imgLogo);
                // let pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // imagen ancho 100%
                logoWidth = doc.internal.pageSize.getWidth() / 4; // ancho imagen: 25% ancho de página
                margenIzqLogo = (doc.internal.pageSize.getWidth() - logoWidth) / 2; // imagen centrada
                logoHeight = (imgProps.height * logoWidth) / imgProps.width;
                // doc.addImage(imgLogo, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');


                /* imprimir fecha y hora actual. para compatibilidad con safari */
                let fFecha: Date;
                // asi suma una hora ¿¿¿????
                // fFecha = new Date(String(this.fecha).replace(/\s/, 'T')); // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
                let aFecha = String(this.fecha).split(/[^0-9]/);
                fFecha = new Date(+aFecha[0], +aFecha[1] - 1, +aFecha[2], +aFecha[3], +aFecha[4], +aFecha[5]);
                // doc.text(5, pdfHeight, this.datePipe.transform(this.fecha, 'dd/MM/yyyy  H:mm'));

                var number_of_pages = doc.internal.getNumberOfPages()
                var pdf_pages = doc.internal.pages
                for (var i = 1; i < pdf_pages.length; i++) {

                    doc.setPage(i)

                    // cabecera
                    // tenemos que poner antes como margen superior de la página la altura de la cabecera para que no pise el contenido            
                    doc.addImage(imgLogo, 'PNG', margenIzqLogo, margenSupLogo, logoWidth, logoHeight, undefined, 'FAST');

                    let posY = alturaCabecera - 5;
                    doc.setFontSize(8);
                    doc.setTextColor(0);
                    doc.text(10, posY, this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm')); // uso fFecha para safari

                    doc.setFontSize(12);
                    doc.setTextColor(0);
                    doc.text(50, posY, tituloInforme);

                    // pie de pagina
                    doc.setFontSize(8);
                    doc.setTextColor(0);
                    // en la cabecera          
                    doc.text(doc.internal.pageSize.getWidth() - 30, 10, "Página " + doc.internal.getCurrentPageInfo().pageNumber + ' de ' + number_of_pages);
                    // en el pie
                    // doc.text(doc.internal.pageSize.getWidth() - 40, doc.internal.pageSize.getHeight() - 5, "Página " + doc.internal.getCurrentPageInfo().pageNumber + ' de ' + number_of_pages);

                }

                // this.toastr.success('Pdf creado. <br>Verifica que el navegador no bloquea la descarga', 'Aviso', { timeOut: 3000, positionClass: 'toast-top-right', progressBar: true, enableHtml: true });      
                // window.open(pdf.output('bloburl'), '_blank');
                return doc;
            }).then((doc) => {

                // OPCION 1: descargar sin previsualizar
                // doc.save(this.tituloInforme + '.pdf');

                // OPCION 2: abrir en nueva pestaña. ojo con el bloqueo del navegador.
                // no coje el nombre el blob
                // window.open(doc.output('bloburl'), '_blank');

                // OPCION 3.
                // lo tratamos como en el componente documentos
                let fblob = doc.output('blob');
                // let download = false;
                // this.mostrarBLOB(fblob, this.tituloInforme + '.pdf', download);

                // this._utilsService.mostrarBLOB(fblob, tituloInforme + '.pdf', download);
                this.mostrarBLOB(fblob, tituloInforme + '.pdf', download);

                this.cargandoPDF = false;
                this.ver_pdf = false;
                console.log('PDF CREADO')
                this.toastr.success('Pdf creado. <br>Verifica que el navegador no bloquea la descarga', 'Aviso', { timeOut: 3000, positionClass: 'toast-top-right', progressBar: true, enableHtml: true });
            })
                .catch(() => {
                    this.cargandoPDF = false;
                    this.ver_pdf = false;
                    console.log('PDF ERROR')
                    this.toastr.error('Informe no creado', 'Error', { timeOut: 3000, positionClass: 'toast-top-right' });
                })

        }, 200);

    }

    // JSPF -> OK ( EL CANVAS TAL CUAL, NO MULTIPAGINA)
    generar_PDF_JSPDF_ONE_PAGE(tituloInforme: string, element: string, download: boolean) {

        /*
        en la llamada: paraPDFJustif.offsetWidth, paraPDFJustif.offsetHeight
        como parametros: anchoHtml, altoHtml
        console.log('anchoHtml: '); console.log(anchoHtml);
        console.log('altoHtml: '); console.log(altoHtml);
        */

        this.cargandoPDF = true;
        this.ver_pdf = true; // para ocultar iconos q no se soportan
        // mejor haber usado la etiqueta: <div data-html2canvas-ignore

        const div = document.getElementById(element);
        // ¿le hace caso a estas opciones?
        const options: Partial<any> = {
            backgroundColor: 'white', // color lineas entre filas
            // scale: 1
            // size: '70px',
            pagesplit: true,
            // margin: 150, // ni caso
            // width: 170 // si le hace caso
            // windowWidth: 1920
        };

        let ahora = new Date();
        console.log('HOY:');
        console.log(this.datePipe.transform(ahora, 'yyyy-MM-dd'));
        // this.tituloInforme = "Justificante_de_asistencia_" + this.fcita.historia + '_' +  this.datePipe.transform(this.ahora,"yyyy-MM-dd") + '_' + this.datePipe.transform(this.ahora,"HH:mm");
        // this.tituloInforme = "Justificante_de_asistencia_" + this.fcita.historia + '_' +  this.fcita.fecha + '_' + this.fcita.hora;
        html2canvas(div, options).then((canvas) => {
            var self = this;
            var img = canvas.toDataURL('image/PNG');
            var doc = new jsPDF('p', 'mm', 'a4');

            // Add image Canvas to PDF
            const bufferX = 5; // margen izq
            const bufferY = 5; // margen sup

            // fit to width
            const imgProps = (doc as any).getImageProperties(img);
            const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
            return doc;

        }).then((doc) => {

            // doc.save( this.tituloInforme);
            if (download) {
                this.toastr.success('Pdf creado. <br>Verifica que el navegador no bloquea la descarga', 'Aviso', { timeOut: 3000, positionClass: 'toast-top-right', progressBar: true, enableHtml: true });
            } else {
                this.toastr.success('Pdf creado. <br>Verifica que el navegador no bloquea las ventanas emergentes', 'Aviso', { timeOut: 3000, positionClass: 'toast-top-right', progressBar: true, enableHtml: true });
            }
            let fblob = doc.output('blob');
            this.mostrarBLOB(fblob, this.tituloInforme + '.pdf', download);

            this.cargandoPDF = false;
            this.ver_pdf = false;
            console.log('PDF CREADO');

        }).catch(() => {

            this.cargandoPDF = false;
            this.ver_pdf = false;
            console.log('PDF ERROR');
            this.toastr.error('Pdf no creado', 'Error', { timeOut: 3000, positionClass: 'toast-top-right' });

        });

    }

    // MOSTRAR O DESCARGAR ARCHIVOS PDFS
    mostrarBLOB(blob, tituloInforme, download) {
        // download es para chrome windows
        console.log('mostrarBLOB');
        console.log(tituloInforme);

        // este codigo va en chrome pero no en edge
        // descarga con el nombre del documento
        /*
        let a = document.createElement("a");
        // a.style = "display: none";
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = tituloInforme; // gives it a name via an a tag
        a.click();
        window.URL.revokeObjectURL(url);
        return;
        */

        /* este codigo funciona en general pero no descarga con el nombre original */
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
            //window.location.href = URL.createObjectURL(blob);
            console.log('Safari & Opera iOS');
            window.open(URL.createObjectURL(blob), tituloInforme);
        } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
            console.log('IE 11+');
            window.navigator.msSaveOrOpenBlob(blob, tituloInforme);
        } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
            console.log('FF iOS');
            alert("Cannot display on FF iOS");
        } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
            console.log('Chrome iOS');
            var reader = new FileReader();
            reader.onloadend = function () { window.open(<string>reader.result, tituloInforme); };
            reader.readAsDataURL(blob);
        } else {
            console.log('otro navegador'); // Chrome

            if (download) {
                // descarga con nombre original
                let a = document.createElement("a");
                // a.style = "display: none";
                document.body.appendChild(a);
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = tituloInforme; // gives it a name via an a tag
                a.click();
            } else {
                // abre en nueva pestaña pero con nombre aleatorio
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl, tituloInforme);
            }

        }

    }

    uintToString(uintArray) {
        var encodedString = String.fromCharCode.apply(null, uintArray);
        var decodedString = decodeURIComponent(escape(atob(encodedString)));
        return decodedString;
    }

}
