import { Component, OnInit } from '@angular/core';
import { Log } from '../../models/log';
import { LogService } from 'src/app/services/log.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Usuario } from 'src/app/models/usuario';
import { GLOBAL } from 'src/app/services/global.service';
import { UsuarioService } from 'src/app/services/usuario.service';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css', '../../../style.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class LogComponent implements OnInit {

  public logs: any;
  public filtro: boolean;
  public tablas: any;
  public codigo_empresa: string;
  public lista_usuarios: any;

  public filterCode: string;
  public filterFecha: string;
  public filterTabla: string;
  public filterFechaHasta: string;
  public filterDescripcion: string;
  public filterObservaciones: string;

  constructor(private _logservice: LogService, private _usuarioService: UsuarioService) {
    this.logs = [];
    this.tablas = new Array();
    this.filtro = false;
    this.codigo_empresa = GLOBAL.codigo_empresa;
    this.filterCode = '';
    this.filterTabla = '';
    this.filterFecha = '';
    this.filterFechaHasta = '';
    this.filterDescripcion = '';
    this.filterObservaciones = '';
   }

  ngOnInit() {
    this.getLogs();
    this.list_usuarios();
  }
  
  getLogs(){
    let j = 0;
    //Desde aqui se traeran todos los logs y los guardaremos en logs_todos
    this._logservice.getLogs().subscribe( $response => {
      if($response['code'] === 200){
        console.log("Logs obtenidos");
        this.logs = $response['data'];
        console.log(this.logs);
        for(let i = 0; i < this.logs.length; i++){
          let text = this.logs[i]['tabla'].charAt(0).toUpperCase()+this.logs[i]['tabla'].substr(1).toLowerCase();
          if(!this.tablas.includes(text)){
            this.tablas[j] = text;
            j++;
          }
        }
      }else{
        console.log("Error en la obtención de logs");
      } 
      this.tablas = this.tablas.sort();
    });
  }

  activeFiltrar(){
    this.filtro = !this.filtro;
    if(!this.filtro){
      this.filterCode = '';
      this.filterTabla = '';
      this.filterFecha = '';
      this.filterFechaHasta = '';
      this.filterDescripcion = '';
      this.filterObservaciones = '';
    }
  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios(){

    // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
    if ( GLOBAL.rol !== "admin" ) {

        let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
        this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
        this.lista_usuarios.push(user);

    } else {
      
        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

  }

  nullerValues(index){
    if(index == 1){
      this.filterCode = '';
    }else if(index == 2){
      this.filterTabla = '';
    }else if(index == 3){
      this.filterFecha = '';
    }else if(index == 4){
      this.filterFechaHasta = '';
    }else if(index == 5){
      this.filterDescripcion = '';
    }else if(index == 6){
      this.filterObservaciones = '';
    }
  }
}
