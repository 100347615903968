import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { UsuarioService } from '../../services/usuario.service';
import { HorarioService } from '../../services/horario.service';
import { StyleService } from '../../services/style.service';
import { VacacionesService } from '../../services/vacaciones.service';
import { GLOBAL } from '../../services/global.service';
import { EmpresaService } from '../../services/empresa.service';
import { Empleado } from 'src/app/models/empleado';
import { Style } from 'src/app/models/style';
import { Vacaciones } from 'src/app/models/vacaciones';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { ControlContainer } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector : 'empleados',
    templateUrl : './empleados.component.html',
    styleUrls : ['./empleados.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, UsuarioService, HorarioService,  StyleService, VacacionesService, DepartamentosService ]
})

export class EmpleadosComponent {
    public fecha_actual;
    public titulo: string;
    public menu: number;
    public empleados: Array<Empleado>;
    public confirmado;
    public mostrar_colores: boolean;
    public mostrar_filtro: boolean;
    public new_style: Style;
    public filtro: number;
    public empledos_vacaciones: Array<Empleado>;
    public vacaciones_masivas: Array<Vacaciones>;
    public vacaciones: Vacaciones;
    public codigo_empresa: string;
    public departamento: Departamentos;

    //Variables de busqueda
    public nombre_busqueda;
    public departamento_busqueda;
    public genero_busqueda;

    //Variables de busqueda 2
    public filterNombre;
    public filterDepartamento;
    public filterGenero;

    //Variables vacaciones
    public inicio_vacaciones: Date;
    public fin_vacaciones: Date;
    public departamento_vacaciones: string;
    public empresa:any;

    public emplActivos: any;

    public superadmin = GLOBAL.superadmin;

    objectKeys;

    constructor(
        private _route: ActivatedRoute,
        private _empleadoService: EmpleadoService,
        private _vacacionesService: VacacionesService,
        private _departamentosService: DepartamentosService,
        private _horarioService: HorarioService,
        private _empresaService: EmpresaService,
        protected toastr: ToastrService
        ){
        this.titulo = "Página empleados";
        this.menu = 1;
        this.empleados = [];
        this.empledos_vacaciones = [];
        this.vacaciones_masivas = [];
        this.departamento = null;
        this.vacaciones = new Vacaciones(0, null, null, null, null, null);
        this.new_style = new Style(0, null, null, null, null, null);
        this.confirmado = null;
        this.mostrar_colores = false;
        this.mostrar_filtro = true;
        this.nombre_busqueda = null;
        this.departamento_busqueda = null;
        this.genero_busqueda = null;
        this.filtro = 0;
        this.inicio_vacaciones = null;
        this.fin_vacaciones = null;
        this.departamento_vacaciones = '';
        this.filterNombre = '';
        this.filterDepartamento = '';
        this.filterGenero = '';
        this.empresa = '';
        this.emplActivos = [];
    }

    ngOnInit(){

        this.objectKeys = Object.keys;

        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });
        this.getEmpleadosActivos();
        // this.getEmpleados();
        this.getEmpresa();
        this.list_departamentos();
        this.fechaActual();
        // this.getEmpleadosActivos();
        this.getEmpleados();
    }

    borrarConfirm(code){
        this.confirmado = code;
    }

    cancelarConfirm(){
        this.confirmado = null;
    }

    fechaActual() {
        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    this.fecha_actual = response['data'];
                    this.inicializa_vacaciones();
                    console.log('Fecha actual recuperada: ' + response['data']);
                } else {
                    console.log('No se ha podido conseguir la fecha actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );
    }

    //Metodo que se trae todos los departamentos de la base de datos
    list_departamentos(){

        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log('Departamentos conseguidos con exito')
                    this.departamento = response['data'];
                    console.log(this.departamento);

                } else {
                    console.log("Los departamentos no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que limpia el valor de las variables de busqueda
    nullerValues(index){

        console.log("Llamando metodo nullerValues");

        if (index == 1) {
            this.filterNombre = '';
        }
        else if (index == 2) {
            this.filterDepartamento = '';
        }
        else if (index == 3) {
            this.filterGenero = '';
        }

    }

    //Activa o desactiva el div que muestra los filtros de busqueda
    activeFiltrar(index){
        if (this.mostrar_filtro == true){
            this.mostrar_filtro = false;
            this.filtro = index;
        } else {
            this.mostrar_filtro = true;
            this.filtro = 0;
        }
        if (index === 2) {
            this.inicializa_vacaciones();
        }
    }

    inicializa_vacaciones() {
        this.inicio_vacaciones = this.fecha_actual;
        this.fin_vacaciones = this.fecha_actual;
    }

    getEmpleadosActivos(){
        this._empleadoService.getEmpleadosActivos().subscribe( result => {
            if(result['code'] == 200){
                this.emplActivos = result['data'];
            }
        });
    }

    aplicarVacacionesMasivas() {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        let fdesde = new Date(this.inicio_vacaciones);
        let fhasta = new Date(this.fin_vacaciones);
        console.log('Fecha desde: ' + fdesde);
        console.log('Fecha hasta: ' + fhasta);
        if (fdesde > fhasta) {
            this.toastr.error('Fechas no válidas.', 'Aplicar vacaciones');
            return;
        }

        //Se trae de la base de datos los empledos del departamento al que se le van asignar vacaciones
        console.log('Empleados del Departamento: ');
        console.log(this.codigo_empresa + ' / ' + this.departamento_vacaciones);
        this._empleadoService.getEmpleadosDepartamento(this.departamento_vacaciones, this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Empleados encontrados: ");
                    this.empledos_vacaciones = response['data'];
                    console.log(this.empledos_vacaciones);

                    //Componemos las vacaciones
                    this.vacaciones_masivas = [];
                    // for (let i = 0; i < this.empledos_vacaciones.length; i++){
                    for (let fempleado of this.empledos_vacaciones){
                        // this.vacaciones.code = this.empledos_vacaciones[i].code;
                        console.log('Empleado:');
                        console.log(fempleado);
                        this.vacaciones = new Vacaciones(0, null, null, null, null, null);
                        this.vacaciones.code = fempleado.code;
                        this.vacaciones.inicio_vacaciones = this.inicio_vacaciones;
                        this.vacaciones.fin_vacaciones = this.fin_vacaciones;
                        this.vacaciones.codigo_empresa = this.codigo_empresa;

                        // this.vacaciones_masivas[i] = this.vacaciones;
                        this.vacaciones_masivas.push(this.vacaciones);
                    }

                    console.log('Crear vacaciones:');
                    console.log(this.vacaciones_masivas);
                    let contador = 0;
                    //Insertamos vacaciones masivas en la base de datos
                    for (let vacacion of this.vacaciones_masivas) {

                        this._vacacionesService.addVacaciones(vacacion).subscribe(
                            response => {
                                contador = contador + 1;
                                if (response['code'] == 200){
                                    console.log('Vacaciones insertada:');
                                    console.log(vacacion);

                                    if (contador >= this.vacaciones_masivas.length) {
                                        this.toastr.success('Vacaciones aplicadas correctamente.', 'Aplicar vacaciones');
                                        this.empleados = response['data'];
                                        this.getEmpleados();
                                        setTimeout(() => {
                                            // 5 segundos después...
                                            this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
                                        }, 5000);
                                    }

                                } else {
                                    console.log("La vacacion no se ha podido insertar");
                                }
                            }, error => {
                                contador = contador + 1;
                                console.log('Ha sucedido un error');
                                console.log(<any>error);
                            }
                        );

                    }


                } else {
                    console.log("Empleados no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    controlVacaciones() {
        console.log('controlVacaciones ...');
        this._vacacionesService.automatic_update_vacaciones(this.codigo_empresa, String(this.fecha_actual)).subscribe(
          response => {
              console.log(response);
              if (response['code'] === 200) {
                    // console.log('Se ha realizado la actualizacion automática de vacaciones');
                    console.log(response['message']);
              } else {
                    console.log('Error realizando la actualizacion automática de vacaciones');
              }
          }, error => {
                console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
                console.log(error as any);
          }
        );
    }

    // Método que devuelve la empresa actual
    getEmpresa(){
        this._empresaService.getEmpresa(GLOBAL.codigo_empresa).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    this.empresa = response['data'];
                    console.log('Empresa cargada:');
                    console.log(this.empresa);
                } else {
                    console.log('Empresa no encontrada');
                }
            }, error => {
                console.log(this.empresa);
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    //Se trae todos los empleados de la base de datos
    getEmpleados(){
        this._empleadoService.getEmpleados(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Empleados encontrados");
                    this.empleados = response['data'];
                    console.log(this.empleados);
                } else {
                    console.log("Empleados no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }

    deleteEmpleado(code) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        //Cambia el estado activo del empleado BD
        this._empleadoService.updateEstadoEmpleado(code, false, this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Estado actualizado");
                    this.toastr.success('El empleado se ha desactivado.', 'Desactivar empleado');
                    this.confirmado = null;
                    this.getEmpleados();
                } else {
                    console.log("El estado no se ha podido actualizar");
                    this.toastr.error('El empleado NO se ha desactivado.', 'Desactivar empleado');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
                this.toastr.error('El empleado NO se ha desactivado.', 'Desactivar empleado');
            }
        );

    }

}
