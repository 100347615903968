export class ConfirmaInformeSemanal{

    public code:String;
    public fechaFirma:Date;
    public fechaSemanaIni:Date;
    public fechaSemanaFin:Date;
    public confirmado: boolean;
    public informe:string;

    constructor( code:String,fechaFirma:Date,fechaSemanaIni:Date,fechaSemanaFin:Date,informe:string, confirmado: boolean=false)
    {

        this.code = code;
        this.fechaFirma = fechaFirma;
        this.fechaSemanaIni = fechaSemanaIni;
        this.fechaSemanaFin = fechaSemanaFin;
        this.confirmado = confirmado;
        this.informe = informe;
    }

    public setCode(code:String){
        this.code = code;
    }

    public setFechaFirma(fechaFirma: Date){
        this.fechaFirma = fechaFirma;
    }

    public setFechaSemanaIni(fechaSemanaIni: Date){
        this.fechaSemanaIni = fechaSemanaIni;
    }    

    public setFechaSemanaFin(fechaSemanaFin: Date){
        this.fechaSemanaFin = fechaSemanaFin;
    }    

    public setInforme(informe:string){
        this.informe = informe;
    }

    //---------------------------------------------
    
    public getCode(code:String){
        return this.code;
    }

    public getFechaFirma(fechaFirma: Date){
        return this.fechaFirma;
    }

    public getFechaSemanaIni(fechaSemanaIni: Date){
        return this.fechaSemanaIni;
    }    

    public getFechaSemanaFin(fechaSemanaFin: Date){
        return this.fechaSemanaFin;
    }    

    public getInforme(informe:String){
        return this.informe;
    }


}