import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { HorarioService } from '../../services/horario.service';
import { CalculoIncidenciasService } from '../../services/calculo_incidencias.service';
import { Horario } from 'src/app/models/horario';
import { EmpleadosMenos } from '../../models/empleados_menos';
import { CalculoIncidencias } from '../../models/calculo_incidencias';
import { Empleado } from 'src/app/models/empleado';
import { MensajesService } from '../../services/mensajes.service';  // Nuestro proveedor de mensajes
import { GLOBAL } from '../../services/global.service';

@Component({
    selector: 'avisos',
    templateUrl: './avisos.component.html',
    styleUrls : ['./avisos.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, HorarioService, CalculoIncidenciasService]
})

export class AvisosComponent {
    public titulo: string;
    public codigo_empresa: string;
    public incidencias: boolean;
    public incidencia_olvido: boolean;
    public incidencias_visto: Array<Horario>;
    public horarios: Array<Horario>;
    public empleados_menos: Array<EmpleadosMenos>;
    public empleados_menos_filtrados: Array<EmpleadosMenos>;
    public calculo_incidencias: Array< CalculoIncidencias >;
    public empleado_olvido: Array< Empleado >;  //Empleado que se le ha olvidado fichar al entrar o salir (y tiene fichajes ese día)
    public horaEntrada: any;
    public incidenciaEntrada: any;
    public horaSalida: any;
    public incidenciaSalida: any;
    public horario: Horario;
    public empleadoCode: any;
    public empleadoCodigo_empresa: any;
    public horas_totales: any;
    public fecha_incidencia: any;

    public num_incidencias: number;         // total incidencias de empleados manuales
    public num_incidencias_olvido_fichar;   // total de incidencias de olvido fichar ( total agrupados por fecha y empleado)
    public total_incidencias: number;

    public licencias_superadas;

    constructor(
        private _horarioService: HorarioService,
        private _route: ActivatedRoute,
        protected _router: Router,
        private mensajesService: MensajesService,
        private _empleadoService: EmpleadoService,
        private _calculo_incidenciasService: CalculoIncidenciasService,
    ) {
        this.titulo = 'Avisos';
        this.incidencias = false;
        this.incidencia_olvido = false;
        this.num_incidencias = 0;
        this.num_incidencias_olvido_fichar = 0;
        this.total_incidencias = 0;
        this.incidencias_visto = [];
        this.empleados_menos = [];
        this.empleados_menos_filtrados = [];
        //this.calculo_incidencia = new CalculoIncidencias(0, null, null, 0, false, null);
        this.calculo_incidencias = [];
        this.empleado_olvido = [];
        this.horario = new Horario(0, "", "", null, null, null, null, 0, null, true, null, null, null, false, 0, 0, 0, 0, null, null);
        this.licencias_superadas = GLOBAL.licencias_superadas;
    }

    ngOnInit(){

        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });
        this.getIncidencias_visto();
        // this.getIncidenciasHorario(); NO LO VEO, LO IGNORO
        this.getIncidenciasHorarioOlvido();
    }

    actualizarNumIncidenciasMenu() {

        this.mensajesService.emite({
        categoria: 'incidenciasMenu',
        contenido: this.total_incidencias.toString()
      });

    }

    cambiarEstadoCalculo_incidencias_visto(){

        this._calculo_incidenciasService.updateVisto_Calculo_incidencias(this.codigo_empresa, false, "olvido_fichar").subscribe(
            response => {
                if (response['code'] == 200){
    
                    console.log("Calculo_Incidencias_visto actualizado con exito");
    
                } else {
                    
                    console.log("Calculo_Incidencias_visto no se ha podido actualizar");
    
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        
            );

        document.getElementById("datos_ol").style.backgroundColor = "#99FAB5";

    }

    cambiarEstadoIncidencias_visto(id, fila_incidencia_usuario) {

        // compruebo si ya se ha verificado (por el color)
        let color = document.getElementById("incid_users" + fila_incidencia_usuario ).style.backgroundColor;
        // console.log(color);
        if (color === 'rgb(29, 209, 161)' ) { // verde: '#1dd1a1', rojo: 'rgb(241, 188, 188)'
            console.log('Ya se ha validado');
            return;
        }

        // console.log( id, fila_incidencia_usuario);
        this._horarioService.updateIncidencias_visto(id, false).subscribe(
        response => {
            if (response['code'] == 200) {

                console.log("Incidencias_visto actualizado con exito");
                this.num_incidencias = this.num_incidencias - 1;
                this.total_incidencias = this.num_incidencias + this.num_incidencias_olvido_fichar;
                this.actualizarNumIncidenciasMenu();
            } else {

                console.log("Incidencias_visto no se ha podido actualizar");

            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(error as any);
        }

        );

        document.getElementById("incid_users" + fila_incidencia_usuario ).style.backgroundColor = "#1dd1a1";

    }

    getIncidencias_visto(){
    
        this._horarioService.getIncidencias_visto(this.codigo_empresa).subscribe(
          response => {
              
              if (response['encontrado'] == true){

                  this.incidencias = true;
                  this.num_incidencias = response['size'];
                  this.incidencias_visto = response['data'];
                  this.total_incidencias = this.num_incidencias + this.num_incidencias_olvido_fichar;
                  this.actualizarNumIncidenciasMenu();
                  console.log('Encontradas incidencias de empleados sin validar: ' + this.num_incidencias);

              } else {
                  console.log("No hay incidencias sin ver");
                  
              }
          }, error => {
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
      
        );
      }

    getIncidenciasHorario(){ // ¿empleados trabajando menos horas de su contarto?

        this._calculo_incidenciasService.getVisto_Calculo_incidencias(this.codigo_empresa, "incidencia_horario").subscribe(
            response => {
                if (response['code'] == 200){
                    
                    let estado = response['result'];

                    if ( estado == true ){

                        this._empleadoService.getEmpleadosMenos_horas(this.codigo_empresa).subscribe(
                            response => {
                                if (response['code'] == 200){

                                    console.log("Empleados menos horas encontrados");

                                    this.empleados_menos = response['data'];

                                    let contador = 0;

                                    //30 minutos de margen
                                    for (let i = 0; i < this.empleados_menos.length; i++) {

                                        if (this.empleados_menos[i].total_horas < this.empleados_menos[i].horas_contrato - 0.70) {
                                            
                                            this.empleados_menos_filtrados[contador] = this.empleados_menos[i];
                                            contador++;
                                        }
                    
                                    }

                                    //Actualiza el campo visto
                                    this._calculo_incidenciasService.updateVisto_Calculo_incidencias(this.codigo_empresa, false, "incidencia_horario").subscribe(
                                        response => {
                                            if (response['code'] == 200){

                                                console.log("Campo visto actualizado con exito");

                                            } else {

                                                console.log("El campo visto no se ha podido actualizar");

                                            }
                                        }, error => {
                                            console.log('Ha sucedido un error');
                                            console.log(<any>error);
                                        }
                                    
                                        );
                                

                                } else {
                                    console.log("No se han encontrado empleados echando menos horas de su jornada");
                                }
                            }, error => {
                                console.log('Ha sucedido un error');
                                console.log(<any>error);
                            }
                        
                          );

                    }

                } else {
                    console.log("No hay incidencias de horario");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }

            );

    }

    //Metodo que comprueba en la tabla 'calculo_incidencias' si hay alguna incidencia producida por un empleado que se le ha olvidado fichar
    getIncidenciasHorarioOlvido() {

        this._calculo_incidenciasService.getHorario_Olvido_Fichar(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] === 200){

                    this.incidencia_olvido = response['result'];

                    if (this.incidencia_olvido == true) {
                        this.empleado_olvido = response['data'];
                        this.num_incidencias_olvido_fichar = response['num_incidencias_olvido_fichar'];
                        this.total_incidencias = this.num_incidencias + this.num_incidencias_olvido_fichar;
                        this.actualizarNumIncidenciasMenu();
                        console.log("Encontradas incidencias de empleados que se le ha olvidado fichar: " + this.num_incidencias_olvido_fichar);
                    } else {
                        console.log("NO Se han encontrado incidencias de empleados que se le ha olvidado fichar");
                    }

                } else {

                    console.log("error recuperando incidencias de empleados que se le ha olvidado fichar");

                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }

            );

    }

    //Metodo que comprueba en la tabla 'calculo_incidencias' si hay alguna incidencia producida por un empleado que se le ha olvidado fichar
    getIncidenciasHorarioOlvidoOLD() {

        // metodo chapuza. se podrian traer todos los datos en una sola consulta
        console.log("Llamando metodo getIncidenciasHorarioOlvido");

        this._calculo_incidenciasService.getVisto_Calculo_incidencias_olvido(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] === 200){

                    this.incidencia_olvido = response['result'];

                    if (this.incidencia_olvido == true) {

                        console.log("Se han encontrado incidencias de empleados que se le ha olvidado fichar");
                        
                        this.calculo_incidencias = response['data'];
                        this.empleado_olvido = [];

                        //Trae de la BD el empleado que se le ha olvidado fichar por la mañana
                        for (let i = 0; i < this.calculo_incidencias.length; i++) {
                            this._empleadoService.getEmpleado(this.calculo_incidencias[i].code, this.codigo_empresa).subscribe(
                            response => {
                                if (response['code'] == 200){
                    
                                    console.log("Empleado con olvido se ha conseguido");
                                    this.empleado_olvido[i] = response['data'];
                                    console.log('empleado_olvido = ' + this.empleado_olvido.length + ' response = ' + response['data'])
                                    this.empleado_olvido[i].fecha_nacimiento = this.calculo_incidencias[i].fecha
                                } else {
                                    
                                    console.log("No se ha podido conseguir el empleado con olvido");
                    
                                }
                            }, error => {
                                console.log('Ha sucedido un error');
                                console.log(<any>error);
                            }
                        
                            );
                        }

                    } else {
                        console.log("NO Se han encontrado incidencias de empleados que se le ha olvidado fichar");
                    }

                } else {

                    console.log("error recuperando incidencias de empleados que se le ha olvidado fichar");

                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        
            );

    }

    compopnerHorario( empleado: Empleado ){
        this.empleadoCode = empleado.code;
        this.empleadoCodigo_empresa = empleado.codigo_empresa;
        this.fecha_incidencia = empleado.fecha_nacimiento;
    }

    verFichajes(code, fecha) {        
        let disabledCode = !GLOBAL.esAdmin;
        let origen = 'avisos';
        console.log(code + ' ' + fecha + ' ' + disabledCode + ' ' + origen); // lo de la fecha_nacimiento es un apaño para no crear otro campo
        this._router.navigate(['/fichajedia', this.codigo_empresa, code, fecha, disabledCode, origen]);
    }

    /*
    fichar() {
        //Compone horario
        this.horario.id = null;
        this.horario.code = this.empleadoCode;
        this.horario.codigo_empresa = this.empleadoCodigo_empresa;
        this.horario.hora_entrada = this.horaEntrada;
        this.horario.incidencias = this.incidenciaEntrada;
        this.horario.hora_salida = this.horaSalida;
        this.horario.incidencias_salida = this.incidenciaSalida;
        this.horario.fecha = this.fecha_incidencia;
        this.horario.estado = false;

        if (this.horario.incidencias === undefined) {this.horario.incidencias = null;};
        if (this.horario.incidencias_salida === undefined) {this.horario.incidencias_salida = null;};

        //Calcula el total de horas trabajadas
        // this.horario.total_horas = parseFloat(Number( parseFloat(this.horario.hora_salida.toString().replace(":",".")) - parseFloat(this.horario.hora_entrada.toString().replace(":",".")) ).toFixed(1) )
        this.horario.total_horas = this.difHoras(this.horario.hora_entrada.toString(),this.horario.hora_salida.toString());

        //Compone horarioAdmin
        this.horarioAdmin.code = this.empleadoCode;
        this.horarioAdmin.codigo_empresa = this.empleadoCodigo_empresa;
        this.horarioAdmin.hora_entrada = this.horaEntrada;
        this.horarioAdmin.incidencia_entrada = this.incidenciaEntrada;
        this.horarioAdmin.hora_salida = this.horaSalida;
        this.horarioAdmin.incidencia_salida = this.incidenciaSalida;
        // Introduce horario en la tabla de horarios
        console.log('Registrar Horario:');
        console.log(this.horario);
        this._horarioService.addHorario(this.horario).subscribe(
            response =>  {
                console.log(response);
                if ( response['code'] == 200 ) {

                    console.log('Horario introducido en tabla horarios');

                    // marcar como solucionado
                    this.cambiarEstadoCalculo_incidencias_visto();

                    // Introduce horario en la tabla de horario_por_admin
                    this._horario_por_admin.addHorarios_por_admin(this.horarioAdmin).subscribe(
                        response =>  {
                            if ( response['code'] == 200 ) {
                                console.log('Horario introducido en tabla horario_por_admin');
                            } else {
                                console.log('Fallo al introducir en la tabla horario_por_admin');
                            }
                        },
                        error => {
                            console.log(error)
                            console.log('Error');
                        }
                    );

                } else {
                    console.log('Fallo al introducir en la tabla horarios');
                }
            },
            error => {
                console.log(error);
                console.log('Error');
            }
        );

    }
    */

    difHoras(horaInicio: string, horaFin: string): number {
        let hora1 = horaFin.split(':');
        let hora2 = horaInicio.split(':');
        let t1 = new Date();
        let t2 = new Date();

        if (hora1.length == 3) {
            t1.setHours(+hora1[0], +hora1[1], +hora1[2]); // '+' convierte de string a integer
            t2.setHours(+hora2[0], +hora2[1], +hora2[2]);
        } else {
            t1.setHours(+hora1[0], +hora1[1], 0); // '+' convierte de string a integer
            t2.setHours(+hora2[0], +hora2[1], 0);
        }

        //Aquí hago la resta
        t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

        let horasDif = this.Round(t1.getHours() + ((t1.getMinutes()*60 + t1.getSeconds())/3600),2);
        return horasDif;
    }

    Round (numero, decimales = 2, usarComa = false): number {
        var opciones = {
            maximumFractionDigits: decimales,
            useGrouping: false
        };
        return +new Intl.NumberFormat((usarComa ? "es" : "en"), opciones).format(numero);
    }    

}
