import { Component, ElementRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FilterPipe } from '../../pipes/filter.pipe';
import { FichajeBusqueda } from 'src/app/models/fichaje-busqueda';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmaInformeSemanalService } from '../../services/confirmacion-informe-semanal.service'

// Servicios 
import { HorarioService } from '../../services/horario.service';
import { GLOBAL } from '../../services/global.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmaInformeSemanal } from 'src/app/models/confir_informe_semanal';
import { single } from 'rxjs/operators';
import { parseDateSpan } from '@fullcalendar/core/structs/date-span';


@Component({
    selector: 'informe-confirmacion',
    templateUrl: './informe-confirmacion.component.html',
    styleUrls: ['./informe-confirmacion.component.css', '../../../style.scss'],
    providers: [HorarioService, ReportesService, FilterPipe, ConfirmaInformeSemanalService]
})

export class InformeConfirmacionComponent {

    public urlData: SafeResourceUrl = null;
    public fecha: Date;
    public fecha_confirma: string;
    public fecha_actual: any;
    public fecha_inicio_semana_actual: Date;
    public fecha_inicio_semana_anterior: Date;
    public fecha_fin_semana_anterior: Date;
    public codigo_empresa: any;
    public fichajes_resumen: Array<any>;

    public confirmado: boolean;
    private fblob: any;
    public informeSinConfirmar;

    public informes_pendientes = [];
    public informeSeleccionado;

    @ViewChild("informe") informe: ElementRef;
    
    public boton_confirmacion_estado : boolean = true;

    public informe_fichajes_detallado: boolean;
    public informe_fichajes_resumen: boolean;
    public informeStr: string;
    public num_confirmados = 0;
    constructor(
        protected toastr: ToastrService,
        private sanitizer: DomSanitizer,
        private _reportesService: ReportesService,
        private _confirmInformeSemanalService: ConfirmaInformeSemanalService
    ) {
        this.fecha = new Date();
        this.fecha_confirma = this.fecha.toLocaleDateString();
        this.codigo_empresa = GLOBAL.codigo_empresa;
    }

    ngOnInit() {
        this.fechaAnterior();
    }

    fechaAnterior() {

        this.fecha_actual = new Date();

        let fechaTemp: any
        if (this.fecha_actual.getDay() == 0) {
            fechaTemp = this.dateformatYMD(null, -14)
        } else {
            fechaTemp = (this.dateformatYMD(null, (-6 - this.fecha_actual.getDay())))
        }

        this.fecha_actual = this.dateformatYMD(this.fecha_actual)
        this.fecha_inicio_semana_anterior = this.dateformatYMD(fechaTemp);
        this.fecha_fin_semana_anterior = this.dateformatYMD(fechaTemp, 6);
        this.fecha_inicio_semana_actual = this.dateformatYMD(fechaTemp);
    }

    dateformatYMD(dateParam: any = null, sum: any = 0,): any {
        if (!dateParam) { dateParam = Date.now() }
        var newDate: any = new Date(dateParam);
        newDate.setDate(newDate.getDate() + sum)
        let month = (newDate.getMonth() + 1);
        if (parseInt(month) < 10) {
            month = "0" + month;
        }
        let day: any = newDate.getDate();
        if (parseInt(day) < 10) {
            day = "0" + day;
        }
        let year = newDate.getFullYear();
        let date = year + "-" + month + "-" + day
        return date
    }

    confirmarInforme() {

        let persona = JSON.parse(localStorage.getItem('identity'))['code'];
        let confirmaInforme: ConfirmaInformeSemanal = new ConfirmaInformeSemanal(GLOBAL.code,
            this.fecha_actual,
            this.currentSelectedReport["fecha_semana_inicio"],
            this.currentSelectedReport["fecha_semana_fin"], this.informeStr);
        this._confirmInformeSemanalService.aceptarConfirmacion(confirmaInforme, persona).subscribe(
            response => {
                console.log(response)
                this.num_confirmados--;
                for (let i = 0; i < this.informes_pendientes.length; i++) {
                    if (this.informes_pendientes[i] == this.currentSelectedReport) {
                        this.informes_pendientes.splice(i, 1);
                        this.urlData = null;
                        if (this.informes_pendientes.length > 0) {
                            this.currentSelectedReport = this.informes_pendientes[0];
                            this.informeSeleccionado = this.informes_pendientes[0]['fecha_semana_inicio'] + this.informes_pendientes[0]['usuario']
                            this.crearPDF('Informe Semanal', false, this.currentSelectedReport)
                            break;
                        }
                        this.informeSeleccionado = "disabledSelect;"
                        break;
                    }
                }
                if (this.informes_pendientes.length < 1) {
                    this.onNumeroPendientes.emit(this.informes_pendientes.length);
                }
                this.toastr.success(response['message'])
                //this.subirInformeSinConfirmarYRefresacar(true)
            }, error => {
                this.toastr.error("Ha ocurrido un error durante la solicitud");
                console.log(error)
            }
        );
    }

    currentSelectedReport = null;
    async onInformeSeleccionado(eventSelect) {
        this.informeSeleccionado = eventSelect.target.value
        for (let item of this.informes_pendientes) {
            this.onNumeroPendientes.emit(1);
            let id_unico = item['fecha_semana_inicio'] + item['usuario']
            if (id_unico == this.informeSeleccionado) {
                this.currentSelectedReport = item;
                await this.crearPDF("Informe sin confirmar", false, this.currentSelectedReport)
                this.mostrarBLOB(this.fblob, 'Informe Semanal', false);
                break;
            }
        }
    }

    // setCurrentSelected(item){
    //     this.currentSelectedReport = item;
    //  }

    @Output() onNumeroPendientes : EventEmitter<any>= new EventEmitter<any>()
    async subirInformeSinConfirmarYRefresacar(refrescar = false) {
        this.onNumeroPendientes.emit(0);
        this.subirNuevoInformesLunes();        
        let date = new Date()
        if (date.getDay() === 1) {
            let existe_informe_semana_anterior = false;
            //si hoy es lunes , hacemos la peticion para crear nuevo informe sin confirmar
            this.fechaAnterior();
            if (this.informes_pendientes.length > 0 ) {
                for (let item of this.informes_pendientes) {
                    if (item["fecha_semana_inicio"] == this.fecha_inicio_semana_anterior
                     && item["fecha_semana_fin"] == this.fecha_fin_semana_anterior) {
                        existe_informe_semana_anterior = true
                    }
                }
                if (!existe_informe_semana_anterior) {
                    await this.crearPDF('Informe Semanal', false);
                    await this.subirNuevoInformesLunes(this.informeStr);
                }
            }
            
        }


    }

    subirNuevoInformesLunes(informe = null) {
        return new Promise<void>((resolve, reject) => {
            let persona = JSON.parse(localStorage.getItem('identity'))['code'];
            let confirmaInforme: ConfirmaInformeSemanal = new ConfirmaInformeSemanal(GLOBAL.code, null, this.fecha_inicio_semana_anterior, this.fecha_fin_semana_anterior, informe);
            this._confirmInformeSemanalService.subirInformeSinConfirmar(confirmaInforme, persona).subscribe(
                response => {
                    this.num_confirmados = response["num_filas"]
                    this.informes_pendientes = response['informes_sin_confirmar']
                    this.onNumeroPendientes.emit(0);
                    for (let item of this.informes_pendientes) {
                        this.mostrarBLOB(this.fblob, 'Informe Semanal', false);
                    }
                    resolve();
                    console.log("subir informe sin confirmar  ", response)
                }, error => {
                    this.toastr.error("Ha ocurrido un error durante la solicitud");
                    console.log(error)
                    reject();
                }
            );
        })
    }

    crearPDF(tituloInforme, download, informe_seleccionado_select = null) {

        return new Promise<void>((resolve, reject) => {
            let fichajeBusqueda = null;
            if (informe_seleccionado_select) {
                fichajeBusqueda = informe_seleccionado_select;
                fichajeBusqueda = new FichajeBusqueda(null, null, null, null, null, null, null, null, null, null);
                fichajeBusqueda.nombre = GLOBAL.nombre;
                fichajeBusqueda.code = informe_seleccionado_select["code"];
                fichajeBusqueda.fecha = informe_seleccionado_select["fecha_semana_inicio"];
                fichajeBusqueda.fechaHasta = informe_seleccionado_select["fecha_semana_fin"];
            }
            else {
                this.fechaAnterior();
                fichajeBusqueda = new FichajeBusqueda(null, null, null, null, null, null, null, null, null, null);
                fichajeBusqueda.nombre = GLOBAL.nombre;
                fichajeBusqueda.code = GLOBAL.code;
                fichajeBusqueda.fecha = this.fecha_inicio_semana_anterior;
                fichajeBusqueda.fechaHasta = this.fecha_fin_semana_anterior;
            }
            this._reportesService.createReportFichajes(this.codigo_empresa, fichajeBusqueda, tituloInforme, GLOBAL.logo, this.informe_fichajes_detallado, this.informe_fichajes_resumen).subscribe(
                response => {
                    if (response['code'] == 200) {
                        console.log("Informe cargado");
                        this.fblob = this.b64PDFtoBlob(response['data']);
                        this.informeStr = JSON.stringify(fichajeBusqueda);
                        resolve()
                    } else {
                        console.log("Informe no creado");
                        this.toastr.error('Se ha producido un error', 'Cargar Informe PDF');
                        reject()
                    }
                }, error => {
                    this.toastr.error('Se ha producido un error Jairo', 'Cargar Informe PDF');
                    console.log('Ha sucedido un error');
                    console.log(<any>error);
                    reject()
                }
            );
            // } else {
            //     this.mostrarBLOB(this.fblob, tituloInforme,download);
            //     resolve()
            // }
        });
    }

    b64PDFtoBlob(dataURI) {
        var byteString = atob(dataURI);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'application/pdf' });
    }

    mostrarBLOB(blob, tituloInforme, download) {

        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
            //window.location.href = URL.createObjectURL(blob);
            console.log('Safari & Opera iOS');
            this.urlData = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
            console.log('IE 11+');
            this.urlData = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
            console.log('FF iOS');
            alert("Cannot display on FF iOS");
        } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
            console.log('Chrome iOS');
            var reader = new FileReader();
            reader.onloadend = function () { window.open(<string>reader.result, tituloInforme); };
            reader.readAsDataURL(blob);
        } else {
            console.log('otro navegador'); // Chrome

            if (download) {
                // descarga con nombre original
                let a = document.createElement("a");
                document.body.appendChild(a);
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = tituloInforme;
                a.click();
                this.urlData = URL.createObjectURL(blob);
            } else {
                // this.urlData = URL.createObjectURL(blob);
                this.urlData = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
            }
        }
    }
}


