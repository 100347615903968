import {Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { DocumentosService } from '../../services/documentos.service';
import { HorarioService } from '../../services/horario.service';
import { Empleado } from '../../models/empleado';
import { Documentos } from '../../models/documentos';
import { GLOBAL } from '../../services/global.service';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'documentos',
    templateUrl: './documentos.component.html',
    styleUrls : ['./documentos.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, DocumentosService, HorarioService, DepartamentosService ]
})

export class DocumentosComponent {
    public titulo: string;
    public filesToUpload:any;
    public file_name: string;
    public codigo_empresa: string;
    public documentos: Array<Documentos>;
    public empleados: Array<Empleado>;
    public confirmado;
    public documento: Documentos;
    // public documentoAPI: Documentos;
    public documentoAPI: any;
    public fecha;
    public hora_actual;
    public global_documentos;
    public departamento: Departamentos;
    public empleado_select:any;

    //Variables de busqueda
    public filterNombre;
    public filterDepartamento;
    public filterGenero;

    public extensionesImagen;
    public extensionesDoc;
    public extensionesExc;

    public licencias_superadas;

    constructor(
        private _empleadoService: EmpleadoService,
        private _documentosService: DocumentosService,
        private _horarioService: HorarioService,
        private _departamentosService: DepartamentosService,
        private _route: ActivatedRoute,
        private _router: Router,
        protected toastr: ToastrService
    ) {
        this.titulo = "Documentos";
        this.empleados = [];    
        this.documentos = []; 
        this.departamento = null;
        this.confirmado = null;  
        this.documento = new Documentos(0,null, null, null, null, null, null, null);
        // this.documentoAPI = new Documentos(0,null, null, null, null, null, null, null);
        this.filterNombre = '';
        this.filterDepartamento = '';
        this.filterGenero = '';
        this.global_documentos = null;
        this.extensionesImagen = ['png','jpeg','jpg'];
        this.extensionesDoc = ['doc','dot'];
        this.extensionesExc = ['xls','xlt','xla'];
        this.licencias_superadas = GLOBAL.licencias_superadas;
    }

    ngOnInit(){

        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });

        this.global_documentos = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_documentos;

        this.getEmpleados();
        this.list_departamentos();
        this.fechaActual();

    }

    //Metodo que se trae todos los departamentos de la base de datos
    list_departamentos(){

        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log('Departamentos conseguidos con exito')
                    this.departamento = response['data'];

                } else {
                    console.log("Los departamentos no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que limpia el valor de las variables de busqueda 
    nullerValues(index){

        console.log("Llamando metodo nullerValues");

        if (index == 1) {
            this.filterNombre = '';
        }
        else if (index == 2) {
            this.filterDepartamento = '';
        }
        else if (index == 3) {
            this.filterGenero = '';
        }

    }

    borrarConfirm(id){
        this.confirmado = id;
        console.log('ID Documento '+this.confirmado);
    }

    cancelarConfirm(){
        this.confirmado = null;
    }

    fileChangeEvent(fileInput: any, code, nombre, apellidos) {
        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        this.filesToUpload = <Array<File>>fileInput.target.files;
        console.log('Archivo a subir:');
        console.log(this.filesToUpload);
        console.log('para el empleado:');
        console.log(code); console.log(nombre); console.log(apellidos);

        // this.file_name = '' + this.fecha + '-' + this.hora_actual + '-' + code + '-' + this.codigo_empresa + '.pdf';
        this.file_name = this.filesToUpload[0].name;
        console.log(this.file_name);

        this._empleadoService.makeFileRequest(GLOBAL.url + '/upload-file-documentos/' + this.codigo_empresa + '/' + code + '/' +  GLOBAL.url_documentos + '/' + this.file_name, [], this.filesToUpload).then( 
            (result) => {

                console.log(result);
                if (result['code'] == 200) {
                    this.toastr.success('Archivo subido', 'DOCUMENTOS');
                    // Inserta el documento en la base de datos
                    console.log('Añadir a la BD documento:');
                    console.log(code); console.log(nombre); console.log(apellidos); console.log(this.file_name);
                    this.addDocumento(code, nombre, apellidos, this.file_name);
                } else {
                    console.log('Error al subir el archivo');
                    this.toastr.warning('Error al subir el archivo. Revise el tamaño máximo permitido', 'DOCUMENTOS');
                }

            }, (error) => {
                console.log(error);
                this.toastr.error('Error al subir el archivo.', 'DOCUMENTOS');
            }
        );

    }

    //Metodo que se trae de la base de datos todos los empleados de la empresa
    getEmpleados(){

        this._empleadoService.getEmpleados(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Empleados conseguidos");
                    this.empleados = response['data'];
                    console.log(this.empleados);
                    this.allDocuments();
                    
                } else {
                   console.log("No se han podido conseguir los empleados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );
    }


    //Se trae los documentos del empleado seleccionado
    selectEmpleado(code){
        console.log('Empleado seleccionado para filtrar documentos:');
        this.empleado_select = code;
        console.log(code);
        console.log(this.empleado_select);
        this._documentosService.getDocumentosEmpleado(code, this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Documentos conseguidos");
                    this.documentos = response['data'];
                    this.infoDocuments();

                } else {
                    console.log("No se han podido conseguir los documentos del empleado");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    /*
    open(base64, tipo, nombre){
        if(tipo == 'imagen'){
            let image = new Image();
            image.src = base64;
            let w = window.open("");
            w.document.write(image.outerHTML);
        }else{
            if(tipo == 'pdf'){
                console.log("Es un pdf");
                let fblob = this.b64PDFtoBlob(base64, 'application/pdf');
                this.mostrarBLOB(fblob, nombre);
            }else if(tipo == 'doc'){
                console.log("Es un doc");
                let fblob = this.b64PDFtoBlob(base64, 'application/msword');
                this.mostrarBLOB(fblob, nombre);
            }else if(tipo == 'docx'){
                console.log("Es un docx");
                let fblob = this.b64PDFtoBlob(base64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                this.mostrarBLOB(fblob, nombre);
            }else if(tipo == 'xlsx'){
                console.log("Es un xlsx");
                let fblob = this.b64PDFtoBlob(base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                this.mostrarBLOB(fblob, nombre);
            }else{
                console.log("Es un excel");
                let fblob = this.b64PDFtoBlob(base64, 'application/vnd.ms-excel');
                this.mostrarBLOB(fblob, nombre);
            }
        }
    }
    */

   getDocumentoAPI(id, code, download) {
    // this.cargandoListaDocs = true;
    // this._upload.getDocumentoPacienteAPI(id).subscribe(
    let documentoTMP = new Documentos(0,null, null, null, null, null, null, null);
    documentoTMP.id = id;
    documentoTMP.code = code;
    // this._documentosService.downloadDocumento(documentoTMP).subscribe(
    this._documentosService.getDocumentoAPI(id).subscribe(
      result => {
        this.documentoAPI = result['data'];
        // this.cargandoListaDocs = false;
        console.log('this.documentoAPI');
        console.log(this.documentoAPI);
        this.infoDocument(download);
      },
      error => {
        // this.cargandoListaDocs = false;
        console.log(error as any);
        this.toastr.error('Documento no cargado', 'Error', {timeOut: 3000, positionClass: 'toast-top-right'});
      });
}

   infoDocument(download) {
    let img = '';
    for(let x = 0; x<this.documentoAPI.length;x++){
        // let y = this.documentoAPI[x]['documento'].indexOf('.'); // si el nombre tiene mas de un punto, no coge la extion final !!!        
        // let sufix = this.documentoAPI[x]['documento'].substring(y+1).toLowerCase();
        let nombreArchivo = this.documentoAPI[x]['documento'];
        let sufix = (nombreArchivo.substring(nombreArchivo.lastIndexOf(".")+1)).toLowerCase();
        console.log('extension archivo: ' + sufix);
        if(this.extensionesImagen.includes(sufix)){ // 'png','jpeg','jpg'
            img = 'data:image/png;base64,' + this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'imagen';
        }else if(sufix == 'pdf'){                       // pdf
            img = this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'pdf'; 
        }else if(this.extensionesDoc.includes(sufix)){ // 'doc','dot'
            img = this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'doc'; 
        }else if(this.extensionesExc.includes(sufix)){ // 'xls','xlt','xla'
            img = this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'excel';
        }else if(sufix == 'docx'){                    // 'docx'
            img = this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'docx';
        }else if(sufix == 'xlsx'){                    // 'xlsx'
            img = this.documentoAPI[x]['base64'];
            this.documentoAPI[x]['tipo'] = 'xlsx';
        }else if(sufix == 'odt'){                     // 'odt'
          img = this.documentoAPI[x]['base64'];
          this.documentoAPI[x]['tipo'] = 'odt';
        }
        this.documentoAPI[x]['base64'] = img;
    } 
    console.log('Documento cargado: ');
    console.log(this.documentoAPI);
    console.log('Tipo Documento cargado: ');
    console.log(this.documentoAPI[0]['tipo']);
    console.log(this.documentoAPI[0].tipo);

    this.openFILE(download, this.documentoAPI[0].base64, this.documentoAPI[0].tipo, this.documentoAPI[0].documento);
}    

   openFILE(download, base64, tipo, nombre) {
    console.log('openFILE');
    console.log('download');
    console.log(download);
    console.log('tipo');
    console.log(tipo);
    console.log('nombre');
    console.log(nombre);     
  
      if(tipo == 'imagen'){
        if (download) {
          var a = document.createElement("a"); //Create <a>
          // a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
          a.href = base64; //Image Base64 Goes here
          a.download = nombre; //File name Here
          a.click(); //Downloaded file
          this.toastr.success('Documento descargado. Verifique que el navegador no bloquea la descarga', 'Documentos', {timeOut: 3000, positionClass: 'toast-top-right'});
        } else {
          let image = new Image();
          image.src = base64;
          let w = window.open("");
          w.document.write(image.outerHTML);
        }
      }else{
          if(tipo == 'pdf'){
              console.log("Es un pdf");
              let fblob = this.b64PDFtoBlob(base64, 'application/pdf');
              this.mostrarBLOB(fblob, nombre, download);
          }else if(tipo == 'doc'){
              console.log("Es un doc");
              let fblob = this.b64PDFtoBlob(base64, 'application/msword');
              this.mostrarBLOB(fblob, nombre, download);
          }else if(tipo == 'docx'){
              console.log("Es un docx");
              let fblob = this.b64PDFtoBlob(base64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
              this.mostrarBLOB(fblob, nombre, download);
          }else if(tipo == 'xlsx'){
              console.log("Es un xlsx");
              let fblob = this.b64PDFtoBlob(base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
              this.mostrarBLOB(fblob, nombre, download);
          }else if(tipo == 'odt'){
              console.log("Es un odt");
              let fblob = this.b64PDFtoBlob(base64, 'application/vnd.oasis.opendocument.text');
              this.mostrarBLOB(fblob, nombre, download);            
          }else{
              console.log("Tipo/extension no contemplado. ");
              this.toastr.warning('Tipo de archivo posiblemente no admitido', 'Aviso', {timeOut: 3000, positionClass: 'toast-top-right'});
              let fblob = this.b64PDFtoBlob(base64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
              this.mostrarBLOB(fblob, nombre, download);
          }
      }
  
    }

    b64PDFtoBlob(dataURI, type2) {
        // var byteString = atob(dataURI.split(',')[1]);
        var byteString = atob(dataURI);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // return new Blob([ab], { type: 'image/jpeg' });
        return new Blob([ab],{ type:  type2});
    }

    mostrarBLOB_OLD(blob, tituloInforme) {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
            //window.location.href = URL.createObjectURL(blob);
            window.open(URL.createObjectURL(blob), tituloInforme);
        } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
            window.navigator.msSaveOrOpenBlob(blob, tituloInforme);
        } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
            alert("Cannot display on FF iOS");
        } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
            var reader = new FileReader();
            reader.onloadend = function () { window.open(<string>reader.result, tituloInforme);};
            reader.readAsDataURL(blob);
        } else {
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl, tituloInforme);
        }

    }

    mostrarBLOB(blob, tituloInforme, download) {
        // download es para chrome windows
        console.log('mostrarBLOB');
        console.log(tituloInforme);
    
        // este codigo va en chrome pero no en edge
        // descarga con el nombre del documento
        /*
        let a = document.createElement("a");
        // a.style = "display: none";
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = tituloInforme; // gives it a name via an a tag
        a.click();
        window.URL.revokeObjectURL(url);
        return;
        */
    
        /* este codigo funciona en general pero no descarga con el nombre original */
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
            //window.location.href = URL.createObjectURL(blob);
            console.log('Safari & Opera iOS');
            window.open(URL.createObjectURL(blob), tituloInforme);
        } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
            console.log('IE 11+');
            window.navigator.msSaveOrOpenBlob(blob, tituloInforme);
        } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
            console.log('FF iOS');
            alert("Cannot display on FF iOS");
        } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
            console.log('Chrome iOS');
            var reader = new FileReader();
            reader.onloadend = function () { window.open(<string>reader.result, tituloInforme);};
            reader.readAsDataURL(blob);
        } else {
            console.log('otro navegador'); // Chrome
            
            if (download) {
              // descarga con nombre original
              let a = document.createElement("a");
              // a.style = "display: none";
              document.body.appendChild(a);
              let url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = tituloInforme; // gives it a name via an a tag
              a.click();
            } else {
              // abre en nueva pestaña pero con nombre aleatorio
              var objectUrl = URL.createObjectURL(blob);
              window.open(objectUrl, tituloInforme);
            }
    
        }
    
      }    


    //Se trae los docuemntos de todos los empleados
    allDocuments(){
        let img = '';
        this._documentosService.getDocumentos(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    this.documentos = response['data'];
                    this.infoDocuments();
                } else {
                    console.log("No se han podido conseguir los documentos");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    infoDocuments(){
        let img = '';
        for(let x = 0; x<this.documentos.length;x++){
            // let y = this.documentos[x]['documento'].indexOf('.'); // si el nombre tiene mas de un punto, no coge la extion final !!!        
            // let sufix = this.documentos[x]['documento'].substring(y+1).toLowerCase();
            let nombreArchivo = this.documentos[x]['documento'];
            let sufix = (nombreArchivo.substring(nombreArchivo.lastIndexOf(".")+1)).toLowerCase();
            if(this.extensionesImagen.includes(sufix)){
                img = 'data:image/png;base64,' + this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'imagen';
            }else if(sufix == 'pdf'){
                img = this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'pdf'; 
            }else if(this.extensionesDoc.includes(sufix)){
                img = this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'doc'; 
            }else if(this.extensionesExc.includes(sufix)){
                img = this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'excel'; 
            }else if(sufix == 'docx'){
                img = this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'docx'; 
            }else if(sufix == 'xlsx'){
                img = this.documentos[x]['base64'];
                this.documentos[x]['tipo'] = 'xlsx'; 
            } else if(sufix == 'odt'){                     // 'odt'
            img = this.documentos[x]['base64'];
            this.documentos[x]['tipo'] = 'odt';
          }
            this.documentos[x]['base64'] = img;
        } 
        console.log('Documentos encontrados: ');
        console.log(this.documentos);
    }

    //Metodo que elimina un documento
    deleteDocument(id, code, nombre_fichero){
        console.log('Eliminar documento: ');
        console.log('id: ' + id);
        console.log('code: ' + code);
        console.log('codigo_empresa: ' + this.codigo_empresa);
        console.log('ruta: ' + GLOBAL.url_documentos);
        console.log('nombre_fichero: ' + nombre_fichero);
        this._documentosService.deleteDocumento(id, code, this.codigo_empresa, GLOBAL.url_documentos, nombre_fichero).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log("Documento eliminado");
                    this.toastr.success('Archivo eliminado.', 'DOCUMENTOS');
                    //Se trae los documentos para recargar la vista
                    this.confirmado = null;
                    this.allDocuments();

                } else {
                    console.log("No se han podido eliminar el documento");
                    this.toastr.warning('Error al eliminar el archivo.', 'DOCUMENTOS');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
                this.toastr.error('Error al eliminar el archivo.', 'DOCUMENTOS');
            }
        );

    }

    descargarDocumento(doc) {
        console.log('Descargar documento: ' + doc);
        this._documentosService.downloadDocumento(doc).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log("Documento descargado");
                } else {
                    console.log("No se ha podido descargar el documento");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );

    }

    fechaActual() {

        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Fecha actual obtenida");
                    this.fecha = response['data'];
                } else {
                    console.log("No se ha podido conseguir la fecha actual");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

        this._horarioService.getHoraActualGuion().subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Hora actual obtenida");
                    this.hora_actual = response['data'];

                } else {
                    console.log("No se ha podido conseguir la hora actual");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que inserta un fichero en la base de datos
    addDocumento(code, nombre, apellidos, file){

        this.documento.code = code;
        this.documento.codigo_empresa = this.codigo_empresa;
        this.documento.nombre = nombre;
        this.documento.apellidos = apellidos;
        this.documento.documento = file;
        this.documento.fecha = this.fecha;
        this.documento.created_by = GLOBAL.code;

        this._documentosService.addDocumento(this.documento).subscribe(
            response => {
                if (response['code'] == 200){
                    this.toastr.success('Archivo añadido a la base de datos.', 'DOCUMENTOS');
                    console.log("Documento insertado en la base de datos con exito");
                    this.allDocuments();
                } else {
                    this.toastr.warning('Error al añadir el archivo a la base de datos.', 'DOCUMENTOS');
                    console.log("El documento no se ha podido insertar en la base de datos");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
                this.toastr.error('Error al añadir el archivo a la base de datos.', 'DOCUMENTOS');
            }
        );

    }

}