import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GLOBAL } from './global.service';
import { FichajeBusqueda } from '../models/fichaje-busqueda';

@Injectable()
export class ReportesService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    createReportAbsentismo(codigo_empresa){
        return this._http.get(this.url+'/reporte-absentismo');
    }

    createReportFichajes(codigo_empresa, fichajeBusqueda, tituloInforme, logoEmpresa, detallado, resumen) {
      	// OJO: los empleados tienen q tener asignado un calendario laboral, si no, puede dar error el informe resumen.
    	// ASEGURARSE QUE EL usuario admin_xxx creado por defecto al crear la empresa lo tiene

        console.log('Cargando Informe...');
        // return this._http.get(this.url + '/reporte-fichajes/' + encodeURIComponent(codigo_empresa) + '/' + encodeURIComponent(tituloInforme) + '/' + logoEmpresa);

        let json = JSON.stringify(fichajeBusqueda);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/reporte-fichajes/' + resumen + '/' + detallado + '/' + encodeURIComponent(tituloInforme) + '/' + logoEmpresa, params, {headers: headers});

    }

}
