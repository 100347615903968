import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GLOBAL } from './global.service';
import { SolicitudVacaciones } from '../models/solicitudVacaciones';

@Injectable({
  providedIn: 'root'
})
export class SolicitudVacacionesService {
  public url: string;

  constructor(public _http: HttpClient){
    this.url = GLOBAL.url;

  }

  pedirVacaciones(vacaciones: SolicitudVacaciones, persona?: string){
    let json = JSON.stringify(vacaciones);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this._http.post(this.url + '/solicitud_vacaciones/'+persona, params, {headers: headers});
  }


  solicitudes_pendientes(user_info : Object, persona?: string){
    let json = JSON.stringify(user_info);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this._http.post(this.url + '/solicitudes_pendientes/'+persona, params, {headers: headers});
  }

  resolver_solicitud(user_info : Object, persona?: string){
    let json = JSON.stringify(user_info);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this._http.post(this.url + '/resolver_solicitud/'+persona, params, {headers: headers});
  }

  asignar_encargado(user_info : Object, persona?: string){
    let json = JSON.stringify(user_info);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this._http.post(this.url + '/asignar_encargado/'+persona, params, {headers: headers});
  }

  // automatic_update_vacaciones(codigo_empresa: string, fecha: string) {
  //     return this._http.get(this.url + '/vacaciones-actualizar-fecha/' + fecha);
  // }


}
