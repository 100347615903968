import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global.service';
import { UsuarioLogin } from 'src/app/models/usuarioLogin';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
  })
export class UsuarioService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    loginUsuario(userLogin: UsuarioLogin, getToken = null): Observable<any> {
        // devuelvo datos como objeto. accedo luego como: response.status, response.token.data, ....

        if (getToken != null) {
            userLogin.getToken = 'true';
        }

        // paso parametros por post
        let json = JSON.stringify(userLogin);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/usuario-login', params, {headers: headers});

    }

    getUsuario(code, codigo_empresa) {
        return this._http.get(this.url + '/usuario/' + code);
    }

    getUsuarios(codigo_empresa) {
        return this._http.get(this.url + '/usuarios');
    }

    addUsuario(usuario: Usuario){
        let json = JSON.stringify(usuario);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/usuario', params, {headers: headers});
    }

    editUsuario(id, usuario: Usuario) {
      let json = JSON.stringify(usuario);
      let params = 'json='+json;
      let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this._http.post(this.url+'/usuario-update-rol/' +id, params, {headers: headers});
    }

    deleteUsuario(code){
        return this._http.get(this.url+'/usuario-delete/'+code);
    }

    recuperarContrasena(info) {

        let obj = {};
        info.forEach(item => obj[item.Field] = item.Value);

        let json = JSON.stringify(obj);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'/recuperarContrasena', params, { headers: headers });
    }

    comprobarCodigo(correo, codigo){
        return this._http.get(this.url+'/comprobarCodigo/'+correo+'/'+codigo);
    }

}
