import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Departamentos } from '../models/departamentos';
import { GLOBAL } from './global.service';

@Injectable()
export class DepartamentosService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addDepartamento(departamento: Departamentos){
        let json = JSON.stringify(departamento);
        // let params = 'json='+json;
        let params = 'json=' + encodeURIComponent(json);
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'/departamento', params, {headers: headers});
    }

    getDepartamentos(codigo_empresa: string){
        return this._http.get(this.url+'/departamentos');
    }

    deleteDepartamento(id: number){
        return this._http.get(this.url + '/departamento-delete/' + id);
    }
    updateDepartamento(departamento: Departamentos){
        let json = JSON.stringify(departamento);
        let params = 'json='+ encodeURIComponent(json);
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/departamento-update', params, {headers: headers});
    }

}    