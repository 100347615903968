import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items:any, filterNombre: string, filterDepartamento: string, filterGenero:string, filterFecha: Date, filterFechaHasta: Date, filterHoras_contrato: number, tipofecha:number ){

    /*
    console.log('items: ');
    console.log(items);
    console.log('horas: ');
    console.log(filterHoras_contrato);

    console.log('type of filterHoras_contrato: ');
    console.log(typeof filterHoras_contrato);
    */

    if (items && items.length){
        return items.filter(item => {

          /*
          console.log('type of item.horas_contrato: ');
          console.log(typeof item.horas_contrato);
          */

            /* if (filterHoras_contrato && item.horas_contrato.indexOf(filterHoras_contrato) === -1){ */ // input tipo string
            // if ( filterHoras_contrato && item.horas_contrato !== String(filterHoras_contrato)) {          // input tipo number

            /* version OK SIN DECIMALES
            if ( filterHoras_contrato && String(item.horas_contrato) !== String(filterHoras_contrato) && filterHoras_contrato != 0) {          // input tipo number  
              return false;
            }
            */

            // VERSION OK CON DECIMALES
            /*
            console.log('comparar filterHoras_contrato: ');
            console.log(this.add_decimal(filterHoras_contrato));
            console.log('comparar con item.horas_contrato: ');
            console.log(String(item.horas_contrato));
            */

            if ( filterHoras_contrato && String(item.horas_contrato) !== this.add_decimal(filterHoras_contrato) && filterHoras_contrato != 0) { // input tipo number
              return false;
            }


            // if (filterNombre && item.nombre.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1){

            // buscamos realmente en los campos nombre y apellidos
            /*
            if ( (filterNombre && (item.nombre.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1)) || (filterNombre && (item.apellidos.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1))  )  {
                return false;
            }
            */
           let nombre_apellidos = item.nombre + ' ' + item.apellidos;
           nombre_apellidos = this.filtrar_acentos(nombre_apellidos);
           filterNombre = this.filtrar_acentos(filterNombre);
           if ( (filterNombre && nombre_apellidos.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1) )
           {
               return false;
           }

            if (filterDepartamento && item.puesto.toLowerCase().indexOf(filterDepartamento.toLowerCase()) === -1) {
                return false;
            }

            if (filterGenero && item.genero.toLowerCase().indexOf(filterGenero.toLowerCase()) === -1) {
                return false;
            }

            if (tipofecha == 0) {
              if (filterFecha && item.fecha.indexOf(filterFecha) === -1) {
                  return false;
              }
            }
            /*
            if (tipofecha == 1) { // vacaciones
              // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
              if (filterFecha && (filterFecha < item.inicio_vacaciones || filterFecha > item.fin_vacaciones) ){
                  return false;
              }
            }
            */
            if (tipofecha == 1) { // vacaciones
              // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
              if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
                return true;
              } else
              if ( !((filterFecha >= item.inicio_vacaciones && filterFecha <= item.fin_vacaciones) ||
                   (filterFechaHasta >= item.inicio_vacaciones && filterFechaHasta <= item.fin_vacaciones) ||
                   (filterFecha < item.inicio_vacaciones && filterFechaHasta > item.fin_vacaciones))) {
                  return false;
              }
            }

            if (tipofecha == 2) { // bajas
              // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
              if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
                return true;
              } else
              if ( !((filterFecha >= item.inicio_baja && filterFecha <= item.fin_baja) ||
                   (filterFechaHasta >= item.inicio_baja && filterFechaHasta <= item.fin_baja) ||
                   (filterFecha < item.inicio_baja && filterFechaHasta > item.fin_baja))) {
                  return false;
              }
            }
            if (tipofecha == 3) { // ausencias
              // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
              if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
                return true;
              } else
              if ( !((filterFecha >= item.inicio_ausencias && filterFecha <= item.fin_ausencias) ||
                   (filterFechaHasta >= item.inicio_ausencias && filterFechaHasta <= item.fin_ausencias) ||
                   (filterFecha < item.inicio_ausencias && filterFechaHasta > item.fin_ausencias))) {
                  return false;
              }
            }

            return true;

       } );

    } else {
        return items;
    }

}

add_decimal(valor) : string {
  let svalor = '';
  if (valor) {
      if (String(valor).indexOf('.') == -1 && String(valor).indexOf(',') == -1) {
        svalor = String(valor) + '.0';
      } else {
        svalor = String(valor);
      }

  }
  // console.log('filtro a comparar: ' + svalor);
  return svalor;
}

filtrar_acentos(input) {

  // no sólo acentos; ñ -> n
  // var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
  // var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
  // no sólo acentos; Quito: ñ -> n
  var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç";
  var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc";

  for (var i = 0; i < acentos.length; i++) {
      input = input.replace(acentos.charAt(i), original.charAt(i)).toLowerCase();
  }
  return input;
}

}
