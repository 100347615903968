import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Documentos } from '../models/documentos';
import { GLOBAL } from './global.service';

@Injectable()
export class DocumentosService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addDocumento(documento: Documentos){
        let json = JSON.stringify(documento);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url+'/documentos-add', params, {headers: headers});
    }

    getDocumentos(codigo_empresa){
        return this._http.get(this.url+'/documentos-empresa');
    }

    getDocumentosEmpleado(code, codigo_empresa){
        return this._http.get(this.url+'/documentos-empleado/'+code);
    }

    deleteDocumento(id, code, codigo_empresa, folder, nombre_fichero){
        return this._http.get(this.url+'/documentos-delete/'+id+'/'+code+'/'+folder+'/'+nombre_fichero);
    }

    downloadDocumento(documento: Documentos){
        let json = JSON.stringify(documento);
        let params = 'json=' + json;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

        return this._http.post(this.url + '/documentos-download', params, {headers: headers});
    }

    
    getDocumentoAPI(id){
        console.log('URL: ' + this.url+'/documentoAPI/' + id );
		var result = this._http.get(this.url+'/documentoAPI/'+ id);
		return result;
    }
    
}
