export class Usuario{

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public rol: string;

    constructor(id:number, code:string, codigo_empresa: string, rol:string){
        this.id = id;
        this.rol = rol;
        this.codigo_empresa = codigo_empresa;
        this.code = code;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getRol(){
        return this.rol;
    }

    setRol(rol: string){
        this.rol = rol;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getCode(){
        return this.rol;
    }

    setCode(code: string){
        this.code = code;
    }

}