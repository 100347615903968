export class Departamentos{

    public id: number;
    public codigo_empresa: string;
    public nombre_departamento: string;

    constructor(id: number, codigo_empresa: string, nombre_departamento: string){

        this.id = id;
        this.codigo_empresa = codigo_empresa;
        this.nombre_departamento = nombre_departamento;

    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getNombre_departamento(){
        return this.nombre_departamento;
    }

    setNombre_departamento(nombre_departamento: string){
        this.nombre_departamento = nombre_departamento;
    }

}