import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ausencias } from '../models/ausencias';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AusenciasService {
  public url: string;

  constructor(public _http: HttpClient){
      this.url = GLOBAL.url;
  }

  addAusencias(ausencias: Ausencias, persona?: string){
      let json = JSON.stringify(ausencias);
      let params = 'json='+json;
      let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

      if(persona){
        return this._http.post(this.url + '/ausencias-add/' + persona, params, {headers: headers});
      }else{
        return this._http.post(this.url + '/ausencias-add', params, {headers: headers});
      }
  }

  automatic_update_ausencias(codigo_empresa: string, fecha: string) {
      return this._http.get(this.url + '/ausencias-actualizar-fecha/' + fecha);
  }

  deleteAusencias(id: number, code: string, fecha_ini: string, fecha_fin:string){
    return this._http.get(this.url + '/ausencias-delete/'+id+'/'+code+'/'+fecha_ini+'/'+fecha_fin);
  }

  updateAusencias(ausencias: Ausencias, fecha_ini:string, fecha_fin:string, code_upd: string){
    let json = JSON.stringify(ausencias);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    console.log(params);
    return this._http.post(this.url + '/ausencias-update/'+fecha_ini+'/'+fecha_fin+'/'+code_upd,params, {headers:headers});
  }

}

