import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { CalendariosTrabajo } from '../models/calendarios_trabajo';
import { DiasFestivos } from '../models/dias_festivos';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class CalendariosLaboralesService {

  public url: string;

  constructor(public _http: HttpClient){
      this.url = GLOBAL.url;
  }

  getCalendariosLaborales(codigo_empresa: string, comunes: string){
      return this._http.get(this.url + '/calendarios-laborales/' + comunes );
  }

  addCalendarioLaboral(titulo: string) {
    let codigo_empresa = GLOBAL.codigo_empresa
    let json = JSON.stringify({titulo, codigo_empresa});
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this._http.post(this.url + '/calendarios-laborales-add', params, {headers: headers});
  }

  editCalendarioLaboral(titulo: string, id: number) {
    let codigo_empresa = GLOBAL.codigo_empresa
    let json = JSON.stringify({titulo, codigo_empresa, id});
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this._http.post(this.url + '/calendarios-laborales-edit', params, {headers: headers});
  }

  deleteCalendarioLaboral(id: number) {
    let params = 'json={"id": ' + id + '}';
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    console.log(params);

    return this._http.post(this.url + '/calendarios-laborales-delete', params, {headers: headers});
  }

  getDiasFestivosFullCalendar(id_calendarios_laborales: string){
    return this._http.get(this.url + '/dias-festivos-fullcalendar/' + id_calendarios_laborales );
  }

  addDiasFestivos(dia_festivo: DiasFestivos) {
    // return this._http.get(this.url + '/calendarios-trabajo-add/' + codigo_empresa );

    // paso parametros por post
    let json = JSON.stringify(dia_festivo);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this._http.post(this.url + '/dias-festivos-add', params, {headers: headers});

  }

  updateDiasFestivos(dia_festivo: DiasFestivos) {

      // paso parametros por post
      let json = JSON.stringify(dia_festivo);
      let params = 'json=' + json;
      let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

      return this._http.post(this.url + '/dias-festivos-update', params, {headers: headers});

  }

  deleteDiasFestivos(id) {

      return this._http.get(this.url + '/dias-festivos-delete/' + id );

  }

}
