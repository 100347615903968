import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class IdentityGuard implements CanActivate {

  constructor( protected _authService: AuthService, private _router: Router ) {}

  canActivate() {
    console.log("Identity Guard: Validando ...");
    let identity = this._authService.getStorageIdentity();
    let token = this._authService.getStorageToken();
    if ( identity && token) {
      console.log("Identity Guard: Validación OK");
      return true;
    } else {
        console.log("Identity Guard: redirigiendo a inicio ...");
        this._router.navigate(['/']);
        return false;
    }


    // podria también verificar si el token está guardado y es válido ( como en app.component )
    /* no va, se va a inicio */
    /*
    if (this.storageAvailable('localStorage')) {
      console.log("Identity Guard: Local Storage disponible");

      // si token no valido, borro localStorage
      console.log("Identity Guard: Validando token...");
      this._authService.checkToken().subscribe(
        response => {
            if (response.status === 'error' && response.code === 401){
                console.log("Identity Guard: Token no válido");
                //this.inicializar();
                console.log("Identity Guard: Redireccionando a Inicio ...");
                this._router.navigate(['/']);
                return false;
            } else {
                console.log("Identity Guard: Token válido");
                return true;
            }
        }, error => {
            console.log('Identity Guard: Ha sucedido un error validando el token');
            console.log(<any>error);
            //this.inicializar();
            console.log("Identity Guard: Redireccionando a Inicio ...");
            this._router.navigate(['/']);
            return false;
        }

      );
    } else {
      console.log("Identity Guard: Local Storage no disponible");
      console.log("Identity Guard: Redireccionando a Inicio ...");
      this._router.navigate(['/']);
      return false;
    }
    */

  }

  //Detecta si los métodos de almacenamiento local están disponibles
  storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
  }

}
