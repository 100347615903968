import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horasContrato'
})
export class HorasContratoPipe implements PipeTransform {
  transform(items: any, horas: number): any {
    /*
    console.log('items: ');
    console.log(items);
    console.log('horas: ');
    console.log(horas);
    */

    // al añadir la posibilidad de que las horas de trabajo sean con 1 decimal hay q
    // tener en cuenta que en el filtro no tecleamos la parte decimal si es 0 pero en la BD sí está guardado así
    // tecleamos "30" pero está guardado "30.0"

    if (items && items.length) {
      return items.filter(item => {

        // console.log('item.horas_contrato: ');
        // console.log(String(item.horas_contrato));
        if ( horas && String(item.horas_contrato) !== this.add_decimal(horas) && horas != 0) { // input tipo number
          return false;
        }
          return true;

      });
    } else {
      return items;
    }
  }

  add_decimal(valor) : string {
    let svalor = '';
    if (valor) {
        if (String(valor).indexOf('.') == -1 && String(valor).indexOf(',') == -1) {
          svalor = String(valor) + '.0';
        } else {
          svalor = String(valor);
        }

    }
    // console.log('filtro a comparar: ' + svalor);
    return svalor;
  }

}
