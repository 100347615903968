import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'festivosFilter'
})
export class FestivosFilterPipe implements PipeTransform {

  transform(items: any, fechacalendario: string) {
    if (items && items.length) {
        return items.filter(item => {

              // si el item es del mes y año visible actual en el calendario
              if (fechacalendario.substr(0, 7) === item.start.substr(0, 7) ) {
                  return true;
              }

              return false;

       });

    } else {
      return items;
    }

  }

}
