export class FichajeBusqueda{
    public codigo_empresa: string;
    public code: string;
    public fecha: Date;
    public fechaHasta: Date;
    public nombre: string;
    public departamento: string;
    public incidencias_salida: string;
    public incidencias: string;
    public horas_contrato: number;
    public confirmados: boolean;

    constructor(codigo_empresa: string, code: string, fecha: Date, fechaHasta: Date, nombre: string, departamento: string, incidencias_salida: string, incidencias:string, horas_contrato: number, confirmados: boolean){
        this.codigo_empresa = codigo_empresa;
        this.code = code;
        this.fecha = fecha;
        this.fechaHasta = fechaHasta;
        this.nombre = nombre;
        this.departamento = departamento;
        this.incidencias_salida = incidencias_salida;
        this.incidencias = incidencias;
        this.horas_contrato = horas_contrato;
        this.confirmados = confirmados;

    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getCode(){
        return this.code;
    }

    getIncidenciaSalida() {
        return this.incidencias_salida = this.incidencias_salida;
    }

    getIncidencias() {
        return this.incidencias = this.incidencias;
    }


    setCode(code: string){
        this.code = code;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: Date){
        this.fecha = fecha;
    }

    getFechaHasta(){
        return this.fechaHasta;
    }

    setFechaHasta(fechaHasta: Date){
        this.fechaHasta = fechaHasta;
    }

    getNombre(){
        return this.nombre;
    }

    setNombre(nombre: string){
        this.nombre = nombre;
    }

    getDepartamento(){
        return this.departamento;
    }

    setApellidos(departamento: string){
        this.departamento = departamento;
    }

    getHoras_contrato() {
        return this.horas_contrato;
    }

    setHoras_contrato(horas_contrato: number){
        this.horas_contrato = horas_contrato;
    }

    getConfirmados(){
        return this.confirmados;
    }

    setConfirmados(confirmados: boolean){
        this.confirmados = confirmados;
    }

}
