import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GLOBAL } from './global.service';
import { ConfirmaInformeSemanal } from '../models/confir_informe_semanal';

@Injectable()
export class ConfirmaInformeSemanalService{
    public url: string;

    constructor(public _http: HttpClient){
      this.url = GLOBAL.url;
    }

    aceptarConfirmacion(modelConfirmacion: ConfirmaInformeSemanal, persona?: string){
      let json = JSON.stringify(modelConfirmacion);
      let params = 'json='+json;
      let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
      return this._http.post(this.url + '/informe-confirmado/'+persona, params, {headers: headers});
    }

    subirInformeSinConfirmar(modelConfirmacion: ConfirmaInformeSemanal, persona?: string){
      let json = JSON.stringify(modelConfirmacion);
      let params = 'json='+json;
      let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
      return this._http.post(this.url + '/informe-sin-confirmar/'+persona, params, {headers: headers});
    }

}