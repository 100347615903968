import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector : 'correct',
    styleUrls : ['./correct.component.css', '../../../style.scss'],
    templateUrl : './correct.component.html'
})

export class CorrectComponent {
    public mensaje: string;
    public mensaje2: string;

    constructor(
        protected _router: Router,
        protected _route: ActivatedRoute
    ) {
        this.mensaje = "Fichaje Registrado";
        this.mensaje2 = ""; // para avisos
    }

    ngOnInit() {

        this._route.params.subscribe(params => {

          let msg: string = params['msg'];
          let msg2: string = params['msg2'];

          if ( msg && msg != '' && msg != 'null') {
            this.mensaje = msg;
          } else {
            this.mensaje = '';
          }

          if ( msg2 && msg2 != '' && msg2 != 'null') {
            this.mensaje2 = msg2;
          } else {
            this.mensaje2 = '';
          }

        });

        setTimeout(() =>
        {
            this._router.navigate(['/registro']);
        },
        2500);
    }
}