import { Injectable } from '@angular/core';
import { Log } from '../models/log';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GLOBAL } from './global.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  public url: string;
  public log: Log;
  constructor(private _http: HttpClient) {
    this.url = GLOBAL.url;
    this.log = new Log(null,null,null,null,null,null,null,null);
   }

  addLogs(categoria: string, descripcion: string , observaciones1?: string , observaciones2?: string){

    //Este metodo se encargara de guardar nuevos logs donde los necesitemos, solo necesitaremos enviarle la tabla a la que 
    // afecta la acción, la descripción de lo que haya pasado y si se necesita se le puede añadir observaciones.
    this.log.codigo = GLOBAL.code;
    this.log.codigo_empresa = GLOBAL.codigo_empresa;
    this.log.categoria = categoria;
    this.log.descripcion = descripcion;
    if(observaciones1){
      this.log.observaciones1 = observaciones1;
    }else{
      this.log.observaciones1 = null;
    }
    if(observaciones2){
      this.log.observaciones2 = observaciones2;
    }else{
      this.log.observaciones2 = null;
    }
    
    let json = JSON.stringify(this.log);
    let params = 'json='+json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url + '/Log', params, {headers: headers});
  }

  getLogs(){
    return this._http.get(this.url + '/Logs');
  }
}
