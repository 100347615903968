import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Bajas } from '../models/bajas';
import { GLOBAL } from './global.service';
import { controlNameBinding } from '@angular/forms/src/directives/reactive_directives/form_control_name';

@Injectable({
    providedIn: 'root'
})
export class BajaService{
    public url: string;

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    addBaja(baja: Bajas, persona?: string){
        let json = JSON.stringify(baja);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        if(persona){
            return this._http.post(this.url + '/bajas-add/' + persona, params, {headers: headers});
        }else{
            return this._http.post(this.url + '/bajas-add', params, {headers: headers});
        }
       
    }

    getBajas(codigo_empresa){
        return this._http.get(this.url + '/bajas');
    }

    automatic_update_bajas(codigo_empresa: string, fecha: string) {
        return this._http.get(this.url + '/bajas-actualizar-fecha/' + fecha);
    }

    // este ya no se usa
    automatic_update_bajas_inicio(codigo_empresa: string) {
        return this._http.get(this.url + '/bajas-actualizar-inicio');
    }

    // este ya no se usa
    automatic_update_bajas_fin(codigo_empresa: string) {
        return this._http.get(this.url + '/bajas-actualizar-fin');
    }

    updateBajas(Baja, inicio, fin, code_upd){
        let json = JSON.stringify(Baja);
        let params = 'json='+json;
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        if(fin == ''){
            fin = '9999-12-31';
        }
        return this._http.post(this.url + '/bajas-update/' + inicio + '/' + fin + '/' + code_upd,params, {headers : headers} );
    }

    deleteBajas(id: number, code: string, inicio: string , fin:string ){
        return this._http.get(this.url + '/bajas-delete/' + id + '/' + code + '/' + inicio + '/' + fin);
    }
}
