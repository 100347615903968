export class UsuarioLogin {

    //public id: number;
    public correo: string;
    public password: string;
    public codigo_empresa: string;
    public nombre_empresa: string;
    public getToken: string;        // no se refiere al token local guardado, es para pedir o no el token. en empresa.service ( loginEmpresa )

    constructor( ) {}

    getId(){
        //return this.id;
    }

    setId(id: number){
        //this.id = id;
    }

    getCorreo(){
        return this.correo;
    }

    setCorreo(correo: string){
        this.correo = correo;
    }

    getPassword(){
        return this.password;
    }

    setPassword(password: string){
        this.password = password;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getNombre_empresa(){
        return this.nombre_empresa;
    }

    setNombre_empresa(nombre_empresa: string){
        this.nombre_empresa = nombre_empresa;
    }

    getGetToken(){
        return this.getToken;
    }

    setGetToken(getToken: string){
        this.getToken = getToken;
    }

}