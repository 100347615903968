import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Style } from 'src/app/models/style';
import { StyleService } from '../../services/style.service';
import { EmpleadoService } from '../../services/empleado.service';
import { GLOBAL } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { AppComponent } from '../../app.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector : 'configuracion',
    templateUrl : './configuracion.component.html',
    styleUrls : ['./configuracion.component.css', '../../../style.scss'],
    providers: [ EmpleadoService ]
})

export class ConfiguracionComponent {
    public identity: any;
    public titulo: string;
    public mostrar_colores: boolean;
    public new_style: Style;
    public fecha: Date;
    public color1: string; // General
    public color2: string; // Fondo menu
    public color3: string; // Pie
    public color4: string; // Cabecera
    public filesToUpload;
    public global_url_logos;
    public file_name: string;
    public codigo_empresa: string;
    public option_select:any;
    // public global_fichero: string;
    // public possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
    public possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    public lengthOfCode = 5;
    public clock_tick: any = this.makeRandom(this.lengthOfCode, this.possible);
    public logo: string;

    constructor(
        private _appComponent:AppComponent,
        protected _authService: AuthService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _styleService: StyleService,
        private _empleadoService: EmpleadoService,
        protected toastr: ToastrService
    ){
        this.titulo = "Configuración";
        this.mostrar_colores = false;
        this.new_style = new Style(0, null, null, null, null, null);
        this.fecha = new Date();
        this.color1 = this.new_style.general;
        this.color2 = this.new_style.fondo;
        this.color3 = this.new_style.pie;
        this.color4 = this.new_style.cabecera;
        // this.global_fichero = null;
        this.global_url_logos = null;
        this.option_select = 0;
    }

    ngOnInit(){
        this._route.params.forEach((params:Params) =>{
            this.codigo_empresa = params['codigo_empresa'];
        });

        // this.global_fichero = GLOBAL.url_ficheros;
        this.global_url_logos = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_logos;

        this.getStyle();
        // this.clock_tick = Date.now();
        this.clock_tick = this.makeRandom(this.lengthOfCode, this.possible);
        this.identity = this._authService.getStorageIdentity();
    }

    //Metodo que consigue el esttylo de la empresa
    getStyle(){
        this._styleService.getStyle(this.codigo_empresa).subscribe(
          response => {
              if (response['code'] == 200){

                  console.log("Estilo encontrado");

                  this.new_style = response['data'];
                  console.log('PRUEBAcolores '+ this.new_style.general);
                  // this.logo = this.global_fichero + '/logos/' + this.new_style.icono + '?d=' + this.makeRandom(this.lengthOfCode, this.possible);
                  this.logo = this.global_url_logos + '/' + this.new_style.icono + '?d=' + this.makeRandom(this.lengthOfCode, this.possible);

              } else {
                  console.log("El estilo no se ha encontrado");
              }
          }, error => {
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }

        );

    }

    //Metodo que prueba los colores que se van a actualizar
    probarColor(color: string, parte: string){
        if ( color == undefined ) {
            this.color1 = this.new_style.general;
            this.color2 = this.new_style.fondo;
            this.color3 = this.new_style.pie;
            this.color4 = this.new_style.cabecera;
        }
        if ( parte == "general" ) {
            this.new_style.general = this.color1;
            //this.updateColor(parte);
        }
        if ( parte == "fondo" ) {
            this.new_style.fondo = this.color2;
            //this.updateColor(parte);
        }
        if ( parte == "pie" ) {
            this.new_style.pie = this.color3;
            //this.updateColor(parte);
        }
        if ( parte == "cabecera" ) {
            this.new_style.cabecera = this.color4;
            //this.updateColor(parte);
        }
    }

    //Metodo que guarda los colores seleccionados
    guardarConfiguracion(color: string, parte: string){
        if ( color == undefined ) {
            this.color1 = this.new_style.general;
            this.color2 = this.new_style.fondo;
            this.color3 = this.new_style.pie;
            this.color4 = this.new_style.cabecera;
        }
        if ( parte == "general" ) {
            this.new_style.general = this.color1;
            //this.updateColor(parte);
        }
        if ( parte == "fondo" ) {
            this.new_style.fondo = this.color2;
            //this.updateColor(parte);
        }
        if ( parte == "pie" ) {
            this.new_style.pie = this.color3;
            //this.updateColor(parte);
        }
        if ( parte == "cabecera" ) {
            this.new_style.cabecera = this.color4;
            //this.updateColor(parte);
        }
    }


    //Metodo que estable los colores que se van a actualizar
    estableceColor2(color: string, parte: string) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        if ( color == undefined ) {
            this.color1 = this.new_style.general;
            this.color2 = this.new_style.fondo;
            this.color3 = this.new_style.pie;
            this.color4 = this.new_style.cabecera;
        }

        if ( parte == "general" ) {
            this.new_style.general = this.color1;
            this.updateColor(parte);
        }
        if ( parte == "fondo" ) {
            this.new_style.fondo = this.color2;
            this.updateColor(parte);
        }
        if ( parte == "pie" ) {
            this.new_style.pie = this.color3;
            this.updateColor(parte);
        }
        if ( parte == "cabecera" ) {
            this.new_style.cabecera = this.color4;
            this.updateColor(parte);
        }
    }

    //Metodo que actualiza los campos cabecera y pie
    updateColor(parte: string){

        this._styleService.updateStyle(this.new_style, parte).subscribe(
            response => {
                if (response['code'] == 200){
                    this.getStyle();
                    this._appComponent.getStyle(this.codigo_empresa);
                    console.log(parte," actulizada");

                } else {
                    console.log(parte," no se ha podido actulizar");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    public onChangeColor1(color: string) {
            this.new_style.general = this.color1;
    }

    public onChangeColor2(color: string) {
            this.new_style.fondo = this.color2;
    }

    public onChangeColor3(color: string) {
            this.new_style.pie = this.color3;
    }

    public onChangeColor4(color: string) {
            this.new_style.cabecera = this.color4;
    }

    //Metodo que ctualiza el logo de la empresa
    fileChangeEvent(fileInput: any) {

        if (GLOBAL.empresa_demo) { this.toastr.warning('Opción deshabilitada.', 'VERSIÓN DEMO'); return; }

        this.filesToUpload = <Array<File>>fileInput.target.files;
        this.file_name = this.filesToUpload[0].name;

        // this._empleadoService.makeFileRequest(GLOBAL.url+'/upload-file-logo/'+this.codigo_empresa, [], this.filesToUpload).then((result) => {
        this._empleadoService.makeFileRequest(GLOBAL.url+'/upload-file-logo/'+this.codigo_empresa + '/' + GLOBAL.url_logos + '/' + this.file_name, [], this.filesToUpload).then((result) => {
          this._appComponent.getStyle(this.codigo_empresa);
          console.log(result);
        }, (error) =>{
            console.log(error);
        });

        // this.new_style.icono = this.codigo_empresa + ".png";
        this.new_style.icono = this.file_name;

        this._styleService.updateIcono(this.new_style).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Logo actualizado");
                    // this.clock_tick = Date.now();
                    this.clock_tick = this.makeRandom(this.lengthOfCode, this.possible);
                    // no refresca el logo siempre, lo hace a veces:
                    // this.logo = this.global_fichero + '/logos/' + this.new_style.icono + '?d=' + this.makeRandom(this.lengthOfCode, this.possible);
                    this.logo = this.global_url_logos + '/' + this.new_style.icono + '?d=' + this.makeRandom(this.lengthOfCode, this.possible);
                } else {
                    console.log("El logo no se ha podido actulizar");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }


    // let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
    // const lengthOfCode = 40;
    // makeRandom(lengthOfCode, possible);
    makeRandom(lengthOfCode: number, possible: string) {
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text + Date.now();
      }


}
