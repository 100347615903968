import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vacacionesFilter'
})
export class VacacionesFilterPipe implements PipeTransform {

  transform(items: any, filterNombre: string, filterCode: string, filterDepartamento: string, filterFecha: Date, filterFechaHasta: Date, tipofecha: number): any {
    if (items && items.length){
      return items.filter( item => {

          /*
          if ( filterNombre )  {
            if(item.nombre.search(filterNombre) == -1 && item.apellidos.search(filterNombre) == -1){
              return false;
            }
          }
          */
          let nombre_apellidos = item.nombre + ' ' + item.apellidos;
          nombre_apellidos = this.filtrar_acentos(nombre_apellidos);
          filterNombre = this.filtrar_acentos(filterNombre);
          if ( (filterNombre && nombre_apellidos.toLowerCase().indexOf(filterNombre.toLowerCase()) === -1) )
          {
              return false;
          }

          if( filterCode){
            if(item.code != filterCode){
              return false;
            }
          }

          if( filterDepartamento){
            if(item.puesto != filterDepartamento){
              return false;
            }
          }

          if (tipofecha == 1) { // vacaciones
            // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
            if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
              return true;
            } else
            if ( !((filterFecha >= item.inicio_vacaciones && filterFecha <= item.fin_vacaciones) ||
                 (filterFechaHasta >= item.inicio_vacaciones && filterFechaHasta <= item.fin_vacaciones) ||
                 (filterFecha < item.inicio_vacaciones && filterFechaHasta > item.fin_vacaciones))) {
                return false;
            }
          }

          if (tipofecha == 2) { // bajas
            // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
            if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
              return true;
            } else
            if ( !((filterFecha >= item.inicio_baja && filterFecha <= item.fin_baja) ||
                 (filterFechaHasta >= item.inicio_baja && filterFechaHasta <= item.fin_baja) ||
                 (filterFecha < item.inicio_baja && filterFechaHasta > item.fin_baja))) {
                return false;
            }
          }

          if (tipofecha == 3) { // ausencias
            // if (filterFecha && item.inicio_vacaciones.indexOf(filterFecha) === -1){
            if (String(filterFecha) === '' && String(filterFechaHasta) === '') {
              return true;
            } else
            if ( !((filterFecha >= item.inicio_ausencias && filterFecha <= item.fin_ausencias) ||
                 (filterFechaHasta >= item.inicio_ausencias && filterFechaHasta <= item.fin_ausencias) ||
                 (filterFecha < item.inicio_ausencias && filterFechaHasta > item.fin_ausencias))) {
                return false;
            }
          }

          return true;
      });

    } else {

      return items;

    }
  }

  filtrar_acentos(input) {

    // no sólo acentos; ñ -> n
    // var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
    // var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
    // no sólo acentos; Quito: ñ -> n
    var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç";
    var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc";

    for (var i = 0; i < acentos.length; i++) {
        input = input.replace(acentos.charAt(i), original.charAt(i)).toLowerCase();
    }
    return input;
  }

}
