
import { Component, DoCheck, OnInit, OnDestroy, ViewChild, Input, ElementRef } from '@angular/core';
import { StyleService } from '../app/services/style.service';
import { Style } from '../app/models/style';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UsuarioService } from './services/usuario.service';
import { HorarioService } from './services/horario.service';
import { EmpleadoService } from './services/empleado.service';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioLogin } from 'src/app/models/usuarioLogin';
import { Horario } from './models/horario';
import { EmpleadosMenos } from './models/empleados_menos';
import { EmpresaService } from './services/empresa.service';
import { CalculoIncidenciasService } from './services/calculo_incidencias.service';
import { CalculoIncidencias } from './models/calculo_incidencias';
import { BajaService } from './services/baja.service';
import { VacacionesService } from './services/vacaciones.service';
import { AusenciasService } from './services/ausencias.service';
import { Bajas } from './models/bajas';
import { GLOBAL } from './services/global.service';
import { ConfigService } from './services/config.service';
import { environment } from '../environments/environment';
import { Empleado } from 'src/app/models/empleado';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validators';
// mensajes
import { Subscription, merge, fromEvent } from 'rxjs';
import { MensajesService } from './services/mensajes.service';  // Nuestro proveedor de mensajes
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef, NgbModalConfig, } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

// Base de datos interna
import { NgxIndexedDB } from 'ngx-indexed-db';
import { config } from '@fullcalendar/core';

//Idle-services
import { UserIdleService } from 'angular-user-idle';
import { SolicitudVacacionesService } from './services/solicitud-vacaciones.service';
import { InformeConfirmacionComponent } from './componentes/informe-confirmacion/informe-confirmacion.component';


var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../style.scss'],
  providers: [MensajesService, StyleService, UsuarioService, HorarioService, EmpleadoService, EmpresaService, CalculoIncidenciasService, BajaService, VacacionesService, AusenciasService]
})


export class AppComponent implements DoCheck, OnInit, OnDestroy {

  @ViewChild('codigo12') private contenido;
  @ViewChild('informeConfirmar') private informeConfirmar : InformeConfirmacionComponent;
  // Variables a usar
  //public informe_conf: InformeConfirmacionComponent;


  public content: any;
  public empleado: Empleado;
  public primaryColor: string;
  public secondaryColor: string;
  public pieColor: string;
  public title = 'Cabecera';
  public fecha = new Date();
  public style: Style;
  public opcion: number;
  public opcionAdmin: boolean;
  public esAdmin: boolean;
  public esAdminSYSTEM: boolean;  // lo usamos por ahora en 

  public superadmin: boolean; // por ahora no lo usamos aqui. Si en empleado-detail
  public superadminSYSTEM: boolean; // por ahora no lo usamos

  public codigo_admin: string;
  public codigo_empresa: string;
  public code: string;
  public color1: string; // color general
  public color2: string; // color fondo
  public color3: string; // color pie
  public color4: string; // Color de la cabecera (login)
  public nombre_empresa: string;
  public usuario: Usuario;
  public mostrarLogo: boolean;
  public fecha_renovacion;
  public emplActivos: any;
  public licencias;
  public activos;
  public licencias_superadas;

  // declaro como array de boolean en lugar de boolean, pero solo uso [0]. explicacion en la funcion
  public config_correccion_por_empleado: Array<boolean>;

  public usuarioLogin: UsuarioLogin;
  public status: string;
  public token;
  public identity;

  public adminValid: boolean;
  public estadoBoton: boolean;
  public parametro;
  public incidencias: boolean;
  public incidencias_visto: Array<Horario>;
  public trabajadores_menos_horas: Array<EmpleadosMenos>;
  public login: boolean;
  public fecha_actual: Date;
  public fecha_inicio_semana: Date;
  public fecha_fin_semana: Date;
  public fecha_incidencia: Date;
  public global_url_logos: string;
  public llenaSalida: number;
  public llenaEntrada: number;
  public calculo_incidencias: Array<CalculoIncidencias>;
  public bajas: Array<Bajas>;
  public logo: string;
  public oculto: boolean;
  public num_incidencias: number;         // total incidencias de empleados manuales
  public num_incidencias_olvido_fichar;   // total de incidencias de olvido fichar ( total agrupados por fecha y empleado)
  public total_incidencias: number;
  public numeroPendientes: number;
  public solicitudesVacacionesPendientes: any;
  public innerWidth: any;
  // Variable Base de datos interna
  public bd1: NgxIndexedDB;
  public bd2: NgxIndexedDB;
  public myForm: FormGroup;
  public myRecuperar: FormGroup;
  public myCodigoR: FormGroup;
  public formRecuperar: FormGroup;
  private suscripcionMensajes: Subscription;
  public modal: NgbModalRef;
  public modal2: NgbModalRef;
  public ocultar_en_produccion: boolean;
  public today = new Date();
  public diffInDays: number;
  public fecha_actual_renovacion: any;
  public empresa: any;
  public tipo_cuenta: string;
  public packs: number;
  public handler: any = null;
  public tipo_pago: number;
  public pack_usuarios: number;
  public total: number;
  public ping;
  public opcionRecuperar: boolean;
  public correoR: string;
  public usuarioR: string;
  public captcha: any[];
  public fecha_renovacion_fin: any;
  public version;
  public version_last;
  public dif_per_gra: any;
  public crearPdf_desdeHijo:any;
  public opcionConfirmacion: boolean = true;
  constructor(
    protected _mensajesService: MensajesService,
    protected _configService: ConfigService,
    protected _authService: AuthService,
    protected _styleService: StyleService,
    protected _usuarioService: UsuarioService,
    protected _horarioService: HorarioService,
    protected _empleadoService: EmpleadoService,
    protected _empresaService: EmpresaService,
    protected _calculo_incidenciasService: CalculoIncidenciasService,
    protected _bajaService: BajaService,
    protected _vacacionesService: VacacionesService,
    protected _ausenciasService: AusenciasService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected fb: FormBuilder,
    protected toastr: ToastrService,
    protected modalService: NgbModal,
    config: NgbModalConfig,
    protected _vacacionesSolicitudService: SolicitudVacacionesService,
    private userIdle: UserIdleService
  ) {
    this.opcionConfirmacion = true;
    this.empleado = new Empleado(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, false, null, null, null, null, false, false, false, false, false, false, false, null, true, false, false, false, null, false, true, true, null, null, null);
    this.style = new Style(0, null, null, null, null, null);
    this.opcion = 0;
    this.opcionAdmin = false;
    this.esAdmin = false;
    this.esAdminSYSTEM = false;
    this.adminValid = true;
    this.parametro = 1;
    this.mostrarLogo = false;
    this.fecha_renovacion = null;
    this.emplActivos = [];
    // this.codigo_empresa = null;
    // this.nombre_empresa = null;
    this.incidencias = false;
    this.login = false;
    this.bd1 = new NgxIndexedDB('BDInternaEntrada', 1);
    this.bd2 = new NgxIndexedDB('BDInternaSalida', 1);
    this.llenaSalida = 0;
    this.llenaEntrada = 0;
    this.global_url_logos = '';
    this.bajas = [];

    this.ocultar_en_produccion = true;

    this.num_incidencias = 0;
    this.num_incidencias_olvido_fichar = 0;
    this.total_incidencias = 0;
    this.config_correccion_por_empleado = [];
    this.config_correccion_por_empleado[0] = false;
    // Ventana modal click fuera bloqueado
    config.backdrop = 'static';
    config.keyboard = false;
    this.estadoBoton = true;
    this.empresa = '';
    //this.oculto = false;
    //Validamos cambiar la contraseña
    this.myForm = this.fb.group({
      newpassword: ['', [Validators.required, Validators.minLength(4)]],
      repeatpassword: ['', [Validators.required]],
    },
      {
        validator: MustMatch('newpassword', 'repeatpassword')
      });
    this.myRecuperar = this.fb.group({
      correo: ['', [Validators.required, Validators.minLength(4)]],
      usuario: ['', [Validators.required]],
    });

    this.myCodigoR = this.fb.group({
      newpassword: ['', [Validators.required, Validators.minLength(4)]],
      repeatpassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newpassword', 'repeatpassword')
      });
    this.formRecuperar = this.fb.group({
      correoR: ['', [Validators.required, Validators.minLength(4)]],
      codigoR: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.userIdle.setCustomActivityEvents(merge(
      fromEvent(window, 'mousemove'),
      fromEvent(document, 'keydown')
    ));

    this.opcionRecuperar = false;
    this.correoR = '';
    this.usuarioR = '';
  }

  openLg(content) {
    this.modalService.open(content, { size: 'lg', centered: true })
  }

  LookColors(primary: string, secondary: string, pie: string, cabecera: string) {
    primary = this.color1;    // general
    secondary = this.color2;  // fondo
    pie = this.color3;
    cabecera = this.color4;
    this.changeTheme(primary, secondary, pie, cabecera);
  }

  Refresh() {
    console.log('location.reload()');
    location.reload();
  }

  get f() {
    return this.myForm.controls;
  }

  @ViewChild("confirmarInformeBT") bt_informeConfirmar: ElementRef;;
  async llamarFuncionHijo() {
    //await this.informeConfirmar.crearPDF('Informe Semanal', false);
    document.getElementById("botDD").click()
  }

  ngOnInit() {
    //this.openLg('')
    this.innerWidth = window.innerWidth;
    let URLdomain = window.location.host;
    GLOBAL.host = URLdomain;
    console.log('Dominio: ' + GLOBAL.host);

    // desactivo logs en modo produccion
    if (environment.production) {
      // enableProdMode(); // lo hago ya en main.ts

      // if ( GLOBAL.host !== 'control.bsw.es' ) { // descomentar para depurar fallos en preproduccion que funcionen en local

      let disFunc = () => 'console has been disabled in production mode';
      console.log = disFunc;
      console.error = disFunc;
      console.warn = disFunc;
      Object.freeze(console);

      // }

      /*
      if ( GLOBAL.host == 'control.bsw.es' ) {
        GLOBAL.url = 'https://control.bsw.es/api/index.php';
        GLOBAL.url_uploads = 'https://control.bsw.es/api/uploads';
      } else if ( GLOBAL.host == 'control.bejornada.es' ) {
        GLOBAL.url = 'https://control.bejornada.es/api/index.php';
        GLOBAL.url_uploads = 'https://control.bejornada.es/api/uploads';
      }
      */

    }



    this.version = GLOBAL.version;
    console.log('this.version: ');
    console.log(this.version);
    this.version_last = ''; // para intentar detectar si por caché no se tiene cargada la última versión
    this.getConfigParam(null, 'GENERAL PARA TODAS EMPRESAS', 'VERSION', '0', GLOBAL.config_version);



    // para ocultar cosas q no quiero mostrar todavia en produccion
    // usar en la vista: *ngIf="!ocultar_en_produccion"
    // if ( GLOBAL.host == 'control.bejornada.es' ) {
    if (GLOBAL.host !== 'control.bsw.es' && GLOBAL.host.toLowerCase().indexOf('localhost') === -1) {
      // hay q tener en cuenta ya los hosting independientes de clientes (ojo, no contemplo servers de clientes en lan propia: localhost)
      this.ocultar_en_produccion = true;
    } else {
      this.ocultar_en_produccion = false;
    }

    if (innerWidth <= 766) {
      this.oculto = false;
    } else {
      this.oculto = true;
    }
    // nos suscribimos al sistema de mensajes
    this.escuchaMensajes(); // se usa ahora mismo para actualizar num_incidencias en menu

    // esto se ejecuta siempre pero solo cierra la sesion cuando le llega el parametro "sure" por la url:
    // this._router.navigate(['/logout/1']);
    this.logout();

    this.fechaActual();
    // this.fechaIncidencias(); // se usa luego en calculo incidencias menos horas

    this.navegarOpcion(0);
    this.storage();
    //this.getEmpresa();


    // acciones programadas para ejecutarse cada cierto tiempo

    // Nota: se siguen ejecutando aunque se salga del componente que las crea.
    // si quiero pararlas:
    /*
    // le asigno un id al crearlas
    this.id = setInterval(() => {
        this.battleInit(); 
      }, 5000);
    }
    // en el destroy, si existe el id, lo elimino
    ngOnDestroy() {
      if (this.id) {
        clearInterval(this.id);
      }
    }
    */

    // this.abrirBDSalida(); // la creamos aunque actualmente no se usa, a la espera de revisar su funcionamiento
    // this.abrirBDEntrada(); // la creamos aunque actualmente no se usa, a la espera de revisar su funcionamiento
    // this.interval_checkBDES();

    /*
    this._notificationService.notifications.subscribe( notification => {
      if (notification.status === NotificationStatus.Unauthorized) {
          // Handle any unauthorized errors here like and call logout() method
          this.cerrarSesion();
      }
    });
    */

    // this.loadStripe();

  }


  ngOnDestroy() {
    console.log('app.component destroy ...');
    if (this.suscripcionMensajes != null) {
      // this.suscripcionMensajes.unsubscribe();
    }
  }

  solicitudesPendientes() {
    let identityUser = JSON.parse(localStorage.getItem('identity'))
    let persona = identityUser['code'];
    let user_info: Object = { codigo_empresa: identityUser["codigo_empresa"] }
    this._vacacionesSolicitudService.solicitudes_pendientes(user_info, persona).subscribe(
      response => {
        this.solicitudesVacacionesPendientes = response["result"]["solicitudes"];
        this.numeroPendientes = response["result"]["num_rows"]
      }, error => {
        this.toastr.error("Error: No se ha podido recuperar las solicitudes pendientes ");
      }
    );

  }


  ngDoCheck() {
    // este metodo sirve para refrescar la pantalla cuando algo cambia
    // por ejemplo, si estuviera mostrando el nombre del usuario y lo edito
    // en nuestro caso, para al cambiar los colores en la confi, se actualizen sin salir de la sesion

    /* como ahora mismo no es importante esto, podemos comentarlo: */
    /*
    if ( this.identity != null && this._authService.getStorageIdentity() != null) {
      this.identity = this._authService.getStorageIdentity();
    }
    if (this.identity != null && this.identity.codigo_empresa != null ) {
      this.getStyle(this.identity.codigo_empresa);
      //this.codigo_empresa = this.identity.codigo_empresa;
    }
    */
  }

  changeTheme(primary: string, secondary: string, pie: string, cabecera: string) {
    document.documentElement.style.setProperty('--primary-color', primary);     // color general
    document.documentElement.style.setProperty('--secondary-color', secondary); // color fondo
    document.documentElement.style.setProperty('--pie-color', pie);
    document.documentElement.style.setProperty('--cabecera-color', cabecera);
  }

  /*getStyle(){
    this._styleService.getStyle(this.codigo_empresa).subscribe(
      response => {
        if (response['code'] == 200){
          console.log("Estilo encontrado");
          this.style = response['data'];
          //this.logo = this.global_fichero + '/logos/' + this.new_style.icono + '?d=' + this.makeRandom(this.lengthOfCode, this.possible);   
            } else {
                console.log("El estilo no se ha encontrado");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
      );
  }*/


  private escuchaMensajes(): void {
    this.suscripcionMensajes = this._mensajesService.escucha().subscribe(
      msj => {
        console.log('app.component ha recibido el mensaje: ');
        console.log(msj);

        if (msj.categoria === 'Authorization') { // no llegan al estar fuera de la estructura principal
          switch (msj.contenido) {
            case 'Unauthorized':
              // el interceptor, cuando la cabecera llega con code=401 ( token invalido ), emite este mensaje
              // this._router.navigate(['/']);
              break;
          }
        }
        if (msj.categoria === 'incidenciasMenu') {
          this.total_incidencias = +msj.contenido; // actualizo num_incidencias en menu
        }
        if (msj.categoria === "opcionSolicitudes"){
          GLOBAL.opcion_hacer_solicitudes =  msj.contenido ==="true"?true:false;
          this.empresa.opcion_hacer_solicitudes = GLOBAL.opcion_hacer_solicitudes ;
        }
        if (msj.categoria === "solicitudesPendientes"){
          this.numeroPendientes = parseInt(msj.contenido);
        }
      }
    );
  }

  // Controlamos los días que quedan hasta el final de la subscripción, le sumamos 2 días por que empieza en 0 y queremos contar el día actual
  CompareDates() {
    this.fecha_actual_renovacion = this.today;
    this.fecha_renovacion = moment(this.fecha_renovacion);
    this.fecha_renovacion_fin = moment(this.fecha_renovacion).add(30, 'days');
    this.fecha_actual_renovacion = moment(this.fecha_actual_renovacion);

    /*
    if(this.fecha_renovacion.diff(this.fecha_actual_renovacion.format('YYYY-M-DD')) === 0){
      this.diffInDays = 0;
    }else{
      this.diffInDays = Math.abs(this.fecha_renovacion.diff(this.fecha_actual_renovacion, 'days') + 1);
    }
    this.dif_per_gra = Math.abs(this.fecha_actual_renovacion.diff(this.fecha_renovacion_fin, 'days') + 1);
    */

    console.log('this.fecha_renovacion:');
    console.log(this.fecha_renovacion);
    console.log('this.fecha_actual_renovacion:');
    console.log(this.fecha_actual_renovacion);

    if (this.fecha_renovacion.diff(this.fecha_actual_renovacion.format('YYYY-M-DD')) === 0) {
      // if(this.fecha_renovacion.diff(this.fecha_actual_renovacion) === 0){
      console.log('PERIODO NORMAL - ACABA HOY');
      this.diffInDays = 0;
      // } else if ( this.fecha_actual_renovacion.format('YYYY-M-DD') < this.fecha_renovacion ) {
    } else if (this.fecha_actual_renovacion < this.fecha_renovacion) {
      console.log('PERIODO NORMAL');
      this.diffInDays = Math.abs(this.fecha_renovacion.diff(this.fecha_actual_renovacion, 'days')) + 1;
    } else {
      console.log('PERIODO DE GRACIA');
      this.diffInDays = -1 * Math.abs(this.fecha_renovacion.diff(this.fecha_actual_renovacion, 'days'));
    }

    this.dif_per_gra = Math.abs(this.fecha_actual_renovacion.diff(this.fecha_renovacion_fin, 'days'));

    console.log('dif_per_gra: ');
    console.log(this.dif_per_gra);
    console.log('diffInDays: ');
    console.log(this.diffInDays);
  }

  cerrarSesion() {
    /* metodo alternativo a this._router.navigate(['/logout/1']); por la forma en q esta hecho el componente */
    console.log('cerrarSesion ...');
    this.storage(); // cargar stilos antes de cerrar por si han cambiado 
    this.inicializar();
    console.log('navigate to / ...');
    this._router.navigate(['/']);
    localStorage.clear()
  }

  inicializar() {
    console.log('inicializar ...');
    localStorage.removeItem('token');
    localStorage.removeItem('identity');
    localStorage.clear();
    this.identity = null;
    this.token = null;
    this.login = false;
    this.opcionAdmin = false;
    this.opcion = 0;
    this.codigo_admin = null;
    this.userIdle.resetTimer();
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.ping.unsubscribe();
  }

  logout() {
    // para evitar crear otra pagina logout, llamo a esta pagina con parametro 1: this._router.navigate(['/logout/1']);
    // al cargar la pagina y entrar a esta funcion desde ngInit(), cierra la sesion al llegarle el parametro "sure" a 1
    // en local funciona pero en produccion no va en este proyecto

    // console.log('logout¿/1? ...');
    this._route.params.subscribe(params => {
      // console.log('check logout param: ');
      let logout = + params['sure'];
      // console.log(logout);
      if (logout === 1) {
        this.cerrarSesion();
      }
    });

  }

  correcto(numero) {
    this._router.navigate(['/']);
    this.opcion = numero;
    this.opcionAdmin = false;
    this.codigo_admin = null;
  }

  navegarOpcion(numero) {
    console.log('navegarOpcion: ' + numero);
    console.log('login: ' + this.login);

    if (numero === 4) { // prueba para poner boton volver en menu registro. TODO: no va, muestra login en lugar del menu inicial
      this.login = true;
      numero = 0;
    }

    if (numero === 3 || numero === 2) { // no permitir fichar ni acceder a registros el usuario normal en periodo de gracia de la licencia
      if (GLOBAL.periodo_gracia) {
        this.toastr.warning('Opción deshabilitada.', 'LICENCIA EXPIRADA');
        return;
      }
    }

    this.opcion = numero;
    this.opcionAdmin = false;
    this.codigo_admin = null; // ya no usamos esto, era para login para el menu admin
    // this.storage(); // actualizar stilo por si se ha cambiado

    if (numero === 1) {
      // nos saltamos la pantalla de login antigua para acceder al menu de admin
      this.opcionAdmin = true; // mostarr / ocultar menu admin
      this.adminValid = true; // mostrar / quitar mensaje de error de acceso en rojo
      // this.codigo_empresa = this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null;
      // console.log('Cargando ' + '/empleados/' + this.codigo_empresa );
      // this._router.navigate(['/empleados/' + this.codigo_empresa ]);
      this._router.navigate(['/avisos/' + this.codigo_empresa]);
      this.codigo_admin = null;

      // Comprueba si hay incidencias
      this.total_incidencias = 0;
      this.getIncidencias_visto();
      this.calculoIncidenciasOlvido();

    }

  }

  // LOGIN DE LA APP ANTIGUO POR EMPRESA
  // Lee la BDLocal para traer el logo de la empresa
  // // YA NO SE USA
  // accesoAppOLD(correo, password) {

  //   this.usuarioLogin = new UsuarioLogin();
  //   this.usuarioLogin.setCorreo(correo);
  //   this.usuarioLogin.setPassword(password);

  //   // TODO: cuando loguemos por usuario, no por empresa, asignar el valor segun sea admin o no
  //   // ahora mismo, mostramos siempre el boton de acceso al menu admin ya que todavia no sabemos si es admin o no el usuario:
  //   GLOBAL.esAdmin = true; // acceso global para saber despues en cualquier momento si es admin
  //   this.esAdmin = true;

  //   // TODO: cambiar login empresa a login usuario:

  //   // this._empresaService.loginEmpresa(correo, password).subscribe(
  //   this._empresaService.loginEmpresa(this.usuarioLogin).subscribe(
  //     response => {
  //       // TOKEN
  //       // console.log(response);

  //       if (response.status === 'success' && response.busqueda === true) {
  //         this.login = true;
  //         this.status = 'success';
  //         this.token = response.token;
  //         this._empresaService.loginEmpresa(this.usuarioLogin, true).subscribe( // paso parametro a true
  //           response2 => {
  //             // OBJETO USUARIO IDENTIFICADO ( TOKEN DECODIFICADO )
  //             // console.log(response2);

  //             this.identity = response2.token.data;
  //             // console.log(this.token);
  //             // console.log(this.identity);

  //             // if (this.token != null && this.identity != null) {
  //             localStorage.setItem('identity', JSON.stringify(this.identity));
  //             localStorage.setItem('token', this.token);
  //             // no guardo la password en localstorage , antes si y sin codificar....
  //             // this.getStyle(localStorage.getItem('codigo_empresa'));
  //             this.identity = this._authService.getStorageIdentity();
  //             this.getStyle(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null);
  //             this.codigo_empresa = this.identity.codigo_empresa;
  //             GLOBAL.codigo_empresa = this.identity.codigo_empresa;

  //             this.global_url_logos = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_logos;
  //             this.mostrarLogo = true;
  //             console.log('id Empresa: ' + GLOBAL.codigo_empresa);
  //             // }

  //           }, error => {
  //             // this.status = 'error';
  //             this.mostarMsgErrAccesoEmpresa();
  //             console.log(Error as any);
  //           }
  //         );

  //       } else {
  //         // this.status = 'error';
  //         this.mostarMsgErrAccesoEmpresa();
  //         console.log(response.message);
  //       }

  //     }, error => {
  //       // this.status = 'error';
  //       this.mostarMsgErrAccesoEmpresa();
  //       console.log(Error as any);
  //     }
  //   );

  // }

  setearVariablesApp(correo, password) {
    return new Promise<any>((resolve, reject) => {
      this.codigo_empresa = this.identity.codigo_empresa;
      this.nombre_empresa = this.identity.nombre_empresa;
      this.code = this.identity.code;

      GLOBAL.codigo_empresa = this.identity.codigo_empresa;
      GLOBAL.nombre_empresa = this.identity.nombre_empresa;
      GLOBAL.code = this.identity.code;
      GLOBAL.nombre = this.identity.nombre;
      GLOBAL.apellidos = this.identity.apellidos;
      GLOBAL.rol = this.identity.rol;
      GLOBAL.superadmin = this.identity.superadmin;
      this.superadmin = GLOBAL.superadmin;
      if (GLOBAL.codigo_empresa == '0000') {
        GLOBAL.superadminSYSTEM = GLOBAL.superadmin;
      } else {
        GLOBAL.superadminSYSTEM = false;
      }
      this.superadminSYSTEM = GLOBAL.superadminSYSTEM;
      if (GLOBAL.rol == 'admin') {
        this.opcionConfirmacion = false;
      }else{
        this.opcionConfirmacion = true;
      }
      this.solicitudesPendientes();
      // Iniciamos la escucha de userIdle para comprobar inactividad
      // Los cambios a userIdle se hacen en app.module.ts
      this.userIdle.startWatching();
      // Cuando pase el tiempo inicial, comenzamos con la cuenta atras 
      this.userIdle.onTimerStart().subscribe(count => {
        var eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel',
          'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
        for (let event of eventList) {
          document.body.addEventListener(event, () => this.userIdle.resetTimer());
        }
        //Añadimos aviso para que el usuario sepa que en 15 segundos se le desconectara
        if (count == 1) {
          this.toastr.clear();
          this.toastr.warning("Se ha detectado inactividad", "Desconexión por inactividad");
        }
      });
      

      // Lo siguiente hara que cada 120 segundos actualizemos el token
      this.ping = this.userIdle.ping$.subscribe(() => {
        this.usuarioLogin = new UsuarioLogin();
        this.usuarioLogin.setCorreo(correo);
        this.usuarioLogin.setPassword(password);
        this._usuarioService.loginUsuario(this.usuarioLogin).subscribe(
          response => {
            if (response.status === 'success' && response.busqueda === true) {
              this.login = true;
              this.status = 'success';
              this.token = response.token;
              localStorage.removeItem('token');
              localStorage.setItem('token', this.token);
            }
          });
      });

      if (GLOBAL.rol === 'admin') {
        GLOBAL.esAdmin = true;
        this.esAdmin = true;

        if (GLOBAL.codigo_empresa == '0000') {
          GLOBAL.esAdminSYSTEM = true;
          this.esAdminSYSTEM = true;
        }

        this._configService.getFechaRenovacion().subscribe(
          response => {
            // console.log(response);
            if (response['code'] === 200) {
              GLOBAL.fecha_renovacion = response['data'];
              console.log('Fecha renovación: ' + GLOBAL.fecha_renovacion);
              this.fecha_renovacion = GLOBAL.fecha_renovacion;
              this.CompareDates();
              this.getEmpresa();

            } else {
              // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
            }
          },
          error => {
            console.log(error as any);
          }
        );

      } else {
        GLOBAL.esAdmin = false;
        this.esAdmin = false;

        if (GLOBAL.codigo_empresa == '0000') {
          GLOBAL.esAdminSYSTEM = false;
          this.esAdminSYSTEM = false;
        }

      }
      resolve(null)


    })


  }
  inicioSession() {
    return new Promise<void>((resolve, reject) => {
      localStorage.clear()
      this._usuarioService.loginUsuario(this.usuarioLogin, true).subscribe( // paso parametro a true
        response => {
          // OBJETO USUARIO IDENTIFICADO ( TOKEN DECODIFICADO )
          // console.log(response2);
          console.clear()
          console.log(response);
          let dif_per_gra = null;
          if (response.status === 'success' && response.busqueda === true) {
            this.login = true;
            this.status = 'success';
            this.token = response.token;
            dif_per_gra = response['fecha_dif']
            this.identity = response.token_data.data;
            localStorage.setItem('identity', JSON.stringify(this.identity));
            localStorage.setItem('token', this.token);
            this.identity = this._authService.getStorageIdentity();
            this.getStyle(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null);

            resolve(dif_per_gra)
          }
          else {

            if (response.code == 600) { // suscripcion ha sobrepasado el periodo de gracia de 30 días desde la fecha de renovacion
              this.toastr.warning('Revise la fecha de subscripción', 'Acceso');
            } else if (response.code == 402) { // empresa no activa (puede ser temporalmente. campo 'activa' de la tabla 'empresas')
              this.toastr.warning(response.message, 'Acceso');
              console.log(response.message);
            } else {
              // this.status = 'error';
              this.mostarMsgErrAccesoEmpresa();
              console.log(response.message);
            }
            resolve(null)

          }
        }, error => {
          // this.status = 'error';
          this.mostarMsgErrAccesoEmpresa();
          console.log(Error as any);
          reject()
        }
      );
    });
  }
  // LOGIN DE LA APP NUEVO POR USUARIO
  // Lee la BDLocal para traer el logo de la empresa
  opcion_solicitudes = false
  async accesoApp(correo, password) {
    if (correo && password) {
      this.usuarioLogin = new UsuarioLogin();
      this.usuarioLogin.setCorreo(correo);
      this.usuarioLogin.setPassword(password);
      let dif_per_gra: any = await this.inicioSession()
      await this.setearVariablesApp(correo, password)
      await this.getEmpresa();
      await this.getEmpleadosActivos();
      // Lo siguiente saltara en el momento que haya pasado todo el tiempo estimado
      this.userIdle.onTimeout().subscribe(() => {
        this.toastr.clear();
        this.toastr.error("Se ha detectado inactividad", "Desconexión por inactividad");
        this.cerrarSesion();

      });
  
      if (GLOBAL.codigo_empresa === 'DEMO') {
        console.log('MODO DEMO');
        GLOBAL.empresa_demo = true;
        GLOBAL.periodo_gracia = false;
        this.toastr.warning('Está en la versión demo', 'VERSIÓN DEMO');
      } else if (dif_per_gra <= 0 || isNaN(dif_per_gra)) { // licencia expirada, en periodo de gracia
        console.log('SUBSCRIPCION: PERIODO DE GRACIA');
        GLOBAL.empresa_demo = true;
        GLOBAL.periodo_gracia = true;
        if (this.esAdmin) { document.getElementById('alertPeriodoGracia').click(); }
      } else {
        console.log('SUBSCRIPCION: MODO NORMAL');
        GLOBAL.empresa_demo = false;
        GLOBAL.periodo_gracia = false;
        if (this.diffInDays <= 30) { // queda poco para fin subscripción
          console.log('SUBSCRIPCION: FIN CERCANO');
          if (this.esAdmin) { document.getElementById('alertLicense').click(); }
        }
      }
  
      this.global_url_logos = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_logos;
      this.mostrarLogo = true;
  
      // recupero parámetros de configuracion de la BD
      this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'CORRECCION_POR_EMPLEADO', true, this.config_correccion_por_empleado);
      this.getConfigParamBoolean(this.codigo_empresa, 'GENERAL', 'CORRECCION_POR_EMPLEADO', true, GLOBAL.config_correccion_por_empleado);
      this.getConfigParam(this.codigo_empresa, 'GENERAL', 'CAMARA', '0', GLOBAL.config_empresa_enable_camera);
      this.getConfigParam(this.codigo_empresa, 'GENERAL', 'LOCALIZACION', '0', GLOBAL.config_empresa_enable_localizacion);
      this.getConfigParam(null, 'GENERAL PARA TODAS EMPRESAS', 'VERSION', '0', GLOBAL.config_version);
      // uso después:
      // GLOBAL.empresa_enable_camera[0] === '1' ? true : false;
      // GLOBAL.empresa_enable_localizacion[0] === '1' ? true : false;
      // GLOBAL.config_parametro_comun[0];
  
      // IMPORTANTE:
      // con la función GetBoolean, de haberla tenido antes, me hubiese ahorrado cosas raras que he hecho con booleanos
      // USO: GLOBAL.deBaja =  this.getBoolean(this.empleado.baja); // la uso ya en entrada/salida component
      // ANTES:
      // GLOBAL.deBaja =  this.empleado.baja ? true : false; // FALLA cuando es 0, tambien lo pasa a true ¿¿¿???
      if (GLOBAL.rol!=="admin") {      
        try {
          this.informeConfirmar.subirInformeSinConfirmarYRefresacar();
        } catch (error) {
          console.log("No se ha podido recuperar los confirmados de los empleados")
        }
      }
  
      this.getEmpleado();
      this.procesos_automaticos();
    }
    else{
      this.toastr.warning("Los campos deben estar rellenos")
    }
  }

  getPendientesChild(numero){
    this.bt_informeConfirmar.nativeElement.disabled = false;
    if (numero < 1 ) {
      this.bt_informeConfirmar.nativeElement.disabled = true
    }
  }

  getEmpleadosActivos() {
    return new Promise<any>((resolve, reject) => {

      this._empleadoService.getEmpleadosActivos().subscribe(result => {
        if (result['code'] == 200) {
          this.emplActivos = result['data'];
          this.licencias = +(this.emplActivos[0]['num_licencias']);
          console.log('LICENCIAS:');
          console.log(this.licencias);
          this.activos = this.emplActivos.length - 1; // -1: no contamos al administrador creado por defecto
          console.log('EMPLEADOS ACTIVOS:');
          console.log(this.emplActivos);
          console.log(this.activos);
          if (this.emplActivos && (this.activos > this.licencias)) {
            GLOBAL.licencias_superadas = true;
            this.licencias_superadas = true;
          } else {
            GLOBAL.licencias_superadas = false;
            this.licencias_superadas = false;
          }
          console.log('GLOBAL.licencias_superadas:');
          console.log(GLOBAL.licencias_superadas);
          if (GLOBAL.rol === 'admin' && GLOBAL.licencias_superadas) {
            this.toastr.warning('Aviso: tiene más empleados activos (' + this.activos + ') de los permitidos (' + this.licencias + ').', 'Subscripción');
          }
        }
        resolve(null)
      });
    })
  }

  getBoolean(value) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  procesos_automaticos() {

    // Nota: hasta que pasa el intervalo, no se ejecuta por primera vez
    // let myInterval1 = setInterval(() => { this.getIncidencias_visto(); }, 3600000);
    // let myInterval2 = setInterval(() => { this.calculoIncidenciasMenosHoras(); }, 3600000); // lo ignoro, no veo que lo haga bien
    // let myInterval3 = setInterval(() => { this.calculoIncidenciasOlvido(); }, 3600000);

    this.controlBajas();
    this.controlVacaciones();
    this.controlAusencias();
    let myInterval4 = setInterval(() => { this.controlBajas(); }, 3600000); // cada 1 Hora (ms)
    let myInterval5 = setInterval(() => { this.controlVacaciones(); }, 3600000); // cada 1 Hora (ms)
    let myInterval6 = setInterval(() => { this.controlAusencias(); }, 3600000); // cada 1 Hora (ms)

  }

  getConfigParam(codigo_empresa, seccion, clave, porDefecto, aValor) {
    // aValor: el hecho de usar un array en lugar de una variable directamente es porque los arrays se pasan por referencia y 
    // así directamente modificamos su valor aquí dentro y nos ahorramos problemas.
    // es raro, pero es lo que se me ha ocurrido !!!!

    this._configService.getConfigParam(codigo_empresa, seccion, clave).subscribe(
      response => {
        console.log(response);
        if (response['code'] === 200) {
          if (response['result'] === true) {
            aValor[0] = response['valor'];
            if (response['empresa'] = 'null' && response['seccion'] == 'GENERAL PARA TODAS EMPRESAS' && response['clave'] == 'VERSION') {
              this.version_last = aValor[0];
              console.log('this.version_last: ');
              console.log(this.version_last);
            }
            // console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha encontrado: ');
          } else {
            // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
            aValor[0] = porDefecto;
          }
        } else {
          // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
          aValor[0] = porDefecto;
        }
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ' + aValor[0]);
      },
      error => {
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado');
        console.log(error as any);
        aValor[0] = porDefecto;
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ');
        console.log(aValor[0]);
      }
    );

  }

  getConfigParamBoolean(codigo_empresa, seccion, clave, porDefecto, aValor) {
    // aValor: el hecho de usar un array en lugar de una variable directamente es porque los arrays se pasan por referencia y 
    // así directamente modificamos su valor aquí dentro y nos ahorramos problemas.
    // es raro, pero es lo que se me ha ocurrido !!!!

    this._configService.getConfigParam(codigo_empresa, seccion, clave).subscribe(
      response => {
        // console.log(response);
        if (response['code'] === 200) {
          if (response['result'] === true) {
            aValor[0] = this.getBoolean(response['valor']);
            // console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha encontrado: ');
          } else {
            // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
            aValor[0] = this.getBoolean(porDefecto);
          }
        } else {
          // console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado' );
          aValor[0] = this.getBoolean(porDefecto);
        }
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ' + aValor[0]);
      },
      error => {
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' no se ha encontrado');
        console.log(error as any);
        aValor[0] = this.getBoolean(porDefecto);
        console.log('El parametro config: ' + seccion + ' - ' + clave + ' se ha asignado: ');
        console.log(aValor[0]);
      }
    );

  }

  // Guardar en la localStorage la identidad y el token
  // OJO, ahora mismo no estoy guardando la contraseña por lo que no entrará directamente
  // localStorage.getItem('password') NO EXISTE
  storage() {
    console.log('Comprobando localStorage');
    if (this.storageAvailable('localStorage')) {
      console.log('Local Storage disponible');

      // si token no valido, borro localStorage
      // por como esta hecha la pagina, al combinar varias, hay casos en los q puede entrar en bucle, lo comento en este proyecto
      /*
      console.log('Validando token...');
      this._authService.checkToken().subscribe(
        response => {
            console.log('Respuesta token: ');
            console.log(response);
            if (response.status === 'success' && response.code === 200) {
                console.log('Token válido');
            } else if (response.status === 'error' && response.code === 401) {
              console.log('Token no válido');
              this.inicializar();
            } else {
              console.log('Token no válido');
              this.inicializar();
            }
        }, error => {
            console.log('Ha sucedido un error validando el token');
            this.inicializar();
            console.log(error as any);
        }

      );
      */

      console.log('Identity guardado en LocalStorage: ');
      console.log(localStorage.getItem('identity'));

      this.identity = JSON.parse(localStorage.getItem('identity'));
      // console.log('Identity cargado: ');
      // console.log(this.identity);

      if (this.identity != null && this.identity.codigo_empresa != null) {
        console.log('Cargando estilo de la Empresa guardada: ' + this.identity.codigo_empresa);
        this.getStyle(this.identity.codigo_empresa);
      } else {
        console.log('Empresa guardada no encontrada, cargando estilo de la empresa por defecto: 0000');
        this.getStyle('0000');
      }

      // if ( this.identity != null && this.identity.correo != null && localStorage.getItem('password') != null ) {  // cuando login por empresa
      if (this.identity != null && this.identity.code != null && localStorage.getItem('password') != null) { // login por usuario
        // ahora ya no entra por aqui, ya que no guardamos como antes localStorage.getItem('password')
        // this.accesoApp(localStorage.getItem('password'), this.identity.correo); // cuando login por empresa
        this.accesoApp(localStorage.getItem('password'), this.identity.code); // login por usuario
        this.login = true;
      } else {
        this.login = false;
      }
    } else {
      console.log('local Storage no disponible');
    }

  }

  // Detecta si los métodos de almacenamiento local están disponibles
  storageAvailable(type) {
    let storage;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  }

  storageAvailable_OLD(type) {
    try {
      var storage = window[type],
        x = '__storage_test__';
      return true;
    } catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  }

  getUsuario() { // ya no se usa, accedemos directamente al menu usuario si el usuario tiene rol 'admin'

    let code = this.codigo_admin;
    console.log('Verificar admin: ');
    console.log(code);
    console.log('empresa: ');
    console.log(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null);

    this._usuarioService.getUsuario(code, this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null).subscribe(
      response => {
        // console.log('response: ');
        // console.log(response);
        if (response['code'] === 200) {
          console.log('Usuario encontrado');

          this.usuario = response['data'];

          if (this.usuario.rol === 'admin') {
            this.opcionAdmin = true; // mostarr / ocultar menu admin
            this.adminValid = true; // mostrar / quitar mensaje de error de acceso en rojo
            this.codigo_empresa = this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null;
            console.log('Cargando ' + '/empleados/' + this.codigo_empresa);
            this._router.navigate(['/empleados/' + this.codigo_empresa]);
            this.codigo_admin = null;

            // Comprueba si hay incidencias
            // this.getIncidencias_visto();
          } else {
            this.mostarMsgErrAccesoUsuario();
          }

        } else {
          this.mostarMsgErrAccesoUsuario();
        }
      }, error => {
        console.log('Ha sucedido un error: ');
        console.log(error as any);
        if (error.error.code === 401) {
          // console.log('cerrando sesion en getUsuario ...');  // funciona pero quiero hacerlo en el interceptor para no repetir esto
          // this.cerrarSesion();
        }
        // alert('La conexión con el servidor ha fallado');
      }
    );

  }

  mostarMsgErrAccesoUsuario() {
    this.adminValid = false; // mostramos mensaje de error en rojo
    setTimeout(() => {
      // 2 segundos después...
      this.adminValid = true; // ocultamos mensaje de error en rojo
    }, 2000);
  }

  mostarMsgErrAccesoEmpresa() {
    this.status = 'error'; // mostramos mensaje de error en rojo
    setTimeout(() => {
      // 2 segundos después...
      this.status = ''; // ocultamos mensaje de error en rojo
    }, 2000);
  }

  getStyle(codigo_empresa) {

    this._styleService.getStyle(codigo_empresa).subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Estilo cargado de la empresa (' + codigo_empresa + '): ');
          this.style = response['data'];
          this.color1 = this.style.general;
          this.color2 = this.style.fondo;
          this.color3 = this.style.pie;
          this.color4 = this.style.cabecera;
          this.LookColors(this.color1, this.color2, this.color3, this.color4);
          console.log(this.style);
          GLOBAL.logo = this.style.icono;
          console.log('Logo empresa: ' + GLOBAL.logo);
        } else {
          console.log('El estilo de la empresa (' + codigo_empresa + ') no se ha cargado correctamente');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }

    );

  }

  // Metodo que comprueba quien está hoy de baja y actualiza los estados
  controlBajas() {

    console.log('controlBajas ...');

    this._bajaService.automatic_update_bajas(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
        // console.log(response);
        if (response['code'] === 200) {
          // console.log('Se ha realizado la actualizacion automática de bajas');
          console.log(response['message']);
        } else {
          console.log('Error realizando la actualizacion automática de bajas');
        }
      }, error => {
        console.log('Ha sucedido un error realizando la actualizacion automática de bajas');
        console.log(error as any);
      }

    );

  }

  // Metodo que comprueba el estado de las bajas y lo actualiza
  controlBajas_OLD() {

    console.log('controlBajas ...');
    // esto no seria correcto porque si hay un dia que nadie entra ( sabado, domingo, festivos,...) y ese dia alguien inicia/termina las vacaciones, no se actualizaria otro dia el estado 'baja' del empleado

    this._bajaService.automatic_update_bajas_inicio(this.codigo_empresa).subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Se ha realizado una actualizacion de bajas');
        } else {
          console.log('No se ha realizado ninguna actualizacion de bajas');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }

    );

    // this._bajaService.automatic_update_bajas_fin(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null).subscribe(
    this._bajaService.automatic_update_bajas_fin(this.codigo_empresa).subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Se ha realizado una actualizacion de bajas');
        } else {
          console.log('No se ha realizado ninguna actualizacion de bajas');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }

    );

  }

  controlVacaciones() {

    console.log('controlVacaciones ...');

    this._vacacionesService.automatic_update_vacaciones(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
        // console.log(response);
        if (response['code'] === 200) {
          // console.log('Se ha realizado la actualizacion automática de vacaciones');
          console.log(response['message']);
        } else {
          console.log('Error realizando la actualizacion automática de vacaciones');
        }
      }, error => {
        console.log('Ha sucedido un error realizando la actualizacion automática de vacaciones');
        console.log(error as any);
      }
    );

  }

  controlAusencias() {

    console.log('controlAusencias ...');

    this._ausenciasService.automatic_update_ausencias(this.codigo_empresa, String(this.fecha_actual)).subscribe(
      response => {
        // console.log(response);
        if (response['code'] === 200) {
          // console.log('Se ha realizado la actualizacion automática de ausencias');
          console.log(response['message']);
        } else {
          console.log('Error realizando la actualizacion automática de ausencias');
        }
      }, error => {
        console.log('Ha sucedido un error realizando la actualizacion automática de ausencias');
        console.log(error as any);
      }
    );

  }

  //Metodo que comprueba en la tabla 'calculo_incidencias' si hay alguna incidencia producida por un empleado que se le ha olvidado fichar
  calculoIncidenciasOlvido() {

    this._calculo_incidenciasService.getHorario_Olvido_Fichar(this.codigo_empresa).subscribe(
      response => {
        if (response['code'] === 200) {

          if (response['result'] == true) {

            this.num_incidencias_olvido_fichar = response['num_incidencias_olvido_fichar'];
            this.total_incidencias = this.num_incidencias + this.num_incidencias_olvido_fichar;
            this.incidencias = true;
            console.log("Encontradas incidencias de empleados que se le ha olvidado fichar: " + this.num_incidencias_olvido_fichar);

          } else {
            console.log("No se han encontrado incidencias de empleados que se le ha olvidado fichar");
          }

        } else {

          console.log("error recuperando incidencias de empleados que se le ha olvidado fichar");

        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(<any>error);
      }

    );

  }

  // Busca si se ha producido el olvido de fichar de algun empleado
  calculoIncidenciasOlvido_OLD() {

    this._calculo_incidenciasService.getVisto_Calculo_incidencias(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null, 'olvido_fichar').subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Se ha encontrado empleado con olvido de fichar');
          this.calculo_incidencias = response['data'];

          this.num_incidencias += this.calculo_incidencias.length;

          this.incidencias = true;
        } else {
          console.log('No se encontro ningun empleado con olvidos de fichar');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }

    );

  }

  // Busca si se producen incidencias en el horario de los trabajadores
  // lo ignoro, no veo que lo haga bien
  calculoIncidenciasMenosHoras() {

    console.log('Metodo calculoIncidenciasMenosHoras()');

    if (this.fecha_incidencia === this.fecha_actual) {

      // Adquiere los empleados que echan menos horas de su contrato
      this._empleadoService.getEmpleadosMenos_horas(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null).subscribe(
        response => {
          if (response['code'] === 200) {
            this.trabajadores_menos_horas = response['data'];
            console.log(this.trabajadores_menos_horas.length);
            let num_inciden_menos = 0;

            // 30 minutos de margen
            for (let i = 0; i < this.trabajadores_menos_horas.length; i++) {

              if (this.trabajadores_menos_horas[i].total_horas < this.trabajadores_menos_horas[i].horas_contrato - 0.70) {
                this.incidencias = true;
                num_inciden_menos++;
              }

            }

            if (num_inciden_menos >= 1) {

              // this.num_incidencias += num_inciden_menos; lo quito hasta revsar este tipo de incidencias
              this.incidencias = true;

              this._calculo_incidenciasService.updateVisto_Calculo_incidencias(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null, true, 'incidencia_horario').subscribe(
                response => {
                  if (response['code'] === 200) {
                    console.log('Campo visto actualizado con exito');
                  } else {
                    console.log('El campo visto no se ha podido actualizar');
                  }
                }, error => {
                  console.log('Ha sucedido un error');
                  console.log(error as any);
                }

              );

            }

            // Aumentamos la fecha
            this._calculo_incidenciasService.aumentarFecha(this.fecha_incidencia).subscribe(
              response => {
                if (response['code'] === 200) {
                  console.log('Fecha aumentada con exito');
                  this.fecha_incidencia = response['data'];

                  // Insertamos la fecha aumentada
                  this._calculo_incidenciasService.updateFecha_Calculo_incidencias(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null, this.fecha_incidencia).subscribe(
                    response => {
                      if (response['code'] === 200) {
                        console.log('Fecha insertada con exito');
                      } else {
                        console.log('La fecha no se ha podido insertar');
                      }
                    }, error => {
                      console.log('Ha sucedido un error');
                      console.log(error as any);
                    }

                  );

                } else {
                  console.log('La fecha no se ha podido aumentar');
                }
              }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
              }

            );

          } else {

            // Aumentamos la fecha
            this._calculo_incidenciasService.aumentarFecha(this.fecha_incidencia).subscribe(
              response => {
                if (response['code'] === 200) {
                  console.log('Fecha aumentada con exito');
                  this.fecha_incidencia = response['data'];

                  // Insertamos la fecha aumentada
                  this._calculo_incidenciasService.updateFecha_Calculo_incidencias(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null, this.fecha_incidencia).subscribe(
                    response => {
                      if (response['code'] === 200) {
                        console.log('Fecha insertada con exito');
                      } else {
                        console.log('La fecha no se ha podido insertar');
                      }
                    }, error => {
                      console.log('Ha sucedido un error');
                      console.log(error as any);
                    }

                  );

                } else {
                  console.log('La fecha no se ha podido aumentar');
                }
              }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
              }

            );

          }
        }, error => {
          console.log('Ha sucedido un error');
          console.log(error as any);
        }
      );

    } else {
      console.log('Esperando para realizar calculos');
    }

  }

  boton() {
    if (this.estadoBoton) {
      document.getElementById('sidebar').style.marginLeft = '-250px';
      this.estadoBoton = false;
    } else {
      document.getElementById('sidebar').style.marginLeft = '0px';
      this.estadoBoton = true;
      this.oculto = true;
    }
  }

  mostrarMenu(mostrar: boolean) {
    if (mostrar) {
      document.getElementById('sidebar').style.marginLeft = '0px';
      this.estadoBoton = true;
      this.oculto = true;
    } else {
      document.getElementById('sidebar').style.marginLeft = '-250px';
      this.estadoBoton = false;
    }
  }

  getEmpleado() {
    // console.log(this.empleado.password);
    console.log('GET EMPLEADO:');
    console.log('GLOBAL.code:');
    console.log(GLOBAL.code);
    console.log('GLOBAL.codigo_empresa:');
    console.log(GLOBAL.codigo_empresa);
    this._empleadoService.getEmpleado(GLOBAL.code, GLOBAL.codigo_empresa).subscribe(
      response => {
        console.log(response);
        if (response['code'] == 200) {
          this.empleado = response['data'];
          console.log('Empleado cargado:');
          console.log(this.empleado);
        } else {
          console.log('Empleado no encontrado');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }
    );
  }

  // Método que devuelve la empresa actual
  getEmpresa() {
    return new Promise<void>((resolve, reject)=>{
      this._empresaService.getEmpresa(GLOBAL.codigo_empresa).subscribe(
        response => {
          if (response['code'] == 200) {
            this.empresa = response['data'];
            console.log('Empresa cargada:');
            console.log(this.empresa);
            // actualmente esto YA NO ES ASI, LO IGNORAMOS
            // REDIRIGIMOS A LA PAGINA DE BEJORNADA PARA QUE RENUEVEN
            // POR AHORA, MANUALMENTE CAMBIAMOS EN LA BD EL nª LICENCIAS Y LA FECHA DE SUBSCRIPCIÓN CUANDO PAGUEN
            if (this.empresa.num_licencias >= 25) {
              this.tipo_cuenta = 'Professional';
            } else if (this.empresa.num_licencias < 25) {
              this.tipo_cuenta = 'Basic';
            }
            if (this.tipo_cuenta == 'Professional') {
              this.tipo_pago = 1500;
            } else if (this.tipo_cuenta == 'Basic') {
              this.tipo_pago = 275;
            }
  
            // actualmente esto YA NO ES ASI, LO IGNORAMOS
            // Total de usuarios extra sin contar con los usuarios gratis
            this.packs = this.empresa.num_licencias - 25;
            // Obtengo el número de paquetes contratados
            this.packs = this.packs / 10;
            this.pack_usuarios = this.packs * 25;
            this.total = this.tipo_pago + this.pack_usuarios;
            //opcion que le permite al empleado hacer solicitudes de vacaciones desde rol empleado 
            GLOBAL.opcion_hacer_solicitudes = this.empresa.opcion_hacer_solicitudes
            resolve()
          } else {
            console.log('Empresa no encontrada');
            resolve()
          }
        }, error => {
          console.log(this.empresa);
          console.log('Ha sucedido un error');
          console.log(error as any);
          reject();
        }
      );
    })
  }

  open(content) {
    this.modal = this.modalService.open(content, { centered: true, backdropClass: 'light-blue-backdrop' })
    this.modal.result.then((e) => {
      console.log("dialogo cerrado")
    });
  }

  openCodigo(content) {
    this.modalService.dismissAll();
    this.modal2 = this.modalService.open(content, { centered: true, backdropClass: 'light-blue-backdrop' })
    this.modal2.result.then((e) => {
      console.log("dialogo cerrado")
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  openCompra(compra) {
    console.log('HOLAAAA');
    this.modal = this.modalService.open(compra, { size: 'lg', centered: true })
    this.modal.result.then((e) => {
      console.log("dialogo cerrado")
    });
  }

  // Cambiamos la password del empleado
  changePassword() {
    this.empleado.password = this.myForm.value.newpassword;
    this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
    // console.log(this.empleado);
    this._empleadoService.updatePassword(this.empleado).subscribe(
      result => {
        // console.log(result);
        if (result['code'] == 200) {
          console.log('Contraseña cambiada con éxito');
          this.toastr.success('La contraseña se ha cambiado con éxito.', 'Contraseña cambiada');
          this.sendEmailPassword();
          this.myForm.reset();
          this.modal.close();
        } else {
          console.log('Error al cambiar la contraseña');
          console.log(result['message']);
          // this.toastr.error(result['message'], 'Error');
          this.toastr.error('Error al cambiar la contraseña', 'Error');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  sendEmailPassword() {
    // this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
    this._empleadoService.sendEmailPassword(this.empleado).subscribe(
      result => {
        console.log(result);
        if (result['code'] == 200) {
          console.log('La nueva contraseña se ha enviado por email');
          this.toastr.info('La nueva contraseña se ha enviado por email.', 'Actualizar contraseña');
          //console.log(result);
          // this.msgInfo = 'Contraseña del usuario actualizada y enviada por email';
        } else {
          // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
          console.log('Error: ' + result['message']);
          this.toastr.error('Error: no se ha podido enviar la nueva contraseña por email.', 'Actualizar contraseña');
        }
      },
      error => {
        // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
        console.log(error as any);
      }
    );
  }

  closeChangePassword() {
    this.myForm.reset();
    this.modal.close();
  }

  // Metodo que comprueba si hay alguna incidencia sin ver en la tabla horario (las introducidas manualmente al fichar los empleados)
  getIncidencias_visto() {

    this._horarioService.getIncidencias_visto(this._authService.getStorageIdentity() ? this._authService.getStorageIdentity().codigo_empresa : null).subscribe(
      response => {
        if (response['encontrado'] === true) {

          this.incidencias = true;
          this.num_incidencias = response['size'];
          this.incidencias_visto = response['data'];
          this.total_incidencias = this.num_incidencias + this.num_incidencias_olvido_fichar;
          console.log('Encontradas incidencias de empleados sin validar: ' + this.num_incidencias);

        } else {
          console.log('No se han encontrado incidencias de empleados sin validar');
        }
      }, error => {
        console.log('Ha sucedido un error');
        console.log(error as any);
      }

    );
  }

  // ocultarNumIncidenciasMenu() {
  //   // this.incidencias = false; como parece q ya actualiza bien el numero de incidencias, no las ocultamos del menu
  // }

  fechaActual() {

    this._horarioService.getDiaActual().subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Fecha actual conseguida');
          this.fecha_actual = response['data'];

          // obtengo las fechas del primer y ultimo dia de la semana
          this._horarioService.getFechasInicioFinSemana(this.fecha_actual).subscribe(
            response2 => {
              if (response2['code'] === 200) {
                console.log('Fechas inicio y fin de semana conseguidas:');
                // console.log(response2);
                //this.fecha_actual = response['data'];
                this.fecha_inicio_semana = response2['data']['fechaInicioSemana'];
                console.log('Inicio Semana:');
                console.log(this.fecha_inicio_semana);
                this.fecha_fin_semana = response2['data']['fechaFinSemana'];
                console.log('Fin Semana:');
                console.log(this.fecha_fin_semana);
              } else {
                console.log('Las fechas inicio y fin de la semana no se han podido conseguir');
              }
            }, error => {
              console.log('Ha sucedido un error en fechaInicioFinSemana');
              console.log(error as any);
              // alert('No hay conexión con el Servidor');
            }

          );

        } else {
          console.log('La fecha actual no se ha podido conseguir');
        }
      }, error => {
        console.log('Ha sucedido un error en fechaActual');
        console.log(error as any);
        alert('No hay conexión con el Servidor');
      }

    );

  }

  fechaIncidencias() {

    this._calculo_incidenciasService.getFecha_Calculo_incidencias('333Z', 'incidencia_horario').subscribe(
      response => {
        if (response['code'] === 200) {
          console.log('Fechas conseguidas');
          this.fecha_incidencia = response['data']; // se usa para calculo incidencias menos horas
        } else {
          console.log('No se ha podido conseguir la fecha');
        }
      }, error => {
        console.log('Ha sucedido un error en fechaIncidencias');
        console.log(error as any);
      }

    );

  }

  // Metodos de las Bases de Datos Internas

  // Abrir/Crear base de datos interna Entrada
  abrirBDEntrada() {
    this.bd1.openDatabase(1, evt => {

      let objectStore = evt.currentTarget.result.createObjectStore('Entrada', { keyPath: 'id', autoIncrement: true });

      objectStore.createIndex('code', 'code', { unique: false });
      objectStore.createIndex('codigo_empresa', 'ecodigo_empresamail', { unique: false });
      objectStore.createIndex('foto', 'foto', { unique: false });
      objectStore.createIndex('hora_entrada', 'fohora_entradato', { unique: false });
      objectStore.createIndex('fecha', 'fecha', { unique: false });
      objectStore.createIndex('estado', 'estado', { unique: false });
      objectStore.createIndex('incidencias', 'incidencias', { unique: false });
      objectStore.createIndex('latitud_entrada', 'latitud_entrada', { unique: false });
      objectStore.createIndex('longitud_entrada', 'longitud_entrada', { unique: false });

    });
  }

  // Abrir/Craer base de datos interna Salida
  abrirBDSalida() {
    this.bd2.openDatabase(1, evt => {

      let objectStore = evt.currentTarget.result.createObjectStore('Salida', { keyPath: 'id', autoIncrement: true });

      objectStore.createIndex('code', 'code', { unique: false });
      objectStore.createIndex('codigo_empresa', 'ecodigo_empresamail', { unique: false });
      objectStore.createIndex('fecha', 'fecha', { unique: false });
      objectStore.createIndex('hora_salida', 'hora_salida', { unique: false });
      objectStore.createIndex('total_horas', 'total_horas', { unique: false });
      objectStore.createIndex('foto_salida', 'foto_salida', { unique: false });
      objectStore.createIndex('estado', 'estado', { unique: false });
      objectStore.createIndex('incidencias_salida', 'incidencias_salida', { unique: false });
      objectStore.createIndex('incidencias_visto', 'incidencias_visto', { unique: false });
      objectStore.createIndex('latitud_salida', 'latitud_salida', { unique: false });
      objectStore.createIndex('longitud_salida', 'longitud_salida', { unique: false });

    });
  }

  // Introducir datos en la base de datos Externa ( Entrada )
  // volcarBDEntrada() {

  //   // TODO: revisar uso BD INTERNAS
  //   return;

  //   this.bd1.getAll('Entrada').then(
  //     response => {
  //       let horario: Horario;
  //       for (let i = 0 ; i < response.length ; i++) {

  //         horario = new Horario(0, null, null, null, null, null, null, 0, null, false, null, null, null, false, 0, 0, 0, 0, null, null);
  //         horario.code = response[i].code;
  //         horario.codigo_empresa = response[i].codigo_empresa;
  //         horario.foto = response[i].foto;
  //         horario.hora_entrada = response[i].hora_entrada;
  //         horario.fecha = response[i].fecha;
  //         horario.estado = response[i].estado;
  //         horario.incidencias = response[i].incidencias;
  //         horario.latitud_entrada = response[i].latitud_entrada;
  //         horario.longitud_entrada = response[i].longitud_entrada;

  //         this._horarioService.addHorario(horario).subscribe(
  //           response => {
  //             if (response['code'] === 200) {
  //               console.log('Datos introducidos correctamente');
  //               this.bd1.clear('Entrada').then( () => {
  //                   console.log('Borrada la base de datos entrada');
  //                   this.llenaEntrada = 0;
  //                 },
  //                 error => {
  //                   console.log('Error al borrar: ', error);
  //                 }
  //               );
  //             } else {
  //               console.log('Error response no es 200 Al volcar entrada');
  //             }
  //           },
  //           error => {
  //             console.log(error as any);
  //           }
  //         );
  //       }
  //     },
  //     error => {
  //       console.log('Error al leer: ' , error);
  //     }
  //   );
  // }

  // Introducir datos en la base de datos Externa ( Salida )
  // volcarBDSalida() {

  //   // TODO: REVISAR USO BD INTERNAS
  //   return;

  //   let horarioSalida: Array<Horario>;
  //   let horario: Horario;
  //   let horaEntrada: any;
  //   this.bd2.getAll('Salida').then(
  //     response1 => {
  //       for ( let i = 0 ; i < response1.length ; i++ ) {
  //         this._horarioService.getHorario(response1[i].code , response1[i].fecha).subscribe(
  //           response2 => {
  //             if (response2['code'] === 200) {
  //               horarioSalida = response2['data'];
  //               horaEntrada = horarioSalida[horarioSalida.length - 1].hora_entrada;
  //             }

  //             horario = new Horario(0, null, null, null, null, null, null, 0, null, false, null, null, null, false, 0, 0, 0, 0, null, null);

  //             horario.foto_salida = response1[i].foto_salida;
  //             horario.hora_salida = response1[i].hora_salida;
  //             horario.total_horas = response1[i].total_horas;
  //             horario.estado = response1[i].estado;
  //             horario.incidencias_salida = response1[i].incidencias_salida;
  //             horario.incidencias_visto = response1[i].incidencias_visto;
  //             horario.latitud_salida = response1[i].latitud_salida;
  //             horario.longitud_salida = response1[i].longitud_salida;

  //             // TODO: MAL: no tiene fecha, ¿actualiza los de todos los dias de ese code y esa hora entrada ?
  //             // this._horarioService.upDateHorarioSalida(response1[i].code, horaEntrada, horario).subscribe(

  //             // TODO: revisar el id ¿¿¿???
  //             this._horarioService.insertHorarioSalida_OK(response1[i].id).subscribe(
  //               response3 => {
  //                 if (response3['code'] === 200) {
  //                   console.log('Datos introducidos correctamente');
  //                   this.bd2.clear('Salida').then( () => {
  //                       console.log('Borrada la base de datos salida');
  //                       this.llenaSalida = 0;
  //                     },
  //                     error => {
  //                       console.log('Error al borrar: ', error);
  //                     }
  //                   );
  //                 } else {
  //                   console.log('Error response no es 200 al volcar salida');
  //                 }
  //               },
  //               error => {
  //                 console.log(error as any);
  //               }
  //             );
  //           }
  //         );
  //       }
  //     },
  //     error => {
  //       console.log('Error al leer: ' , error);
  //     }
  //   );
  // }

  // leerBDEntrada() {
  //   return;

  //   this.bd1.getAll('Entrada').then(
  //     response => {
  //       if ( response.length > 0 ) {
  //         console.log('BDInterna Entrada encontrada');
  //         this.llenaEntrada = 1;
  //       }
  //     },
  //     error => {
  //       console.log('Error al leer: ' , error);
  //       this.llenaEntrada = 2;
  //     }
  //   );
  // }

  // leerBDSalida() {

  //   return;

  //   this.bd2.getAll('Salida').then(
  //     response => {
  //       if ( response.length > 0 ) {
  //         console.log('BDInterna Salida encontrada');
  //         this.llenaSalida = 1;
  //       }
  //     },
  //     error => {
  //       console.log('Error al leer: ' , error);
  //       this.llenaSalida = 2;
  //     }
  //   );
  // }

  // leerBDInternas() {
  //   this.leerBDEntrada();
  //   this.leerBDSalida();
  // }

  // comporbarBDEntrada() {

  //   return;

  //   if ( this.llenaEntrada === 1 ) {
  //     console.log('Las bases de datos Entrada esta llena');
  //     this.volcarBDEntrada();
  //   } else {
  //     console.log('Las bases de datos Entrada esta vacia');
  //   }
  // }

  // comprobarBDSalida() {

  //   return;

  //   if ( this.llenaSalida === 1 ) {
  //     console.log('Las bases de datos Salida esta llena');
  //     if ( this.llenaEntrada === 0 ) {
  //       this.volcarBDSalida();
  //     }
  //   } else {
  //     console.log('Las bases de datos Salida esta vacia');
  //   }
  // }

  // interval_checkBDES() {
  //   //setInterval(() => { this.leerBDInternas(); }, (60000 * 9) );
  //   // setInterval(() => { this.comporbarBDEntrada(); }, (60000 * 10) );
  //   // setInterval(() => { this.comprobarBDSalida(); }, (60000 * 15) );
  // }

  // Borra los regisdtro de la BDInterna tanto de Entrada como de Salida ( Solo utilizado para desarrollo )
  borrar() {
    this.bd2.clear('Salida').then(() => {
      console.log('Borrada la base de datos salida');
    },
      error => {
        console.log('Error al borrar: ', error);
      }
    );

    this.bd1.clear('Entrada').then(() => {
      console.log('Borrada la base de datos salida');
    },
      error => {
        console.log('Error al borrar: ', error);
      }
    );
  }

  // por ahora, vamos a la url de Bejornada, no terminamos de integrar Stripe
  urlBejornada() {
    // <a href="#nombre_marcador">Ir al título</a>
    let url = "https://bejornada.es/Inicio#planes";
    this.goToLink(url);
  }

  goToLink(url: string) {
    console.log('Abrir web Bejornada: ' + url);
    window.open(url, '_blank');
  }

  // Realización de renovaciones de licencias con stripe
  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_hUPHNADv1aWMqpeybhA4RhY500P8tuHwQ9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            alert('Payment Success!!');
          }
        });
      }
      window.document.body.appendChild(s);
    }
  }

  pay(amount) {
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_hUPHNADv1aWMqpeybhA4RhY500P8tuHwQ9',
      locale: 'es',
      country: 'ES',
      currency: 'eur',
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        console.log(token)
        alert('Token Created!!');
      }
    });
    handler.open({
      name: 'Renovación de licencia',
      description: 'Renovación de licencia Bejornada',
      amount: amount * 100
    });
  }

  recuperarContrasena(index: any) {
    if (index == 1) {
      this.correoR = this.myRecuperar.get('correo').value;
      this.usuarioR = this.myRecuperar.get('usuario').value;
      let info = [{ Field: 'email', Value: this.correoR }, { Field: 'usuario', Value: this.usuarioR }];
      this._usuarioService.recuperarContrasena(info).subscribe(result => {
        if (result['code'] == 404) {
          this.toastr.error(result['message'], "Error");
        } else if (result['code'] == 450) {
          this.toastr.error('Demasiado intentos, hable con el administrador del sistema', "Código de recuperación");
        } else {
          if (result['code'] == 220) {
            this.toastr.warning("Tienes alguno activo, revisa el correo", "Codigo de recuperación");
          }
          this.modal.close();
          this.myRecuperar.reset();
          this.opcionRecuperar = true;
          this.toastr.success("Se le ha enviado al correo un código de verificación", "Código de recuperación");
        }
      });
    } else if (index == 2) {
      if (this.formRecuperar.get('correoR').value != '' && this.formRecuperar.get('codigoR').value != '') {
        this._usuarioService.comprobarCodigo(this.formRecuperar.get('correoR').value, this.formRecuperar.get('codigoR').value).subscribe(result => {
          if (result['code'] === 200) { // Hay codigo activo
            console.log("Solo uno activo");
            //Iniciamos modal para cambiar contraseña
            this.open(this.contenido);
          } else { // Codigo erroneo
            this.toastr.error("El código es incorrecto", "Recuperación de contraseña");
          }
        });
      }
    } else if (index == 3) {
      let info = [{ Field: 'email', Value: this.correoR }, { Field: 'usuario', Value: this.usuarioR }, { Field: 'password', Value: this.myCodigoR.get('newpassword').value }];
      this._usuarioService.recuperarContrasena(info).subscribe(result => {
        if (result['code'] == 200) {
          this.toastr.success("Contraseña cambiada correctamente para el usuario: " + this.usuarioR + ".", "Recuperación de contraseña");
        } else {
          this.toastr.error("Ha sucedido un error", "Recuperación de contraseña");
        }
      });
      this.opcionRecuperar = false;
      this.modal.close();
    }
  }


}