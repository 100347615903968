export class Horario{

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public foto: string;
    public foto_salida: string;
    public hora_entrada: number;
    public hora_entrada_updated_by: string;
    public hora_salida: number;
    public hora_salida_updated_by: string;
    public total_horas: number;
    public fecha: Date;
    public estado: boolean; //True == entrada       False == salida
    public incidencias: string;
    public incidencias_salida: string;
    public foto_incidencia: string;
    public incidencias_visto: boolean;
    public latitud_entrada: number;
    public longitud_entrada: number;
    public latitud_salida: number;
    public longitud_salida: number;
    public nombre: string;
    public apellidos: string;
    public puesto: string;

    //Constructor por parametros
    constructor(id: number, code: string, codigo_empresa: string, foto: string, foto_salida: string, hora_entrada: number, hora_salida: number, total_horas: number, fecha: Date, estado :boolean, incidencias: string, incidencias_salida: string, foto_incidencia: string, incidencias_visto: boolean, latitud_entrada: number, longitud_entrada: number, latitud_salida: number, longitud_salida: number, hora_entrada_updated_by: string, hora_salida_updated_by: string, nombre?: string, apellidos?: string, puesto?: string){
        this.id = id;
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.foto = foto;
        this.foto_salida = foto_salida;
        this.hora_entrada = hora_entrada;
        this.hora_salida = hora_salida;
        this.hora_entrada_updated_by = hora_entrada_updated_by;
        this.hora_salida_updated_by = hora_salida_updated_by;
        this.total_horas = total_horas;
        this.fecha = fecha;
        this.estado = estado;
        this.incidencias = incidencias;
        this.incidencias_salida = incidencias_salida;
        this.foto_incidencia = foto_incidencia;
        this.incidencias_visto = incidencias_visto;
        this.latitud_entrada = latitud_entrada;
        this.longitud_entrada = longitud_entrada;
        this.latitud_salida = latitud_salida;
        this.longitud_salida = longitud_salida;
        this.nombre = nombre;
        this.apellidos = apellidos;
        this.puesto = puesto;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getFoto(){
        return this.foto;
    }

    setFoto(foto: string){
        this.foto = foto;
    }

    getFoto_salida(){
        return this.foto_salida;
    }

    setFoto_salida(foto_salida: string){
        this.foto_salida = foto_salida;
    }

    getHora_entrada(){
        return this.hora_entrada;
    }

    setHora_entrada(hora_entrada: number){
        this.hora_entrada = hora_entrada;
    }

    getHora_salida(){
        return this.hora_salida;
    }

    setHora_salida(hora_salida: number){
        this.hora_salida = hora_salida;
    }

    getHora_entrada_updated_by(){
        return this.hora_entrada_updated_by;
    }

    setHora_entrada_updated_by(hora_entrada_updated_by: string){
        this.hora_entrada_updated_by = hora_entrada_updated_by;
    }

    getHora_salida_updated_by(){
        return this.hora_salida_updated_by;
    }

    setHora_salida_updated_by(hora_salida_updated_by: string){
        this.hora_salida_updated_by = hora_salida_updated_by;
    }

    getTotal_horas(){
        return this.total_horas;
    }

    setTotal_horas(total_horas: number){
        this.total_horas = total_horas;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: Date){
        this.fecha = fecha;
    }

    getEstado(){
        return this.estado;
    }

    setEstado(estado: boolean){
        this.estado = estado;
    }

    getIncidencias(){
        return this.incidencias;
    }

    setIncidencias(incidencias: string){
        this.incidencias = incidencias;
    }

    getIncidencias_salida(){
        return this.incidencias_salida;
    }

    setIncidencias_salida(incidencias_salida: string){
        this.incidencias_salida = incidencias_salida;
    }

    getFoto_incidencia(){
        return this.foto_incidencia;
    }

    setFoto_incidencia(foto_incidencia: string){
        this.foto_incidencia = foto_incidencia;
    }

    getIncidencia_visto(){
        return this.incidencias_visto;
    }

    setIncidencias_Visto(incidencias_visto: boolean){
        this.incidencias_visto = incidencias_visto;
    }
    
    getLatitud_entrada(){
        return this.latitud_entrada;
    }

    setLatitud_entrada(latitud_entrada: number){
        this.latitud_entrada = latitud_entrada;
    }

    getLongitud_entrada(){
        return this.longitud_entrada;
    }

    setLongitud_entrada(longitud_entrada: number){
        this.longitud_entrada = longitud_entrada;
    }

    getLatitud_salida(){
        return this.latitud_salida;
    }

    setLatitud_salida(latitud_salida: number){
        this.latitud_salida = latitud_salida;
    }

    getLongitud_salida(){
        return this.latitud_salida;
    }

    setLongitud_salida(longitud_salida: number){
        this.longitud_salida = longitud_salida;
    }

}