export class Log{
    public id: number;
    public codigo: string;
    public codigo_empresa: string;
    public fecha: Date;
    public categoria: string;
    public descripcion: string;
    public observaciones1: string;
    public observaciones2: string;

    constructor(id, codigo, codigo_empresa, fecha, categoria, descripcion, observaciones1, observaciones2){
        this.id = id;
        this.codigo = codigo;
        this.codigo_empresa = codigo_empresa;
        this.fecha = fecha; 
        this.categoria = categoria;
        this.descripcion = descripcion;
        this.observaciones1 = observaciones1;
        this.observaciones2 = observaciones2;
    }

	getId(){
        return this.id;
    }

    getCodigo(){
        return this.codigo;
    }

    getFecha(){
        return this.fecha;
    }

    getcategoria(){
        return this.categoria;
    }

    getDescripcion(){
        return this.descripcion;
    }

    getObservaciones1(){
        return this.observaciones1;
    }
    getObservaciones2(){
        return this.observaciones2;
    }

    setId(id:number){
        this.id = id;
    }
    setCodigo(codigo:string){
        this.codigo = codigo;
    }
    setcategoria(categoria:string){
        this.categoria = categoria;
    }
    setFecha(fecha:Date){
        this.fecha = fecha;
    }
    setDescripcion(descripcion:string){
        this.descripcion = descripcion;
    }
    setObservaciones1(observaciones1:string){
        this.observaciones1 = observaciones1;
    }
    setObservaciones2(observaciones2:string){
        this.observaciones2 = observaciones2;
    }

}