import { Component } from '@angular/core';
import { Empleado } from 'src/app/models/empleado';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpleadoService } from '../../services/empleado.service';
import { HorarioService } from '../../services/horario.service';
import { CalculoIncidenciasService } from '../../services/calculo_incidencias.service';
import { Horario } from '../../models/horario';
import { Foto } from 'src/app/models/foto';
import { Observable, Subject } from 'rxjs';
import { GLOBAL } from '../../services/global.service';
import { CalculoIncidencias } from '../../models/calculo_incidencias';
import { Incidencia } from '../../models/incidencias';
import { IncidenciasService } from '../../services/incidencias.service';
import { Location } from '@angular/common';

// Base de datos interna
import { NgxIndexedDB } from 'ngx-indexed-db';

// Cámara
// https://www.npmjs.com/package/ngx-webcam
// Chrome >53, Safari >11, Firefox >38, Edge
import { WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

// import { ConsoleReporter } from 'jasmine';

@Component({
    selector : 'salida',
    templateUrl : '../entrada/entrada.component.html',
    styleUrls : ['../entrada/entrada.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, HorarioService, CalculoIncidenciasService ]
})

export class SalidaComponent {
    public codigo_empresa: string;
    public titulo: string;
    public texto:string;
    public empleado: Empleado;
    public horarios_empleado: Array<Horario>;
    public horario: Array<Horario>; // Array que contiene los uno-multiples horarios de entrada del empleado
    public horario_update: Horario; // Horario que se va a insertar en la base de datos ya con todos los campos completos
    public ultima_hora_salida: any;
    public idHorario: any;
    public fecha_salida: any;
    public hora_salida: any;
    public hora_entrada: any;
    public horas_totales: any;
    public codigo_empleado: string;
    public passwd_empleado: string;
    public fecha: Date;
    public contador: number;
    public incidencia: boolean;
    public tipo_incidencia: string;
    public encontrado: boolean;
    public incidencias_visto: boolean;
    public foto: Foto;
    // public hora_foto;
    public consultaHorario: boolean;
    public FalloConx: boolean;
    public calculo_incidencia: CalculoIncidencias;
    public suma_total_horas: number;
    public suma_total_horas_prevista: number;
    public lista_incidencias: Array<Incidencia>;

    // Variable Base de datos interna
    public bd: NgxIndexedDB;
    public bd1: NgxIndexedDB;

    // Variables geolocalizacion
    public latitud: number;
    public longitud: number;
    public geoLocationSupported: boolean;
    public empresa_enable_localizacion: boolean = false;
    public usuario_enable_localizacion: boolean = false;
    public enable_localizacion: boolean = false;

    // Cámara
    public seconds: number ;
    private trigger: Subject<void> = new Subject<void>();
    public webcamImage: WebcamImage = null;
    public camVisible: boolean;
    public empresa_enable_camara: boolean = false;
    public usuario_enable_camara: boolean = false;
    public enable_camara: boolean = false;

    public deBaja: boolean = false;
    public deVacaciones: boolean = false;
    public deAusencias: boolean = false;

    constructor(private _horarioService: HorarioService,
                private _empleadoService: EmpleadoService,
                private _incidenciaService: IncidenciasService,
                private _calculoIncidenciasService: CalculoIncidenciasService,
                private _location: Location,
                private _route: ActivatedRoute,
                private _router: Router) {
        this.titulo = 'Salida';
        this.texto = 'Salida';
        this.fecha = new Date();
        this.idHorario = null;
        this.horario = [];
        this.horarios_empleado = [];
        this.horario_update = new Horario(0, null, null, null, null, null, null, 0, null, false, null, null, null, false, 0, 0, 0, 0, null, null);
        this.contador = 0;
        //this.tipo_incidencia = 'Sin incidencia';
        this.tipo_incidencia = null;
        this.incidencia = false;
        this.encontrado = true;
        this.incidencias_visto = false;
        this.foto = new Foto(null);
        this.latitud = 0;
        this.longitud = 0;
        this.consultaHorario = false;
        this.bd = new NgxIndexedDB('BDInternaSalida' , 1);
        this.bd1 = new NgxIndexedDB('BDInternaEntrada' , 1);
        this.FalloConx = false;
        this.calculo_incidencia = new CalculoIncidencias(0, null, null, null, false, null);
        this.camVisible = false;
        this.geoLocationSupported = true;
        this.lista_incidencias = null;
        this.suma_total_horas_prevista = 0;
    }

    // Metodos para la cámara
    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public handleInitErrorCamera(error: WebcamInitError): void {
        // if the user denies permission -> NotAllowedError / PermissionDeniedError
        // matching media is not available ->  NotFoundError
        this.camVisible = false;
        console.log('Error de cámara: ');
        console.log(error);
        if (error.mediaStreamError && (error.mediaStreamError.name === "NotAllowedError" || error.mediaStreamError.name === "PermissionDeniedError")) {
            console.warn("Camera access was not allowed by user!");
            this.camVisible = false;
        }
        if (error.mediaStreamError && error.mediaStreamError.name === "NotFoundError") {
            console.warn("Camera access not possible !");
            this.camVisible = false;
        }
    }

    ngOnInit() {

        this.codigo_empresa = GLOBAL.codigo_empresa;

        this.abrirBD();
        this.abrirBDEntrada();

        this.list_incidencias();

        // ahora ya no pedimos login:
        this.getEmpleado();
    }

    backClicked() {
        this._location.back();
    }

    //Metodo que se trae todos los tipos de incidencias de la base de datos
    list_incidencias(){
        console.log('Buscar tipos de incidencias de la empresa: ' + this.codigo_empresa);
        this._incidenciaService.getIncidencias(this.codigo_empresa, '1').subscribe( // 1: incluye las generales, no solo las de la empresa
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Tipos de incidencias conseguidos con exito')
                    this.lista_incidencias = response['data'];
                    console.log(this.lista_incidencias);

                } else {
                    console.log("Los tipos de incidencias no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    // antes, para login antes de fichar
    getEmpleadoFichar() {        
        let code = this.codigo_empleado;

        this._empleadoService.getEmpleadoFichar(code, this.passwd_empleado).subscribe(
            response => {
                if (response['code'] === 200) {
                    this.empleado = response['data'];
                    this.encontrado = true;
                    this.getLocation();
                    // this.getHoraActual();  // -> this.hora_salida , calculoTotalHoras(), ...
                    this.getFechaActual(); // -> this.fecha_salida, this.hora_salida
                    this.getFechayHoraActual(); // -> this.fecha
                    console.log('Empleado encontrado');
                } else {
                    console.log('No se ha encontrado el empleado');
                    this.mostarMsgErrAccesoRegistro();
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );

    }

    // ahora, entramos directamente a fichar sin login
    getEmpleado() {
        this.codigo_empleado = GLOBAL.code;
        let codigo_empresa = GLOBAL.codigo_empresa;

        this._empleadoService.getEmpleado(this.codigo_empleado, codigo_empresa).subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Empleado encontrado');
                    this.empleado = response['data'];
                    this.encontrado = true;
                    this.getFechaActual(); // recoje la fecha y hora -> this.hora_actual , this.fecha_actual
                    this.getFechayHoraActual(); // -> this.fecha
                    this.checkActivacionCamaraLocalizacion();

                    // GLOBAL.deBaja =  this.empleado.baja ? true : false; // FALLA cuando es 0, tambien lo pasa a true ¿¿¿???
                    GLOBAL.deBaja =  this.getBoolean(this.empleado.baja);
                    GLOBAL.deVacaciones =  this.getBoolean(this.empleado.vacaciones);
                    GLOBAL.deAusencias =  this.getBoolean(this.empleado.ausencia);
                    this.deBaja = GLOBAL.deBaja;
                    this.deVacaciones = GLOBAL.deVacaciones;
                    this.deAusencias = GLOBAL.deAusencias;
                    // console.log('De baja:' + this.empleado.baja + ' -> ' + GLOBAL.deBaja );
                    // console.log('De vacaciones:' + this.empleado.vacaciones + ' -> ' + GLOBAL.deVacaciones );
                    // console.log('De ausencia:' + this.empleado.ausencia + ' -> ' + GLOBAL.deAusencias );

                } else {
                    console.log('No se ha encontrado el empleado');
                    // this.mostarMsgErrAccesoRegistro();
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    getBoolean(value) {
        switch (value) {
             case true:
             case "true":
             case 1:
             case "1":
             case "on":
             case "yes":
                 return true;
             default:
                 return false;
        }
    }

    checkActivacionCamaraLocalizacion() {
        this.empresa_enable_camara = GLOBAL.config_empresa_enable_camera[0] === '1' ? true : false;
        this.empresa_enable_localizacion = GLOBAL.config_empresa_enable_localizacion[0] === '1' ? true : false;
        console.log('empresa_enable_camara: ' + this.empresa_enable_camara);
        console.log('empresa_enable_localizacion: ' + this.empresa_enable_localizacion);
        this.usuario_enable_camara = this.empleado.camara;
        this.usuario_enable_localizacion = this.empleado.localizacion;
        console.log('usuario_enable_camara: ' + this.usuario_enable_camara);
        console.log('usuario_enable_localizacion: ' + this.usuario_enable_localizacion);
        this.enable_camara = this.empresa_enable_camara && this.usuario_enable_camara;
        this.enable_localizacion = this.empresa_enable_localizacion && this.usuario_enable_localizacion;
        console.log('activar camara: ' + this.enable_camara);
        console.log('activar localizacion: ' + this.enable_localizacion);

        if (+this.enable_camara == 1) { // es raro, pero asi es como funciona con las 4 combinaciones posibles
            this.camVisible = true;
        } else {
            this.camVisible = false;
        }

        this.getLocation();

    }

    mostarMsgErrAccesoRegistro() {
        this.encontrado = false; // mostramos mensaje de error en rojo
        setTimeout(() => {
          // 2 segundos después...
          this.encontrado = true; // ocultamos mensaje de error en rojo
        }, 2000);
    }

    // Metodo que guarda el horario de salida
    saveHorario() {
        // Se trae el horario
        this.getHorario();
    }

    getHorario() {
        console.log('Consultando registros del empleado:');

        // obtenemos los fichajes del dia actual
        this._horarioService.getHorario(this.codigo_empleado, this.fecha_salida).subscribe(
            response1 => {
                console.log('Registros encontrados:');
                console.log(response1);
                if (response1['code'] === 200) {
                    this.horario = response1['data'];

                    if ( this.horario.length === 0 ) {
                        // es el primer fichaje del dia y lo hace de salida

                        // ¿ entró a trabajar el día anterior ?
                        // TODO: comprobar (ver como hacerlo) si entró el dia anterior o check campo en empleado 'horario_noche' (crear este campo)                        
                        // contemplamos también los fichajes del dia anterior y decidimos segun los registros q obtengamos
                        // this._horarioService.getHorariosEmpleado(this.codigo_empleado, this.empleado.codigo_empresa,).subscribe(

                        // IF SI entró el día anterior

                        // IF NO entró el dia anterior
                            this.registrar_olvido_registro_entrada();

                    } else {
                        // existen fichajes

                        console.log('Se han encontrado registros hoy para este empleado');
                        this.hora_entrada = this.horario[this.horario.length - 1].hora_entrada;
                        this.ultima_hora_salida = this.horario[this.horario.length - 1].hora_salida;
                        this.idHorario = this.horario[this.horario.length - 1].id;
                        console.log('Ultimo registro encontrado:');
                        console.log('Id: ');
                        console.log(this.idHorario);
                        console.log('Hora entrada: ');
                        console.log(this.hora_entrada);
                        console.log('Hora salida: ');
                        console.log(this.ultima_hora_salida);

                        if (this.ultima_hora_salida != null) {
                            // fichajes de salida seguidos

                            this.registrar_olvido_registro_entrada();

                        } else if (this.hora_entrada != null && this.ultima_hora_salida == null) {
                            // correcto, el ultimo fichaje es de entrada

                            // recupera la hora actual del sistema y guarda el registro en la tabla horario
                            let incidenciaOlvido = false;
                            this.getHoraActualandSave(incidenciaOlvido);

                        }

                    }

                    /*** CODIGO ORIGINAL TENIENDO EN CUENTA LA BD INTERNA ***/
                    /* TODO: hay q ver como tratar este tema de la BD INTERNA */
                    /*
                    if ( this.horario.length === 0 ) {
                        console.log('No se ha encontrado ningún registro hoy para este empleado');


                        // Comprobamos si la base de datos interna tiene algun contenido
                        this.bd1.getAll('Entrada').then(
                        response2 => {
                            if ( response2.length > 0 ) {
                                for ( let i = 0 ; i < response2.length ; i++) {
                                    if ( response2[i].code === this.codigo_empleado ) {
                                        this.hora_entrada = response2[i].hora_entrada;
                                        this.FalloConx = true;
                                        this.getHoraActualandSave();
                                    } else {
                                        this.olvido_fichar_en_calculo_incidecias();
                                        this._router.navigate(['/correct']);
                                    }
                                }
                            } else {
                                // Base de datos interna vacia por lo que se le olvido fichar por la mañana
                                this.olvido_fichar_en_calculo_incidecias();
                                this._router.navigate(['/correct']);
                            }
                        },
                        error => {
                            console.log('Error al leer la BDINterna: ' , error);
                            }
                        );

                    } else {
                        console.log('Se han encontrado registros hoy para este empleado');
                        this.hora_entrada = this.horario[this.horario.length - 1].hora_entrada;
                        this.ultima_hora_salida = this.horario[this.horario.length - 1].hora_salida;
                        console.log('Ultimo registro encontrado:');
                        console.log('Hora entrada: ');
                        console.log(this.hora_entrada);
                        console.log('Hora salida: ');
                        console.log(this.ultima_hora_salida);

                        // recupera la hora actual del sistema y guarda el registro
                        this.getHoraActualandSave();

                    }
                    */

                } else {

                }
            }, error => {
                console.log('Error recuperando los registros del empleado:');
                console.log(error as any);
            }
        );

    }

    registrar_olvido_registro_entrada() {

        console.log('Registrando fichaje de SALIDA SIN ENTRADA');

        // guarda  aviso en tabla calculo_incidencias:
        this.olvido_fichar_en_calculo_incidencias();

        // recupera la hora actual del sistema y guarda el registro en la tabla horario
        let incidenciaOlvido = true;
        this.getHoraActualandSave(incidenciaOlvido);

    }

    insertarSalida_INCIDENCIA() {
        console.log('Añadiendo registro de salida SIN REGISTRO DE ENTRADA:');
        console.log(this.horario_update);
        this._horarioService.insertHorarioSalida_INCIDENCIA(this.horario_update).subscribe(
            response => {
                console.log(response);
                if (response['code'] === 200) {
                    if ( this.FalloConx === true ) {
                        this.add_a_BD();
                        this.leerBD();
                        this.FalloConx = false;
                    } else {
                        console.log('Error al acceder a BD interna');
                    }
                    console.log('Horario añadido a la BD');
                    this._router.navigate(['/correct/Salida registrada/OLVIDÓ REGISTRAR LA ENTRADA ANTERIOR']);
                } else {
                    console.log('Error: Horario NO añadido a la BD');
                    this.add_a_BD();
                    this.leerBD();
                    this._router.navigate(['/correct/null/ERROR: Salida NO registrada']);
                }
            },
            error => {
                console.log('Error: Horario NO añadido a la BD');
                this.add_a_BD();
                this.leerBD();
                this._router.navigate(['/correct/null/ERROR: Salida NO registrada']);
            }
        );
    }

    // Inserta los datos en la base de datos
    insertarSalida_OK() {
        console.log('Añadiendo registro de salida SIN INCIDENCIA OLVIDO:');
        console.log(this.horario_update);
        this._horarioService.insertHorarioSalida_OK(this.horario_update).subscribe(
            response => {
                console.log(response);
                if (response['code'] === 200) {
                    if ( this.FalloConx === true ) {
                        this.add_a_BD();
                        this.leerBD();
                        this.FalloConx = false;
                    } else {
                        console.log('Error al acceder a BD interna');
                    }
                    console.log('Horario añadido a la BD');
                    this._router.navigate(['/correct/Salida registrada/null']);
                } else {
                    console.log('Error: Horario NO añadido a la BD');
                    this.add_a_BD();
                    this.leerBD();
                    this._router.navigate(['/correct/null/ERROR: Salida NO registrada']);
                }
            },
            error => {
                console.log('Error: Horario NO añadido a la BD');
                this.add_a_BD();
                this.leerBD();
                this._router.navigate(['/correct/null/ERROR: Salida NO registrada']);
            }
        );
    }

    add_a_BD() {
        /* TOD: check si lo hace bien y no da error */
        /*
        this.addElemetosBD(
            this.empleado.code,
            this.empleado.codigo_empresa,
            this.fecha_salida,
            this.horario_update.hora_salida,
            this.horario_update.total_horas,
            this.horario_update.foto_salida,
            this.horario_update.estado,
            this.horario_update.incidencias_salida,
            this.horario_update.incidencias_visto,
            this.horario_update.latitud_salida,
            this.horario_update.longitud_salida
        );
        */
    }

    olvido_fichar_en_calculo_incidencias() {

        // Componer incidencia
        this.calculo_incidencia.codigo_empresa = this.empleado.codigo_empresa;
        this.calculo_incidencia.fecha = this.fecha_salida;
        this.calculo_incidencia.tipo_incidencias = 'olvido_fichar';
        this.calculo_incidencia.visto = true;
        this.calculo_incidencia.code = this.empleado.code;


        // Inserta la incidencia de tipo olvido_fichar
        console.log('Añadir ')
        this._calculoIncidenciasService.addCalculo_incidencias(this.calculo_incidencia).subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Calculo incidencia insertada con exito');
                    console.log(response['message']);
                } else {
                    console.log('Calculo incidencia no se ha podido insertar con exito');
                    console.log(response['message']);
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );

    }

    getHoraActualandSave(incidenciaOlvido: boolean) {
        this._horarioService.getHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Hora actual recuperada: ' + response['data']);
                    this.hora_salida = response['data'];

                    // Estado work trabajador a false
                    this.updateEstadoWorking();

                    if ( !incidenciaOlvido) {
                        // Calculo de las horas totales trabajadas por el empleado desde la ultima entrada
                        this.calculoTotalHoras();
                        // completo los datos del horario
                        this.componerHorario();
                        this.horario_update.id = this.idHorario;
                        // Insertando el horario en la base de datos
                        this.insertarSalida_OK();
                    } else {
                        this.horas_totales = 0;
                        this.componerHorario();
                        this.horario_update.id = null;
                        // Insertando el horario en la base de datos
                        this.insertarSalida_INCIDENCIA();
                    }

                    // Guarda la foto en el directorio
                    this.saveFoto();

                } else {
                    console.log('No se ha podido conseguir la hora actual');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
            }
        );
    }

    difHoras(horaInicio: string, horaFin: string): number {
        let hora1 = horaFin.split(':');
        let hora2 = horaInicio.split(':');
        let t1 = new Date();
        let t2 = new Date();

        t1.setHours(+hora1[0], +hora1[1], +hora1[2]); // '+' convierte de string a integer
        t2.setHours(+hora2[0], +hora2[1], +hora2[2]);

        //Aquí hago la resta
        t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

        let horasDif = this.Round(t1.getHours() + ((t1.getMinutes()*60 + t1.getSeconds())/3600),2);
        return horasDif;
    }

    Round (numero, decimales = 2, usarComa = false): number {
        var opciones = {
            maximumFractionDigits: decimales,
            useGrouping: false
        };
        return +new Intl.NumberFormat((usarComa ? "es" : "en"), opciones).format(numero);
    }

    // Metodo que calcula las horas totales que ha echado el empleado ese dia ( creo q es desde la ultima entrada a esta salida, verificarlo)
   calculoTotalHoras() {

        console.log('calculoTotalHoras:');
        console.log('hora_salida:');
        console.log(this.hora_salida);
        console.log('hora_entrada:');
        console.log(this.hora_entrada);

        this.horas_totales = this.difHoras(this.hora_entrada,this.hora_salida);
        console.log('horas totales:');
        console.log(this.horas_totales);
    }

    componerHorario() {
        // Introduciendo los valores al horario definitivo
        this.horario_update.code = this.empleado.code;
        this.horario_update.codigo_empresa = this.empleado.codigo_empresa;
        this.horario_update.fecha = this.fecha_salida;
        this.horario_update.hora_salida = this.hora_salida;
        this.horario_update.total_horas = this.horas_totales;
         // quitar ':' de la hora, los ficheros no lo admiten en su nombre
         if (this.webcamImage != null) {
            this.horario_update.foto_salida = this.fecha_salida.replace(new RegExp('-', 'g'), '') + '-' + this.hora_salida.replace(new RegExp(':', 'g'), '')  + '-SAL-' + this.codigo_empleado + '.jpg';
        }
        this.horario_update.estado = false;
        this.horario_update.incidencias_salida = this.tipo_incidencia;
        this.horario_update.incidencias_visto = this.incidencias_visto;
        this.horario_update.latitud_salida = this.latitud;
        this.horario_update.longitud_salida = this.longitud;
    }

    ponerIncidencia() {
        this.incidencia = true;
        this.incidencias_visto = true;
        console.log('Activada incidencia de empleado');
    }

    quitarIncidencia() {
        this.incidencia = false;
        //this.horario.incidencias_visto = false;
        this.incidencias_visto = false;
        // this.tipo_incidencia = 'Sin incidencia';
        this.tipo_incidencia = null;
        console.log('Desactivada incidencia de empleado');
    }

    tipoIncidencia(tipo: string) {
        this.tipo_incidencia = tipo;
        this.incidencia = false;
    }

    // Metodo que actualiza en la base de datos el campo work_now
    // Este campo indica si el trabajador esta trabajando en ese momento
    updateEstadoWorking() {

        this._empleadoService.updateEstadoWorking(this.empleado.code, false).subscribe(
            response => {

                if (response['code'] === 200) {
                    console.log('Campo work_now actualizado con exito');
                    // this._router.navigate(['/correct']);
                } else {
                    console.log('Campo work_now no se ha podido actualizar');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    saveFoto() {
        if (this.webcamImage == null) {
            console.log('No hay foto que subir');
            return;
        }
        let folder = GLOBAL.url_fotos_fichajes;
        this.foto.bytes = this.webcamImage.imageAsBase64;
        this._horarioService.uploadFotoFichaje(GLOBAL.codigo_empresa, folder, this.horario_update.foto_salida, this.foto).subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('Foto enviada con exito');
                } else {
                    console.log('La foto no se ha podido insertar');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    getHorariosEmpleado() {
        // cargamos los registros del empleado del dia actual
        this.suma_total_horas = 0;
        this.suma_total_horas_prevista = 0;
        let fultima_hora_entrada: any;
        let fultima_hora_salida: any;

        this._horarioService.getHorariosEmpleado(this.empleado.code, this.empleado.codigo_empresa).subscribe(
            response => {

                if (response['code'] === 200) {
                    this.horarios_empleado = response['data'];
                    // calculamos el total de horas del dia
                    for(let j=0;j<this.horarios_empleado.length;j++) {
                        this.suma_total_horas += +this.horarios_empleado[j].total_horas;
                    }
                    this.suma_total_horas = this.Round(this.suma_total_horas, 2);

                    // Calculo la previsión total de horas trabajadas del dia si fichara de salida en este momento
                    if ( this.horarios_empleado.length === 0 ) {
                        // es el primer fichaje del dia y lo hace de salida
                        this.suma_total_horas_prevista = this.suma_total_horas;
                    } else {

                        // existen fichajes
                        fultima_hora_entrada = this.horarios_empleado[this.horarios_empleado.length - 1].hora_entrada;
                        fultima_hora_salida = this.horarios_empleado[this.horarios_empleado.length - 1].hora_salida;
                        // this.idHorario = this.horario[this.horario.length - 1].id;
                        console.log('Ultimo registro encontrado:');
                        // console.log('Id: ');
                        // console.log(this.idHorario);
                        console.log('Ultima Hora entrada: ');
                        console.log(fultima_hora_entrada);
                        console.log('Ultima Hora salida: ');
                        console.log(fultima_hora_salida);
                        console.log('Hora actual: ');
                        console.log(this.hora_salida);

                        if (fultima_hora_salida != null) {
                            // fichajes de salida seguidos
                            this.suma_total_horas_prevista = this.suma_total_horas;

                        } else if (fultima_hora_entrada != null && fultima_hora_salida == null) {
                            // correcto, el ultimo fichaje es de entrada, puedo calcular la previsión de horas si ficho ahora
                            this.suma_total_horas_prevista = this.suma_total_horas + this.difHoras(fultima_hora_entrada, this.hora_salida);
                            this.suma_total_horas_prevista = this.Round(this.suma_total_horas_prevista, 2);
                        }

                    }

                    console.log('Horarios del empleado encontrados');
                } else {
                    console.log('Los horarios del empleado no se han podido conseguir');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    // Metodo para geolocalización
    getLocation() {

        if (+this.enable_localizacion == 1) {
        } else {
            console.log('Posicionamiento no buscado');
            this.geoLocationSupported = false; // para ocultar el icono que nos dice si se va a guardar o no
            return;
        }

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position: Position) => {
            if (position) {

                this.geoLocationSupported = true;
                console.log('Posicionamiento encontrado');

                if (+this.enable_localizacion == 1) { // es raro, pero asi es como funciona con las 4 combinaciones posibles
                    this.latitud = position.coords.latitude;
                    this.longitud = position.coords.longitude;
                    console.log('Posicionamiento asignado');
                } else {
                    console.log('Posicionamiento no asignado');
                    this.geoLocationSupported = false; // para ocultar el icono que nos dice si se va a guardar o no
                }

            }
          },
            (error: PositionError) => {
                this.geoLocationSupported = false;
                console.log('Posicionamiento no encontrado');
                console.log(error);
                /*
                switch(error.code) {
                    case error.PERMISSION_DENIED:
                      // "User denied the request for Geolocation."
                      break;
                    case error.POSITION_UNAVAILABLE:
                      // "Location information is unavailable."
                      break;
                    case error.TIMEOUT:
                      // "The request to get user location timed out."
                      break;
                    default:
                      // "An unknown error occurred."
                      break;
                }
                */
            });
        } else {
            this.geoLocationSupported = false;
            console.log('Geolocation is not supported by this browser');
            // alert('Geolocation is not supported by this browser.');
        }
    }

    getFechaActual() {

        this._horarioService.getHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                   // this.hora_foto = response['data'];
                   this.hora_salida = response['data'];
                   console.log('Hora actual recuperada: ' + response['data']);
                } else {
                   console.log('No se ha podido conseguir la hora actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('fecha actual recuperada: ' + response['data']);
                    this.fecha_salida = response['data'];
                } else {
                    console.log('No se ha podido conseguir la fecha actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    getFechayHoraActual() {

        this._horarioService.getFechayHoraActual().subscribe(
            response => {
                if (response['code'] === 200) {
                    console.log('fecha y hora actual recuperada: ' + response['data']);
                    this.fecha = response['data'];

                    /* para compatibilidad con safari */
                    let fFecha: Date;
                    // asi suma una hora ¿¿¿????
                    // fFecha = new Date(String(this.fecha).replace(/\s/, 'T')); // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
                    let aFecha = String(this.fecha).split(/[^0-9]/);
                    fFecha = new Date (+aFecha[0], +aFecha[1] - 1, +aFecha[2], +aFecha[3], +aFecha[4], +aFecha[5] );

                    this.fecha = fFecha;

                } else {
                    console.log('No se ha podido conseguir la fecha y hora actual');
                }
            },
            error => {
                console.log(error as any);
            }
        );

    }

    // La variable consultaHorario es la que hace que se vea o no el Bloque div
    muestraHorario(empleado, consultaHorario) {
        this.consultaHorario = consultaHorario;
    }

    // Metodos para la base de datos interna

    // Crear la base de datos Salida
    abrirBD() {
        console.log('Abriendo BD INTERNA Salida');
        this.bd.openDatabase(1 , evt => {

            let objectStore = evt.currentTarget.result.createObjectStore('Salida', { keyPath: 'id', autoIncrement: true });

            objectStore.createIndex('code', 'code', { unique: false });
            objectStore.createIndex('codigo_empresa', 'ecodigo_empresamail', { unique: false });
            objectStore.createIndex('fecha', 'fecha', { unique: false });
            objectStore.createIndex('hora_salida', 'hora_salida', { unique: false });
            objectStore.createIndex('total_horas', 'total_horas', { unique: false });
            objectStore.createIndex('foto_salida', 'foto_salida', { unique: false });
            objectStore.createIndex('estado', 'estado', { unique: false });
            objectStore.createIndex('incidencias_salida', 'incidencias_salida', { unique: false });
            objectStore.createIndex('incidencias_visto', 'incidencias_visto', { unique: false });
            objectStore.createIndex('latitud_salida', 'latitud_salida', { unique: false });
            objectStore.createIndex('longitud_salida', 'longitud_salida', { unique: false });
            console.log('Abierta BD INTERNA Salida');
        });
    }

    // Añadir elementos a la base de datos salida
    addElemetosBD(
        code,
        codigo_empresa,
        fecha,
        hora_salida,
        total_horas,
        foto_salida,
        estado,
        incidencias_salida,
        incidencias_visto,
        latitud_salida,
        longitud_salida
    ) {
        this.abrirBD();
        this.bd.add('Salida', {
            code : code,
            codigo_empresa : codigo_empresa,
            fecha : fecha,
            hora_salida : hora_salida,
            total_horas : total_horas,
            foto_salida : foto_salida,
            estado : estado,
            incidencias_salida : incidencias_salida,
            incidencias_visto : incidencias_visto,
            latitud_salida : latitud_salida,
            longitud_salida : longitud_salida
        }).then( () => {
            console.log('Añadido con exito a la BD INTERNA Salida');
        },
        error => {
            console.log('Error addElemetosBD Salida: ', error);
        });
    }

    // Leer base de datos salida
    leerBD() {
        console.log('Leyendo la BD INTERNA Salida...');
        this.abrirBD();
        this.bd.getAll('Salida').then(
          response => {
            console.log('Base de datos interna Salida leida');
          },
          error => {
            console.log('Error al leer la BD INTERNA Salida: ' , error);
          }
        );
    }

    abrirBDEntrada() {
        console.log('Abriendo BD INTERNA Entrada');
        this.bd1.openDatabase(1 , evt => {

            let objectStore = evt.currentTarget.result.createObjectStore('Entrada', { keyPath: 'id', autoIncrement: true });

            objectStore.createIndex('code', 'code', { unique: false });
            objectStore.createIndex('codigo_empresa', 'ecodigo_empresamail', { unique: false });
            objectStore.createIndex('foto', 'foto', { unique: false });
            objectStore.createIndex('hora_entrada', 'fohora_entradato', { unique: false });
            objectStore.createIndex('fecha', 'fecha', { unique: false });
            objectStore.createIndex('estado', 'estado', { unique: false });
            objectStore.createIndex('incidencias', 'incidencias', { unique: false });
            objectStore.createIndex('latitud_entrada', 'latitud_entrada', { unique: false });
            objectStore.createIndex('longitud_entrada', 'longitud_entrada', { unique: false });
            console.log('Abierta BD INTERNA Entrada');
        });
      }

}
