import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SolicitudVacaciones } from 'src/app/models/solicitudVacaciones';
import { GLOBAL } from 'src/app/services/global.service';
import { SolicitudVacacionesService } from 'src/app/services/solicitud-vacaciones.service';
import { SolicitudesComponent } from '../solicitudes/solicitudes.component';


@Component({
  selector: 'app-solicitud-vacaciones',
  templateUrl: './solicitud-vacaciones.component.html',
  styleUrls: ['./solicitud-vacaciones.component.css']
})
export class SolicitudVacacionesComponent implements OnInit {
  @ViewChild('fecha_desde') input_desde: ElementRef;
  @ViewChild('fecha_hasta') input_hasta: ElementRef;
  @ViewChild('solicitudesComponent') solicitudesComponent: SolicitudesComponent;


  fecha_inicial: String = "";
  fecha_final: String = "";

  constructor(
    protected _toastr: ToastrService,
    protected _vacacionesService: SolicitudVacacionesService,
  ) { }

  ngOnInit() {

  }

  onDateSelected() { // VALIDAR EL INPUT DE LAS FECHASS
    this.fecha_inicial = this.input_desde.nativeElement.value
    this.fecha_final = this.input_hasta.nativeElement.value
    if (this.fecha_final && this.fecha_inicial) {
      if (this.isValidDate(this.fecha_inicial) && this.isValidDate(this.fecha_final)) {
        try {
          let persona = JSON.parse(localStorage.getItem('identity'))['code'];
          let vacaciones: SolicitudVacaciones = new SolicitudVacaciones(
            GLOBAL.code,
            new Date(this.fecha_inicial.toString()),
            new Date(this.fecha_final.toString()));
          //########### HACIENDO PETICION PARA SOLICITAR VACACIONES ################
          this._vacacionesService.pedirVacaciones(vacaciones, persona).subscribe(
            response => {
              console.log(response)
              this._toastr.success(response["message"]);
              this.solicitudesComponent.refrescar()
            }, error => {
              this._toastr.error("Ha ocurrido un error durante la solicitud");
              console.log(error)
            }
          );
        } catch (error) {
          this._toastr.error('"El formato de las fechas no ha sido valido"');
          console.log("Error en el formato de las fechas")
        }
      }
    }
    else {
      this._toastr.warning("Los dos rangos de fechas deben estar seleccionados");
    }

  }

  isValidDate(_date: String) {
    try {
      _date = _date.replace(/-/, "").replace(/\D/, "");
      if (_date.length === 8) {
        let jsdate = new Date(_date.slice(0, 4) + '-' + (("0" + _date.slice(4, 6)).toString()).slice(-2) + '-' + (("0" + _date.slice(6, 8)).toString()).slice(-2)).toISOString();
        console.log('validateDateYYYYMMDD', jsdate.slice(0, 10));
        let month: string = jsdate.slice(0, 10).match(/\d{4}-(\d{2})-\d{2}/)![1];
        return parseInt(month) === parseInt((("0" + _date.slice(4, 6)).toString()).slice(-2));
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

}