import { Component, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { IncidenciasService } from '../../services/incidencias.service';
import { Incidencia } from '../../models/incidencias';
import { Usuario } from '../../models/usuario';
import { EmpleadoService } from '../../services/empleado.service';
import { UsuarioService } from '../../services/usuario.service';
import { HorarioService } from '../../services/horario.service';
import { Horario } from 'src/app/models/horario';
import { FichajeDia } from 'src/app/models/fichaje-dia';
import { Empleado } from 'src/app/models/empleado';
import { FichajeBusqueda } from '../../models/fichaje-busqueda';
import { GLOBAL } from '../../services/global.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { formatDate } from '@angular/common';
import { AgmMap } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { ReportesService } from '../../services/reportes.service';
import { DomSanitizer } from '@angular/platform-browser'; // para mostrar la foto en src cargada por api
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'fichajedia',
    templateUrl: './fichajedia.component.html',
    styleUrls : ['./fichajedia.component.css', '../../../style.scss'],
    providers: [ EmpleadoService, UsuarioService, IncidenciasService, ReportesService, HorarioService, DepartamentosService],
    animations: [
        trigger(
          'enterAnimation', [
            transition(':enter', [
              style({transform: 'translateX(100%)', opacity: 0}),
              animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
            ]),
            transition(':leave', [
              style({transform: 'translateX(0)', opacity: 1}),
              animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
            ])
          ]
        )
    ],
})

export class FichajediaComponent {
    //public primaryColor: string;
    //public secondaryColor: string;
    public titulo: string;
    public mostrar_filtro: boolean;
    public filtro: number;
    public vec_fichajes: Array<FichajeDia>;
    public fichajeUpdate: FichajeDia;
    public fichajeInsert: Horario;
    // public fecha: Date;
    public codigo_empresa: string;
    public lat: number;
    public lng: number;
    public zoom: number;
    public global_url_fotos_fichajes;
    public globaladmin;
    public photoLoaded;
    public url_foto: string;
    public url_foto_titulo: string;
    public url_foto_es: string; // 'E' ó 'S'
    public mostrar_eliminar: boolean;
    public url_foto_fichero;
    public url_foto_id: number;
    public url_foto_cabecera: string;
    public url_foto_pie: string;
    public mostrar_modificar: boolean;
    public suma_total_horas: number;

    public lista_departamentos: Array<Departamentos>;
    public lista_usuarios: Array<Usuario>;
    public lista_incidencias: Array<Incidencia>;
    public fecha_actual;

    public licencias_superadas;

    // parametros para la llamada desde menu inicial
    @Input() iCode;
    @Input() iFecha;
    @Input() iFechaHasta;
    @Input() idisabledCode;
    @Input() iOrigen;

    //Variables de busqueda y parametro para la llamada desde el menu admin->avisos
    public filterUsuario = '';
    public filterNombre = '';
    public filterDepartamento = '';
    public filterFecha: any;
    public filterFechaHasta: any;
    public informe_fichajes_resumen: boolean; // prevalece sobre el detallado
    public informe_fichajes_detallado: boolean;

    //Variables de modificacion
    public idModificar: number;
    public modificarHora_entrada_antes: any;
    public modificarHora_salida_antes: any;
    public modificarHora_entrada: any;
    public modificarHora_salida: any;
    public hora_entrada_updated_by_antes: any;
    public hora_entrada_updated_at_antes: any;
    public hora_salida_updated_by_antes: any;
    public hora_salida_updated_at_antes: any;
    public modificarHoras_trabajadas: any;
    public modificarFecha: Date;
    public incidencia_entradaModificar: string;
    public incidencia_salidaModificar: string;
    public codeModificar: string;
    public nombreModificar: string;
    public apellidosModificar: string;
    public msgErrorModificar: string;
    public msgModificar: string;
    public saveORcancel: boolean = false;
    public disabledFecha: boolean = true;
    public disabledHoraEntrada: boolean = true;
    public disabledHoraSalida: boolean = true;
    public confirmado;
    public disabledCode: boolean = false;
    public fentrada:Date;
    public fsalida:Date;
    public empleado:Empleado;
    @ViewChild('AgmMap')
    public AgmMap: AgmMap;
    public disabledC:boolean;

    public loading: boolean = false; // cargando lista..
    public cargandoPDF: boolean;
    public msgLista: string = '';

    // declaro como array de boolean en lugar de boolean, pero solo uso [0]. explicacion en la funcion
    public empresa_correccion_por_empleado: boolean;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private datePipe: DatePipe,
        protected toastr: ToastrService,
        private domSanitizer: DomSanitizer,
        private _empleadoService: EmpleadoService,
        private _reportesService: ReportesService,
        private _usuarioService: UsuarioService,
        private _incidenciaService: IncidenciasService,
        private _departamentosService: DepartamentosService,
        private _horarioService: HorarioService){
        this.titulo = "Página de fichajes del dia"
        this.vec_fichajes = [];
        this.fichajeUpdate = new FichajeDia(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
        this.mostrar_modificar = false;
        this.global_url_fotos_fichajes = null;
        this.url_foto = null;
        this.photoLoaded = null;
        this.url_foto_id = 0;
        this.url_foto_es = '';
        this.mostrar_eliminar = false;
        this.url_foto_fichero = '';
        this.url_foto_titulo = '';
        this.url_foto_cabecera = '';
        this.url_foto_pie = '';
        this.mostrar_filtro = true;
        this.filtro = 0;
        this.lista_departamentos = null;
        this.lista_usuarios = null;
        this.lista_incidencias = null;
        this.confirmado = null;
        this.disabledCode = false;
        this.filterFecha = '';
        this.filterFechaHasta = '';
        this.suma_total_horas = 0;
        this.incidencia_entradaModificar = '';
        this.incidencia_salidaModificar = '';
        this.globaladmin = GLOBAL.esAdmin;
        this.cargandoPDF = false;
        this.informe_fichajes_resumen = false;
        this.informe_fichajes_detallado = true;
        this.disabledC = false;

        this.licencias_superadas = GLOBAL.licencias_superadas;
                


        //this.changeTheme('red', 'yellow'); // Set default theme
    }

    /*changeTheme(primary: string, secondary: string) {
        document.documentElement.style.setProperty('--primary-color', primary);
        document.documentElement.style.setProperty('--secondary-color', secondary);
    }*/

    ngOnInit(){

        console.log('GLOBAL.config_correccion_por_empleado: ' + GLOBAL.config_correccion_por_empleado); // guarda true / false
        
        this.empresa_correccion_por_empleado = this.getBoolean( GLOBAL.config_correccion_por_empleado[0] );
        console.log('this.empresa_correccion_por_empleado: ' + this.empresa_correccion_por_empleado); // guarda true / false
        
        this.globaladmin;
        this.resizeMap();
        this._route.params.forEach((params:Params) =>{

            //if (params['codigo_empresa'] != undefined) {
            //    this.codigo_empresa = params['codigo_empresa'];
            //} else {
                this.codigo_empresa = GLOBAL.codigo_empresa;
            //}

            this.list_departamentos();
            this.list_usuarios();
            this.list_incidencias();

            if (params['codigo_empresa'] != undefined) {
                // para las llamadas desde el menu admin
                console.log('Parámetros de entrada navigate a la página:');
                console.log(params['codigo_empresa']);
                console.log(params['code']);
                console.log(params['fecha']);
                console.log(params['disabledCode']);
                console.log(params['origen']);

                if (params['code'] != undefined) {
                    this.filterUsuario = params['code'];
                }

                if (params['disabledCode'] != undefined) {
                    // this.disabledCode = params['disabledCode'];
                    if (params['disabledCode'] === 'true') {
                        this.disabledCode = true;
                    } else {
                        this.disabledCode = false;
                    }
                }
                
                console.log('DISABLEDCODE: ');
                console.log(this.disabledCode);

                if (params['fecha'] != undefined) {
                    this.filterFecha = params['fecha'];
                    this.filterFechaHasta = params['fecha'];
                    this.cargarFichajes();
                    this.fechaActual(); // necesario para las correcciones de fichajes
                } else {
                    this.fechaActualInicial(); // y carga los fichajes
                }

            } else {
                // para las llamadas desde el menu inicial

                console.log('Parámetros de entrada html a la página:');
                // console.log(params['codigo_empresa']);
                console.log(this.iCode);
                console.log(this.iFecha);
                console.log(this.iFechaHasta);
                console.log(this.idisabledCode);
                console.log(this.iOrigen);

                if (this.iCode !== undefined) {
                    this.filterUsuario = this.iCode;
                }

                if (this.idisabledCode !== undefined) {
                    this.disabledCode = this.idisabledCode;
                }

                if (this.iFechaHasta !== undefined) {
                    this.filterFechaHasta = this.iFechaHasta;
                }

                if (this.iFecha !== undefined) {
                    this.filterFecha = this.iFecha;
                    this.cargarFichajes();
                    this.fechaActual();  // necesario para las correcciones de fichajes
                } else {
                    this.fechaActualInicial();  // y carga los fichajes
                }

            }

        });

        this.loading = false;

        this.global_url_fotos_fichajes = GLOBAL.url_uploads + '/' + this.codigo_empresa + '/' + GLOBAL.url_fotos_fichajes;
        this.msgErrorModificar = '';

        setTimeout(() =>
        {
            // this.cargarFichajes();
            // this.list_departamentos();
        },
        100);

    }

    getBoolean(value) {
        switch (value) {
             case true:
             case "true":
             case 1:
             case "1":
             case "on":
             case "yes":
                 return true;
             default:
                 return false;
        }
    }

    resizeMap() {
        this.AgmMap.triggerResize();
    }

    //Activa o desactiva el div que muestra los filtros de busqueda
    activeFiltrar(index){
        //Reseteamos el formulario
        if(this.saveORcancel){
            this.saveORcancel = false;
        }
        if(index == 2) { // crear fichaje
            this.idModificar = null;
            this.filtro = 2;
        } else if (index == 1) { // filtros de busqueda
            this.mostrar_modificar = false;
            if (this.filtro == 1) {
                this.filtro = 0; // ya se veía el filtro, lo oculto....
            } else {
                this.filtro = 1;
            }
            // console.log(this.filtro + ' ' + index);
        } else if(index == 0) {
            this.idModificar == 0;
            this.filtro = 0;
        }
        else {
            // console.log(this.filtro + ' ' + index);
        }




        /*if (this.mostrar_filtro == true){
            this.mostrar_filtro = false;
            this.filtro = index;
        } else {
            this.mostrar_filtro = true;
            this.filtro = 0;
        }*/
    }

    active() {
        if(this.filtro == 0) {
            this.filtro = 0;
        }
                
    }

    deleteLocalizacion() {
        console.log('Eliminar localizacion: ' + this.url_foto_id + ' ' + this.url_foto_es);
        //Eliminamos foto
        this._horarioService.deleteLocalizacion(this.url_foto_id, this.url_foto_es).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Localizacion eliminada con exito');
                    this.toastr.success('La Localización se ha eliminado', 'Eliminar Localización');
                    //Recargamos la vista
                    this.cargarFichajes();

                } else {
                    console.log('La Localización no se ha podido eliminar');
                    this.toastr.error('Error, La Localización no se ha eliminado', 'Eliminar Localización');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
                this.toastr.error('Error, La Localización no se ha eliminado', 'Eliminar Localización');
            }
        );
    }

    //Metodo que elimina una foto
    deleteFoto() {
        console.log('Eliminar foto: ' + this.url_foto_id + ' ' + this.url_foto_es + ' ' + this.codigo_empresa + ' ' + this.url_foto_fichero);
        //Eliminamos foto
        this._horarioService.deleteFoto(this.url_foto_id, this.url_foto_es, this.codigo_empresa, this.url_foto_fichero).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Foto eliminada con exito');
                    this.toastr.success('La foto se ha eliminado', 'Eliminar foto');
                    //Recargamos la vista
                    this.cargarFichajes();

                } else {
                    console.log('La foto no se ha podido eliminar');
                    this.toastr.error('Error, La foto no se ha eliminado', 'Eliminar foto');
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
                this.toastr.error('Error, La foto no se ha eliminado', 'Eliminar foto');
            }
        );

    }

    active_modificar(id, code, nombre, apellidos, entrada, salida, total_horas, fecha, incidencias, incidencias_salida, hora_entrada_updated_by, hora_entrada_updated_at, hora_salida_updated_by, hora_salida_updated_at){
        console.log(this.vec_fichajes);
        this.disabledC = false;
        if(this.saveORcancel){            
            this.saveORcancel = false;
        }
        //console.log(this.empleado.incidencias_salida);
        this.filtro = 0;
        if (this.mostrar_modificar == false) {
            this.mostrar_modificar = true;
            // window.scrollTo(0, 0);
            scroll(0, 0);

            this.idModificar = id; // llega null si estamos añadiendo
            this.codeModificar = code;
            this.nombreModificar = nombre;
            this.apellidosModificar = apellidos;
            this.modificarFecha = fecha;
            this.modificarHora_entrada = entrada;
            this.modificarHora_salida = salida;
            this.modificarHoras_trabajadas = total_horas;
            this.incidencia_entradaModificar = incidencias;
            this.incidencia_salidaModificar = incidencias_salida;

            if (this.incidencia_entradaModificar == null) { this.incidencia_entradaModificar = ''; }
            if (this.incidencia_salidaModificar == null) { this.incidencia_salidaModificar = ''; }

            // para cuando modificamos
            this.hora_entrada_updated_by_antes = hora_entrada_updated_by;
            this.hora_entrada_updated_at_antes = hora_entrada_updated_at;
            this.hora_salida_updated_by_antes = hora_salida_updated_by;
            this.hora_salida_updated_at_antes = hora_salida_updated_at;

            if (this.idModificar == null) { // crear
                this.disabledFecha = false;
                if (this.disabledCode) {
                    this.codeModificar = this.filterUsuario;
                }
            } else {                        // modificar
                this.disabledFecha = true;
            }

            this.disabledHoraEntrada = false;
            this.disabledHoraSalida = false;

            // para controlar que valores han cambiado
            this.modificarHora_entrada_antes = this.modificarHora_entrada;
            this.modificarHora_salida_antes = this.modificarHora_salida;

        } else {
            this.mostrar_modificar = false;
        }

    }

    //Metodo que se trae todos los departamentos de la base de datos
    list_departamentos(){
        console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
        this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Departamentos conseguidos con exito')
                    this.lista_departamentos = response['data'];
                    console.log(this.lista_departamentos);

                } else {
                    console.log("Los departamentos no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que se trae todos los usuarios de la base de datos
    list_usuarios(){

        // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
        if ( GLOBAL.rol !== "admin" ) {

            let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
            this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
            this.lista_usuarios.push(user);

        } else {

            console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
            this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
                response => {
                    if (response['code'] == 200){
                        console.log(response);
                        console.log('Usuarios conseguidos con exito')
                        this.lista_usuarios = response['data'];
                        console.log(this.lista_usuarios);

                    } else {
                        console.log("Los usuarios no se han podido conseguir");
                    }
                }, error => {
                    console.log('Ha sucedido un error');
                    console.log(<any>error);
                }
            );

        }

    }

    //Metodo que se trae todos los tipos de incidencias de la base de datos
    list_incidencias(){
        console.log('Buscar tipos de incidencias de la empresa: ' + this.codigo_empresa);
        this._incidenciaService.getIncidencias(this.codigo_empresa, '1').subscribe( // 1: incluye las generales, no solo las de la empresa
            response => {
                console.log(response);
                if (response['code'] == 200){
                    console.log('Tipos de incidencias conseguidos con exito')
                    this.lista_incidencias = response['data'];
                    console.log(this.lista_incidencias);

                } else {
                    console.log("Los tipos de incidencias no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que limpia el valor de las variables de busqueda
    nullerValues(index){

        console.log("Llamando metodo nullerValues");

        if (index == 1) {
            if (!this.disabledCode) { // si tenemos code bloqueado, no permitimos quitar el filtro ( para correcciones desde el usuario, no desde el admin)
                this.filterUsuario = '';
                this.cargarFichajes();
            }
        }
        else if (index == 2) {
            this.filterFecha = '';
            this.cargarFichajes();
        }
        else if (index == 3) {
            this.filterFechaHasta = '';
            this.cargarFichajes();
        }
        else if (index == 4) {
            this.filterNombre = '';
            this.cargarFichajes();
        }
        else if (index == 5) {
            this.filterDepartamento = '';
            this.cargarFichajes();
        }

    }

    borrarConfirm(id){
        this.confirmado = id;
    }

    cancelarConfirm(){
        this.confirmado = null;
    }

    pierdeFoco($event) {
        // console.log('pierdeFoco: ');
        // console.log($event, $event.target, $event.currentTarget);
        this.cargarFichajes();
    }

    updateFilter($event): void {
        // console.log('introPulsado');
        this.cargarFichajes();
    }

    teclaPulsada($event): void {
        // console.log('teclaPulsada: ');
        // console.log($event.target.value);
    }

    cancelarModificarFichaje()
    {
        this.mostrar_modificar = false;
    }

    modificarFichaje() {
        if (this.idModificar != null) { console.log('Modificar fichaje:'); }
        else { console.log('Crear fichaje:'); }
        console.log('id: ' + this.idModificar);
        console.log('code: ' + this.codeModificar);
        console.log('Fecha: ' + this.modificarFecha);
        console.log('Hora entrada: ' + this.modificarHora_entrada);
        console.log('Hora salida: ' + this.modificarHora_salida);
        console.log('Incidencia entrada: ' + this.incidencia_entradaModificar);
        console.log('Incidencia salida: ' + this.incidencia_salidaModificar);
        console.log('hora_entrada_updated_by_antes: ' + this.hora_entrada_updated_by_antes);
        console.log('hora_entrada_updated_at_antes: ' + this.hora_entrada_updated_at_antes);
        console.log('hora_salida_updated_by_antes: ' + this.hora_salida_updated_by_antes);
        console.log('hora_salidada_updated_at_antes: ' + this.hora_salida_updated_at_antes);

        // VALIDAMOS el usuario
        if (this.codeModificar == null) { 
            this.msgError('Usuario no válido');
            return;
        }

        // VALIDAMOS incidencias
        if (this.incidencia_entradaModificar === undefined) { this.incidencia_entradaModificar = ''; }
        if (this.incidencia_salidaModificar === undefined) { this.incidencia_salidaModificar = ''; }

        // VALIDAMOS la fecha
        if (this.modificarFecha == null) { 
            this.msgError('Fecha no válida');
            return;
        } else {
            let factual = new Date(this.fecha_actual);
            let fcrearmodificar = new Date(this.modificarFecha);
            console.log('Fecha actual: ' + factual);
            console.log('Fecha a crear/modificar: ' + fcrearmodificar);
            if (fcrearmodificar >= factual) {
                this.msgError('La fecha del fichaje debe ser anterior a hoy');
                return;
            }
        }

        // VALIDAMOS las horas
        let amodificarHora_entrada: string[];
        let amodificarHora_salida: string[];

        if (this.modificarHora_entrada == null) { this.msgError('Hora de entrada no válida'); }
        else if (this.modificarHora_salida == null) { this.msgError('Hora de salida no válida'); }
        else {

            // hora de salida > hora de entrada?
            // en safari espera la fecha en formato: 2017-11-14T20:00:00 no 2017-11-14 20:00:00
            // new Date("<?php echo (new DateTime())->format('D M j G:i:s'); ?>")

            // String are sometimes parsed as UTC and sometimes as localtime (based on browser vendor and version).
            // The best practice should always be to store dates as UTC and make computations as UTC.
            // To parse a date as UTC, append a Z - e.g.: new Date('2011-04-11T10:20:30Z').
            // To display a date in UTC, use .toUTCString(),
            // to display a date in user's local time, use .toString().
            // For old Internet Explorer compatibility (IE versions less than 9 do not support ISO format in Date constructor),
            // you should split datetime string representation to it's parts and then you can use constructor using datetime parts,
            // e.g.: new Date('2011', '04' - 1, '11', '11', '51', '00'). Note that the number of the month must be 1 less.

            // check entrada manual de hora ( Safari )
            amodificarHora_entrada = this.modificarHora_entrada.split(':');
            if (amodificarHora_entrada.length > 0) {
               this.modificarHora_entrada = this.fillLeftString(amodificarHora_entrada[0],2,'0');
            }
            if (amodificarHora_entrada.length > 1) {
                this.modificarHora_entrada = this.modificarHora_entrada + ':' + this.fillLeftString(amodificarHora_entrada[1],2,'0');
             }
             if (amodificarHora_entrada.length > 2) {
                this.modificarHora_entrada = this.modificarHora_entrada + ':' + this.fillLeftString(amodificarHora_entrada[2],2,'0');
             }

             amodificarHora_salida = this.modificarHora_salida.split(':');
             if (amodificarHora_salida.length > 0) {
                this.modificarHora_salida = this.fillLeftString(amodificarHora_salida[0],2,'0');
             }
             if (amodificarHora_salida.length > 1) {
                 this.modificarHora_salida = this.modificarHora_salida + ':' + this.fillLeftString(amodificarHora_salida[1],2,'0');
              }
              if (amodificarHora_salida.length > 2) {
                 this.modificarHora_salida = this.modificarHora_salida + ':' + this.fillLeftString(amodificarHora_salida[2],2,'0');
              }

            if (this.modificarHora_entrada.length === 5) { this.modificarHora_entrada = this.modificarHora_entrada + ':00'; }
            if (this.modificarHora_salida.length === 5) { this.modificarHora_salida = this.modificarHora_salida + ':00'; }

            let str_entrada:string = this.fecha_actual + ' ' + String(this.modificarHora_entrada);
            console.log('Crear fecha de entrada: ');
            console.log(str_entrada);
            // let fentrada:Date = new Date(str_entrada);                       // no funciona en safari !!!
            this.fentrada = new Date(str_entrada.replace(/\s/, 'T'));

            // let t1:string[] = str_entrada.split(/[- :]/);
            // let fentrada:Date = new Date(+t1[0], +t1[1]-1, +t1[2], +t1[3]);
            // let fentrada:Date = this.stringToDateForIos(str_entrada);

            let str_salida:string = this.fecha_actual + ' ' + String(this.modificarHora_salida);
            console.log('Crear fecha de salida: ');
            console.log(str_salida);
            // let fsalida:Date = new Date(str_salida);                         // no funciona en safari !!!
            this.fsalida = new Date(str_salida.replace(/\s/, 'T'));

            // let t2:string[] = str_salida.split(/[- :]/);
            // let fsalida:Date = new Date(+t2[0], +t2[1]-1, +t2[2], +t2[3]);
            // let fsalida:Date = this.stringToDateForIos(str_salida);

            console.log('Comparar E/S:');
            console.log('Entrada:');
            console.log(this.fentrada);
            console.log('Salida:');
            console.log(this.fsalida);

            if (this.fsalida > this.fentrada) {
                console.log('FECHAS VALIDAS');
                // calculamos las horas trabajadas
                let horas_totales = this.difHoras(this.modificarHora_entrada, this.modificarHora_salida);
                console.log('Horas trabajadas: ' + horas_totales);
                this.modificarHoras_trabajadas = horas_totales;
                this.saveORcancel = true;
                this.disabledFecha = true;
                this.disabledHoraEntrada = true;
                this.disabledHoraSalida = true;
            } else {
                this.msgError('Hora de Salida debe ser posterior a la de Entrada');
            }

        }

    }

    stringToDateForIos(sdate) { // pruebas
        let sad:string[] = sdate.split(/[- :]/);
        let fdate:Date = new Date(+sad[0], +sad[1]-1, +sad[2], +sad[3]);
        return fdate;
    }

    zfillNUmber(number, width) {
        let numberOutput = Math.abs(number); /* Valor absoluto del número */
        let length = number.toString().length; /* Largo del número */
        let zero = "0"; /* String de cero */

        if (width <= length) {
            if (number < 0) {
                 return ("-" + numberOutput.toString()); 
            } else {
                 return numberOutput.toString(); 
            }
        } else {
            if (number < 0) {
                return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
            } else {
                return ((zero.repeat(width - length)) + numberOutput.toString()); 
            }
        }
    }

    fillLeftString(cadena, longitud, caracter)
    {
        //Siendo "cadena" el texto a completar
        //"longitud" el largo de la cadena deseada
        //"caracter" el símbolo con el cual se llenarán los espacios faltantes
        return caracter.repeat(longitud - String(cadena).length).concat(cadena);
    }

    save() { // para crear o modificar
        console.log('save');
        this.saveORcancel = false;
        this.modificarFichaje();
        this.saveORcancel = false;
        this.componerFichaje();

        if (this.idModificar != null) { // modificar

            this.fichajeUpdate.incidencias = this.incidencia_entradaModificar;
            this.fichajeUpdate.incidencias_salida = this.incidencia_salidaModificar;

            console.log('Modificar Horario: ');
            console.log(this.idModificar);
            console.log(this.fichajeUpdate);

            this._horarioService.updateHorasHorario(this.idModificar, this.fichajeUpdate).subscribe(
                response => {
                    console.log(response);
                    if (response['code'] == 200){
                        console.log("Horario actualizado");
                        console.log(response);
                        this.msgModificado('Horario actualizado');
                    } else {
                        if (response["messageModificacion"]) {
                            this.toastr.warning( response["messageModificacion"])
                        }
                        console.log("Error: horario no actualizado");
                        this.msgError('Error: horario no actualizado');
                    }
                }, error => {
                    console.log('Ha sucedido un error');
                    console.log(<any>error);
                    this.msgError('Error: horario no actualizado');
                }
            );
        }

        if (this.idModificar == null) { // crear
            this.fichajeInsert = new Horario(0, '', '', null, null, null, null, 0, null, true, null, null, null, false, 0, 0, 0, 0, null, null);
            this.fichajeInsert.code = this.fichajeUpdate.code;
            this.fichajeInsert.codigo_empresa = this.fichajeUpdate.codigo_empresa;
            this.fichajeInsert.estado = false;
            this.fichajeInsert.fecha = this.fichajeUpdate.fecha;
            this.fichajeInsert.hora_entrada = this.fichajeUpdate.hora_entrada;
            this.fichajeInsert.hora_salida = this.fichajeUpdate.hora_salida;
            this.fichajeInsert.hora_entrada_updated_by = this.fichajeUpdate.hora_entrada_updated_by;
            this.fichajeInsert.hora_salida_updated_by = this.fichajeUpdate.hora_salida_updated_by;
            this.fichajeInsert.total_horas = this.fichajeUpdate.total_horas;
            this.fichajeInsert.incidencias = this.incidencia_entradaModificar;
            this.fichajeInsert.incidencias_salida = this.incidencia_salidaModificar;

            if (this.incidencia_entradaModificar && this.incidencia_entradaModificar.length == 0) { this.incidencia_entradaModificar = null; }
            if (this.incidencia_salidaModificar && this.incidencia_salidaModificar.length == 0) { this.incidencia_salidaModificar = null; }

            if ( (this.fichajeInsert.incidencias != null && this.fichajeInsert.incidencias !== '') ||
                 (this.fichajeInsert.incidencias_salida != null && this.fichajeInsert.incidencias_salida !== '') )
            {
                this.fichajeInsert.incidencias_visto = true;
            } else {
                this.fichajeInsert.incidencias_visto = false;
            }

            console.log('Añadir Horario: ');
            console.log(this.fichajeInsert);
            this._horarioService.addHorario(this.fichajeInsert).subscribe(
                response => {
                    console.log(response);
                    if (response['code'] == 200){
                        console.log("Fichaje creado");
                        console.log(response);
                        this.msgModificado('Fichaje creado correctamente');
                    } else {
                        if (response["messageModificacion"]) {
                            this.toastr.warning( response["messageModificacion"])
                        }
                        console.log("Error: fichaje no creado");
                        this.msgError('Error: Fichaje no creado');
                    }
                }, error => {
                    console.log('Ha sucedido un error');
                    console.log(<any>error);
                    this.msgError('Error: fichaje no creado');
                }
            );
        }

        this.disabledC = true;

    }

    componerFichaje() {

        this.fichajeUpdate = new FichajeDia(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
        this.fichajeUpdate.codigo_empresa =  this.codigo_empresa;
        this.fichajeUpdate.code =  this.codeModificar;
        this.fichajeUpdate.fecha = this.modificarFecha;
        this.fichajeUpdate.hora_entrada = this.modificarHora_entrada;
        this.fichajeUpdate.hora_salida = this.modificarHora_salida;
        this.fichajeUpdate.total_horas = this.modificarHoras_trabajadas;

        this.fichajeUpdate.hora_entrada_updated_by = this.hora_entrada_updated_by_antes;
        this.fichajeUpdate.hora_entrada_updated_at = this.hora_entrada_updated_at_antes;
        this.fichajeUpdate.hora_salida_updated_by = this.hora_salida_updated_by_antes;
        this.fichajeUpdate.hora_salida_updated_at = this.hora_salida_updated_at_antes;

        if (this.modificarHora_entrada_antes !== this.modificarHora_entrada) {
            console.log('se ha cambiado la hora de entrada');
            this.fichajeUpdate.hora_entrada_updated_by = GLOBAL.code;
            this.fichajeUpdate.hora_entrada_updated_at = new Date('1970-12-11'); // en la api esta fecha me indicará q se ha cambiado la hora de entrada
            // this.fichajeUpdate.hora_entrada_updated_at = moment('1970-12-11', 'YYYY/MM/DD').toDate();
            // this.fichajeUpdate.hora_entrada_updated_at = this.datePipe.transform(fFecha, 'dd/MM/yyyy  H:mm');
        } else {
            console.log('no se ha cambiado la hora de entrada');
        }

        if (this.modificarHora_salida_antes !== this.modificarHora_salida) {
            console.log('se ha cambiado la hora de salida');
            this.fichajeUpdate.hora_salida_updated_by = GLOBAL.code;
            this.fichajeUpdate.hora_salida_updated_at = new Date('1970-12-11'); // en la api esta fecha me indicará q se ha cambiado la hora de salida
            // this.fichajeUpdate.hora_salida_updated_at = moment('1970-12-11', 'YYYY/MM/DD').toDate();
        } else {
            console.log('no se ha cambiado la hora de salida');
        }
    }

    cancelSave() {
        console.log('cancelSave');
        this.saveORcancel = false;
        this.mostrar_modificar = false;
    }

    public msgError(msg: string) {
        this.msgErrorModificar = msg;
        setTimeout(() =>
        {
            this.msgErrorModificar = '';
        },
        2000);
    }

    public msgModificado(msg: string) {
        this.msgModificar = msg;
        setTimeout(() =>
        {
            this.msgModificar = '';
            this.mostrar_modificar = false;
            //this.seleccionarEmpleados();
            this.cargarFichajes();
        },
        1000);
    }

    difHoras(horaInicio: string, horaFin: string): number {
        // con tipos Date: var diferencia = datefin.getTime() - dateinicio.getTime(); // tiempo en milisegundos
        // num to string: num.toString();
        // string to num: +var

        if (horaInicio.length == 5) { horaInicio = horaInicio + ':00'; } // por si viene sin seg
        if (horaFin.length == 5) { horaFin = horaFin + ':00'; } // por si viene sin seg
        console.log('Diferencia de horas entre:');
        console.log(horaInicio);
        console.log(horaFin);
        let hora1 = horaFin.split(':');
        let hora2 = horaInicio.split(':');
        let t1 = new Date();
        let t2 = new Date();

        t1.setHours(+hora1[0], +hora1[1], +hora1[2]); // '+' convierte de string a integer
        t2.setHours(+hora2[0], +hora2[1], +hora2[2]);

        //Aquí hago la resta
        t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

        let horasDif = this.Round(t1.getHours() + ((t1.getMinutes()*60 + t1.getSeconds())/3600),2);
        return horasDif;
    }

    Round (numero, decimales = 2, usarComa = false): number {
        var opciones = {
            maximumFractionDigits: decimales,
            useGrouping: false
        };
        return +new Intl.NumberFormat((usarComa ? "es" : "en"), opciones).format(numero);
    }

    //Metodo que consigue la fecha actual del sistema
    fechaActual(){

        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Fecha adquirida");
                    this.fecha_actual = response['data'];
                    // this.filterFecha = response['data'];
                    // this.filterFechaHasta = response['data'];
                } else {
                    console.log("La fecha no se ha podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    fechaActualInicial(){

        this._horarioService.getDiaActual().subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Fecha adquirida");
                    this.fecha_actual = response['data'];
                    this.filterFecha = response['data'];
                    this.filterFechaHasta = response['data'];
                    this.cargarFichajes(); // hasta q no tenemos la fecha actual cargada, no pedimos los fichajes ( a veces daba error sino por tener la fecha undefined)
                } else {
                    console.log("La fecha no se ha podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }    

    //Metodo que guarda en un array los empleados que hayann fichado ese dia
    seleccionarEmpleados(){ // ya no se usa

        this._empleadoService.getEmpleadosFichaje_dia(this.filterFecha, this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log("Fichajes encontrados");
                    this.vec_fichajes = response['data'];
                    console.log('Fichajes cargados:');
                    console.log(this.vec_fichajes);
                } else {
                    console.log("Fichaje no encontrados");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    //Metodo que guarda en un vetor los empleados que hallan fichado ese dia
    async cargarFichajes(){

        let fichajeBusqueda = new FichajeBusqueda(null, null, null, null, null, null, null, null, null, null);
        fichajeBusqueda.codigo_empresa = this.codigo_empresa;
        fichajeBusqueda.code = this.filterUsuario;
        fichajeBusqueda.fecha = this.filterFecha;
        fichajeBusqueda.fechaHasta = this.filterFechaHasta;
        fichajeBusqueda.nombre = this.filterNombre;
        fichajeBusqueda.departamento = this.filterDepartamento;
        fichajeBusqueda.incidencias_salida;
        fichajeBusqueda.incidencias;

        console.log('Buscar fichajes: ');
        console.log(fichajeBusqueda);

        this.loading = true;
        this.msgLista = '';
        this.vec_fichajes = null;
        await this.getEmpleado_fichaje(fichajeBusqueda)

    }
    
    getEmpleado_fichaje(fichajeBusqueda){
        return new Promise<void>((resolve,reject)=>{
            this._empleadoService.getEmpleadosFichajes(fichajeBusqueda).subscribe(
                response => {
                    console.log(response);
                    if (response['code'] == 200) {
                        console.log("Fichajes encontrados");
                        this.vec_fichajes = response['data'];
                        console.log('Fichajes cargados:');
                        console.log(this.vec_fichajes);
                        if (this.vec_fichajes.length == 0) {
                            this.msgLista = 'No se han encontrado datos';
                        } else {
                            // calculamos el total de horas del periodo seleccionado
                            this.suma_total_horas = 0;
                            for(let j=0;j<this.vec_fichajes.length;j++) {
                                this.suma_total_horas += +this.vec_fichajes[j].total_horas;
                            }
                            this.suma_total_horas = this.Round(this.suma_total_horas, 2);
                        }
                        this.loading = false;
                    } else {
                        this.loading = false;
                        console.log("Ha sucedido un error");
                        // this.msgLista = response['message'];
                        this.msgLista = 'Se ha producido un error';
                    }
                    resolve()
                }, error => {
                    this.loading = false;
                    console.log('Ha sucedido un error:');
                    console.log(<any>error);
                    // this.msgLista = error;
                    this.msgLista = 'Se ha producido un error';
                    reject()
                }
            );
        })
    }

    geolocalizacion_entrada(id, fecha, entrada, salida, code, nombre, apellidos, latitud, longitud) {
        this.zoom = 14;
        this.lat = parseFloat(latitud);
        this.lng = parseFloat(longitud);
        this.AgmMap.triggerResize();
        this.url_foto_id = id;
        this.url_foto_es = 'E';
        if (code === GLOBAL.code) { // solo puede eliminarla el propio usuario
            this.mostrar_eliminar = true;
        } else {
            this.mostrar_eliminar = false;
        }
        this.url_foto_titulo = "FICHAJE DE ENTRADA";
        this.url_foto_cabecera = nombre + ' ' + apellidos;
        this.url_foto_pie = fecha.substr(8,2) + '/' + fecha.substr(5,2) + '/' + fecha.substr(0,4) + ' - ' + entrada;
    }

    geolocalizacion_salida(id, fecha, entrada, salida, code, nombre, apellidos, latitud, longitud) {
        this.zoom = 14;
        this.lat = parseFloat(latitud);
        this.lng = parseFloat(longitud);
        this.AgmMap.triggerResize();
        this.url_foto_id = id;
        this.url_foto_es = 'S';
        if (code === GLOBAL.code) { // solo puede eliminarla el propio usuario
            this.mostrar_eliminar = true;
        } else {
            this.mostrar_eliminar = false;
        }
        this.url_foto_titulo = "FICHAJE DE SALIDA";
        this.url_foto_cabecera = nombre + ' ' + apellidos;
        this.url_foto_pie = fecha.substr(8,2) + '/' + fecha.substr(5,2) + '/' + fecha.substr(0,4) + ' - ' + salida;
    }

    ver_foto_fichaje_entrada(id, foto, code, nombre, apellidos) {

        this.url_foto_id = id;
        this.url_foto_es = 'E';
        this.url_foto_fichero = foto;
        if (code === GLOBAL.code) { // solo puede eliminarla el propio usuario
            this.mostrar_eliminar = true;
        } else {
            this.mostrar_eliminar = false;
        }
        this.url_foto_titulo = "FICHAJE DE ENTRADA";
        this.url_foto_cabecera = nombre + ' ' + apellidos;
        this.url_foto_pie = foto.substr(6,2) + '/' + foto.substr(4,2) + '/' + foto.substr(0,4) + ' - ' + foto.substr(9,2) + ':' + foto.substr(11,2) + ':' + foto.substr(13,2);

        // actualmente, la foto ya no la traigo por URL (carpeta protegida con .htaccess) la traemos por la API
        // this.url_foto = this.global_url_fotos_fichajes + '/' +  foto.substr(0,4) + '/' + foto.substr(4,2) + '/' + foto.substr(6,2) + '/' + foto;
        // la foto, por la API, no por URL:
        this.getFotoFichaje(this.url_foto_id, this.url_foto_es);
    }

    ver_foto_fichaje_salida(id, foto, code, nombre, apellidos) {

        this.url_foto_id = id;
        this.url_foto_es = 'S';
        this.url_foto_fichero = foto;
        if (code === GLOBAL.code) { // solo puede eliminarla el propio usuario
            this.mostrar_eliminar = true;
        } else {
            this.mostrar_eliminar = false;
        }
        this.url_foto_titulo = "FICHAJE DE SALIDA";
        this.url_foto_cabecera = nombre + ' ' + apellidos;
        this.url_foto_pie = foto.substr(6,2) + '/' + foto.substr(4,2) + '/' + foto.substr(0,4) + ' - ' + foto.substr(9,2) + ':' + foto.substr(11,2) + ':' + foto.substr(13,2);

        // actualmente, la foto ya no la traigo por URL (carpeta protegida con .htaccess) la traemos por la API
        // this.url_foto = this.global_url_fotos_fichajes + '/' +  foto.substr(0,4) + '/' + foto.substr(4,2) + '/' + foto.substr(6,2) + '/' + foto;
        // la foto, por la API, no por URL:
        this.getFotoFichaje(this.url_foto_id, this.url_foto_es); // la foto, por la API, no por URL
    }

    getFotoFichaje(idHorario, es) {
        this.photoLoaded = null;
        this._horarioService.getFotoFichaje(idHorario, es).subscribe(
            response => {
                // console.log(response);
                if (response['code'] == 200){
                    let img = 'data:image/jpeg;base64,' + response['data'];
                    this.photoLoaded = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
                    // console.log('this.photoLoaded: ' + this.photoLoaded);
                    console.log('La foto se ha cargado correctamente');
                } else {
                    console.log('La foto no se ha podido cargar');
                    // muestro imagen alternativa en lugar de toast
                    // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
                }

            }, error => {
                console.log('Ha sucedido un error');
                console.log(error as any);
                // muestro imagen alternativa en lugar de toast
                // this.toastr.error('Error, La foto no se ha cargado', 'Cargar foto');
            }
        );
    }

    //Metodo que elimina un fichaje
    eliminarFichaje(empleado) {
        console.log("Llamando metodo eliminarFichaje");
        console.log('eliminar horario: ' + empleado.id);
        //Eliminamos fichaje
        this._horarioService.deleteHorario_id(empleado.id).subscribe(
            response => {
                debugger
                if (response['code'] == 200){
                    console.log("Fichaje eliminado con exito");
                    this.vec_fichajes = response['data'];

                    //Recargamos la vista
                    this.cargarFichajes();

                } else {
                    if (response["messageModificacion"]) {
                        this.toastr.warning( response["messageModificacion"])
                    }
                    console.log("El fichaje no se ha podido eliminar");
                }
            }, error => {
                debugger
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

    cargarPDF(tituloInforme: string) {
        console.log('Filtros:');
        console.log('code: ' + this.filterUsuario);
        console.log('nombre: ' + this.filterNombre);
        console.log('departamento: ' + this.filterDepartamento);
        console.log('fecha_desde: ' + this.filterFecha);
        console.log('fecha_hasta: ' + this.filterFechaHasta);
        // console.log('horas_contrato: ' + this.filterHoras_contrato);
        this.cargandoPDF = true;

        let fichajeBusqueda = new FichajeBusqueda(null, null, null, null, null, null, null, null, null,null);
        fichajeBusqueda.codigo_empresa = this.codigo_empresa;
        fichajeBusqueda.code = this.filterUsuario;
        fichajeBusqueda.nombre = this.filterNombre;
        fichajeBusqueda.departamento = this.filterDepartamento;
        fichajeBusqueda.fecha = this.filterFecha;
        fichajeBusqueda.fechaHasta = this.filterFechaHasta;
        // fichajeBusqueda.horas_contrato = this.filterHoras_contrato;

        // fichajeBusqueda.incidencias_salida;
        // fichajeBusqueda.incidencias;

        console.log('Buscar fichajes: ');
        console.log('codigo_empresa: ' + this.codigo_empresa);
        console.log('tituloInforme: ' + tituloInforme);
        console.log('Resumen: ' + this.informe_fichajes_resumen);
        console.log('Detallado: ' + this.informe_fichajes_detallado);
        console.log('logo: ' + GLOBAL.logo);
        console.log(fichajeBusqueda);

        this._reportesService.createReportFichajes(this.codigo_empresa, fichajeBusqueda, tituloInforme, GLOBAL.logo, this.informe_fichajes_detallado, this.informe_fichajes_resumen).subscribe(
            response => {
                //console.log('Respuesta informe:');
                //console.log(response);
                if (response['code'] == 200){
                    console.log("Informe cargado");
                    // console.log(response['data']);
                    // console.log(atob(response['data'])); // viene en base64
                    this.toastr.success('Compruebe si tiene los Poupus bloqueados', 'Informe PDF cargado.');
                    let fblob = this.b64PDFtoBlob(response['data']);
                    // mostramos embebido: no va
                    // this.content_informe = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(fblob));
                    // mostramos en nueva ventana
                    this.mostrarBLOB(fblob, tituloInforme);
                    this.cargandoPDF = false;

                } else {
                    console.log("Informe no creado");
                    this.toastr.error('Se ha producido un error', 'Cargar Informe PDF');
                    this.cargandoPDF = false;
                }

            }, error => {
                this.toastr.error('Se ha producido un error', 'Cargar Informe PDF');
                console.log('Ha sucedido un error');
                console.log(<any>error);
                this.cargandoPDF = false;
            }
        );

    }

    mostrarBLOB(blob, tituloInforme) {

        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { // Safari & Opera iOS
            //window.location.href = URL.createObjectURL(blob);
            window.open(URL.createObjectURL(blob), '_blank');
        } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE 11+
            window.navigator.msSaveOrOpenBlob(blob, tituloInforme + '.pdf');
        } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
            alert("Cannot display on FF iOS");
        } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
            var reader = new FileReader();
            reader.onloadend = function () { window.open(<string>reader.result);};
            reader.readAsDataURL(blob);
        } else {
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl, '_blank');
        }

    }

    b64PDFtoBlob(dataURI) {
        // var byteString = atob(dataURI.split(',')[1]);
        var byteString = atob(dataURI);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // return new Blob([ab], { type: 'image/jpeg' });
        return new Blob([ab], { type: 'application/pdf' });
    }

}
