export class CalendariosLaborales {

    public id: number;
    public codigo_empresa: string;
    public titulo: string;

    //Constructor por parametros
    constructor(id: number, codigo_empresa: string, titulo: string) {
        this.id = id;
        this.codigo_empresa = codigo_empresa;
        this.titulo = titulo;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getTitulo(){
        return this.titulo;
    }

    setTitulo(titulo: string){
        this.titulo = titulo;
    }

}