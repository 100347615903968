export class Style{

    public id: number;
    public codigo_empresa: string;
    public general:string;
    public cabecera: string;
    public icono: string;
    public pie: string;
    public fondo: string;

    constructor(id: number, /*codigo_empresa: string,*/ general: string, cabecera: string, pie:string, fondo:string, icono: string){
        this.id = id;
        //this.codigo_empresa = codigo_empresa;
        this.general = general;
        this.cabecera = cabecera;
        this.icono = icono;
        this.pie = pie;
        this.fondo = fondo;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getGeneral(){
        return this.general;
    }

    setGeneral(general: string){
        this.general = general;
    }

    getCabecera(){
        return this.cabecera;
    }

    setCabecera(cabecera: string){
        this.cabecera = cabecera;
    }

    getPie(){
        return this.cabecera;
    }

    setPie(cabecera: string){
        this.cabecera = cabecera;
    }

    getFondo(){
        return this.cabecera;
    }

    setFondo(cabecera: string){
        this.cabecera = cabecera;
    }
    
    getIcono(){
        return this.icono;
    }

    setIcono(icono: string){
        this.icono = icono;
    }

}    