import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Componentes
import { AppComponent } from './app.component';
import { RegistroComponent } from './componentes/registro/registro.component';
import { EntradaComponent } from './componentes/entrada/entrada.component';
import { SalidaComponent } from './componentes/salida/salida.component';
import { ErrorComponent } from './componentes/error/error.component';
import { CorrectComponent } from './componentes/correct/correct.component';
import { EmpleadosComponent } from './componentes/empleados/empleados.component';
import { EmpresasComponent } from './componentes/empresas/empresas.component';
import { VacacionesComponent } from './componentes/vacaciones/vacaciones.component';
import { BajasComponent } from './componentes/bajas/bajas.component';
import { AusenciasComponent } from './componentes/ausencias/ausencias.component';
import { EmpleadoDetailComponent } from './componentes/empleado-detail/empleado-detail.component';
import { InformesComponent } from './componentes/informes/informes.component';
import { FichajediaComponent } from './componentes/fichajedia/fichajedia.component';
import { CalendariosComponent } from './componentes/calendarios/calendarios.component';
import { CreaempleadosComponent } from './componentes/creaempleados/creaempleados.component';
import { ConfiguracionComponent } from './componentes/configuracion/configuracion.component';
import { ConfigEmpresaComponent } from './componentes/config-empresa/config-empresa.component';
import { AvisosComponent } from './componentes/avisos/avisos.component';
import { DocumentosComponent } from './componentes/documentos/documentos.component';
import { EmpleadoModificarComponent } from './componentes/empleado-modificar/empleado-modificar.component';
import { DepartamentoComponent } from './componentes/departamentos/departamento.component';

// servicios
import { IdentityGuard } from './services/identity.guard.service';
import { LogComponent } from './componentes/log/log.component';
import { SolicitudesComponent } from './componentes/solicitudes/solicitudes.component';

const appRoutes: Routes = [
    {path: '', component: AppComponent},
    // {path: '', redirectTo: "/login", pathMatch: "full"},
    {path: 'logout/:sure', component: AppComponent}, // para cerrar la sesion
    // {path: 'login', component: AppComponent},
    {path: 'registro', component: RegistroComponent, canActivate: [IdentityGuard]},
    {path: 'entrada', component: EntradaComponent, canActivate: [IdentityGuard]},
    {path: 'salida', component: SalidaComponent, canActivate: [IdentityGuard]},
    {path: 'correct/:msg/:msg2', component: CorrectComponent, canActivate: [IdentityGuard]},
    {path: 'correct', component: CorrectComponent, canActivate: [IdentityGuard]},
    {path: 'empleados/:codigo_empresa', component: EmpleadosComponent, canActivate: [IdentityGuard]},
    {path: 'empleado-detail/:code/:codigo_empresa', component:  EmpleadoDetailComponent},
    {path: 'empleado-modificar/:code/:codigo_empresa', component:  EmpleadoModificarComponent},
    {path: 'empresas/:codigo_empresa', component:  EmpresasComponent, canActivate: [IdentityGuard]},
    {path: 'vacaciones/:codigo_empresa', component:  VacacionesComponent, canActivate: [IdentityGuard]},
    {path: 'bajas/:codigo_empresa', component:  BajasComponent, canActivate: [IdentityGuard]},
    {path: 'ausencias/:codigo_empresa', component:  AusenciasComponent, canActivate: [IdentityGuard]},
    {path: 'informes/:codigo_empresa', component:  InformesComponent, canActivate: [IdentityGuard]},
    {path: 'fichajedia/:codigo_empresa/:code/:fecha/:disabledCode/:origen', component:  FichajediaComponent, canActivate: [IdentityGuard]},
    {path: 'fichajedia/:codigo_empresa', component:  FichajediaComponent, canActivate: [IdentityGuard]},
    {path: 'creaempleados/:codigo_empresa', component:  CreaempleadosComponent, canActivate: [IdentityGuard]},
    {path: 'calendarios/:codigo_empresa', component:  CalendariosComponent, canActivate: [IdentityGuard]},
    {path: 'configuracion/:codigo_empresa', component:  ConfiguracionComponent, canActivate: [IdentityGuard]},
    {path: 'config-empresa/:codigo_empresa', component:  ConfigEmpresaComponent, canActivate: [IdentityGuard]},
    {path: 'avisos/:codigo_empresa', component:  AvisosComponent, canActivate: [IdentityGuard]},
    {path: 'documentos/:codigo_empresa', component:  DocumentosComponent, canActivate: [IdentityGuard]},
    {path: 'departamento/:codigo_empresa', component:  DepartamentoComponent, canActivate: [IdentityGuard]},
    {path: 'log/:codigo_empresa', component:  LogComponent, canActivate: [IdentityGuard]},
    {path: 'solicitudes/:codigo_empresa', component:  SolicitudesComponent, canActivate: [IdentityGuard]},
    // {path: '**', redirectTo: ''},
    {path: '**', component: ErrorComponent} // La ruta ** siempre debe de ser la ultima
];

/*
const appRoutes: Routes = [
    {path: '', component: AppComponent},
    // {path: '', redirectTo: "/login", pathMatch: "full"},
    {path: 'logout/:sure', component: AppComponent}, // para cerrar la sesion
    // {path: 'login', component: AppComponent},
    {path: 'registro', component: RegistroComponent},
    {path: 'entrada', component: EntradaComponent},
    {path: 'salida', component: SalidaComponent},
    {path: 'correct', component: CorrectComponent},
    {path: 'empleados/:codigo_empresa', component: EmpleadosComponent},
    {path: 'empleado-detail/:code/:codigo_empresa', component:  EmpleadoDetailComponent},
    {path: 'empleado-modificar/:code/:codigo_empresa', component:  EmpleadoModificarComponent},
    {path: 'informes/:codigo_empresa', component:  InformesComponent},
    {path: 'fichajedia/:codigo_empresa', component:  FichajediaComponent},
    {path: 'creaempleados/:codigo_empresa', component:  CreaempleadosComponent},
    {path: 'configuracion/:codigo_empresa', component:  ConfiguracionComponent},
    {path: 'avisos/:codigo_empresa', component:  AvisosComponent},
    {path: 'documentos/:codigo_empresa', component:  DocumentosComponent},
    {path: 'departamento/:codigo_empresa', component:  DepartamentoComponent},
    {path: 'error', component:  ErrorComponent},
    // {path: '**', redirectTo: ''},
    {path: '**', component: ErrorComponent} // La ruta ** siempre debe de ser la ultima
];
*/

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
