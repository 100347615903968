export var GLOBAL = {
    //control.bsw.es
    // compilar con ng build
    version: '2021.03.18.01', // si se actualiza, debemos actualizarlo en la tabla 'config' para avisar en el login que está cacheado y no tenemos la última versión cargada
    host: '', // -se actualiza en appcomponent- para saber si estamos en pruebas (control.bsw.es ó localhost) o produccion (control.bejornada.es) por ejemplo, para el envio de emails de la api (empleado.service: sendemail)
    codigo_empresa: null,
    nombre_empresa: null,
    fecha_renovacion: null,
    licencias_superadas: false, // por si reducen el numero de licencias en la renovación, limitar ciertas cosas
    empresa_demo: false,
    periodo_gracia: false, // para limitar funcionalidades a parte del modo demo. por ejemplo, no fichar q en modo demo sí se puede
    logo: null,
    code: null,
    nombre: null,
    apellidos: null,
    esAdmin: false, // administradores de la empresa
    esAdminSYSTEM: false, // administradores de la empresa 0000. Para crear empresas y editar datos de otras empresas (renovaciones y licencias)
    rol: null,
    superadmin: false, // administrador principal de la empresa (los otros administradores no pueden modificar sus datos). Sólo editable en la BD directamente
    superadminSYSTEM: false, // administrador principal de la empresa 0000. 
    deVacaciones: false,
    deBaja: false,
    deAusencias: false,
    config_empresa_enable_camera: ['0'], // valor que se carga despues desde la tabla 'config', seccion:'GENERAL', clave:'CAMARA'
    config_empresa_enable_localizacion: ['0'], // valor que se carga despues desde la tabla 'config', seccion:'GENERAL', clave:'LOCALIZACION'
    config_correccion_por_empleado: ['0'], // valor que se carga despues desde la tabla 'config', seccion:'GENERAL', clave:'CORRECCION_POR_EMPLEADO'
    opcion_hacer_solicitudes: false, // opcion que le permite al empleado hacer solicitudes de vacaciones con el rol empleado
    config_version: ['0'], // parametro comun a todas las empresas (con codigo_empresa = null)

    /* desarrollo malaga -acceso desde intranet */
    // url: 'http://192.168.1.41/control.bejornada.es/api/index.php',
    // url_uploads: 'http://192.168.1.41/control.bejornada.es/api/uploads',

    /* desarrollo malaga */
    // url: 'http://localhost/control.bejornada.es/api/index.php',
    // url_uploads: 'http://localhost/control.bejornada.es/api/uploads',

    /* pre-produccion pruebas en control.bsw.es */
    url: 'https://control.bsw.es/api/index.php',
    url_uploads: 'https://control.bsw.es/api/uploads',

    // Subida de archivos
    // http://localhost/control.bejornada.es/api/uploads/codigo_empresa/xxx
    // http://control.bsw.es/api/uploads/codigo_empresa/xxx
    url_documentos: 'documentos',
    url_logos: 'logos',
    url_fotos_empleados: 'fotos_empleados',
    url_fotos_fichajes: 'fotos_fichajes'

};
