
/********************************* */
/* ESTE CODIGO ESTA COGIDO DE VACACIONES Y NO ESTÁ TERMINADO,
   FALTAN LOS FILTROS, OCULTOS MIENTRAS SE TERMINA
   POR ESO NO ESTÁ LIMPIO DE TODO LO QUE SOBRA DE VACACIONES */
/********************************* */

import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DepartamentosService } from '../../services/departamentos.service';
import { Departamentos } from '../../models/departamentos';
import { Usuario } from '../../models/usuario';
import { EmpleadoService } from '../../services/empleado.service';
import { UsuarioService } from '../../services/usuario.service';
import { HorarioService } from '../../services/horario.service';
import { Horario } from 'src/app/models/horario';
import { FichajeDia } from 'src/app/models/fichaje-dia';
import { Empleado } from 'src/app/models/empleado';
import { Vacaciones } from 'src/app/models/vacaciones';
import { Empresa } from 'src/app/models/empresa';
import { EmpresaService } from '../../services/empresa.service';
import { EmpleadosVacaciones } from '../../models/empleados_vacaciones';
import { VacacionesService } from '../../services/vacaciones.service';
import { GLOBAL } from '../../services/global.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css', '../../../style.scss'],
  providers : [EmpleadoService, UsuarioService, HorarioService, DepartamentosService, VacacionesService,DatePipe],
  animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          ]),
          transition(':leave', [
            style({transform: 'translateX(0)', opacity: 1}),
            animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
          ])
        ]
      )
  ],
})

export class EmpresasComponent implements OnInit{

  public titulo: string;
    public mostrar_filtro: boolean;
    public filtro: number;
    public fichajeUpdate: FichajeDia;
    public fichajeInsert: Horario;
    // public fecha: Date;
    public codigo_empresa: string;
    public globaladmin;
    public mostrar_modificar: boolean;

    public lista_departamentos: Array<Departamentos>;
    public lista_usuarios: Array<Usuario>;
    public fecha_actual;

    public identidad: string;

    public seleccionada: string;


    //Variables de busqueda y parametro para la llamada desde el menu admin->avisos
    public filterCode = '';
    public filterUsuario = '';
    public filterNombre = '';
    public filterDepartamento = '';
    public filterFecha: any;
    public filterFechaHasta: any;
    public filterHoras_contrato;

    //Variables de modificacion
    public idModificar: number;
    public modificarInicio_vacaciones_antes: any;
    public modificarFin_vacaciones_antes: any;
    public modificarInicio_vacaciones: any;
    public modificarFin_vacaciones: any;
    public modificarFecha: Date;
    public codeModificar: string;
    public nombreModificar: string;
    public apellidosModificar: string;
    public msgErrorModificar: string;
    public msgModificar: string;
    public saveORcancel: boolean = false;
    public confirmado;
    public disabledCode: boolean = false;
    public fentrada:Date;
    public fsalida:Date;
    public empleado:Empleado;

    public vacaciones: Vacaciones;
    public vec_Vacaciones: Array<EmpleadosVacaciones>;

    public empresa: Empresa;
    public empresas: Array<Empresa>;

    public inicio_vac_sel: string;
    public fin_vac_sel: string;
    public code_seleccionado:string;
    public modificar: boolean = false;
    public item;
    

    public loading: boolean = false; // cargando lista..
    public cargandoPDF: boolean;
    public msgLista: string = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        protected toastr: ToastrService,
        private _empleadoService: EmpleadoService,
        private _empresaService: EmpresaService,
        private _usuarioService: UsuarioService,
        private _departamentosService: DepartamentosService,
        private _vacacionesService: VacacionesService, private _date: DatePipe){
        this.titulo = "Página de Vacaciones"
        this.vec_Vacaciones = [];
        this.fichajeUpdate = new FichajeDia(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
        this.mostrar_modificar = false;
        this.mostrar_filtro = true;
        this.filtro = 0;
        this.modificar = false;
        this.lista_departamentos = null;
        this.lista_usuarios = null;
        
        this.vacaciones = new Vacaciones(0, null, null, null, null, null);

        this.empresa = new Empresa('', '', '', 5, null, true);
        this.empresas = [];

        this.confirmado = null;
        this.disabledCode = false;
        this.filterFecha = '';
        this.filterFechaHasta = '';
        this.globaladmin = GLOBAL.esAdmin;
        this.code_seleccionado = '';
        this.filterHoras_contrato = 0;
        this.inicio_vac_sel = '';
        this.item = JSON.parse(localStorage.getItem('identity'));
        this.codeModificar = '';
    }

    ngOnInit(){
      this.list_usuarios();
      this.list_departamentos();
      this.getEmpresas();
      this.globaladmin;
  }

  getEmpresas() {

    this._empresaService.getEmpresas().subscribe(
        response => {
            if (response['code'] == 200){

                this.empresas = response['data'];
                console.log("Empresas encontradas: ");
                console.log(this.empresas);

            } else {
                console.log("Empresas no encontradas");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

  }

  active_modificar(){ // al crear empresa
    this.modificar = false;
    this.filtro = 0;
    if (this.mostrar_modificar == false) {
        this.mostrar_modificar = true;
        this.code_seleccionado = '';
        this.disabledCode = false;
        scrollTo(0, 0);
    } else {
        this.mostrar_modificar = false;
    }

  }

  //Metodo que se trae todos los departamentos de la base de datos
  list_departamentos(){
    console.log('Buscar departamentos de la empresa: ' + this.codigo_empresa);
    this._departamentosService.getDepartamentos(this.codigo_empresa).subscribe(
        response => {
            console.log(response);
            if (response['code'] == 200){
                console.log('Departamentos conseguidos con exito')
                this.lista_departamentos = response['data'];
                console.log(this.lista_departamentos);

            } else {
                console.log("Los departamentos no se han podido conseguir");
            }
        }, error => {
            console.log('Ha sucedido un error');
            console.log(<any>error);
        }
    );

  }

  //Metodo que se trae todos los usuarios de la base de datos
  list_usuarios(){

    // si no es admin, no hace falta, asi evito q en el navegador se pueda ver el array de los datos de los demás usuarios, hay q tener cuidado con esto !!!
    if ( GLOBAL.rol !== "admin" ) {

        let user = new Usuario(null, GLOBAL.code, this.codigo_empresa, GLOBAL.rol);
        this.lista_usuarios = [{}] as Usuario[]; // vaciamos el array preservando el tipo
        this.lista_usuarios.push(user);

    } else {

        console.log('Buscar usuarios de la empresa: ' + this.codigo_empresa);
        this._usuarioService.getUsuarios(this.codigo_empresa).subscribe(
            response => {
                if (response['code'] == 200){
                    console.log(response);
                    console.log('Usuarios conseguidos con exito')
                    this.lista_usuarios = response['data'];
                    console.log(this.lista_usuarios);

                } else {
                    console.log("Los usuarios no se han podido conseguir");
                }
            }, error => {
                console.log('Ha sucedido un error');
                console.log(<any>error);
            }
        );

    }

  }


  activeFiltrar(index){
    if(index == 2) { // crear
        // this.modificar = false;
        this.empresa = new Empresa('', '', '', 5, null, true);
        this.empresa.password = this.randomString(); // realmente es para asignarsela al usuario admin q se va a crear
        this.code_seleccionado = '';
        console.log(this.empresa);
        this.filtro = 2;
    } else if (index == 1) { // filtros de busqueda
        this.mostrar_modificar = false;
        if (this.filtro == 1) {
            this.filtro = 0; // ya se veía el filtro, lo oculto....
        } else {
            this.filtro = 1;
        }
        // console.log(this.filtro + ' ' + index);
    } else if(index == 0) {
        this.idModificar == 0;
        this.filtro = 0;
    }
    else {
        // console.log(this.filtro + ' ' + index);
    }
    /*if (this.mostrar_filtro == true){
        this.mostrar_filtro = false;
        this.filtro = index;
    } else {
        this.mostrar_filtro = true;
        this.filtro = 0;
    }*/
  }


  active() {
  
      if(this.filtro == 0) {
          this.filtro = 0;
      }
  }

  cancelarModificar(){
    this.mostrar_modificar = false;
  }

  CrearModificarVacaciones(){
    if(this.code_seleccionado != null){
      //Si me llega un codigo
      if(this.fin_vac_sel == '9999-12-31'){
        this.fin_vac_sel = '';
      }
      this.modificarInicio_vacaciones = this.inicio_vac_sel;
      this.modificarFin_vacaciones = this.fin_vac_sel;
      this.codeModificar = this.code_seleccionado;
      this.mostrar_modificar = true;
    }
    this.code_seleccionado = '';
  }

  /*
  delete(code: number, user: string, inicio_vacaciones: string, fin_vacaciones: string){
    this._vacacionesService.deleteVacaciones(code,user,inicio_vacaciones,fin_vacaciones).subscribe(
      response => {
        console.log(response);
        if (response['code'] == 200){
            console.log("Eliminado con exito");
            this.toastr.success('Vacaciones eliminadas correctamente.', 'Eliminar vacaciones');
            this.controlVacaciones(); // actualizo el estado de las vacaciones de los empleados
        } else {
            this.toastr.error('Se ha producido un error.', 'Eliminar vacaciones');
            console.log("No se ha podido eliminar vacaciones");
        }
        this.getVacaciones();
      }, error => {
          this.toastr.error('Se ha producido un error.', 'Eliminar vacaciones');
          console.log('Ha sucedido un error');
          console.log(<any>error);
      }
    );
  }
  */

  deleteEmpresa(codigo:string) {
    console.log('DELETE empresa: ' + codigo);
    this.toastr.warning('Funcionalidad no disponible.', 'Eliminar empresa');
  }

  /*
  modificarVacaciones(code: string,inicio_vacaciones: string,fin_vacaciones: string, nombre:string, apellidos: string){
    console.log(inicio_vacaciones+ ' '+ fin_vacaciones);
    this.modificar = true;
    this.code_seleccionado = code;
    this.nombreModificar = nombre;
    this.apellidosModificar = apellidos;
    this.inicio_vac_sel = inicio_vacaciones;
    this.modificarInicio_vacaciones_antes = inicio_vacaciones;
    this.modificarFin_vacaciones_antes = fin_vacaciones;
    this.fin_vac_sel = fin_vacaciones;
    this.CrearModificarVacaciones();
  }
  */

 gotoTop() {
  // let el = document.getElementById('arriba');
  // console.log(el);
  // el.scrollTop = el.scrollHeight;

  // location.hash = '#arriba';
  // window.scrollTo(0, 0);
  scrollTo(0, 0); // ESTE SI VA !!!!
  }

  updateEmpresa(codigo: string) {
    this.gotoTop();
    console.log('UPDATE empresa: ' + codigo);
    this.modificar = true;
    this.empresa = new Empresa('', '', '', 5, null, true);
    this.code_seleccionado = '';
    // this.empresa =  this.empresas.find(x => x.codigo_empresa == codigo);
    // clono el objeto. si no, al cambiar el valor se cambia en la lista
    this.empresa = Object.assign({}, this.empresas.find(x => x.codigo_empresa === codigo))
    console.log(this.empresa);
    this.code_seleccionado = this.empresa.codigo_empresa;
    console.log(this.code_seleccionado);
    if (this.code_seleccionado !== ''){
      if (this.empresa.activa == '1') { this.empresa.activa = true; } else { this.empresa.activa = false; }
      this.mostrar_modificar = true;
    }
  }

  cambiarBoolean(indice: number, estado: boolean){
    console.log('Funcion: ' + indice + ' -- ' + estado);
    // tslint:disable-next-line: triple-equals
    if ( indice == 1 ) {
        this.empresa.activa = estado;
    }
  }

  public msgError(msg: string) {
    this.msgErrorModificar = msg;
    setTimeout(() =>
    {
        this.msgErrorModificar = '';
    },
    2000);
  }
  
  public msgModificado(msg: string) {
      this.msgModificar = msg;
      setTimeout(() =>
      {
          this.msgModificar = '';
          this.mostrar_modificar = false;
          this.getEmpresas();
      },
      2000);
  }


  nullerValues(index){

    console.log("Llamando metodo nullerValues");

    if (index == 1) {
        if (!this.disabledCode) { // si tenemos code bloqueado, no permitimos quitar el filtro ( para correcciones desde el usuario, no desde el admin)
            this.filterCode = '';
        }
    }
    else if (index == 2) {
        this.filterFecha = '';
        
    }
    else if (index == 3) {
        this.filterFechaHasta = '';
        
    }
    else if (index == 4) {
        this.filterNombre = '';
        
    }
    else if (index == 5) {
        this.filterDepartamento = '';
        
    }
    else if (index == 6) {
      this.filterHoras_contrato = 0;
      
  }

  }

  randomString() {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 15;
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
    }
    // console.log('randomstring: ');
    // console.log(randomstring);
    return randomstring;
  }

  updateCode() {
    console.log('event (change) en updateCode')
    // para mandar el code del usuario a crear:
    // uso este campo que no necesita mandarse (la api lo saca del token por seguridad) puntualmente aqui para no crear otro nuevo
    this.empresa.created_by = 'admin_' + this.empresa.codigo_empresa.toLowerCase();

  }

  aplicarCambios() {
    console.log('this.modificar:');
    console.log(this.modificar);

    if (this.empresa.codigo_empresa == null || this.empresa.codigo_empresa == ''){
      this.toastr.error("Código no válido", "Aplicar cambios");
      return;
    } else if (this.empresa.nombre_empresa == null || this.empresa.nombre_empresa == ''){
      this.toastr.error("Nombre no válido", "Aplicar cambios");
      return;
    } else if (this.empresa.correo == null || this.empresa.correo == ''){
      this.toastr.error("Email no válido", "Aplicar cambios");
      return;
    } else if (!this.empresa.fecha_renovacion) {
      this.toastr.error("Fecha no válida", "Aplicar cambios");
      return;
    } else if (this.empresa.num_licencias < 5) {
      this.toastr.error("El nº de licencias mínimo es 5", "Aplicar cambios");
      return; 
    } else if (!this.modificar && (this.empresa.password == null || this.empresa.password == '')){
      // solo al crear, no al modificar
      this.toastr.error("Contraseña no válida", "Aplicar cambios");
      return;
    } else if (!this.modificar && (this.empresa.created_by == null || this.empresa.created_by == '')){
      // solo al crear, no al modificar
      this.toastr.error("Usuario no válido", "Aplicar cambios");
      return;
    } else {
      if (this.modificar){ //Se trata de un update
        console.log(this.empresa.activa);
        if (this.empresa.activa) { this.empresa.activa = '1';}
        else { this.empresa.activa = '0';}
        console.log('UPDATE empresa: ');
        console.log(this.empresa);

        this._empresaService.updateEmpresa(this.empresa).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  console.log("Modificado con exito");
                  this.toastr.success('Empresa modificada correctamente.', 'Modificar empresa');
              } else {
                  this.toastr.warning('Se ha producido un error.', 'Modificar empresa');
                  console.log("Error modificando la empresa.");
              }
              this.getEmpresas();
              this.mostrar_modificar = false;
          }, error => {
              this.toastr.error('Error modificando la empresa.', 'Modificar empresa');
              console.log('Ha sucedido un error');
              console.log(error as any);
          }
        );

      } else { // crear empresa
        if (this.empresa.activa) { this.empresa.activa = '1';}
        else { this.empresa.activa = '0';}

        // this.empresa.password = this.randomString(); // realmente es para asignarsela al usuario admin q se va a crear
        console.log('INSERT empresa:');
        console.log(this.empresa);

        // para mandar el code del usuario a crear:
        // uso este campo que no necesita mandarse (la api lo saca del token por seguridad) puntualmente aqui para no crear otro nuevo
        // this.empresa.created_by = 'admin_' + this.empresa.codigo_empresa.toLowerCase();

        this._empresaService.addEmpresa(this.empresa).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  this.sendEmailPassword();
                  console.log("Empresa creada con exito");
                  this.toastr.success('Empresa creada correctamente.', 'Crear empresa');
                  this.getEmpresas();
                  this.mostrar_modificar = false;
              } else {
                  this.toastr.warning(response['message'], 'Crear empresa');
                  console.log(response['message']);
              }
          }, error => {
              this.toastr.error('Error creando la empresa.', 'Crear empresa');
              console.log(error as any);
          }
        );

      }

    }

  }

  sendEmailPassword() {
    // this.empleado = new Empleado(this.empleado.id, this.empleado.code, this.empleado.codigo_empresa, this.empleado.password, this.empleado.DNI, this.empleado.nombre, this.empleado.apellidos, this.empleado.puesto, this.empleado.inicio_contrato, this.empleado.fin_contrato, this.empleado.foto, this.empleado.direccion, this.empleado.num_seg_social, this.empleado.codi_postal, this.empleado.provincia, this.empleado.localidad, this.empleado.email, this.empleado.telefono, this.empleado.persona_contacto_uno, this.empleado.movil_uno, this.empleado.persona_contacto_dos, this.empleado.movil_dos, this.empleado.num_cuenta, this.empleado.fecha_nacimiento, this.empleado.genero, this.empleado.dni_doble_cara, this.empleado.tarjeta_seg_social, this.empleado.documento_banco, this.empleado.normas_seguridad, this.empleado.pantalon_talla, this.empleado.camiseta_talla, this.empleado.zapato_talla, this.empleado.num_taquilla, this.empleado.entrega_epis, this.empleado.informacion_formacion, this.empleado.cuestionario, this.empleado.vigilancia_salud_rrrmm, this.empleado.manipulador_alimentos, this.empleado.carnet_carretillero, this.empleado.firma_trabajador, this.empleado.contrato, this.empleado.activo, this.empleado.baja, this.empleado.vacaciones, this.empleado.ausencia, this.empleado.horas_contrato, this.empleado.work_now, this.empleado.camara, this.empleado.localizacion, this.empleado.id_calendarios_trabajo, this.empleado.created_by, this.empleado.updated_by);
    this.empleado = new Empleado(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    
    this.empleado.code = this.empresa.created_by; // uso este campo que no necesita mandarse (la api lo saca del token por seguridad) puntualmente aqui para no crear otro nuevo
    this.empleado.email = this.empresa.correo;
    this.empleado.password = this.empresa.password;
    console.log('ENVIAR PASSWORD POR EMAIL A:');
    console.log(this.empleado);
    this._empleadoService.sendEmailPassword(this.empleado).subscribe(
    result => {
        console.log(result);
        if(result['code'] == 200) {
            console.log('Contraseña del usuario enviada por email');
            //console.log(result);
            // this.msgInfo = 'Contraseña del usuario actualizada y enviada por email';
            this.toastr.success('Email enviado al usuario admin creado.', 'Crear empresa');
        } else {
            // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
            this.toastr.warning('Error enviando el email al usuario admin creado.', 'Crear empresa');
            console.log('Error: ' + result['message']);
        }
    },
        error => {
            // this.msgError = 'No ha sido posible enviar la nueva contraseña al usuario';
            this.toastr.error('Error enviando el email al usuario admin creado.', 'Crear empresa');
            console.log(error as any);
        }
    );
  }

  /*
  aplicarVacaciones(){
    let persona = JSON.parse(localStorage.getItem('identity'))['code'];
    if(this.codeModificar == null || this.codeModificar == ''){
      this.toastr.error("Falta usuario", "Aplicar Vacaciones");
      return;
    }else if(this.modificarInicio_vacaciones == null || this.modificarInicio_vacaciones == ''){
      this.toastr.error("Falta fecha de inicio", "Aplicar Vacaciones");
      return;
    }else if(this.modificarFin_vacaciones < this.modificarInicio_vacaciones && (this.modificarFin_vacaciones != null && this.modificarFin_vacaciones != '')){
      this.toastr.error("Fechas incorrectas", "Aplicar Vacaciones");
      return;
    }else{
      if(this.modificar){ //Se trata de un update
      this.vacaciones.code = this.codeModificar;
      this.vacaciones.inicio_vacaciones = this.modificarInicio_vacaciones;
      this.vacaciones.fin_vacaciones = this.modificarFin_vacaciones;
      let code_upd = this.item['code'];
        //Insertar vacaciones en la base de datos
      this._vacacionesService.updateVacaciones(this.vacaciones,this.modificarInicio_vacaciones_antes, this.modificarFin_vacaciones_antes, persona).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  console.log("Modificado con exito");
                  this.toastr.success('Vacaciones modificadas correctamente.', 'Modificar vacaciones');                  
              } else {
                  this.toastr.error('Se ha producido un error.', 'Modificar vacaciones');
                  console.log("No se ha podido modificar vacaciones");
              }
              this.getEmpresas();
          }, error => {
              this.toastr.error('Se ha producido un error2.', 'Modificar vacaciones');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
      );
      
      this.mostrar_modificar = false;
    }else{ //Se trata de un insert
      
      let fdesde = new Date(this.modificarInicio_vacaciones);
      let fhasta = new Date(this.modificarFin_vacaciones);
      if(this.codeModificar == null || this.codeModificar == ''){
        this.toastr.error('Datos no válidos.', 'Aplicar vacaciones');
        return;
      }

      //Componer Vacaciones
      this.vacaciones.code = this.codeModificar;
      this.vacaciones.fin_vacaciones = this.modificarFin_vacaciones;
      this.vacaciones.inicio_vacaciones = this.modificarInicio_vacaciones;

      console.log('Añadir vacaciones:');
      console.log(this.vacaciones);
      //Insertar vacaciones en la base de datos
      this._vacacionesService.addVacaciones(this.vacaciones, persona).subscribe(
          response => {
              console.log(response);
              if (response['code'] == 200){
                  console.log("Insertado con exito");
                  this.toastr.success('Vacaciones aplicadas correctamente.', 'Aplicar vacaciones');
              } else {
                  this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
                  console.log("No se ha podido insertar vacaciones");
              }
              this.getEmpresas();
          }, error => {
              this.toastr.error('Se ha producido un error.', 'Aplicar vacaciones');
              console.log('Ha sucedido un error');
              console.log(<any>error);
          }
      );
      this.getEmpresas();
      this.mostrar_modificar = false;
    }}
    
  }
  */

}

