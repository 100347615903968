export class Documentos {

    public id: number;
    public code: string;
    public codigo_empresa: string;
    public nombre: string;
    public apellidos: string;
    public documento: string;
    public fecha: Date;
    public created_by: string;

    constructor(id: number, code: string, codigo_empresa: string, nombre: string, apellidos:string, documento: string, fecha: Date, created_by: string){
        this.id = id;
        this.code = code;
        this.codigo_empresa = codigo_empresa;
        this.nombre = nombre;
        this.apellidos = apellidos;
        this.documento = documento;
        this.fecha = fecha;
        this.created_by = created_by;
    }

    getId(){
        return this.id;
    }

    setId(id: number){
        this.id = id;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getCodigo_empresa(){
        return this.codigo_empresa;
    }

    setCodigo_empresa(codigo_empresa: string){
        this.codigo_empresa = codigo_empresa;
    }

    getNombre(){
        return this.nombre;
    }

    setNombre(nombre: string){
        this.nombre = nombre;
    }

    getApellidos(){
        return this.apellidos;
    }

    setApellidos(apellidos: string){
        this.apellidos = apellidos;
    }

    getDocumento(){
        return this.documento;
    }

    setDocuemnto(documento: string){
        this.documento = documento;
    }

    getFecha(){
        return this.fecha;
    }

    setFecha(fecha: Date){
        this.fecha = fecha;
    }

    getCreated_by(){
        return this.created_by;
    }

    setCreated_by(created_by: string){
        this.created_by = created_by;
    }

}