export class SolicitudVacaciones{

    public code: string;
    public codigo_empresa: string;
    public inicio_vacaciones: Date;
    public fin_vacaciones: Date;
    public observaciones: string;

    constructor(
        code: string, 
        inicio_vacaciones: Date,
        fin_vacaciones: Date
        ){

        this.code = code;
        this.inicio_vacaciones = inicio_vacaciones;
        this.fin_vacaciones = fin_vacaciones;
    }

    getCode(){
        return this.code;
    }

    setCode(code: string){
        this.code = code;
    }

    getInicio_vacaciones(){
        return this.inicio_vacaciones;
    }

    setInicio_vacaciones(inicio_vacaciones: Date){
        this.inicio_vacaciones = inicio_vacaciones;
    }

    getFin_vacaciones(){
        return this.fin_vacaciones;
    }

    setFin_vacaciones(fin_vacaciones: Date){
        this.fin_vacaciones = fin_vacaciones;
    }

}    